<ion-content>
  <img class="close-icon" tooltip="{{'Close' | translate }}" alt="" placement="bottom" (click)="closeModal()" src="assets/icon/cancel.svg">
  <div class="title" *ngIf="confirmationTitle">
    <span>{{ confirmationTitle | translate }}</span>
    <div class="button-container">
      <ion-button class="app-btn" shape="round" fill="outline" color="primary" (click)="confirm()">
        {{ actionText | translate }}
      </ion-button>
    </div>
  </div>
</ion-content>