const generateMenu = (type: string, firstOptionType, secondOptionType) => ({
    type: `${type}`, // Dynamic type based on the input
    items: [
      {
        title: 'Add Or Remove Columns',
        icon: 'images/Box.png',
        type: `${firstOptionType}`, // Dynamic type based on the input
        isEnabled: true,
      },
      {
        title: 'Export to Excel',
        icon: 'images/Excel.png',
        type: `${secondOptionType}`, // Dynamic type based on the input
        isEnabled: true,
      },
    ],
  });
const generateExportMenu = (firstOptionType) => ({
  items: [
    {
      title: 'Export to Excel',
      icon: 'images/Excel.png',
      type: `${firstOptionType}`, // Dynamic type based on the input
      isEnabled: true,
    },
  ],
});
export const popoverMenu: any = {
  addUser: {
    type: 'addUser',
    items: [
      {
        title: 'Assign/Edit User',
        icon: 'icon/Add User.svg',
        type: 'assign',
        isEnabled: true,
      },
      {
        title: 'Edit Task',
        icon: 'icon/Dashboard customise.svg',
        type: 'edit',
        isEnabled: true,
      },
      {
        title: 'Delete Task',
        icon: 'icon/Delete1.svg',
        type: 'delete',
        isEnabled: true,
      },
    ],
  },
  statusTaskManager: {
    type: 'ChangeStatusTaskManager',
    items: [
      {
        title: 'New',
        icon: '',
        type: 'Yet To Start',
        isEnabled: true,
      },
      {
        title: 'Assigned',
        icon: '',
        type: 'in-progress',
        isEnabled: true,
      },
      {
        title: 'Completed',
        icon: '',
        type: 'On Hold',
        isEnabled: true,
      },
      {
        title: 'Closed',
        icon: '',
        type: 'Resolved',
        isEnabled: true,
      },
    ],
  },
  orderManagement: {
    type: 'OrderManagement',
    items: [
      {
        title: 'Notify Via Email',
        icon: 'icon/outlook.svg',
        type: 'shareoutLook',
        isEnabled: true,
      },
      {
        title: 'Notify Via Teams',
        icon: 'icon/teams.svg',
        type: 'shareTeams',
        isEnabled: true,
      },
    ],
  },
  projectManagement: generateMenu(
    'pst_Menu',
    'projectTracker_add_remove',
    'exportProFile'
  ),
  offerManagement: generateMenu(
    'offer_Menu',
    'offerMan_add_remove',
    'exportOfferMan'
  ),
  taskManagement: generateMenu(
    'TaskMan_Menu',
    'taskManager_add_remove',
    'exportTaskFile'
  ),
  orderManagementMenu: generateMenu(
    'OrderMan_Menu',
    'orderMan_add_remove',
    'exportOrderMan'
  ),
  gridMenu : generateMenu(
    'grid_menu',
    'add_remove',
    'export'
  ),
  exportMenu: generateExportMenu(
    'export'
  )
};
