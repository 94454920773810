import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-machine-card',
  templateUrl: './machine-card.component.html',
  styleUrls: ['./machine-card.component.scss'],
})
export class MachineCardComponent implements OnInit {

  // deleteMachineCheckbox is used to pass the checked item from component to machine mgmt page
  @Output() deleteMachineCheckbox = new EventEmitter<string>();
  @Output() checkMore = new EventEmitter<string>();
  @Input() item: any;
  @Input() showStatus: boolean;
  @Input() showMore: boolean;
  @Input() showCheckbox: boolean;
  @Input() status: any;

  constructor() { }

  ngOnInit() {}

  // To prevent the propagation of current event i.e. checkbox check/uncheck on the card
  public stopPropagation(event) {
    event.stopPropagation();
  }

  // To pass the selected checkbox item to the parent component
  public changeMachineCheckboxSelection(item) {
    item.isChecked = !item.isChecked;
    this.deleteMachineCheckbox.emit(item);
  }

  // To pass the selected item to the parent component on clicking ellipsis icon
  public clickMore(ev) {
    this.checkMore.emit(ev);
  }

}
