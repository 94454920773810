import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { hazardConsequences, hazardSources, hazardTypes } from '../../../../modals/hazard-data';
import { Observable, forkJoin, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { LanguageSupportService } from 'src/app/services/language-support.service';
import { ShareValuesService } from 'src/app/services/sharedValues/share-values.service';
import { isNotEmptyArray } from 'src/app/utilities/utils';
import { IonContent, ModalController } from '@ionic/angular';
import { PickHazardComponent } from 'src/app/components/pick-hazard/pick-hazard.component';
import { PlrScreenComponent } from 'src/app/components/plr-screen/plr-screen.component';
import { UserAuthService } from 'src/app/services/user-auth/user-auth.service';
import { BackendCallService } from 'src/app/services/backend-call/backend-call.service';
import { AppConstants, StorageKeys } from 'src/app/utilities/constant';
import { ToastService } from 'src/app/services/toast.service';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { GraphService } from 'src/app/services/graph/graph.service';
import { SubSink } from 'subsink';
import { EventProvider } from 'src/app/services/event';
import { ConfirmationService } from 'src/app/services/confirmation.service';


@Component({
  selector: 'app-add-hazard',
  templateUrl: './add-hazard.page.html',
  styleUrls: ['./add-hazard.page.scss'],
})
export class AddHazardPage implements OnInit, AfterViewInit {
  @Input() showHazardDetails: boolean = false;
  @Input() sections: any;
  @Output() exitHazardDetails = new EventEmitter<any[]>();
  @ViewChild('addHazardDetails') form: NgForm;
  public selectedHazardStep: string = 'Hazard Details';
  public hazardSteps = [
    { isActive: true, title: 'Hazard Details', name: 'Hazard Details', val: 'hd', isdone: false, isSelected: true, },
    { isActive: false, title: 'Current hazard Rating', name: 'Current hazard Rating', val: 'chr', isdone: false, isSelected: false },
    { isActive: false, title: 'Initial Hazard and Control Measures', name: 'Initial Hazard and Control Measures', val: 'hch', isdone: false, isSelected: false },
    { isActive: false, title: 'Indicative Hazard Rating Number (HRN)', name: 'Indicative Hazard Rating Number (HRN)', val: 'hrn', isdone: false, isSelected: false },
    { isActive: false, title: 'Hazard Images', name: 'Hazard Images', val: 'hi', isdone: false, isSelected: false }
  ];
  hardDetailsData: any = {};
  hazardTypeList = hazardTypes;
  hazardSourceList = [];
  translatedHazardSourceList: any[] = [];
  hazardConsequencesList = [];
  translatedHazardConsequencesList: any[] = [];
  translatedMachineModeList: any[] = [];
  reviewImageSrc: string | ArrayBuffer;
  previewHazardFile: any[] = [];
  imageFiles: File[];
  addNewHazardDetails: any[] = [{}];
  gethazardTypeListLoading: boolean;
  gethazardSourceListLoading: boolean;
  gethazardConsequencesListLoading: boolean;
  isIncludeCategoryChecked = false;
  recommendedCategoryValue: any;
  hazardImagesList = [];
  hazardImagesRes: any[] = [];
  selectedLanguage: string = this.languageSupportService.getDefaultLanguage();
  machineModeList = [{ machineMode: 'Normal' }, { machineMode: 'Setup' }, { machineMode: 'Maintenance' }, { machineMode: 'Other' }];
  riskReMachineModeList = [{ machineMode: 'Normal' }, { machineMode: 'Setup' }, { machineMode: 'Maintenance' }, { machineMode: 'Other' }];
  hazardRatingList = [
    {
      listName: 'Probability of Occurrence', listNameView: this.selectedLanguage == 'en' ? 'Likelihood Of Occurences (LO)' : 'Eintrittswahrscheinlichkeit (LO)',
      weightagesList: this.selectedLanguage == 'en'
        ? this.sharedValue.weightagesList : this.sharedValue.weightagesListGermanLng,
      dataChrn: {}, dataRiskChrn: {}, dataRiskIhrn: {}, dataIhrn: {}, rating: ''
    },
    {
      listName: 'Frequency of Exposition',
      listNameView: this.selectedLanguage == 'en'
        ? 'Frequency Of Exposure (FE)' : 'Häufigkeit der Gefährdung (FE)',
      dataChrn: {}, dataRiskChrn: {}, dataRiskIhrn: {},
      weightagesList: this.sharedValue.FEweightagesList, dataIhrn: {}, rating: ''
    },
    {
      listName: 'Degree of Severity', listNameView: this.selectedLanguage == 'en'
        ? 'Degree Of Possible Harm (DPH)' : 'Schweregrad der Verletzung (DPH)',
      dataChrn: {}, dataRiskChrn: {}, dataRiskIhrn: {},
      weightagesList: this.sharedValue.DPEweightagesList, dataIhrn: {}, rating: ''
    },
    {
      listName: 'Number of Persons Exposed', listNameView: this.selectedLanguage == 'en'
        ? 'Number of Persons at Risk (NPE)' : 'Anzahl der gefährdeten Personen (NPE)',
      dataChrn: {}, dataRiskChrn: {}, dataRiskIhrn: {},
      weightagesList: this.sharedValue.NPEweightagesList, dataIhrn: {}, rating: ''
    }];
  machineModeLists = [];
  getMachineModesLoading: boolean;
  sectionData: any;
  pickCntrlMeasureValue: any;
  pickInitialHazardValue: any;
  imageSrc: string | ArrayBuffer;
  hazardData: any;
  roadmapStatus = 'In-progress';
  isRiskReassessment = false;
  public isCustom = 'false';
  isEditingFourEyeQuality = false;
  machineModeWithIdsLists: any;
  riskMachineModesWithIds: any;
  addNewHazard: boolean;
  riskResassessmentmachineModeLists: any[];
  hazardSourceConnsequencesData: any[];
  serviceMachineId: string;
  projectId: any;
  machineId: any;
  roadmapId: any;
  serviceId: any;
  selectedMachineName: string;
  public selectedStep = 'hd';
  public subs = new SubSink();
  private isNewHazard = false;
  @ViewChild(IonContent, { static: false }) content: IonContent;
  constructor(private translate: TranslateService,
    private languageSupportService: LanguageSupportService,
    private sharedValue: ShareValuesService,
    public modalController: ModalController,
    public userAuthService: UserAuthService,
    public backendService: BackendCallService,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private graphService: GraphService,
    private eventProvider: EventProvider,
    private confirmationService: ConfirmationService
  ) {
    this.route.queryParams.subscribe(async (params) => {
      console.log("hazard details params: ", params)
      this.selectedMachineName = params.selectedMachineName;
      this.projectId = params?.project_Id;
      this.machineId = params?.machineId;
      this.roadmapId = params?.roadmapId;
      this.serviceId = params?.serviceId;
    });
  }

  ngOnInit() {

  }
  ngAfterViewInit(): void {
    this.scrollToBottom();
  }

 private scrollToBottom(): void {
    setTimeout(() => {
      if (this.content) {
        this.content.scrollToBottom(300);
      } else {
        console.error('IonContent is not defined');
      }
    }, 0);
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.selectedStep = 'hd';
    console.log(this.sectionData);
    this.getTranslatedMode(this.machineModeList).subscribe(translatedData => {
      this.translatedMachineModeList = translatedData;
    });
    const roadmapInfo = sessionStorage.getItem('hazardInfo');
    if (sessionStorage.getItem('hazardInfo')) {
      const roadmapDetails = JSON.parse(roadmapInfo);
      this.serviceMachineId = roadmapDetails.serviceMachineId;
      this.isEditingFourEyeQuality = roadmapDetails.isEditingFourEyeQuality;
      this.sectionData = roadmapDetails.sectionData;
      this.hardDetailsData = roadmapDetails.hazardData;
      this.addNewHazard = roadmapDetails.addNewHazard;
      if (this.isEditingFourEyeQuality && !this.addNewHazard) {
        this.roadmapStatus = roadmapDetails.roadmapStatus;
        this.isCustom = roadmapDetails.isCustom;
        this.isRiskReassessment = roadmapDetails.isRiskReassessment;
      } else if (!this.isEditingFourEyeQuality && this.addNewHazard) {
        this.roadmapStatus = roadmapDetails.roadmapStatus;
        this.isRiskReassessment = roadmapDetails.isRiskReassessment;
        this.isCustom = roadmapDetails.isCustom;
      }
      if (this.isEditingFourEyeQuality) {
        if (isNotEmptyArray(this.hardDetailsData.hazardType)) {
          console.log('In If');
          this.addNewHazardDetails = [];
          this.hardDetailsData.hazardType.forEach(_type => {
            const hazardType = _type.hazardTypeName.split(' ');
            this.hazardSourceList = hazardSources[hazardType[0]];
            this.getTranslatedSource(_type.hazardSources).subscribe(translatedData => {
              _type.hazardSources = translatedData;
            });
            // To display default source list along with populated data
            this.getTranslatedSource(this.hazardSourceList).subscribe(translatedData => {
              this.translatedHazardSourceList = translatedData;
            });
            this.hazardConsequencesList = hazardConsequences[hazardType[0]];
            _type.hazardConsequences.map(item => item.consequence = item.consequences)
            this.getTranslatedConsequence(_type.hazardConsequences).subscribe(translatedData => {
              _type.hazardConsequences = translatedData;
            });
            // To display default consequence list along with populated data
            this.getTranslatedConsequence(this.hazardConsequencesList).subscribe(translatedData => {
              this.translatedHazardConsequencesList = translatedData;
            });
            const body = {
              hazardTypeId: '',
              hazardType: _type.hazardTypeName,
              source: _type.hazardSources,
              consequences: _type.hazardConsequences
            };
            if (_type.hazardTypeId) { body.hazardTypeId = _type.hazardTypeId; }
            this.addNewHazardDetails.push(body);
            this.addNewHazardDetails.forEach(_ad => {
              if (isNotEmptyArray(_ad.consequences)) {
                _ad.consequences.forEach(_cs => {
                  _cs.consequence = _cs.consequences;
                });
              }
            });
          });
          this.addNewHazardDetails.forEach(_ad => { _ad.consequences.forEach(_cs => { if (_cs.consequences) { delete _cs.consequences; } }); });
          this.hazardSourceConnsequencesData = JSON.parse(JSON.stringify(this.addNewHazardDetails));
          this.machineModeLists = this.hardDetailsData.machineModes;
          this.riskResassessmentmachineModeLists = this.hardDetailsData.riskmachineModes;
          this.hardDetailsData.machineMode = this.hardDetailsData.machineModes;
          this.getTranslatedMachineMode(this.hardDetailsData.machineModes).subscribe(translatedMode => {
            this.hardDetailsData.machineMode = translatedMode
          });
          this.machineModeWithIdsLists = this.hardDetailsData.machineModesWithIds;
          this.hardDetailsData.RiskResassessmentMachineMode = this.hardDetailsData?.riskmachineModes,
            this.riskMachineModesWithIds = this.hardDetailsData?.riskMachineModesWithIds,
            this.hazardRatingList = this.hardDetailsData.hazardRatingList;
          this.getTranslatedDatachrnLabels(this.hazardRatingList).subscribe(translatedDataList => {
            this.hazardRatingList = translatedDataList
          });
          this.hazardRatingList.map((element) => {
            this.getTranslatedWeightages(element?.['weightagesList']).subscribe(translatedDataList => {
              element.weightagesList = translatedDataList;
            })
          })
        
          this.subs.sink = this.eventProvider.event('edit-hazard').subscribe((res) => {
            this.isNewHazard = res;
            setTimeout(() => {
              if (this.isNewHazard) {
                this.hazardImagesRes = this.hardDetailsData.hazardImagesList;
              } else  {
                this.hazardImagesRes = [];
              }
            }, 1000);
          });
          this.hardDetailsData.determinePlrValue = this.hardDetailsData.plrObj.determinedPLR;
          if (this.hardDetailsData.recommendedCategory && this.hardDetailsData.hazardName) {
            this.isIncludeCategoryChecked = true;
            this.recommendedCategoryValue = this.hardDetailsData.recommendedCategory;
          }
        }
      } else {
        if (this.hardDetailsData) {
          console.log('In else');
          this.addNewHazardDetails = [{}];
          this.machineModeLists = [];
          this.hazardRatingList = [
            {
              listName: 'Probability of Occurrence',
              listNameView: this.selectedLanguage == 'en' ? 'Likelihood Of Occurences (LO)' : 'Eintrittswahrscheinlichkeit (LO)',
              weightagesList: this.selectedLanguage == 'en' ? this.sharedValue.weightagesList : this.sharedValue.weightagesListGermanLng,
              dataChrn: {}, dataRiskChrn: {}, dataRiskIhrn: {}, dataIhrn: {}, rating: ''
            },
            { listName: 'Frequency of Exposition', listNameView: this.selectedLanguage == 'en' ? 'Frequency Of Exposure (FE)' : 'Häufigkeit der Gefährdung (FE)', dataChrn: {}, dataRiskChrn: {}, dataRiskIhrn: {}, weightagesList: this.selectedLanguage == 'en' ? this.sharedValue.FEweightagesList : this.sharedValue.FEweightagesGermanLng, dataIhrn: {}, rating: '' },
            { listName: 'Degree of Severity', listNameView: this.selectedLanguage == 'en' ? 'Degree Of Possible Harm (DPH)' : 'Schweregrad der Verletzung (DPH)', dataChrn: {}, dataRiskChrn: {}, dataRiskIhrn: {}, weightagesList: this.selectedLanguage == 'en' ? this.sharedValue.DPEweightagesList : this.sharedValue.DPEweightagesListGermanLng, dataIhrn: {}, rating: '' },
            { listName: 'Number of Persons Exposed', listNameView: this.selectedLanguage == 'en' ? 'Number of Persons at Risk (NPE)' : 'Anzahl der gefährdeten Personen (NPE)', dataChrn: {}, dataRiskChrn: {}, dataRiskIhrn: {}, weightagesList: this.sharedValue.NPEweightagesList, dataIhrn: {}, rating: '' }];
          const { hazardName, pickInitialHazard, pickControlMeasure, determinePlrValue } = this.hardDetailsData;
          if (hazardName) { this.hardDetailsData.hazardName = ''; }
          if (pickInitialHazard) { this.hardDetailsData.pickInitialHazard = ''; }
          if (pickControlMeasure) { this.hardDetailsData.pickControlMeasure = ''; }
          if (this.hardDetailsData.category && this.hardDetailsData.hazardName) {
            this.isIncludeCategoryChecked = true;
            this.recommendedCategoryValue = this.hardDetailsData.category;
            this.hardDetailsData.determinePlrValue = this.hardDetailsData.plrObj.determinedPLR;
            this.subs.sink = this.eventProvider.event('edit-hazard').subscribe((res) => {
              this.isNewHazard = res;
              setTimeout(() => {
                if (this.isNewHazard) {
                  this.hazardImagesRes = this.hardDetailsData.hazardImagesList;
                } else  {
                  this.hazardImagesRes = [];
                }
              }, 1000);
            });
        }
      }
      }
    }
    this.scrollToBottom();
  }

  public handleFormAction() {
    if (this.form) {
      if (this.form.valid) {
        // Handle form submission
        this.complete(this.form);
      } else {
        this.toastService.presentToast('Please fill the required details', 4000, AppConstants.WARNING_SNACKBAR, 'top');
        this.form.control.markAllAsTouched();
      }
    }
  }

  ionViewWillEnter() {
    this.hazardRatingList.forEach((element) => {
      if (this.selectedLanguage == 'en') {
        if (element.listName === 'Probability of Occurrence') {
          element.weightagesList = this.sharedValue.weightagesList;
        } else if (element.listName === 'Frequency of Exposition') {
          element.weightagesList = this.sharedValue.FEweightagesList;
        } else if (element.listName === 'Number of Persons Exposed') {
          element.weightagesList = this.sharedValue.NPEweightagesList;
        } else if (element.listName === 'Degree of Severity') {
          element.weightagesList = this.sharedValue.DPEweightagesList;
        }
      } else if (this.selectedLanguage == 'de') {
        if (element.listName === 'Probability of Occurrence') {
          element.weightagesList = this.sharedValue.weightagesListGermanLng;
        } else if (element.listName === 'Frequency of Exposition') {
          element.weightagesList = this.sharedValue.FEweightagesGermanLng;
        } else if (element.listName === 'Number of Persons Exposed') {
          element.weightagesList = this.sharedValue.NPEweightagesList;
        } else if (element.listName === 'Degree of Severity') {
          element.weightagesList = this.sharedValue.DPEweightagesListGermanLng;
        }
      }
    });
    if (!this.isEditingFourEyeQuality) { this.getMachineLimits(); }
    this.hazardTypeList.forEach((each: any) => {
      each.isDisabled = false;
    });

  }

  getMachineLimits() {
    this.machineModeLists = [];
    // this.sharedValue.machineModeLists = [];
    if (this.serviceMachineId) {
      this.backendService.getMachineLimits(this.serviceMachineId)
        .subscribe(
          (data: any) => {
            if (data && isNotEmptyArray(data.machineMode)) {
              this.hardDetailsData.machineMode = data.machineMode;
              data.machineMode.forEach(_mm => {
                this.machineModeLists.push(_mm.machineMode);
                // this.sharedValue.machineModeLists.push(_mm.machineMode)
              });
            }
          },
          (err) => {
            console.log('getting issue in fetching machine limits data :', err);
          }
        );
    }
  }

  stepperChange(event, stepsList) {
    // stepsList.forEach((step) => {
    //   if (event.title === step.title) {
    //     step.isActive = true;
    //   } else {
    //     step.isActive = false;
    //   }
    // });
    console.log(event, 'step clicked');
  }


  changeHazardStep(direction: 'next' | 'back', stepValue?: string) {
    let currentIndex = this.hazardSteps.findIndex(step => step.val === stepValue);
    console.log(currentIndex, 'currentIndex');
    let control: any;
    let fieldName: string;
    if (direction === 'next' && currentIndex < this.hazardSteps.length - 1) {
      if (this.form) {
        Object.keys(this.form.controls).forEach(field => {
          control = this.form.controls[field];
          fieldName = field;
        });
        const hazardGroup: any = this.form.controls['hazardTypesData'];

        if (currentIndex === 0) {
          const hazardName = fieldName === 'hazardName' && control.value === undefined;
          const hazardType = hazardGroup.controls[0].controls.hazardType?.value;
          const source = hazardGroup.controls[0].controls.source?.value;
          const consequences = hazardGroup.controls[0].controls.consequences?.value;
          if (fieldName === 'hazardName' 
            && control.value === undefined || !hazardType || !source || !consequences) {
            this.toastService.presentToast('Please fill all * marked fields', 4000, AppConstants.WARNING_SNACKBAR, 'top');
            return
          } else {
            this.selectedStep = this.hazardSteps[currentIndex + 1].val;
          }
        }
        if (currentIndex === 1) {
          if(this.form.value.machineMode === undefined || this.form.value.machineMode.length === 0) {
            this.toastService.presentToast('Please select machine mode', 4000, AppConstants.WARNING_SNACKBAR, 'top');
            return
          }
          const machineModes = this.form.value.machineMode.map(mode => mode.machineMode || mode);
          const chrnFields = [
            "Probability of Occurrence",
            "Frequency of Exposition",
            "Degree of Severity",
            "Number of Persons Exposed"
          ];
          // Function to check if all necessary fields for a specific machine mode are filled
          const isMachineModeValid = (mode) => {
            
            return chrnFields.every(field => {
              const fieldValue = this.form.value.chrnTable?.[field]?.[mode];
               return !this.isFieldEmpty(fieldValue);
            });
          };
          const hasInvalidMode = machineModes.some(mode => !isMachineModeValid(mode));
          if (hasInvalidMode) {
            this.toastService.presentToast(
              'Please select all dropdown values',
              4000,
              AppConstants.WARNING_SNACKBAR,
              'top'
            );
          } else {
            this.selectedStep = this.hazardSteps[currentIndex + 1]?.val;
          }
        }
        if (currentIndex === 2 || currentIndex === 3) {
          this.selectedStep = this.hazardSteps[currentIndex + 1]?.val;
        }
      }
    } else if (direction === 'back' && currentIndex > 0) {
      this.selectedStep = this.hazardSteps[currentIndex - 1].val;
    }
    console.log(this.selectedStep, 'this.selectedStep');
    // Update the active status of each step in hazardSteps
    this.hazardSteps.forEach((step) => {
      step.isActive = (step.val === this.selectedStep);
    });
  }

  isFieldEmpty(field: any): boolean {
    return field === undefined || Object.keys(field).length === 0;
  }

  private getTranslatedSource(sources): Observable<any[]> {
    const sourceObservables = sources?.map(item =>
      this.translate.get(item.source)
    );
    console.log(sourceObservables);
    return forkJoin(sourceObservables).pipe(
      map(translations => {
        return sources?.map((item, index) => { return { ...item, source: translations[index] }; });
      })
    );
  }

  private getTranslatedMode(modes): Observable<any[]> {
    const modeObservables = modes?.map(item =>
      this.translate.get(item.machineMode)
    );
    return forkJoin(modeObservables).pipe(
      map(translations => {
        return modes?.map((item, index) => { return { ...item, machineMode: translations[index] }; });
      })
    );
  }

  private getTranslatedConsequence(consequences): Observable<any[]> {
    const consequenceObservables = consequences?.map(item =>
      this.translate.get(item.consequence)
    );
    return forkJoin(consequenceObservables).pipe(
      map(translations => {
        return consequences?.map((item, index) => { return { ...item, consequence: translations[index], consequences: translations[index] }; });
      })
    );
  }

  private getTranslatedMachineMode(modes: string[]): Observable<string[]> {
    const labelObservables = modes.map(mode => this.translate.get(mode));
    return forkJoin(labelObservables);
  }

  private getTranslatedDatachrnLabels(dataList): Observable<any[]> {
    const observables = dataList.map(item => {
      const normalLabel = item?.dataChrn?.Normal?.label || '';
      const setupLabel = item?.dataChrn?.Setup?.label || '';
      const maintenanceLabel = item?.dataChrn?.Maintenance?.label || '';
      const otherLabel = item?.dataChrn?.Other?.label || '';

      const normalTranslation = normalLabel ? this.translate.get(normalLabel) : of(normalLabel);
      const setupTranslation = setupLabel ? this.translate.get(setupLabel) : of(setupLabel);
      const maintenanceTranslation = maintenanceLabel ? this.translate.get(maintenanceLabel) : of(maintenanceLabel);
      const otherTranslation = otherLabel ? this.translate.get(otherLabel) : of(otherLabel);

      return forkJoin([normalTranslation, setupTranslation, maintenanceTranslation, otherTranslation]).pipe(
        map(([translatedNormal, translatedSetup, translatedMaintenance, translatedOther]) => {
          return {
            ...item,
            dataChrn: {
              Normal: {
                label: translatedNormal, id: item?.dataChrn?.Normal?.id,
                value: item?.dataChrn?.Normal?.value, rating: item?.dataChrn?.Normal?.rating
              },
              Setup: {
                label: translatedSetup, id: item?.dataChrn?.Setup?.id,
                value: item?.dataChrn?.Setup?.value, rating: item?.dataChrn?.Setup?.rating
              },
              Maintenance: {
                label: translatedMaintenance, id: item?.dataChrn?.Maintenance?.id,
                value: item?.dataChrn?.Maintenance?.value, rating: item?.dataChrn?.Maintenance?.rating
              },
              Other: {
                label: translatedOther, id: item?.dataChrn?.Other?.id,
                value: item?.dataChrn?.Other?.value, rating: item?.dataChrn?.Other?.rating
              }
            },
            dataIhrn: {
              Normal: {
                label: translatedNormal, id: item?.dataIhrn?.Normal?.id,
                value: item?.dataIhrn?.Normal?.value, rating: item?.dataIhrn?.Normal?.rating
              },
              Setup: {
                label: translatedSetup, id: item?.dataIhrn?.Setup?.id,
                value: item?.dataIhrn?.Setup?.value, rating: item?.dataIhrn?.Setup?.rating
              },
              Maintenance: {
                label: translatedMaintenance, id: item?.dataIhrn?.Maintenance?.id,
                value: item?.dataIhrn?.Maintenance?.value, rating: item?.dataIhrn?.Maintenance?.rating
              },
              Other: {
                label: translatedOther, id: item?.dataIhrn?.Other?.id,
                value: item?.dataIhrn?.Other?.value, rating: item?.dataIhrn?.Other?.rating
              }
            }
          };
        })
      );
    });

    return forkJoin(observables);
  }

  private getTranslatedWeightages(weightages): Observable<any[]> {
    const weightageObservables = weightages?.map(item => this.translate.get(item?.label));
    return forkJoin(weightageObservables).pipe(map(translations => {
      return weightages?.map((item, index) => { return { ...item, label: translations[index] }; });
    })
    );
  }

  async selectEvent(ev: any, index: number) {
    const value = ev.split(' ')[0];
    this.hazardSourceList = hazardSources[value];
    this.getTranslatedSource(this.hazardSourceList).subscribe(translatedData => {
      this.translatedHazardSourceList = translatedData;
    });
    this.hazardConsequencesList = hazardConsequences[value];
    this.getTranslatedConsequence(this.hazardConsequencesList).subscribe(translatedData => {
      this.translatedHazardConsequencesList = translatedData;
    });
    this.addNewHazardDetails[index].consequences = null;
    this.addNewHazardDetails[index].source = null;
  }

  public changeMode(ev: []) {
    this.machineModeLists = [];
    // this.sharedValue.machineModeLists = [];
    ev.forEach((ele: any) => {
      this.machineModeLists.push(ele.machineMode);
      // this.sharedValue.machineModeLists.push(ele.machineMode);
    });
  }

  public updateIndicativeHRN(each, hazardRatingList, i) {
    each.dataIhrn = JSON.parse(JSON.stringify(each.dataChrn));
  }

  public updateRiskIndicativeHRN(each, hazardRatingList, i) {
    each.dataRiskIhrn = JSON.parse(JSON.stringify(each.dataRiskChrn));
  }

  changeRiskResassessmentMachineMode(ev: []) {
    this.riskResassessmentmachineModeLists
      = [];
    ev.forEach((ele: any) => {
      this.riskResassessmentmachineModeLists
        .push(ele.machineMode);
    });
  }
  get chrnTotal() {
    return this.machineModeLists?.map((each) => {
      let temp = 1;
      let allDefiend = false;
      for (const eachVal of this.hazardRatingList) {
        if (!allDefiend) {
          allDefiend = eachVal.dataChrn[each]?.value ? true : false;
        }
        temp = temp * parseFloat(eachVal.dataChrn[each]?.value || 1);
      }
      if (this.isEditingFourEyeQuality) {
        return temp.toFixed(4);
      } else {
        if (!allDefiend) {
          return 0;
        }
        return temp == 0 ? 0 : temp.toFixed(4);
      }
    });

  }

  get chrnRiskTotal() {
    return this.riskResassessmentmachineModeLists?.map((each) => {
      let temp = 1;
      let allDefiend = false;
      for (const eachVal of this.hazardRatingList) {
        if (!allDefiend) {
          allDefiend = eachVal?.dataRiskChrn[each]?.value ? true : false;
        }
        temp = temp * parseFloat(eachVal?.dataRiskChrn[each]?.value || 1);
      }
      if (this.isEditingFourEyeQuality) {
        return temp.toFixed(4);
      } else {
        if (!allDefiend) {
          return 0;
        }
        return temp == 0 ? 0 : temp.toFixed(4);
      }
    });

  }

  get ihrnTotal() {
    return this.machineModeLists?.map((each) => {
      let temp = 1;
      let allDefiend = false;
      for (const eachVal of this.hazardRatingList) {
        if (!allDefiend) {
          allDefiend = eachVal.dataIhrn[each]?.value ? true : false;
        }
        temp = temp * parseFloat(eachVal.dataIhrn[each]?.value || 1);
      }
      if (this.isEditingFourEyeQuality) {
        return temp.toFixed(4);
      } else {
        if (!allDefiend) {
          return 0;
        }
        return temp == 0 ? 0 : temp.toFixed(4);
      }
    });
  }

  get ihrnRiskTotal() {
    return this.riskResassessmentmachineModeLists?.map((each) => {
      let temp = 1;
      let allDefiend = false;
      for (const eachVal of this.hazardRatingList) {
        if (!allDefiend) {
          allDefiend = eachVal.dataRiskIhrn[each]?.value ? true : false;
        }
        temp = temp * parseFloat(eachVal.dataRiskIhrn[each]?.value || 1);
      }
      if (this.isEditingFourEyeQuality) {
        return temp.toFixed(4);
      } else {
        if (!allDefiend) {
          return 0;
        }
        return temp == 0 ? 0 : temp.toFixed(4);
      }
    });
  }

  async openPickHazardsList(type) {
    const props = { type };
    const modal = await this.modalController.create({
      component: PickHazardComponent,
      cssClass: 'pickhazard-alert-modal',
      backdropDismiss: false,
      componentProps: props,
      showBackdrop: false,
    });
    await modal.present();
    await modal.onDidDismiss().then((result) => {
      if (result && result.data) {
        if (type == 'Pick Control Measure') {
          this.pickCntrlMeasureValue = result.data.value;
          this.hardDetailsData.pickControlMeasure = result.data.value;
        } else {
          this.pickInitialHazardValue = result.data.value;
          this.hardDetailsData.pickInitialHazard = result.data.value;
        }
      }
    });
  }

  async determinePlr() {
    const props = { isCategoryIncluded: this.isIncludeCategoryChecked };
    props['plrObj'] = this.hardDetailsData.plrObj;
    props['category'] = this.recommendedCategoryValue;
    const modal = await this.modalController.create({
      component: PlrScreenComponent,
      cssClass: 'plr-screen-class',
      backdropDismiss: false,
      componentProps: props,
      showBackdrop: false,
    });
    await modal.present();
    await modal.onDidDismiss().then((result) => {
      if (Object.keys(result?.data).length !== 0) {
        console.log('PLR data', result?.data);
        this.hardDetailsData.determinePlrValue = result.data.plr_value;
        if (result.data.category_value) {
          this.recommendedCategoryValue = result.data.category_value;
          if (this.isIncludeCategoryChecked) { this.hardDetailsData.category = result.data.category_value; }
        }
        this.hardDetailsData.plrObj.sValue = result.data.sValue;
        this.hardDetailsData.plrObj.fValue = result.data.fValue;
        this.hardDetailsData.plrObj.pValue = result.data.pValue;
      }
    });
  }

  controlMeasureAddToLibrary(pickInitialHazard) {
    const payload = [
      {
        id: '',
        value: pickInitialHazard,
        userId: this.userAuthService.getUserList().userId,//"7892B01E-5EE1-4702-81C1-DE9D35EA2FA9"
      }
    ];
    this.backendService.controlMeasureAddToLibrary(payload).subscribe((res: any) => {
      this.toastService.presentToast(res.value, 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
    }, err => {
      this.toastService.presentToast('No Results Found', 4000, AppConstants.ERROR_SNACKBAR, 'top');
    });
  }

  initialMeasureAddToLibrary(pickInitialHazard) {
    const payload = [
      {
        id: '',
        value: pickInitialHazard,
        userId: this.userAuthService.getUserList().userId,
      }
    ];
    this.backendService.initialMeasureAddToLibrary(payload).subscribe((res: any) => {
      this.toastService.presentToast(res.value, 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
    }, err => {
      this.toastService.presentToast('No Results Found', 4000, AppConstants.ERROR_SNACKBAR, 'top');
    });
  }

  addCategoryValue() {
    if (this.isIncludeCategoryChecked && this.recommendedCategoryValue) { this.hardDetailsData.recommendedCategory = this.recommendedCategoryValue; }
    else { this.recommendedCategoryValue = ''; }
  }

  public inputHazardImg(files: File[], hazardImage: HTMLInputElement) {
    this.imageFiles = Array.from(files);
    this.uploadHazardImages();
    for (const file of files) {
      const reader = new FileReader();
      const fileSize = file.size;
      const imgData = {
        fileName: file.name,
        fileSize: file.size,
        fileReaderResult: reader.result,
        fileData: file
      };
      reader.onload = e => {
        this.reviewImageSrc = reader.result;
        imgData.fileReaderResult = reader.result;
        if (fileSize < 4194304) {
          this.previewHazardFile.push(imgData);
        } else {
          this.previewHazardFile.push(imgData);
          // this.sharedValue.errorShowToast("","Image size exceeds 4MB")
        }
      };
      reader.readAsDataURL(file);
      reader.onerror = (error) => {
      };

    }
    hazardImage.value = '';
  }

  private async uploadHazardImages() {
    const file_path = `${this.projectId}/${this.serviceId}/${this.machineId}/${this.roadmapId}/`;
    const formData = new FormData();
    const hazardImageFormData = this.sharedValue.prepareImageFormData(formData, this.imageFiles, file_path)
    await this.backendService.uploadImages(hazardImageFormData).subscribe((res: any) => {
      console.log(res);
      res.forEach(element => {
        const imageData = {
          fileName: element?.file_name,
          contentType: element?.content_type,
          url: element?.url,
          image_Size: element?.file_size
        };
        this.hazardImagesRes.push(imageData);
        this.hazardImagesList.push(imageData);
      })
    }, (err) => {
    });
  }

  public deleteImage(params, i) {
    const  payload =  {
      resourceType: 2,
      id: params.id,
      fileName: params.fileName,
      filePath: params.url,
      modifiedBy: this.userAuthService.getUserList().userId
    }
    this.backendService.deleteResource(payload).subscribe((res: any) => {
      this.hazardImagesRes.forEach((element) => {
            if (element.fileName == params.fileName) { this.hazardImagesRes.splice(i, 1); }
          });
        this.toastService.presentToast('File Deleted Successfully', 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
      }, err => {        
        this.toastService.presentToast('No Results Found', 4000, AppConstants.ERROR_SNACKBAR, 'top');
    });    
  }

  async deleteImageConfirmation(params, i){
    const isConfirmed = await this.confirmationService.presentConfirmationModal(AppConstants.ARE_YOU_SURE_TO_DELETE, AppConstants.DELETE);
    if (isConfirmed) {
      this.deleteImage(params, i);
    }
  }

  async complete(form: NgForm) {
    console.log(form, 'completed form');
    await this.completedData(form);
    this.selectedStep = 'hd';
    this.hazardImagesRes = [];
    this.previewHazardFile = [];
  }

  completedData(form: NgForm) {
    if (form.valid) {
      if (this.isEditingFourEyeQuality) {
        this.saveFourEyeQualityEditInfo(form.value);
      }
      else {
        const navigationExtras: NavigationExtras = {
          queryParamsHandling: 'merge',
          // state: {
          //   hazardDetails: { hazardRatingList: this.hazardRatingList, formData: form.value, recommendedCategory: this.recommendedCategoryValue, files: this.hazardImagesList, pickCntrlMeasureValue: this.pickCntrlMeasureValue, pickInitialHazardValue: this.pickInitialHazardValue, determinePlrValue: this.hardDetailsData.determinePlrValue, isEdited: this.isEditingFourEyeQuality },
          //   sectionData: this.sectionData
          // },
        };
       
        this.pickCntrlMeasureValue = form.value.pickControlMeasure;
        this.pickInitialHazardValue = form.value.pickInitialHazard;
      
        const newhazardDetails = {
          hazardDetails: {
            hazardRatingList: this.hazardRatingList,
            formData: form.value,
            recommendedCategory: this.recommendedCategoryValue,
            files: this.hazardImagesRes,
            pickCntrlMeasureValue: this.pickCntrlMeasureValue,
            pickInitialHazardValue: this.pickInitialHazardValue,
            determinePlrValue: this.hardDetailsData.determinePlrValue,
            isEdited: this.isEditingFourEyeQuality
          },
          sectionData: this.sectionData
        };

        this.sections.forEach(section => {
          if (section.sectionName == this.sectionData.sectionName) {
            section.subSections.forEach(subsection => {
              if (subsection.subSectionName == this.sectionData.subSectionName) {
                let finalHrNs = [];
                let currentHrNs = {
                  hrnType: "Current",
                  version: "",
                  value: []
                }
                console.log(form.value, 'form.value');
                form.value.machineMode.forEach(_element => {
                  console.log(_element);

                  let values = {
                    mode: _element.machineMode,
                    po: form.value.chrnTable['Probability of Occurrence'][_element.machineMode].id,
                    sd: form.value.chrnTable['Degree of Severity'][_element.machineMode].id,
                    fe: form.value.chrnTable['Frequency of Exposition'][_element.machineMode].id,
                    np: form.value.chrnTable['Number of Persons Exposed'][_element.machineMode].id,
                    rating: Math.round(form.value.chrnTable['Probability of Occurrence'][_element.machineMode] *
                      form.value.chrnTable['Degree of Severity'][_element.machineMode].id *
                      form.value.chrnTable['Frequency of Exposition'][_element.machineMode].id *
                      form.value.chrnTable['Number of Persons Exposed'][_element.machineMode].id)
                  }
                  currentHrNs.value.push(values);
                });
                finalHrNs.push(currentHrNs);
                let indicativeHrNs = {
                  hrnType: "Indicative",
                  version: "",
                  value: []
                }
                form.value.machineMode.forEach(_element => {
                  console.log(_element.machineMode);

                  let values = {
                    mode: _element.machineMode,
                    po: form.value.ihrnTable['Probability of Occurrence'][_element.machineMode].id,
                    sd: form.value.ihrnTable['Degree of Severity'][_element.machineMode].id,
                    fe: form.value.ihrnTable['Frequency of Exposition'][_element.machineMode].id,
                    np: form.value.ihrnTable['Number of Persons Exposed'][_element.machineMode].id,
                    rating: Math.round(form.value.ihrnTable['Probability of Occurrence'][_element.machineMode] *
                      form.value.ihrnTable['Degree of Severity'][_element.machineMode].id *
                      form.value.ihrnTable['Frequency of Exposition'][_element.machineMode].id *
                      form.value.ihrnTable['Number of Persons Exposed'][_element.machineMode].id)
                  }
                  indicativeHrNs.value.push(values);
                });
                finalHrNs.push(indicativeHrNs);
                console.log(finalHrNs);

                if (this.addNewHazard) {
                  let hazard = {
                    
                      id: "",
                      hazardName: form.value.hazardName,
                      hazardTypes: [
                        {
                          type: form.value.hazardTypesData[0].hazardType,
                          sources: form.value.hazardTypesData[0].source,
                          consequences: form.value.hazardTypesData[0].consequences
                        }
                      ],
                      hazardRatingNumber: {
                        machineModes: form.value.machineMode,
                        hrNs: finalHrNs
                      },
                      initialHazard: this.pickInitialHazardValue,
                      countermeasure: this.pickCntrlMeasureValue,
                      determinedPLR: this.hardDetailsData.determinePlrValue,
                      category: this.recommendedCategoryValue,
                      files: this.hazardImagesRes,
                      plrObj: {
                        id: "",
                        determinedPLR: this.hardDetailsData.determinePlrValue,
                        sValue: this.sharedValue.sValue,
                        fValue: this.sharedValue.fValue,
                        pValue: this.sharedValue.pValue
                      }
                    
                  };
                  
                  if (!subsection.steps[this.sectionData.index].hazardDetails) {
                    subsection.steps[this.sectionData.index].hazardDetails = { hazards: [] };
                  }
                  
                  // Step 2: Check if the hazards array exists, if not, initialize it
                  if (!Array.isArray(subsection.steps[this.sectionData.index].hazardDetails.hazards)) {
                    subsection.steps[this.sectionData.index].hazardDetails.hazards = [];
                  }
                  
                  // Step 3: Now, you can safely push the new hazard into the hazards array
                  subsection.steps[this.sectionData.index].hazardDetails.hazards.push(hazard);

                  subsection.steps[this.sectionData.index].answers[0].hazardDetails?.push({ id: "", name: form.value.hazardName });
                }
              }
            });
          }

        });

        sessionStorage.setItem(StorageKeys.NEW_HAZARD_DETAILS, JSON.stringify(newhazardDetails));
        // this.location.back();
        //this.roadmapView.onSave();
        // this.eventProvider.event('set-section').subscribe((res)=>{
        //   this.sections = this.roadmapView.returnSection();
        //   console.log(this.sections,'this.sections = this.roadmapData?.section;');
        // })
        this.exitHazardDetails.emit(this.sections);
        //this.router.navigate([window.location.pathname], navigationExtras);
      }
    } else {
      form.form.markAllAsTouched();
      this.toastService.presentToast('Please fill all the mandatory fields', 4000, AppConstants.ERROR_SNACKBAR, 'top');
    }
  }

  saveFourEyeQualityEditInfo(hazardInfo) {
    if (hazardInfo && hazardInfo.hazardName) {
      const hazardPayload = this.sharedValue.initializeEditFourEyeHazardLists(this.recommendedCategoryValue,
        this.hardDetailsData.id,
        hazardInfo,
        this.machineModeLists,
        this.riskResassessmentmachineModeLists,
        this.riskMachineModesWithIds,
        this.machineModeWithIdsLists,
        this.hazardRatingList,
        this.addNewHazardDetails,
        this.hazardImagesRes,
        this.hazardSourceConnsequencesData,
        this.hardDetailsData);
      setTimeout(() => {
        if (hazardPayload && hazardPayload.id) {
          this.backendService.saveEditedHazardInfoInFourEyeQuality(hazardPayload).then(res => {
            if (res && res.statusCode == 200) {
              this.toastService.presentToast('Hazard edited successfully.', 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
              // this.sharedValue.editHazardPayload.push({id:hazardPayload.id,name:hazardPayload.name});
              const state = {
                hazardDetails: { hazardRatingList: this.hazardRatingList, 
                  formData: hazardInfo, 
                  files: this.hazardImagesRes, 
                  pickCntrlMeasureValue: this.pickCntrlMeasureValue,
                  pickInitialHazardValue: this.pickInitialHazardValue, 
                  determinePlrValue: this.hardDetailsData.determinePlrValue, 
                  isEdited: this.isEditingFourEyeQuality, 
                  editedHazardId: this.hardDetailsData.id },
                  sectionData: this.sectionData
              };
              this.sharedValue.editHazardPayload = state;
              this.selectedStep = this.hazardSteps[0].val;
              this.hazardSteps.forEach((step) => {
                step.isActive = (step.val === this.selectedStep);
              });
              this.goBack();
            }
          }).catch(err => {
            console.log('getting issue in saving hazard info for 4-eye quality :', err);
          });
        }
      }, 400);
    }
  }

  goBack() {
    this.hazardImagesRes = [];
    this.subs.unsubscribe();
    this.exitHazardDetails.emit(this.sections);
  }
}
