import { Injectable } from '@angular/core';
import { jobOrderManagementColumnSelectorData, offerManagementColumnSelectorData, projectStatusTrackerColumnSelectorData, taskManagerColumnSelectorData } from 'src/app/modals/add-remove-column-data';
import { StorageService } from './../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class LocalSettingsService {

  private PROJECT_TRACKER_KEY = 'projecttracker_widget';
  private TASK_MANAGER_KEY = 'taskmanager_widget';
  private FUTURE_PROJECTS_KEY = 'futureprojects_widget';
  private OFFER_MANAGEMENT_KEY = 'offermanagement_widget';
  private ORDER_MANAGEMENT_KEY = 'ordermanagement_widget';
  private RISK_ASSESSMENT_KEY = 'riskassessment_widget';
  private PROJECT_STATUS_COLUMN_SELECTOR_KEY = 'projectStatus_column_selector_widget';
  private TASK_MANAGER_COLUMN_SELECTOR_KEY = 'taskmanager_column_selector_widget';
  private JOB_ORDER_MANAGEMENT_COLUMN_SELECTOR_KEY = 'jobOrderManagement_column_selector_widget';
  private OFFER_MANAGEMENT_COLUMN_SELECTOR_KEY = 'offerManagement_column_selector_widget';

  constructor(public storageSrv: StorageService) { }

  storeDashboardCustomizationChoice(key: string, value) {
    this.storageSrv.set(this.getDashboardWidgetStorageKey(key), value);
  }

  getDashboardCustomizationChoice(key: string) {
    return this.storageSrv.get(this.getDashboardWidgetStorageKey(key));
  }

  getDashboardWidgetStorageKey(value: string) {
    let key = '';
    if (value == 'pt') {key = this.PROJECT_TRACKER_KEY;}
    else if (value == 'tm') {key = this.TASK_MANAGER_KEY;}
    else if (value == 'fp') {key = this.FUTURE_PROJECTS_KEY;}
    else if (value == 'ofm') {key = this.OFFER_MANAGEMENT_KEY;}
    else if (value == 'om') {key = this.ORDER_MANAGEMENT_KEY;}
    else if (value == 'rm') {key = this.RISK_ASSESSMENT_KEY;}
    return key;
  }

  getProjectTrackerWidgetPermissionValue() {
    let data = this.storageSrv.get(this.PROJECT_TRACKER_KEY);
    if (data == null) {data = true;}
    return data;
  }

  getTaskManagerWidgetPermissionValue() {
    let data = this.storageSrv.get(this.TASK_MANAGER_KEY);
    if (data == null) {data = false;}
    return data;
  }

  getFutureProjectsWidgetPermissionValue() {
    let data = this.storageSrv.get(this.FUTURE_PROJECTS_KEY);
    if (data == null) {data = false;}
    return data;
  }

  getOfferManagementWidgetPermissionValue() {
    let data = this.storageSrv.get(this.OFFER_MANAGEMENT_KEY);
    if (data == null) {data = false;}
    return data;
  }

  getOrderManagementWidgetPermissionValue() {
    let data = this.storageSrv.get(this.ORDER_MANAGEMENT_KEY);
    if (data == null) {data = false;}
    return data;
  }

  getRiskAssessmentWidgetPermissionValue() {
    let data = this.storageSrv.get(this.RISK_ASSESSMENT_KEY);
    if (data == null) {data = false;}
    return data;
  }

  resetDashboardWidget() {
    this.storageSrv.set(this.PROJECT_TRACKER_KEY, true);
    this.storageSrv.set(this.TASK_MANAGER_KEY, false);
    this.storageSrv.set(this.FUTURE_PROJECTS_KEY, false);
    this.storageSrv.set(this.OFFER_MANAGEMENT_KEY, false);
    this.storageSrv.set(this.ORDER_MANAGEMENT_KEY, false);
    this.storageSrv.set(this.RISK_ASSESSMENT_KEY, false);
  }

  setColumnSelectorWidgetInfo(key, value) {
    const uniqueKey = this.getColumnSelectorStorageKey(key);
    this.storageSrv.set(uniqueKey, value);
  }

  getProjectStatusColumnSelectorWidgetStorageValue() {
    let data = this.storageSrv.get(this.PROJECT_STATUS_COLUMN_SELECTOR_KEY);
    if (data == null) {
      const columnSelectorValue = JSON.parse(JSON.stringify(projectStatusTrackerColumnSelectorData));
      data = columnSelectorValue;
    }
    return data;
  }

  getTaskManagerColumnSelectorWidgetStorageValue() {
    let data = this.storageSrv.get(this.TASK_MANAGER_COLUMN_SELECTOR_KEY);
    if (data == null) {
      const columnSelectorValue = JSON.parse(JSON.stringify(taskManagerColumnSelectorData));
      data = columnSelectorValue;
    }
    return data;
  }

  getJobOrderManagementColumnSelectorWidgetStorageValue() {
    let data = this.storageSrv.get(this.JOB_ORDER_MANAGEMENT_COLUMN_SELECTOR_KEY);
    if (data == null) {
      const columnSelectorValue = JSON.parse(JSON.stringify(jobOrderManagementColumnSelectorData));
      data = columnSelectorValue;
    }
    return data;
  }

  getOfferManagementColumnSelectorWidgetStorageValue() {
    let data = this.storageSrv.get(this.OFFER_MANAGEMENT_COLUMN_SELECTOR_KEY);
    if (data == null) {
      const columnSelectorValue = JSON.parse(JSON.stringify(offerManagementColumnSelectorData));
      data = columnSelectorValue;
    }
    return data;
  }

  getColumnSelectorStorageKey(type) {
    let key: string;
    if (type === 'projectStatus') {key = this.PROJECT_STATUS_COLUMN_SELECTOR_KEY;}
    if (type === 'taskManagerWidgetColumnSelector') {key = this.TASK_MANAGER_COLUMN_SELECTOR_KEY;}
    else if (type === 'jobOrderWidgetColumnSelector') {key = this.JOB_ORDER_MANAGEMENT_COLUMN_SELECTOR_KEY;}
    else if (type === 'offerWidgetColumnSelector') {key = this.OFFER_MANAGEMENT_COLUMN_SELECTOR_KEY;}
    return key;
  }

  resetColumnSelectorWidget(type) {
    let key: string;
    if (type === 'projectStatus') {key = this.PROJECT_STATUS_COLUMN_SELECTOR_KEY;}
    else if (type === 'taskManagerWidgetColumnSelector') {key = this.TASK_MANAGER_COLUMN_SELECTOR_KEY;}
    else if (type === 'taskManagerWidgetColumnSelector') {key = this.TASK_MANAGER_COLUMN_SELECTOR_KEY;}
    else if (type === 'jobOrderWidgetColumnSelector') {key = this.JOB_ORDER_MANAGEMENT_COLUMN_SELECTOR_KEY;}
    else if (type === 'offerWidgetColumnSelector') {key = this.OFFER_MANAGEMENT_COLUMN_SELECTOR_KEY;}
    this.storageSrv.remove(key);
  }

  setLanguagePreference(key, value) {
    this.storageSrv.set(key, value);
  }

  getLanguagePreference(key) {
    return this.storageSrv.get(key);
  }

  resetLanguagePreference() {
    this.storageSrv.remove('ln_pref');
  }

  clearAllLocalStorageInfo() {
    const lang_preff = this.getLanguagePreference('ln_pref');
    this.storageSrv.clear();
    this.storageSrv.set('ln_pref', lang_preff);
    localStorage.removeItem('sharePointHost');
    localStorage.removeItem('bluePrintCommunicationGroupId');
  }

  storeUserInfo(key, value) {
    this.storageSrv.set(key, value);
  }

 public getuserInfo(key) {
    const user = this.storageSrv.get(key);
    return user;
  }
}
