<ion-card>
  <ion-card-header *ngIf="showHeader">
    <div class="flex-container grid-block sticky-header">
      <div class="left-column dash-cont-title">
        {{ gridConfigItems.title }}
      </div>
      <div class="right-column" >
        <app-searchbar class="custom-searchbar" [(ngModel)]="searchText" [showSearchBar]=false placeholder="{{ gridConfigItems.placeholder }}"
             ngDefaultControl (ionChange)="onGridSearchTextChange($event)">
        </app-searchbar>
        <ion-button class="right-margin-12 btn-border" shape="round" fill="outline" 
              [hidden]="!gridConfigItems.isWritePermission" (click)="onButtonClick()">
              <ion-icon slot=start name='add-circle-outline' >
              </ion-icon>
              {{ gridConfigItems.buttonTitle }}
        </ion-button>
        <img tooltip="{{'More actions' | translate }}" alt="" [hidden]="!showKebabIcon" placement="top" class="ellipsis-icon ellipsis-right-margin" 
            src="../../../assets/icon/more.svg" (click)="openStatusPopover($event)">
      </div>
  </div>
  </ion-card-header>
  <!-- Shimmer card-content on ag-grid-->
  <ion-card-content *ngIf="sharedValue?.isGridDataLoading">
    <div class="shimmer-wrapper">
      <div class="shimmer-item" *ngFor="let cell of shimmerGrid"></div>
    </div>
  </ion-card-content>
  <ion-card-content class="card-container scrollVertical" *ngIf="!sharedValue?.isGridDataLoading">
    <div class="outer-div">
      <div class="grid-wrapper {{gridConfigItems.gridWrapperHeightClass}}"
           [ngStyle]="gridstyle"
           *ngIf="gridOptions">
          <ag-grid-angular #agGrid
                  [ngStyle]="gridWrapperstyle"
                  id="myGrid"
                  rowModelType="infinite"
                  cacheBlockSize=8
                  class="ag-theme-alpine"
                  [ngClass]="{'custom-hover': gridConfigItems.isHoverRowColumnHighlight}"
                  (gridReady)="onGridReady($event)"
                  (columnResized)="oncolumnResized($event)"
                  (cellClicked)="onCellClicked($event)"
                  [gridOptions]="gridOptions"
                  [suppressDragLeaveHidesColumns]="true"
                  [suppressMakeColumnVisibleAfterUnGroup]="true"
                  [rowGroupPanelShow]="rowGroupPanelShow"
                  [autoGroupColumnDef]="autoGroupColumnDef"
                  [enableRangeSelection]="true"
                  [groupHideOpenParents]="true"
                  [animateRows]="true"
                  [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                  [overlayLoadingTemplate]="overlayLoadingTemplate"
                  [pinnedBottomRowData]="pinnedBottomRowData"
                  [suppressRowHoverHighlight]="gridConfigItems.suppressRowHoverHighlight"
                  [columnHoverHighlight]="false"
                  [alwaysShowHorizontalScroll]="true">
          </ag-grid-angular>
      </div>
  </div>
  </ion-card-content>
</ion-card>
