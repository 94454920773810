import { Attribute, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiUrls } from 'src/app/utilities/api-urls';
import { protectedResources } from 'src/app/services/user-auth/auth-config';
import { Observable, identity } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root',
})
export class BackendCallService {
  constructor(public http: HttpClient) { }

  getProjectLists() {
    const _self = this;
    const urlParams = new HttpParams();
    return _self.http
      .get<any>(`${ApiUrls.getProjectWidget}`, { params: urlParams })
      .toPromise();
  }

  getAutocompleteSuggestions(data) {
    // const url = `${ApiUrls.getAutocompleteSuggestions}?q=${searchTerm}`;
    // return this.http.get(url).pipe(map((response:any) => response.response as string[])
    // );
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
        // 'Access-Control-Allow-Credentials" : "true",
        // 'Content-Type': 'application/json',
        'api-key': environment.azureSearchKey
      })
    };
    return this.http.post(ApiUrls.getAutocompleteSuggestions, data, httpOptions);
  }

  getServiceTypeByOrderId(orderId) {
    return this.http.get(`${ApiUrls.getServiceTypeByOrderId}${orderId}/servicesbyorder`);
  }

  deleteMachineLimitImage(data) {
    return this.http.post(`${ApiUrls.deleteMachineLimitImage}`, data);//?id={}
  }

  getProjectAuditLogs(data) {
    return this.http.post(`${ApiUrls.getProjectAuditLogs}`, data);//?id={}
  }

  getTaskManagerData() {
    const _self = this;
    const urlParams = new HttpParams();
    // return _self.http
    //   .get<any>(`${ApiUrls.getTaskManagers}`, { params: urlParams })
    //   .toPromise();
    return _self.http
      .get<any>(`${ApiUrls.getTaskManagers}`, { params: urlParams })
      .toPromise();
  }

  getprojectDetail(projectId) {
    const _self = this;
    const urlParams = new HttpParams();
    return _self.http
      .get<any>(`${ApiUrls.getProjectDetail}${projectId}`, {
        params: urlParams,
      })
      .toPromise();
  }

  getRegionByCompanyCode(companyCode) {
    const _self = this;
    const urlParams = new HttpParams();
    return _self.http
      .get<any>(`${ApiUrls.getRegionByCompanyCode}${companyCode}/regionbycompanycode`, {
        params: urlParams,
      })
      .toPromise();
  }

  addMachines(data) {
    return this.http.post(`${ApiUrls.addMachines}`, data);
  }

  addNewMachines(data) {
    return this.http.post(`${ApiUrls.addNewMachines}`, data);
  }

  createNewService(data) {
    return this.http.post(`${ApiUrls.createNewService}`, data);
  }

  deleteMachines(data) {
    return this.http.post(`${ApiUrls.deleteMachines}`, data);
  }

  deleteRoadmap(data) {
    return this.http.post(`${ApiUrls.deleteRoadmap}`, data);
  }

  deleteSistemaReport(data) {
    return this.http.post(`${ApiUrls.deleteSistemaReport}`, data);
  }

  deleteBucketMachines(data) {
    return this.http.delete(`${ApiUrls.deleteBucketMachine}${data}/Machine`);
  }

  deleteOpportunity(payload) {
    return this.http.post(`${ApiUrls.deleteOpportunity}`, payload);
  }

  getCompanyDetails(userId) {
    return this.http.get(`${ApiUrls.getCompanyDetails}${userId}`);
  }

  getRiskResssesmentMachinebyServiceId(serviceId) {
    return this.http.get(`${ApiUrls.getRiskResssesmentMachinebyServiceId}${serviceId}/rramachines`);
  }

  getProjecDetailOrderService(projectId) {
    const urlParams = new HttpParams();
    return this.http
      .get<any>(`${ApiUrls.getProjectDetailServiceOrder}${projectId}/projectorders`, {
        params: urlParams,
      })
      .toPromise();
  }

  getProjectDetailServiceListsInfo(orderId, serviceType, projectId) {
    const urlParams = new HttpParams();
    return this.http
      .get<any>(
        `${ApiUrls.getProjectDetailServiceListBasedonServiceOrderAndType}/${orderId}/${serviceType}/${projectId}`,
        {
          params: urlParams,
        }
      )
      .toPromise();
  }

  getProjectDetailMachineListsInfo(orderId, serviceType, projectId, isReport?) {
    const urlParams = new HttpParams();
    return this.http
      .get<any>(
        `${ApiUrls.getProjectDetailMachinesListBasedonServiceOrderAndType}/${orderId}/${serviceType}/${projectId}/${isReport}`,
        {
          params: urlParams,
        }
      )
      .toPromise();
  }

  getOverViewListsInfo(orderId, serviceType, projectId) {
    const urlParams = new HttpParams();
    return this.http
      .get<any>(
        `${ApiUrls.getOverviewLists}${orderId}/${serviceType}/${projectId}/overview`,
        {
          params: urlParams,
        }
      )
      .toPromise();
  }

  updateLogActualHoursInfo(logActualHoursInfo) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http
      .post<any>(ApiUrls.updateLogActualHours, JSON.stringify(logActualHoursInfo), httpOptions)
      .toPromise();
  }

  getOfferManagementDetails() {
    return this.http.get(`${ApiUrls.getOfferManagementDetails}`);
  }

  viewOfferDetailsById(project_id, offer_id) {
    return this.http.get(
      `${ApiUrls.viewOfferDetailsById}${offer_id},${project_id}`
    );
  }


  conflictedMachines(orderId: string, serviceId: string) {
    return this.http.get(`${ApiUrls.getSystemaData}${orderId}/${serviceId}/conflicts`);
  }

  conflictRoadmap(service_machineId) {
    const urlParams = new HttpParams();
    //https://schriskassessmentdev.azurewebsites.net/api/RiskAssessment/conflictroadmap?ServiceMachineId=dfas
    return this.http.get<any>(`${ApiUrls.conflicts}/${service_machineId}`).toPromise();
  }

  resolveConflicts(machieLists) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http
      .put<any>(ApiUrls.conflicts, JSON.stringify(machieLists), httpOptions)
      .toPromise();
  }


  viewOfferServiceDetails(service_id) {
    return this.http.get(
      `${ApiUrls.viewOfferServiceDetails}${service_id}`
    );
  }

  getServiceDetails(service_id, projId){
    return this.http.get(
      `${ApiUrls.getServiceDetails}${undefined}/${service_id}/${projId}`
    );
  }

  getServiceIdWithDetails(projId){
    return this.http.get(
    //  uncomment when API available and then delete the json file as well
     `${environment.projectBaseUrl}${projId}/servicesbyprojectid`
    );
  }
  getOfferMachineDetails(project_id, offer_id, service_id) {
    return this.http.get(
      `${ApiUrls.getOfferMachineDetails}${project_id},${offer_id},${service_id}`
    );
  }
  getProjectEffortMetrics() {
    const _self = this;
    const urlParams = new HttpParams();
    return _self.http
      .get<any>(`${ApiUrls.getProjectManagementEffortMetrics}`, {
        params: urlParams,
      })
      .toPromise();
  }

  getProjectSearchedCustomer(payload) {
    // let urlParams = new HttpParams();
    // return this.http
    //   .get<any>(`${ApiUrls.getSearchedCustomer}/${customerName}/${company_code}/search`, {
    //     params: urlParams,
    //   })
    //   .toPromise();
    // https://blueprint-d.dnaofsafety.net/api/customersvc/search
    return this.http.post(`${ApiUrls.getSearchedCustomer}`, payload).toPromise();
  }

  getProjecManagemetContactInfo(erp_Customer_Id) {
    const urlParams = new HttpParams();
    return this.http
      .get<any>(`${ApiUrls.getCustomerContactInfo}/${erp_Customer_Id}/searchbyid`, {
        params: urlParams,
      })
      .toPromise();
  }

  createProject(projectInfo) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    const body = JSON.stringify(projectInfo);
    return this.http
      .post<any>(ApiUrls.createProject, body, httpOptions)
      .toPromise();
  }

  editProject(projectInfo) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http
      .put<any>(ApiUrls.editProject, JSON.stringify(projectInfo), httpOptions)
      .toPromise();
  }

  createOffer(data: any) {
    return this.http.post(`${ApiUrls.createOffer}`, data);
  }

  getOrderManagementEffortmetrics() {
    const _self = this;
    const urlParams = new HttpParams();
    return _self.http
      .get<any>(`${ApiUrls.getOrderManagementEffortMetrics}`, {
        params: urlParams,
      })
      .toPromise();
  }

  getOrderManagementDetails() {
    const _self = this;
    const urlParams = new HttpParams();
    return _self.http
      .get<any>(`${ApiUrls.getOrderManagementOrderTrackerDeatils}`, {
        params: urlParams,
      })
      .toPromise();
  }

  getOrderDetail(id) {
    const _self = this;
    const urlParams = new HttpParams();
    return _self.http
      .get<any>(`${ApiUrls.getOrderManagementOrderDeatil}${id}/orders`, {
        params: urlParams,
      })
      .toPromise();
  }

  // deleteOffer(data) {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //     })
  //   };
  //   return this.http.post(`${ApiUrls.deleteOffer}`, data);
  // }

  deleteOffer(data) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post<any>(ApiUrls.deleteOffer, data, httpOptions)
      .toPromise();
  }

  deleteOfferService(data: any) {
    return this.http.post(`${ApiUrls.deleteOfferservice}`, data);
  }

  deleteOfferId(data: any) {
    return this.http.post(`${ApiUrls.deleteOffer}`, data);
  }

  editMachines(data: any) {
    return this.http.post(`${ApiUrls.editMachines}`, data);
  }

  getOfferStatusService() {
    return this.http.get(`${ApiUrls.getOfferStatusService}`);
  }

  getMachineRoadmaps(machineId) {
    return this.http.get(`${ApiUrls.getMachineRoadmaps}${machineId}/machineroadmaps`);
  }

  offerEffortmetrics() {
    return this.http.get(
      `${ApiUrls.offerEffortmetrics}`
      // https://schmersalapiservice.azurewebsites.net/api/OfferStatusService/effortmetrics
    );
  }

  searchServiceCustomerName(ev) {
    const urlParams = new HttpParams(); //https://schcustomerapi.azurewebsites.net/api/Customer/search/j
    // return this.http.get(`${ApiUrls.searchServiceCustomerName}/GetCustomerName?customerName=${ev}`, {
    return this.http.get(`${ApiUrls.searchServiceCustomerName}/GetCustomerName?customerName=${ev}`, {
      params: urlParams,
    });
  }

  offerCustomerInfoSearchByErpId(erp_Customer_Id) {
    const urlParams = new HttpParams();
    return this.http.get(`${ApiUrls.offerCustomerInfo}/${erp_Customer_Id}/customersearch`, {
      params: urlParams,
    });
  }

  // customerProjectIdByEmail(data) {
  //   let urlParams = new HttpParams();
  //   return this.http.get(
  //     `https://schofferstatusapi.azurewebsites.net/api/OfferStatusService/getprojectsids/${data}`,
  //     {
  //       params: urlParams,
  //     }
  //   );
  // }

  customerProjectIdByCustName(data) {
    return this.http.get(`${ApiUrls.customerProjectIdByCustName}${data}`);
  }
  // getmastermachinedetails() {
  //   return this.http.get(`${ApiUrls.getmastermachinedetails}`);
  // }

  getBucketLists(projectId, orderId, serviceType, service_Id?) {
    // let urlParams = new HttpParams().set('project_id', projectId).set('order_id', orderId).set('service_type', serviceType)
    const urlParams = new HttpParams();
    return this.http
      .get<any>(`${ApiUrls.bucketLists}${service_Id}/Buckets`, {
        params: urlParams,
      })
      .toPromise();
  }

  getServiceLevelRoadmap(projectId, service_Id) {
    // let urlParams = new HttpParams().set('project_id', projectId).set('order_id', orderId).set('service_type', serviceType)
    const urlParams = new HttpParams();
    return this.http
      .get<any>(`${ApiUrls.serviceRoadmap}${projectId}/${service_Id}/configureserviceroadmaps`, {
        params: urlParams,
      })
      .toPromise();
  }



  createNewBucket(bucketInfo) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http
      .post<any>(ApiUrls.createBucket, JSON.stringify(bucketInfo), httpOptions)
      .toPromise();
  }

  addMachineToExistingBucket(bucketInfo) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http
      .post<any>(ApiUrls.addmachinesToExistingBucket, JSON.stringify(bucketInfo), httpOptions)
      .toPromise();
  }

  editBuckets(bucketInfo) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http
      .post<any>(ApiUrls.editBucket, JSON.stringify(bucketInfo), httpOptions)
      .toPromise();
  }

  deleteBuckets(bucketInfo) {
    // let headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    // });
    // return this.http
    //   .delete<any>(ApiUrls.deleteBuckets, { headers, body: JSON.stringify(bucketInfo) })
    //   .toPromise();
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http
      .post<any>(ApiUrls.deleteBuckets, JSON.stringify(bucketInfo), httpOptions)
      .toPromise();
  }

  getMachinesAddedToBucket(bucket_Id) {
    const urlParams = new HttpParams();
    return this.http
      .get<any>(`${ApiUrls.getMachinesListsInAddedBucket}${bucket_Id}/Machines`, {
        params: urlParams,
      })
      .toPromise();
  }


  uploadDocument(data: any) {
    return this.http.post(`${ApiUrls.uploadLibrary}`, data);
  }

  getNotification() {
    return this.http.get(`${ApiUrls.getNotification}`);
  }

  /**
   * get Projects charts metrics based on ranges
   *
   * @param dateRange
   * @returns
   */
  getProjectChartsData(dateRange: string) {
    return this.http.get(`${ApiUrls.getProjectChartsData}${dateRange}/dailymetrics`);
  }

  /**
   * get Offer charts metrics based on ranges
   *
   * @param dateRange
   * @returns
   */
  getOfferChartsData(dateRange: string) {
    return this.http.get(`${ApiUrls.getOffertChartsData}/${dateRange}/dailymetrics`);
  }

  /**
   * get Order charts metrics based on ranges
   *
   * @param dateRange
   * @returns
   */
  getOrderChartsData(dateRange: string) {
    return this.http.get(`${ApiUrls.getOrdderChartsData}${dateRange}/dailymetrics`);
  }

  uploadMasterData(data: any) {
    return this.http.post(`${ApiUrls.uploadMasterData}`, data);
  }

  uploadRoadMapData(data) {
    return this.http.post(`${ApiUrls.uploadRoadMap}`, data);
  }

  uploadControlMeasure(data) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post(`${ApiUrls.counterMeasure}`, data, httpOptions);
  }

  uploadIntialHazard(data) {
    return this.http.post(`${ApiUrls.IntialHazard}`, data);
  }

  searchMachineMaster(data: any) {
    return this.http.get(`${ApiUrls.searchMachineMaster}/${data}`);
  }

  searchCustomerMachines(customerId: any, count: number, increasedCount: number) {
    return this.http.get(`${ApiUrls.searchCustomerMachines}${customerId}/${count}/${increasedCount}/searchcustomermachines`);
  }

  getMachinesFromLibrary(data) {
    return this.http.post(`${ApiUrls.machinesFromLibrary}`, data);
  }

  searchMachineMasterWithIndex(data: any, count: number, increasedCount: number) {
    return this.http.get(`${ApiUrls.searchMachineMaster}/${data}/${count}/${increasedCount}`);
  }

  searchPreviousProjectMachineMasterWithIndex(data: any, count: number, increasedCount: number) {
    return this.http.get(`${ApiUrls.searchPreviousProjectMachineMasterWithIndex}/${data}/${count}/${increasedCount}`);
  }

  getMasterLibrary() {
    return this.http.get(`${ApiUrls.getMachineLibrary}`);
  }

  /**
   * Gets machines master data
   *
   * @param data data
   *
   * @returns Machines Master Data
   */
  public getMachinesMasterData(data) {
    return this.http.post(`${ApiUrls.getMachineLibrary}`, data);
  }

  machineApplicationData(payload) {
    return this.http.post(`${ApiUrls.machineApplicationData}`, payload);
  }

  machineMachineTypeData(payload) {
    return this.http.post(`${ApiUrls.machineMachineTypeData}`, payload);
  }

  machineSpecificFeatureData(payload) {
    return this.http.post(`${ApiUrls.machineSpecificFeatureData}`, payload);
  }

  machineControlSystemData(payload) {
    return this.http.post(`${ApiUrls.machineControlSystemData}`, payload);
  }

  getMachinesAttributesData(machineId) {
    return this.http.get(`${ApiUrls.machineAttirbutesData}/${machineId}`);
  }

  getMachinesReferrncesList(machineId) {
    return this.http.get(`${ApiUrls.getMachinesReferrncesList}/${machineId}`);
  }

  generatedLayoutedQuote(data) {
    return this.http.post(`${ApiUrls.generatedLayoutedQuote}`, data);
  }

  generateRiskAssessmentReport(data) {
    return this.http.post(`${ApiUrls.generateRiskAssessmentReport}`, data);
  }

  getFinalQuoteDblist(offerId) {
    return this.http.get(`${ApiUrls.getFinalQuoteDblist}/${offerId}/finalquoteversions`);
  }

  getCommercialQuote(offerId) {
    return this.http.get(`${ApiUrls.getcommercialquote}/${offerId}/getcommercialquote`);
  }

  getCommercialQuoteVersions(offerId) {
    return this.http.get(`${ApiUrls.getcommercialquote}/${offerId}/commercialquoteversions`);
  }

  uploadGenerateQuote(payload) {
    return this.http.post(`${ApiUrls.uploadGenerateQuote}`, payload);
  }

  generateQuotation(payload) {
    return this.http.post(`${ApiUrls.generateQuotation}`, payload);
  }

  saveFinalQuoteDetails(payload) {
    return this.http.post(`${ApiUrls.saveFinalQuoteDetails}`, payload);
  }

  getRoadMapDetails(payload: string) {
    // return this.http.get(`${ApiUrls.getRoadMapLibrary}`)
    return this.http.get(`${ApiUrls.getsavedLibrariesSavedData}${payload}/legislations`);
  }

  getRoadMapLibraryList() {
    // return this.http.get(`${ApiUrls.getRoadMapLibrary}`)
    return this.http.get(`${ApiUrls.PredefinedTemplateRoadMap}`);
  }

  getNewRoadMapDetails() {
    // return this.http.get(`${ApiUrls.getRoadMapLibrary}`)
    return this.http.get(`${ApiUrls.getNewRoadMapDetails}`);
  }

  getRoadmapSteps(roadmapId, userId, serviceMachineId, project_id) {
    // return this.http.get(`${ApiUrls.getRoadmapSteps+"/4afed9b0-4b2b-4256-83ce-15a1b19c8e01"}`);
    return this.http.get(`${ApiUrls.getRoadmapSteps}/${roadmapId}/${userId}/${serviceMachineId}/${project_id}`);
  }

  getServiceRoadmapSteps(roadmapId, userId, serviceMachineId, project_id) {
    // return this.http.get(`${ApiUrls.getRoadmapSteps+"/4afed9b0-4b2b-4256-83ce-15a1b19c8e01"}`);
    return this.http.get(`${ApiUrls.getServiceRoadmapSteps}${roadmapId}/${userId}/${serviceMachineId}/${project_id}/serviceroadmap`);
  }

  getCustomTemplateRoadMap(roadmapId) {
    return this.http.get(`${ApiUrls.getCustomTemplateRoadMapData}/${roadmapId}/CustomTemplateRoadMap`);
  }

  getRoadmapList() {
    return this.http.get(`${ApiUrls.getRoadmapList}`);
  }

  getMachineModesList() {
    return this.http.get(`${ApiUrls.getMachineModesList}`);
  }

  upDateMachineModesList(data) {
    return this.http.post(`${ApiUrls.upDateMachineModesList}`, data);
  }

  deleteRoadmapSectionsData(data) {
    return this.http.post(`${ApiUrls.deleteRoadmapSectionsData}`, data);
  }

  deleteTemplateRoadmapSectionsData(data) {
    return this.http.post(`${ApiUrls.deleteTemplateRoadmapSectionsData}`, data);
  }

  getProjectBudgetInfo(projectId, serviceId, orderId) {
    return this.http.get(`${ApiUrls.getProjectBudgetInfo}${projectId}/${serviceId}/${orderId}/projectcost`);
  }

  getLogActualHoursList(serviceMachineId, userId) {
    return this.http.get(`${ApiUrls.getLogActualHoursList}/${serviceMachineId}/${userId}/logactualhours`);
  }

  getLifeCyclePhasesList() {
    return this.http.get(`${ApiUrls.getLifeCyclePhasesList}`);
  }

  configureRoadmap(data) {
    return this.http.post(`${ApiUrls.configureRoadmap}`, data);
  }

  configureServiceRoadmap(data) {
    return this.http.post(`${ApiUrls.configureServiceRoadmap}`, data);
  }

  getMachineLimits(Service_machineId) {
    return this.http.get(`${ApiUrls.getMachineLimits}${Service_machineId}/machinelimits`);
  }

  machineLimits(data) {
    return this.http.post(`${ApiUrls.machineLimits}`, data);
  }

  finalRaReport(data) {
    return this.http.post(`${ApiUrls.finalRaReport}`, data);
  }

  saveRaReport(data) {
    return this.http.post(`${ApiUrls.saveRaReport}`, data);
  }

  getRAReports(project_id) {
    return this.http.get(`${ApiUrls.getRAReports}/${project_id}`);
  }

  getConfiguredroadmaps(id, project_id) {
    return this.http.get(`${ApiUrls.getConfiguredroadmaps}${id}/${project_id}/configuredroadmaps`);
  }

  createCustomRoadmap(roadmapLists) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http
      .post<any>(ApiUrls.createCustomRoadmap, JSON.stringify(roadmapLists), httpOptions)
      .toPromise();
  }

  // addCustomRoadmap(data) {
  //   return this.http.post(`${ApiUrls.addCustomRoadmap}`, data);
  // }

  // addCustomsection(data) {
  //   return this.http.post(`${ApiUrls.addCustomsection}`, data);
  // }

  // addCustomSubSection(data) {
  //   return this.http.post(`${ApiUrls.addCustomSubSection}`, data);
  // }

  upadteMachineLimits(data) {
    return this.http.put(`${ApiUrls.machineLimits}`, data);
  }

  // gethazardTypeList() {
  //   return this.http.get(`${ApiUrls.gethazardTypeList}`)
  // }

  // gethazardSourceList() {
  //   return this.http.get(`${ApiUrls.gethazardSourceList}`)
  // }

  // gethazardConsequencesList() {
  //   return this.http.get(`${ApiUrls.gethazardConsequencesList}`)
  // }

  getInitialHazardsList() {
    return this.http.get(`${ApiUrls.getInitialHazardsList}`);
  }

  getCounterMeasuresList() {
    return this.http.get(`${ApiUrls.getCounterMeasuresList}`);
  }

  getInitialHazardsListWithIndex(payload) {
    return this.http.post(`${ApiUrls.getInitialHazardsList}`, payload);
  }

  getCounterMeasuresListWithIndex(payload) {
    return this.http.post(`${ApiUrls.getCounterMeasuresList}`, payload);
  }

  getRoadmapListWithIndex(payload) {
    // return this.http.get(`${ApiUrls.getRoadmapListWithIndex}${count}/${increasedCount}/roadmaps`);
    return this.http.post(`${ApiUrls.getRoadmapListWithIndex}roadmaps`, payload);
  }

  extendRoadmap(data) {
    return this.http.post(`${ApiUrls.extendRoadmap}`, data);
  }

  extendRoadmapv1(data) {
    return this.http.post(`${ApiUrls.extendRoadmapv1}`, data);
  }


  extendServiceRoadmap(data) {
    return this.http.post(`${ApiUrls.extendServiceRoadmap}`, data);
  }

  saveToTemplate(data) {
    return this.http.post(`${ApiUrls.saveToTemplate}`, data);
  }

  deleteTemplateRoadmap(data) {
    return this.http.post(`${ApiUrls.deleteTemplateRoadmap}`, data);
  }

  raMarkAsComplete(markAsComleteInfo) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post<any>(ApiUrls.markAsComplete, JSON.stringify(markAsComleteInfo), httpOptions)
      .toPromise();
  }

  raServiceMarkAsComplete(markAsComleteInfo) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post<any>(ApiUrls.raServiceMarkAsComplete, JSON.stringify(markAsComleteInfo), httpOptions)
      .toPromise();
  }



  createBookmark(userId, data) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post<any>(ApiUrls.onAddBookmark + userId, JSON.stringify(data), httpOptions)
      .toPromise();
  }

  getBookmarDataDetails(userId) {
    return this.http.get(`${ApiUrls.getSelectedBookmarkData + userId}`);
  }

  getConsoleLibrary(userId) {
    return this.http.get(`${ApiUrls.getCounterMeasure + '/' + userId}`);
  }

  getInitialMeasure(userId) {
    return this.http.get(`${ApiUrls.getInitialMeasure + '/' + userId}`);
  }

  /**
   * Gets initial hazards
   * 
   * @param userId 
   * @param offset 
   * @param searchText 
   * @returns  
   */
 public getInitialHazards(userId, offset,searchText) {
    const headers = new HttpHeaders({
      'API-Version': '2.0'
    });
    return this.http.get(`${ApiUrls.getInitialMeasure}/${userId}/${offset}/${searchText}`, {headers});
  }

  /**
   * Gets static template
   * 
   * @param offset 
   * @param searchText 
   * @param templateType 
   * @param language 
   * @returns StaticTemplate
   */
  public getStaticTemplate(offset, searchText, templateType, language, isDropDownRequest) {
    const headers = new HttpHeaders({
      'API-Version': '2.0'
    });
    return this.http.get(`${ApiUrls.getStaticTemplate}/${templateType}/${language}/${offset}/${isDropDownRequest}/${searchText}`, { headers });
  }


  /**
   * Gets control measures
   * 
   * @param userId 
   * @param offset 
   * @param searchText 
   * @returns  
   */
 public getControlMeasures(userId, offset,searchText) {
    const headers = new HttpHeaders({
      'API-Version': '2.0'
    });
    return this.http.get(`${ApiUrls.getControlMeasure}/${userId}/${offset}/${searchText}`, {headers});
  }
  /**
   * Update or delete initial hazard
   *
   * @param data
   * @returns
   */
  updateDeleteInitialHazard(data) {
    return this.http.post(`${ApiUrls.updateDeleteInitialHazard}`, data);
  }

  /**
   * Update or delete control measure
   *
   * @param data
   * @returns
   */
  updateDeleteControlMeasures(data) {
    return this.http.post(`${ApiUrls.updateDeleteControlMeasures}`, data);
  }

  deleteBookmarkData(id) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post<any>(ApiUrls.onRemoveBookmark, id, httpOptions)
      .toPromise();
  }

  getOpportunitiesData() {
    return this.http.get(`${ApiUrls.getOpportunitiesDataList}`);
  }

  deleteAssignUser(data) {
    return this.http.post(`${ApiUrls.AssignUserDelete}`, data);
  }

  controlMeasureAddToLibrary(data) {
    return this.http.post(`${ApiUrls.controlMeasureAddToLibrary}`, data);
  }

  initialMeasureAddToLibrary(data) {
    return this.http.post(`${ApiUrls.initialMeasureAddToLibrary}`, data);
  }

  deleteMediaFile(data) {
    return this.http.post(`${ApiUrls.deleteMediaFile}`, data);
  }

  getOpportunitiesIdData(projec_id) {
    return this.http.get(
      `${ApiUrls.getOpportunitiesIdData}/${projec_id}`
    );
  }

  getServiceType(data) {
    // return this.http.get(
    //   `${ApiUrls.getServiceType}/${data}`
    // );
    return this.http.post(`${ApiUrls.getServiceType}`, data);
  }


  // User Management Console
  getAllUsers() {
    const _self = this;
    const urlParams = new HttpParams();
    return _self.http
      .get<any>(`${ApiUrls.getUsers}`, {
        params: urlParams,
      })
      .toPromise();
  }

  getAllProjectUsers(search) {
    return this.http.get(
      `${ApiUrls.getUsers}/${search}`
    );
  }

  getAllRoles() {
    const _self = this;
    const urlParams = new HttpParams();
    return _self.http
      .get<any>(`${ApiUrls.getRoles}`, {
        params: urlParams,
      })
      .toPromise();
  }

  getAllAssignedUsersToRole(roleid) {
    const _self = this;
    const urlParams = new HttpParams();
    return _self.http
      .get<any>(`${ApiUrls.getAssignedUsersInRole}/${roleid}/users`, {
        params: urlParams,
      })
      .toPromise();
  }

  getAllAssignedUsersToGroup(groupid) {
    const _self = this;
    const urlParams = new HttpParams();
    return _self.http
      .get<any>(`${ApiUrls.createGroupAndAddusers}/group/${groupid}/users`, {
        params: urlParams,
      })
      .toPromise();
  }

  revokeRoleFromUser(roleInfo) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http
      .post<any>(ApiUrls.revokeRoleFromUser, JSON.stringify(roleInfo), httpOptions)
      .toPromise();
  }

  revokeGroupFromUser(groupInfo) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http
      .post<any>(ApiUrls.revokeGroupFromUser, JSON.stringify(groupInfo), httpOptions)
      .toPromise();
  }

  getAllRoleAssignments() {
    const _self = this;
    const urlParams = new HttpParams();
    return _self.http
      .get<any>(`${ApiUrls.getRoleAssignments}`, {
        params: urlParams,
      })
      .toPromise();
  }

  getAllRbacPermissions() {
    const _self = this;
    const urlParams = new HttpParams();
    return _self.http
      .get<any>(`${ApiUrls.getRbacPermissions}`, {
        params: urlParams,
      })
      .toPromise();
  }

  getRolesWithAssignedPermission() {
    const _self = this;
    const urlParams = new HttpParams();
    return _self.http
      .get<any>(`${ApiUrls.getRbacRolesWithPermissions}`, {
        params: urlParams,
      })
      .toPromise();
  }

  updateRbacPermissions(permissionPayload) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http
      .post<any>(ApiUrls.updateRbacPermissions, JSON.stringify(permissionPayload), httpOptions)
      .toPromise();
  }

  getAllGroupsWithCount() {
    const _self = this;
    const urlParams = new HttpParams();
    return _self.http
      .get<any>(`${ApiUrls.getGroupsWithCount}`, {
        params: urlParams,
      })
      .toPromise();
  }

  searchUserInfoToAdd() {
    const _self = this;
    const urlParams = new HttpParams();
    return _self.http
      .get<any>(`${ApiUrls.getSearchedUserInfoToAdd}`, {
        params: urlParams,
      })
      .toPromise();
  }

  addNewUsersToAd(userLists) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http
      .post<any>(ApiUrls.addNewUsersToAd, JSON.stringify(userLists), httpOptions)
      .toPromise();
  }

  addNewUserProj(payload) {
    return this.http.post(`${ApiUrls.addProjectUser}`, payload);
  }

  addNewRole(newRole) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http
      .post<any>(ApiUrls.addNewRole, JSON.stringify(newRole), httpOptions)
      .toPromise();
  }

  assignRoleToUsers(roleAssignmentInfo) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http
      .post<any>(ApiUrls.addRoleToUsers, JSON.stringify(roleAssignmentInfo), httpOptions)
      .toPromise();
  }

  createGroupAndAddUsers(groupLists) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http
      .post<any>(ApiUrls.createGroupAndAddusers, JSON.stringify(groupLists), httpOptions)
      .toPromise();
  }

  deleteUser(userid) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http
      .delete<any>(`${ApiUrls.deleteUser}/${userid}`, { headers })
      .toPromise();
  }

  deleteRole(roleid) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http
      .delete<any>(`${ApiUrls.deleteRole}/${roleid}`, { headers })
      .toPromise();
  }

  editAllRoleName(allRoleInfo) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http
      .put<any>(ApiUrls.addNewRole, JSON.stringify(allRoleInfo), httpOptions)
      .toPromise();
  }

  deleteGroup(groupid) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http
      .delete<any>(`${ApiUrls.deleteGroup}/${groupid}`, { headers })
      .toPromise();
  }

  editGroupName(groupInfo) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http
      .put<any>(ApiUrls.deleteGroup, JSON.stringify(groupInfo), httpOptions)
      .toPromise();
  }

  getFourEyeQualityPerformInfo(servicemachineid, userId) {
    const urlParams = new HttpParams();
    return this.http.get(`${ApiUrls.getFourEyeQualityPerformInfo}${servicemachineid}/${userId}/performrequst4eyesquality`,
      { params: urlParams }).toPromise();
  }

  getFourEyeQualityRoadMapLists(servicemachineid) {
    const urlParams = new HttpParams();
    return this.http.get(`${ApiUrls.getFourEyeQualityPerformInfo}${servicemachineid}/4eyesqualityroadmaps`,
      { params: urlParams }).toPromise();
  }

  getFourEyeQualityHazardCheckListsInfo(hazardmachineid) {
    const urlParams = new HttpParams();
    return this.http.get(`${ApiUrls.getFourEyeQualityPerformInfo}${hazardmachineid}/hazarddetails`,
      { params: urlParams }).toPromise();
  }

  requestFourEyeQualityInfo(requestFourEyeQualityPayload) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http
      .post<any>(ApiUrls.requestFourEyeQuality, JSON.stringify(requestFourEyeQualityPayload), httpOptions)
      .toPromise();
  }

  completeFourEyesQUality(markCompletePayload) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http
      .post<any>(ApiUrls.completeFourEyeQuality, JSON.stringify(markCompletePayload), httpOptions)
      .toPromise();
  }


  saveEditedHazardInfoInFourEyeQuality(hazardInfo) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http
      .put<any>(ApiUrls.saveEditedHazardInfoInFourEyeQuality, JSON.stringify(hazardInfo), httpOptions)
      .toPromise();
  }

  azureSearchresults(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
        // 'Access-Control-Allow-Credentials" : "true",
        // 'Content-Type': 'application/json',
        'api-key': environment.azureSearchKey
      })
    };
    return this.http.post<any>(ApiUrls.getAzureSearch, data, httpOptions);
  }


  getToken() {
    return this.http.get<any>(protectedResources.tokenService.endpoint + '/Token');
  }
  isEditOfferSave(editPayload) {
    return this.http.put(`${ApiUrls.editApproveRejectDeleteOffer}`, editPayload);
  }

  isApproveOfferSave(approvePayload) {
    return this.http.put(`${ApiUrls.editApproveRejectDeleteOffer}`, approvePayload);
  }

  assignUserToOffer(approvePayload) {
    return this.http.post(`${ApiUrls.assignUserToOffer}`, approvePayload);
  }

  isRejectedOfferSave(rejectedPayload) {
    return this.http.put(`${ApiUrls.editApproveRejectDeleteOffer}`, rejectedPayload);
  }

  isEditOfferServiceSave(editServicePayload) {
    return this.http.put(`${ApiUrls.editOfferServiceUpdate}`, editServicePayload);
  }

  getUser(payload) {
    return this.http.get(`${ApiUrls.getUser}/${payload}`, payload);
  }

  updateProfileData(payload) {
    return this.http.put(`${ApiUrls.updateProfileBookmarkData}`, payload);
  }

  isUploadSystemaReport(payload) {
    return this.http.post(`${ApiUrls.uploadSystemaFile}`, payload);
  }

  machineImageUpload(payload) {
    return this.http.post(`${ApiUrls.machinemedia}`, payload);
  }

  isUploadlegislationReport(payload) {
    return this.http.post(`${ApiUrls.uploadlegislationFile}`, payload);
  }

  isSavestandardDocuments(payload) {
    return this.http.post(`${ApiUrls.uploadStandardDocFile}`, payload);
  }

  updateTemplateRoadmap(payload) {
    return this.http.post(`${ApiUrls.updateTemplateRoadmap}`, payload);
  }

  updateSiteDetails(payload) {
    return this.http.post(`${ApiUrls.updateSiteDetails}`, payload);
  }

  getSiteDetails(siteName: string) {
    return this.http.get(`${ApiUrls.getSiteDetails}/${siteName}/sitedetails`);
  }

  getSystemaFileInfo(servicemachineid) {
    return this.http.get(
      `${ApiUrls.getSystemaData}${servicemachineid}/sistemareport`
    );
  }

  getMachineImages(servicemachineid) {
    return this.http.get(
      `${ApiUrls.getSystemaData}${servicemachineid}/machinemedia`
    );
  }

  /**
   * Gets upload libraries file info
   *
   * @param payload payload
   * @param userId userId
   * @returns LibrariesFileInfo
   */
  public getUploadLibrariesFileInfo(payload, userId) {
    return this.http.get(
      `${ApiUrls.getsavedLibrariesSavedData}${payload}/${userId}/legislations`
    );
  }

  createMachine(machineInfo) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    const body = JSON.stringify(machineInfo);
    return this.http
      .post<any>(ApiUrls.createMachine, body, httpOptions)
      .toPromise();
  }

  getApplicationData(data) {
    return this.http.get(`${ApiUrls.getMasterMachineData}/${data.search}/${data.count}/${data.increasedCount}/applications`);
  }

  getMachineTypeData(data) {
    return this.http.get(`${ApiUrls.getMasterMachineData}/${data.search}/${data.count}/${data.increasedCount}/machinetypes`);
  }

  getFeaturesData(data) {
    return this.http.get(`${ApiUrls.getMasterMachineData}/${data.search}/${data.count}/${data.increasedCount}/specificfeatures`);
  }

  getControlSystemData(data) {
    return this.http.get(`${ApiUrls.getMasterMachineData}/${data.search}/${data.count}/${data.increasedCount}/controlsystems`);
  }

  getMachineReferencesForBulkUpload() {
    return this.http.get(`${ApiUrls.getMasterMachineData}/GetMachineReferencesForBulkUpload`);
  }

  getSegments(customerId) {
    const urlParams = new HttpParams();
    return this.http.get(`${ApiUrls.offerCustomerInfo}/${customerId}/segments`, {
      params: urlParams,
    });
  }

  deleteApplicationData(data) {
    return this.http.post(`${ApiUrls.deleteApplicationData}`, data);
  }

  editMachineLevelAttributes(data) {
    return this.http.put(`${ApiUrls.editMachineLevelAttributes}`, data);
  }

  getMachineReferncesList(machineId) {
    return this.http.get(`${ApiUrls.getMasterMachineData}/machineReferences/${machineId}`);
  }

  deleteControlSystem(data) {
    return this.http.post(`${ApiUrls.deleteControlSystemData}`, data);
  }

  deleteMachineType(data) {
    return this.http.post(`${ApiUrls.deleteMachineTypeData}`, data);
  }
  deleteSpecificFeature(data) {
    return this.http.post(`${ApiUrls.deleteFeaturesData}`, data);
  }

  /**
   * API call for save RA Template
   *
   * @param data
   * @returns
   */
  saveStaticRATemplate(data) {
    return this.http.post(`${ApiUrls.saveStaticRATemplate}`, data);
  }

  /**
   * Gets templates data
   *
   * @param templateType templateType
   * @param langauge langauge
   * @returns TemplatesData
   */
  getTemplatesData(templateType, langauge) {
    return this.http.get(`${ApiUrls.getTemplatesData}/${templateType}/${langauge}`);
  }

  /**
   * Gets templates data for quotation
   * 
   * @param templateType 
   * @param langauge 
   * @returns  
   */
  getTemplatesDataForQuotation(templateType, langauge) {
    return this.http.get(`${ApiUrls.getTemplatesDataForQuotation}/${templateType}/${langauge}`);
  }

  /**
   * Gets attributes
   *
   * @param data data
   * @param attributeType attributeType
   * @returns attributes
   */
  public getAttributes(data, attributeType) {
    return this.http.get(`${ApiUrls.getMasterMachineData}/${data.search}/${data.count}/${data.increasedCount}/${attributeType}`);
  }

  /**
   * Gets machine list
   *
   * @param data
   * @returns Machine List
   */
  public getMachineList(data) {
    return this.http.post(`${ApiUrls.getMachineList}`, data);

  }

  /**
   * Add update machine images
   *
   * @param data
   * @returns
   */
  addUpdateMachineImages(data) {
    return this.http.post(`${ApiUrls.addUpdateMachineImages}`, data);
  }

  /**
   * Get machine images
   *
   * @param machineId
   * @returns machine images
   */
  getMachineAllImages(machineId) {
    return this.http.get(`${ApiUrls.getMasterMachineData}/GetMachineImages/${machineId}`);
  }

  /**
   * Gets all sales organization
   *
   * @returns sales organization
   */
  public getAllSalesOrganization() {
    const _self = this;
    const urlParams = new HttpParams();
    return _self.http
      .get<any>(`${ApiUrls.getAllSalesOrganization}`, { params: urlParams })
      .toPromise();
  }

  /**
   * Gets all country
   *
   * @returns country
   */
  public getCountry() {
    const _self = this;
    const urlParams = new HttpParams();
    return _self.http
      .get<any>(`${ApiUrls.getCountry}`, { params: urlParams })
      .toPromise();
  }

  /**
   * Gets all customer
   *
   * @returns all customers
   */
  public getAllCustomer() {
    const _self = this;
    const urlParams = new HttpParams();
    return _self.http
      .get<any>(`${ApiUrls.getAllCustomer}`, { params: urlParams })
      .toPromise();
  }

  /**
   * Gets all contact
   *
   * @returns contacts
   */
  public getAllContact() {
    const _self = this;
    const urlParams = new HttpParams();
    return _self.http
      .get<any>(`${ApiUrls.getAllContact}`, { params: urlParams })
      .toPromise();
  }

  public getCustomerByCompanyId(companyId) {
    return this.http.get(`${ApiUrls.getCustomerByCompanyId}/${companyId}`);
  }

  /**
   * Adds or update sales organization
   *
   * @param data
   * @returns organization data
   */
  public addOrUpdateSalesOrganization(data) {
    return this.http.post(`${ApiUrls.addOrUpdateSalesOrganization}`, data);
  }

  /**
   * Adds or update customer
   *
   * @param data
   * @returns customer data
   */
 public addOrUpdateCustomer(data) {
    return this.http.post(`${ApiUrls.addOrUpdateCustomer}`, data);
  }

  /**
   * Creates contact
   *
   * @param data
   * @returns contact data
   */
  public createContact(data) {
    return this.http.post(`${ApiUrls.createContact}`, data);
  }

  /**
   * Gets menu
   *
   * @param userId
   * @returns menu items
   */
  public getMenuItems(userId) {
    return this.http.get(`${ApiUrls.getMenu}${userId}`);
  }

  /**
   * Gets view component details
   *
   * @param componentId
   * @param parameterId
   * @returns view component details
   */
  public getViewComponentDetails(componentId, parameterId) {
    return this.http.get(`${ApiUrls.getViewComponentDetails}${componentId}/${parameterId}`);
  }

  /**
   * Gets all sales orgs
   *
   * @param componentId
   * @param parameterId
   * @returns All Sales Orgs
   */
  public getAllSalesOrgs() {
    return this.http.get(`${ApiUrls.getAllSalesOrgs}`);
  }

  /**
   * Adds user sales org
   *
   * @param data
   * @returns sales org response
   */
  public addUserSalesOrg(data) {
    return this.http.post(`${ApiUrls.addUserSaleOrgAssociation}`, data);
  }

  /**
   * Gets user sales org
   *
   * @param userId
   * @returns user sales org
   */
  public getUserSalesOrg(userId) {
    return this.http.get(`${ApiUrls.getUserSalesOrg}${userId}`);
  }

  /**
   * Gets all service material
   *
   * @returns AllServiceMaterial
   */
  public getAllServiceMaterial() {
    const _self = this;
    const urlParams = new HttpParams();
    return _self.http
      .post<any>(`${ApiUrls.getAllServiceMaterial}`, { params: urlParams })
      .toPromise();
  }

  /**
   * Adds or update service material
   *
   * @param data
   * @returns ServiceMaterial
   */
  public addOrUpdateServiceMaterial(data) {
    return this.http.post(`${ApiUrls.addUpdateServiceMaterial}`, data);
  }

  /**
   * Gets machine details
   *
   * @param serviceId
   * @param projectId
   * @returns MachineDetails
   */
 public getMachineDetails(serviceId, projectId) {
    return this.http.get(`${ApiUrls.getMachineDetails}${serviceId},${projectId}`);
  }

  /**
   * Saves template
   * 
   * @param data 
   * @returns 
   */
  public saveTemplate (data) {
    return this.http.post(`${ApiUrls.saveTemplate}`, data);
  }

  /**
   * To get the steps detail of roadmap
   * @param data
   * @returns
   */
  public getAllStepsByRoadmapId(data) {
    return this.http.post(`${ApiUrls.getAllStepsByRoadmapId}`, data);
  }

  /**
   * To get the roadmaps detail of machine
   * @param data
   * @returns
   */
  public getRoadmapsByServiceMachineId(data) {
    return this.http.post(`${ApiUrls.getRoadmapsByServiceMachineId}`, data);
  }

  /**
   * Gets all hazard by roadmap id
   * 
   * @param data 
   * @returns hazards 
   */
  public getAllHazardByRoadmapId(data) {
    return this.http.post(`${ApiUrls.getAllHazardByRoadmapId}`, data);
  }

  public uploadImages(data) {
    return this.http.post(`${ApiUrls.uploadImages}`, data);
  }

  public editStaticTemplate(authorId, staticTemplateId, identificationId) {
    return this.http.get(`${ApiUrls.editStaticTemplate}${authorId}/${staticTemplateId}/${identificationId}`);
  }

  public getRoadmapsFromSimilarMachine(payload) {
    return this.http.post(`${ApiUrls.getRoadmapsFromSimilarMachine}`, payload);
  }

  public getProjects(offset,searchText) {
    return this.http.get(`${ApiUrls.getProjects}/${offset}/${searchText}`);
  }

  public getOffers(offset,searchText) {
    return this.http.get(`${ApiUrls.getOffers}/${offset}/${searchText}`);
  }

  public getOrders(offset,searchText) {
    return this.http.get(`${ApiUrls.getOrders}/${offset}/${searchText}`);
  }

  public getRoadmapHistory(roadmapId, serviceMachineId) {
    return this.http.get(`${ApiUrls.getRoadmapHistory}/${roadmapId}/${serviceMachineId}`);
  }

  public getRoadmapHistoryForRAWizard(data) {
    return this.http.post(`${ApiUrls.getRoadmapHistoryForRAWizard}`, data);
  }

  public getDashboard() {
    return this.http.get(`${ApiUrls.getDashboard}`);
  }

  public getRecentUpdates() {
    return this.http.get(`${ApiUrls.getRecentUpdates}`);
  }


  public deleteResource(data) {
    const options = {
      body: data
    };
    return this.http.delete(`${ApiUrls.deleteResource}`, options);
  }

  public deleteHazard(data) {
    const options = {
      body: data
    };
    return this.http.delete(`${ApiUrls.deleteHazard}`, options);
  }
}
