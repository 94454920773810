<ion-content role="feed">
  <div class="head-container">
    <div class="header-title">
      {{"Select Roadmaps" | translate}}
    </div>
    <div class="search-block">
      <ion-searchbar debounce="500" mode='ios' placeholder="Search" [(ngModel)]="searchTerm"
        [showCancelButton]="'never'" [showClearButton]="'never'" (ionChange)="getItems($event)">
      </ion-searchbar>
      <img tooltip="{{'Close' | translate }}" (click)="close()" class="close-icon" placement="bottom"
        src="../../../assets/icon/cancel.svg" alt="">
    </div>
  </div>
  <div class="widget-container">
    <div class="search-results scrollVertical" #infScroller infiniteScroll [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="1000" (scrolled)="onScroll()" [scrollWindow]="false" [immediateCheck]="true"
      [alwaysCallback]="true">
      <ion-row class="roadmaps">
        <ion-col size="6" size-sm='6' size-md="6" size-lg="6" size-xl="6" size-xs='12'
          *ngFor="let item of predefinedRoadmapLists">
          <ion-item style="--background: none;" lines="none">
            <ion-checkbox [(ngModel)]="item.isChecked" [disabled]="item.disabled" style="margin-right: 20px;"
              class="col-checkbox" id="roadmap_checkbox_{{item.id}}"
              [ngClass]="item.disabled ? 'disabled-checkbox' : ''" (ionChange)="changeRoadmapCheckedValue(item)">
            </ion-checkbox>
            <ion-label class="content-card-title" id="roadmap_title_{{item.id}}"
              [ngClass]="item.disabled ? 'disabled-class-title' : ''">
              {{item.roadmapName}} </ion-label>
          </ion-item>
        </ion-col>
      </ion-row>
    </div>
    <div class="button-row">
      <ion-button class="app-btn" shape="round" fill="outline" [disabled]="!isAnyValueChanged" id="roadmap_save_button"
        color="primary" (click)="saveRoadmapInfo()">
        {{'Save'|translate}}
      </ion-button>
    </div>
  </div>
</ion-content>