import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ConfirmationModalComponent } from '../components/confirmation-modal/confirmation-modal.component';
import { AppConstants } from '../utilities/constant';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationService {

  constructor(private modalController: ModalController) {}

  // Accept optional parameters with default values
  async presentConfirmationModal(
    confirmationTitle: string = AppConstants.ARE_YOU_SURE_TO_DELETE, // Default title
    actionText: string = AppConstants.DELETE // Default action button text
  ): Promise<boolean> {
    const modal = await this.modalController.create({
      component: ConfirmationModalComponent,
      cssClass: 'small-alert-modal',
      backdropDismiss: false,
      showBackdrop: false,
      componentProps: {
        confirmationTitle,
        actionText,
      },
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    return data.isActionConfirmed;
  }
}
