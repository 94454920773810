import { Component, Input, OnInit, Pipe, PipeTransform } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-filter-moodal',
  templateUrl: './filter-moodal.component.html',
  styleUrls: ['./filter-moodal.component.scss'],
})
export class FilterMoodalComponent implements OnInit {
  @Input() each_service: any;
  @Input() filterFor: any;
  servicesTypeList = [
    'Risk Assessment',
    'Risk Re-Assessment',
    'Retrofitting',
    'Training On-Site',
    'Training Off-Site',
    'Health Check',
  ];
  data = { machineType: null, machineManufacturer: null, bucketList: null, machineAssignee: null, assetNumber: null, serialNumber: null, };
  constructor(
    public popoverController: PopoverController,
  ) { }

  ngOnInit() {
  }

  closePopOver() {
    this.popoverController.dismiss();
  }

  applyFilter() {
    this.popoverController.dismiss({
      data: {
        machineType: this.data.machineType, manufacturer: this.data.machineManufacturer, bucketList: this.data.bucketList,
        machineAssignee: this.data.machineAssignee, assetNumber: this.data.assetNumber, serialNumber: this.data.serialNumber
      }
    });
  }
}
