import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ShareValuesService } from 'src/app/services/sharedValues/share-values.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserAuthService } from 'src/app/services/user-auth/user-auth.service';
import { AppConstants } from 'src/app/utilities/constant';
import { isEmptyArray, isNotEmptyArray } from 'src/app/utilities/utils';

@Component({
  selector: 'app-add-machine-to-bucket',
  templateUrl: './add-machine-to-bucket.component.html',
  styleUrls: ['./add-machine-to-bucket.component.scss'],
})
export class AddMachineToBucketComponent implements OnInit {
  @Input() shouldCreateOnlyNewBucket = false;
  @Input() shouldEditBucket = false;
  @Input() bucketLists: any;
  title: string;
  bucketListInfo: any = [];
  selectedBucketRadioValue: any;
  shouldNewBucketCreate = false;
  newBucketInput: any;
  isAlertBox = false;
  contentToDelete: any;
  contentToDeleteId: any;
  isAnyBucketNameEdited = false;
  bucketListToEdit = [];
  isAnyBucketDeleted = false;
  editOrDeleteSelectionCheckboxInfo = {
    isAllCheckboxSelected: false,
    selectedCount: 0
  };
  chooseAnyBucketFirstToDelete = false;
  deletedBucketLists = [];
  everyCheckBoxSelected: any;
  isAnyBucketCheckboxSelectedToDelete = false;
  isBucketUpdatePermission: boolean;
  isBucketDeletePermission: boolean;
  isBucketCreatePermission: boolean;

  constructor(public translate: TranslateService,public modalController: ModalController, public sharedValue: ShareValuesService, public userAuth: UserAuthService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.translate.get('Add to Bucket').subscribe(res=>{
      this.title = res;
    });
    //this.bucketListInfo = this.sharedValue.bucketLists;
    this.checkPermissions();
    this.bucketListInfo = this.bucketLists;
    if (this.shouldCreateOnlyNewBucket) {
      this.translate.get('Create New Bucket').subscribe(res=>{
        this.title = res;
      });
      this.shouldNewBucketCreate = true;
    } else if (this.shouldEditBucket) {
      this.translate.get('Edit/Delete Buckets').subscribe(res=>{
        this.title = res;
      });
    }
  }

  checkPermissions() {
    const user = this.userAuth.getUserList();
    if (user && isNotEmptyArray(user.permissions)) {
      const { permissions } = user;
      this.isBucketCreatePermission = this.isBucketUpdatePermission = (permissions.includes('Project.Manage') || permissions.includes('Project.Write'));
      this.isBucketDeletePermission = (permissions.includes('Project.Manage') || permissions.includes('Project.Delete'));
    }
  }

  close() {
    if (this.shouldNewBucketCreate && !this.shouldCreateOnlyNewBucket) {this.resetToDefaultType();}
    else {
      this.modalController.dismiss({});
    }
  }

  resetToDefaultType() {
      this.translate.get('Add to Bucket').subscribe(res=>{
        this.title = res;
      });
    this.shouldNewBucketCreate = false;
    this.newBucketInput = '';
  }

  addBucket() {
    if (this.shouldNewBucketCreate) {
      this.newBucketInput && this.closeModalAndPassBucketName(this.newBucketInput, '');
    } else {
      this.selectedBucketRadioValue && this.closeModalAndPassBucketName('', this.selectedBucketRadioValue);
    }
  }

  closeModalAndPassBucketName(bucketName, bucketId) {
    let _scaatb = false;
    if (this.shouldNewBucketCreate && this.newBucketInput) {_scaatb = true;}
    this.modalController.dismiss({
      bucketName,
      bucketId,
      shouldAddToBucket: this.shouldCreateOnlyNewBucket ? true : false,
      shouldConstructAndAddToBucket: _scaatb
    });
  }

  changeSelectedBucketRadioData(event) {
    this.selectedBucketRadioValue = event.detail.value;
  }

  createNewBucket() {
    this.title = 'Add To New Bucket';
    this.shouldNewBucketCreate = true;
  }

  deleteBucket(bucket) {
    if (this.isBucketDeletePermission) {
      this.isAlertBox = true;
      this.contentToDelete = bucket.bucket_Name;
      this.contentToDeleteId = bucket.bucket_Id;
    } else {
      this.toastService.presentToast('You do not have the access to delete bucket. Please contact admin.', 4000, AppConstants.ERROR_SNACKBAR, 'top');
    }
  }

  closeAlert() {
    this.isAlertBox = false;
    this.contentToDelete = null;
    this.contentToDeleteId = null;
    if (this.editOrDeleteSelectionCheckboxInfo.selectedCount > 0) {
      this.isAnyBucketCheckboxSelectedToDelete = true;
      setTimeout(() => { this.isAnyBucketCheckboxSelectedToDelete = false; }, 500);
    }
  }

  confirmBucketDelete() {
    if (this.editOrDeleteSelectionCheckboxInfo.isAllCheckboxSelected || this.everyCheckBoxSelected) {
      this.isAnyBucketDeleted = true;
      this.bucketListInfo.filter(_bl => { if (_bl.isCheckboxSelected) {this.deletedBucketLists.push(_bl);} });
      //if (isEmptyArray(this.deletedBucketLists)) this.deletedBucketLists = this.bucketListInfo;
      this.saveEditOption();
    } else if (!this.editOrDeleteSelectionCheckboxInfo.isAllCheckboxSelected && !this.everyCheckBoxSelected && this.bucketListInfo.some(_bl => _bl.isCheckboxSelected)) {
      this.bucketListInfo.filter(_bl => {
        if (_bl.isCheckboxSelected) {this.deletedBucketLists.push(_bl);}
      });
      this.bucketListInfo = this.bucketListInfo.filter(_bl => !_bl.isCheckboxSelected);
      this.isAnyBucketDeleted = true;
      this.closeAlert();
      this.editOrDeleteSelectionCheckboxInfo.selectedCount = 0;
    } else {
      this.isAnyBucketDeleted = true;
      const _mb = this.bucketListInfo.find(_bl => _bl.bucket_Id == this.contentToDeleteId);
      _mb && this.deletedBucketLists.push(_mb);
      this.bucketListInfo = this.bucketListInfo.filter(_bl => _bl.bucket_Id !== this.contentToDeleteId);
      this.closeAlert();
      this.editOrDeleteSelectionCheckboxInfo.selectedCount = 0;
      if (isEmptyArray(this.bucketListInfo)) {this.saveEditOption();}
    }
  }

  saveEditOption() {
    let editedBucketInfo = [];
    let deletedBucketInfo = [];
    if (this.isAnyBucketNameEdited && isNotEmptyArray(this.bucketListToEdit)) {editedBucketInfo = this.bucketListToEdit.filter(_ble => !_ble.isSameName);}
    if (this.isAnyBucketDeleted && isNotEmptyArray(this.deletedBucketLists)) {deletedBucketInfo = this.deletedBucketLists;}
    this.modalController.dismiss({
      updatedBucketLists: editedBucketInfo,
      deletedBucketLists: deletedBucketInfo,
      isBucketUpdated: true
    });
  }

  isReadonlyCustomerSearch() {
    if (!this.isBucketUpdatePermission) {return true;}
    else {return false;}
  }

  editBucketName(event, bucketInfo) {
    if (this.isBucketUpdatePermission) {
      const isBucketNameIsSame = (bucketInfo.Bucket_Name.trim() == event.detail.value.trim());
      const matchedBucketLists = this.bucketListInfo.find(_bck => _bck.Bucket_Name === bucketInfo.Bucket_Name);
      if (matchedBucketLists) {
        const body = { title: bucketInfo.Bucket_Name, bucketId: bucketInfo.Bucket_Id, newTitle: event.detail.value, isSameName: isBucketNameIsSame };
        if (isNotEmptyArray(this.bucketListToEdit)) {
          const matchedBucketToEdit = this.bucketListToEdit.find(_be => _be.title == bucketInfo.Bucket_Name);
          if (matchedBucketToEdit) {
            matchedBucketToEdit.newTitle = event.detail.value;
            matchedBucketToEdit.isSameName = isBucketNameIsSame;
          }
          else {this.bucketListToEdit.push(body);}
        } else {this.bucketListToEdit.push(body);}
      }
      const isBucketNameEdited = this.bucketListToEdit.some(_ble => !_ble.isSameName);
      this.isAnyBucketNameEdited = isBucketNameEdited;
    } else {
      this.toastService.presentToast('You do not have the access to edit bucket. Please contact admin.', 4000, AppConstants.ERROR_SNACKBAR, 'top');
      }
  }

  deleteAllSelectedBucket() {
    if (this.isBucketDeletePermission) {
      if (this.editOrDeleteSelectionCheckboxInfo.selectedCount == 0) {return this.chooseAnyBucketFirstToDelete = true;}
      else {
        const anyCheckboxSelected = this.bucketListInfo.some(_bkl => _bkl.isCheckboxSelected);
        if (anyCheckboxSelected) {
          const { selectedCount } = this.editOrDeleteSelectionCheckboxInfo;
          this.isAlertBox = true;
          this.contentToDelete = (selectedCount == this.bucketListInfo.length ? 'All Buckets' : selectedCount > 1 ? 'Buckets' : 'Bucket');
        } else {this.chooseAnyBucketFirstToDelete = true;}
      }
    } else {
      this.toastService.presentToast('You do not have the access to delete bucket. Please contact admin.', 4000, AppConstants.ERROR_SNACKBAR, 'top');
      }
  }

  changeAllEditOrDeleteBucketSelection() {
    this.bucketListInfo.forEach(_bl => _bl.isCheckboxSelected = this.editOrDeleteSelectionCheckboxInfo.isAllCheckboxSelected);
  }

  changeParticularEditOrDeleteBucketCheckboxSelection(bucketList) {
    if (!this.isAnyBucketCheckboxSelectedToDelete) {
      if (bucketList.isCheckboxSelected) {this.editOrDeleteSelectionCheckboxInfo.selectedCount++;}
      else {this.editOrDeleteSelectionCheckboxInfo.selectedCount--;}
      if (this.editOrDeleteSelectionCheckboxInfo.selectedCount > 0 && this.chooseAnyBucketFirstToDelete) {this.chooseAnyBucketFirstToDelete = false;}
      const everyCheckboxSelected = this.bucketListInfo.every(_bkl => _bkl.isCheckboxSelected);
      if (everyCheckboxSelected) {this.editOrDeleteSelectionCheckboxInfo.isAllCheckboxSelected = true;}
      this.everyCheckBoxSelected = everyCheckboxSelected;
    }
  }

}
