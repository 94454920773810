<ion-card [ngClass]="showCheckbox ? 'add-checkbox-on-hover' : ''" class="card-transition">
  <div class="card-header">
    <div class="card-checkbox">
      <div class="ccb-first">
        <ion-checkbox [ngClass]="item.isChecked ? 'checkbox-checked' : 'add-checkbox'"
          value="{{item.id}}" name="{{item.machine_Name}}" (click)="stopPropagation($event)" (ionChange)="changeMachineCheckboxSelection(item)">
        </ion-checkbox>
      </div>
    </div>
    <div *ngIf="showMore">
      <img tooltip="{{'More actions'  | translate }}" alt="" placement="bottom" class="" src="../../../assets/icon/more.svg" (click)="clickMore($event)">
    </div>
  </div>
  <div *ngIf="showStatus && status" >
    <ion-chip [ngClass]="(status === 'Completed') ? 'completed-chip' : 'inprogress-chip'">{{ status | translate }}</ion-chip>
  </div>
  <div class="img-flex">
    <img class="img-width" alt="" [src]="item.thumbnail_Url ? item.thumbnail_Url : 'assets/images/Machine Library.svg'"/>
  </div>
  <ion-card-content class="ion-no-padding">
    <div [ngClass]="showStatus ? 'content-list' : '' ">
      <ion-list lines="none" class="ion-no-padding">
        <!--
            * Here displayed the data conditionally as per the offermachinedetails API response
            * Once the key updated on the api will remove the condition
        -->
        <ion-item>
          <img class="ion-padding-end" alt="" src="../../../assets/images/machine.svg">
          <ion-label class="ion-no-margin" title="{{item.machine_Name}}">{{item.machine_Name ? item.machine_Name : item.machine_name}}</ion-label>
        </ion-item>
        <ion-item>
          <ion-icon aria-hidden="true" name="barcode-outline" class="ion-padding-end"></ion-icon>
          <ion-label class="ion-no-margin">{{item.global_Id ? item.global_Id : item.global_id}}</ion-label>
        </ion-item>
        <ion-item>
          <ion-icon name="people-circle-outline" class="ion-padding-end"></ion-icon>
          <ion-label class="ion-no-margin">{{item.customer_Id ? item.customer_Id : item.customer_id}}</ion-label>
        </ion-item>
      </ion-list>
    </div>
  </ion-card-content>
</ion-card>