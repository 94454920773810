export const hazardTypes = [
    {name:'Mechanical Hazard',isDisabled:false},
    {name:'Electrical Hazard',isDisabled:false},
    {name:'Thermal Hazard',isDisabled:false},
    {name:'Noise Hazard',isDisabled:false},
    {name:'Vibration Hazard',isDisabled:false},
    {name:'Radiation Hazard',isDisabled:false},
    {name:'Ergonomics Hazard',isDisabled:false},
    {name:'Environmental Hazard',isDisabled:false},
    {name:'Combination Hazard',isDisabled:false},
];
export const hazardSources = {
Mechanical: [
    { source: 'Acceleration, Deceleration' },
    { source: 'Sharp Edges' },
    { source: 'Moveable Piece Approaching to a Fixed Part' },
    { source: 'Cutting of Parts' },
    { source: 'Elastic Pieces' },
    { source: 'Object Falling' },
    { source: 'Gravity' },
    { source: 'Height From the Ground' },
    { source: 'High Pressure' },
    { source: 'Instability' },
    { source: 'Kinetic Energy' },
    { source: 'Machine Mobility' },
    { source: 'Moving Parts' },
    { source: 'Rotating Elements' },
    { source: 'Rough Surface,Slippery' },
    { source: 'Energy Stored' },
    { source: 'Vacuum' }
],
Electrical: [
    { source: 'Electric Arcs' },
    { source: 'Electromagnetic phenomena' },
    { source: 'Exposed Circuits' },
    { source: 'Low Dielectric Strength' },
    { source: 'Exposed Circuits under fault conditions' },
    { source: 'Short Circuit' },
    { source: 'Thermal Radiation' }

],
Thermal: [
    { source: 'Explosion' },
    { source: 'Fire' },
    { source: 'Objects or Materials with High or Low Temperature' },
    { source: 'Radiation from Hot Springs' }
],
Noise: [
    { source: 'Cavitation Phenomenon' },
    { source: 'Exhaust System' },
    { source: 'Gas Leaking at High Speed' },
    { source: 'Production Processes (Stamping, Cutting, etc.)' },
    { source: 'Moving Parts' },
    { source: 'Friction Surfaces' },
    { source: 'Unbalanced Rotating Parts' },
    { source: 'Tire Noise' },
    { source: 'Worn Parts' }
],
Vibration: [
    { source: 'Cavitation Phenomenon' },
    { source: 'Misalignment of Moving Parts' },
    { source: 'Mobile Equipment' },
    { source: 'Friction Surfaces' },
    { source: 'Unbalanced Rotating Parts' },
    { source: 'Equipment that Vibrates' },
    { source: 'Worn Parts' }
],
Radiation: [
    { source: 'Ionization radiation sources' },
    { source: 'Low Frequency Electromagnetic Radiation' },
    { source: 'Optical Radiation (Infrared, Visible or Ultraviolet), Including Laser' },
    { source: 'Electromagnetic radiations in radiofrequency' }
],
Materials: [
    { source: 'Aerosols' },
    { source: 'Agents biological and microbiological (viral or bacterial)' },
    { source: 'Fuels' },
    { source: 'Dust' },
    { source: 'Explosives' },
    { source: 'Fiber' },
    { source: 'Flammable objects' },
    { source: 'Fluids' },
    { source: 'Fumes' },
    { source: 'Gases' },
    { source: 'Mixtures' },
    { source: 'Oxidants' }
],
Ergonomics: [
    { source: 'Access' },
    { source: 'Project or Location Indicators, Indicators and Dashboards' },
    { source: 'Design, Location or Identification of Control Devices' },
    { source: 'Efforts' },
    { source: 'Twinkling, Glowing, Shadow, Strobe Effect' },
    { source: 'Spot Light' },
    { source: 'Mental Overload, Idle' },
    { source: 'Posture' },
    { source: 'Repetitive Activity' },
    { source: 'Visibility' }
],
Environmental: [
    { source: 'Dust or Fog' },
    { source: 'Electromagnetic Disturbance' },
    { source: 'Lightning' },
    { source: 'Humidity' },
    { source: 'Pollution' },
    { source: 'Snow' },
    { source: 'Temperature' },
    { source: 'Water' },
    { source: 'Wind' },
    { source: 'Lack of Oxygen' }
],
Combination: [
    { source: 'For example, Repetitive Activities + Effort' },
    { source: 'For example, Dehydration, Loss of + High Temperature Environments' },
]

};
export const hazardConsequences = {
Mechanical: [
    { consequence: 'Running Over' },
    { consequence: 'Throwing' },
    { consequence: 'Crushing' },
    { consequence: 'Cutting and Mutilation' },
    { consequence: 'Getting Trapped or Tangled' },
    { consequence: 'Getting Stuck' },
    { consequence: 'Friction and Abrasion' },
    { consequence: 'Impact' },
    { consequence: 'Injection' },
    { consequence: 'Scraping' },
    { consequence: 'Slipping, Stumbling and Falling' },
    { consequence: 'Drilling' },
    { consequence: 'Suffocation' },

],
Electrical: [
    { consequence: 'Burning' },
    { consequence: 'Chemical Effects' },
    { consequence: 'Effects on Medical Implants' },
    { consequence: 'Electrocution' },
    { consequence: 'Falling or Throwing' },
    { consequence: 'Fire' },
    { consequence: 'Shock' },
    { consequence: 'Projection of Sparks' },
],
Thermal: [
    { consequence: 'Burning' },
    { consequence: 'Dehydration' },
    { consequence: 'Discomfort' },
    { consequence: 'Freeze' },
    { consequence: 'Damage caused by radiation of hot sources' },
    { consequence: 'Scald' }
],
Noise: [
    { consequence: 'Discomfort' },
    { consequence: 'Loss of Consciousness' },
    { consequence: 'Loss of Balance' },
    { consequence: 'Permanent Hearing Loss' },
    { consequence: 'Stress' },
    { consequence: 'Buzz' },
    { consequence: 'Tiredness' },
    { consequence: 'others (eg mechanical, electrical) due to interference in communication or acoustic signals' }
],
Vibration: [
    { consequence: 'Discomfort' },
    { consequence: 'Low-back morbidity' },
    { consequence: 'Neurological Dysfunction' },
    { consequence: 'Osteo-articular disorders' },
    { consequence: 'Spine Trauma' },
    { consequence: 'Vascular disorders' }
],
Radiation: [
    { consequence: 'Burning' },
    { consequence: 'Damage to the eyes and skin' },
    { consequence: 'Effects on Reproductive Capacity' },
    { consequence: 'Mutations' },
    { consequence: 'Headache, Insomnia, etc.' },
    { consequence: 'Carcinogenic Effects' }
],
Materials: [
    { consequence: 'Difficulty breathing, Choking' },
    { consequence: 'Cancer' },
    { consequence: 'Corrosion'},
    { consequence: 'Effects on Reproductive Capacity' },
    { consequence: 'Explosions' },
    { consequence: 'Fire' },
    { consequence: 'Infections' },
    { consequence: 'Mutations' },
    { consequence: 'Poisoning' },
    { consequence: 'Sensitivity disorders' }
],
Ergonomics: [
    { consequence: 'Discomfort' },
    { consequence: 'Fatigue' },
    { consequence: 'Musculoskeletal Disorders' },
    { consequence: 'Stress' },
    { consequence: 'Others (eg mechanical, electrical) due to human errors.' },
],
Environmental: [
    { consequence: 'Burn' },
    { consequence: 'Mild Illnesses' },
    { consequence: 'Falling or Slipping' },
    { consequence: 'Suffocation' },
    { consequence: 'Any other consequence of the effect caused by sources hazards of the machine or its specific parts.' },
],
Combination: [
    { consequence: 'For Example, Dehydration, Loss of Consciousness and Heart Attack' },
]
};







