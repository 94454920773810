import { Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss'],
})
export class SearchbarComponent implements OnInit {
  @Input() placeholder: string;
  @Input() showSearchBar: boolean;
  @ViewChild('search') search: any;

  constructor() { }

  ngOnInit() {
  }

  // To display the searchbar
  public showSearchBarIcon() {
    this.showSearchBar = !this.showSearchBar;
    setTimeout(() => {
      this.search.setFocus();
    }, 500);
  }

  // To hide the searchbar
  public hideSearchBar() {
    this.showSearchBar = false;
  }
}
