<div class="header-container">
  <div class="header-left-item">
    <ion-label class="header-label"> {{'Roadmap History Log' | translate }}</ion-label>
  </div>
  <div class="header-right-item">
    <ion-button class="app-btn app-btn-margin margin-top-16" shape="round" 
      fill="outline" color="primary" (click)="exit()">
      {{'Exit'|translate}}
    </ion-button>
  </div>
</div>
<div class="history-container">
  <div class="history-left-item">
    <div class="sidebar" *ngIf="historyData?.roadmapVersions?.length > 0">
      <div *ngFor="let version of historyData?.roadmapVersions; let i = index">
        <div class="left-column version-label" [ngClass]="{'active-version': activeVersionIndex === i}" (click)="getVersionDetail(version, i)">{{ 'Version ' + version}}</div>
      </div>
    </div>
  </div>
  <div class="history-right-item">
    <ion-card class="history-card">
      <div class="version-details" *ngIf="selectedVersionDetails">
        <!-- <pre>{{ selectedVersionDetails | json }}</pre> -->
        <ion-card class="version-detail-card" *ngFor="let detail of selectedVersionDetails">
          <ion-card-content>
            <ion-label>
              <ng-container *ngIf="detail.entity === 'Section'">
                <span class="entity">{{ detail.entity | translate }}:</span> {{ detail.entityName }}
                <span class="updatedAt">Edited By {{ editedBy }} {{ editedAt }}</span>
                <!-- <span class="updatedAt" *ngIf="detail.updatedBy || detail.updatedAt">{{detail.updatedBy}} {{detail.updatedAt}}</span> -->
              </ng-container>
              <ng-container *ngIf="detail.entity === 'SubSection'">
                <span class="entity">{{ 'Section' | translate }}:</span> {{ detail.sectionName }}
                <span class="updatedAt">Edited By {{ editedBy }} {{ editedAt }}</span> <br />
                <span class="entity">{{ detail.entity | translate }}:</span> {{ detail.entityName }}
              </ng-container>
              <ng-container *ngIf="detail.entity === 'Step'">
                <span class="entity">{{ 'Section' | translate }}:</span> {{ detail.sectionName }}
                <span class="updatedAt">Edited By {{ editedBy }} {{ editedAt }}</span> <br />
                <span class="entity">{{ 'Subsection' | translate }}:</span> {{ detail.subSectionName }} <br />
                <span class="entity">{{ detail.entity | translate }}:</span> {{ detail.entityName }}
              </ng-container>
              <ng-container *ngIf="detail.entity === 'Answer'">
                <span class="entity">{{ 'Section' | translate }}:</span> {{ detail.sectionName }}
                <span class="updatedAt">Edited By {{ editedBy }} {{ editedAt }}</span> <br />
                <span class="entity">{{ 'Subsection' | translate }}:</span> {{ detail.subSectionName }} <br />
                <span class="entity">{{ 'Steps' | translate }}:</span> {{ detail.stepName }} <br />
                <span class="entity">{{ detail.entity | translate }}:</span> {{ detail.entityName }}
              </ng-container>
              <ng-container>
                <span *ngIf="detail.action === 'add'">
                  <span class="add">{{ 'is added.' | translate }}</span>
                </span>
                <span *ngIf="detail.action === 'edit'">
                  <span class="edit">{{ 'is changed to' | translate }}</span> {{ detail.updatedName }}
                </span>
                <span *ngIf="detail.action === 'delete'">
                  <span class="delete">{{ 'is deleted.' | translate }}</span>
                </span>
              </ng-container>
            </ion-label>
          </ion-card-content>
        </ion-card>
      </div>
    </ion-card>
  </div>
</div>