import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RefreshAlertService {

  private showAlert = true;

  constructor() {}

  enableAlert() {
    this.showAlert = true;
  }

  disableAlert() {
    this.showAlert = false;
  }

  shouldShowAlert(): boolean {
    return this.showAlert;
  }
}
