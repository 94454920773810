import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { ShareValuesService } from '../../../../services/sharedValues/share-values.service';
import { BackendCallService } from 'src/app/services/backend-call/backend-call.service';
import { GridConfig } from '../../../../shared/grid-config';
import { AppGridComponent } from '../../../../shared/grid/grid-component/grid-component';
import { ColDef } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-project-items-view',
  templateUrl: './project-items-view.component.html'  
})
export class ProjectItemsViewComponent implements OnInit,OnChanges {
  @Input() gridData:any;
  @ViewChild(AppGridComponent) gridComponent: AppGridComponent;
  gridColumnDefs: ColDef[];
  gridConfigItems: GridConfig; 
  gridDetails: any = [];
  constructor(public backendService: BackendCallService,
    public sharedValue: ShareValuesService,
    public translate: TranslateService,
    public modalController: ModalController) {
  }

  ngOnInit(): void {
    this.gridDetails=[];
    this.fnInitialiseGrid();       
  }
  
  ngOnChanges() {
    this.gridDetails = this.gridData;
    setTimeout(() => {
      this.gridComponent.refreshData();
    }, 1000);
  }    

  fnCreateColumnDefs() {
    this.gridColumnDefs = [
      {
        headerName: this.translate.instant('Machine Name'),
        field: 'machineName',
        minWidth: 350,
        sortable: true,
        suppressMenu: true,
        cellClass:'grid-column-value grid-hover-link'
      },
      {
        headerName : this.translate.instant('Machine Type'),
        field: 'machineType',
        minWidth : 350,
        sortable: true,
        suppressMenu: true,
        cellClass:'grid-column-value'
      },
      {
        headerName : this.translate.instant('Road Maps Applied'),
        field: 'roadmaps',
        minWidth: 350,
        sortable: true,
        suppressMenu: true,
        cellClass:'grid-column-value'
      },
      {
        headerName : this.translate.instant('Total Questions'),
        field: 'questions',
        minWidth: 350,
        sortable: true,
        suppressMenu: true,
        cellClass:'grid-column-value'
      },
      {
        headerName : this.translate.instant('Hazards Reported'),
        field: 'hazards',
        minWidth: 350,
        sortable: true,
        suppressMenu: true,
        cellClass:'grid-column-value'
      }
      
    ];
  }

  fnGetGridConfigItems() {
    this.gridConfigItems = new GridConfig(
      'machineId',
      'machineId',
      false,
      true,
      true,
      false,
      'desc',
      0,
      'no_data_available',
      '',
      '',
      '',
      false,
      1,
      'grid-wrapper-height-40',
      '',
      '',
      '',
      ''
    );
  }

  fnInitialiseGrid() {

    this.fnCreateColumnDefs();
    this.fnGetGridConfigItems();
    setTimeout(() => {
      this.gridComponent.initializeGrid(false);
    }, 100);

  }   
}
