import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonInput, LoadingController, ModalController } from '@ionic/angular';
import { BackendCallService } from 'src/app/services/backend-call/backend-call.service';
import { ShareValuesService } from 'src/app/services/sharedValues/share-values.service';
import { ToastService } from 'src/app/services/toast.service';
import { AppConstants } from 'src/app/utilities/constant';
import { isEmptyArray, isNotEmptyArray } from 'src/app/utilities/utils';

@Component({
  selector: 'app-search-modal',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.scss'],
})
export class SearchModalComponent implements OnInit, AfterViewInit {
  @Input() type: any;
  @Input() assignedStaffList: [];
  isItemAvailable = false;
  dummyData = [];
  users = [];
  userNotFound = false;
  enterUserConsoleGroupName: any;
  enterUserConsoleAddRole: any;
  @Input() valueToEdit: any;
  availableRolesInfo = [];
  usersListsInfo = [];
  selectedRoleToAssign = null;
  availableUnasssignedRoleToUser: any;
  searchValue: any;
  usersList: any;
  isAnyUserSelectedFor4EyeCheck = false;
  isprojectAssign = false;
  isAssignEnable = false;
  selectedCustomerRadioValue: any;
  selectedUsersList=[];
  @ViewChild('search') search: any;
  @ViewChild('inputEl', {static: false}) ionInput: IonInput;

  constructor(public loadingController: LoadingController,
    private toastService: ToastService,public modalController: ModalController, private router: Router, public backendCallService: BackendCallService, public sharedValue: ShareValuesService,) { }

  ngAfterViewInit() {
    setTimeout(() => {
      this.ionInput.setFocus();
    }, 500);
  }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.users = [];
    setTimeout(() => {
      if(this.search){
        this.search.setFocus();
      }
    }, 500);
    if (this.type === 'user_console_role_mang_assign_user_role') {
      this.getAllRoles();
    }
  }

  getAllRoles() {
    return this.backendCallService.getAllRoles().then(_roles => {
      const { Data } = _roles;
      if (isNotEmptyArray(Data)) {
        this.availableRolesInfo = Data;
      }
    }).catch(err => {
    });
  }

  async presentLoading(msg) {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-loading',
      message: msg,
      spinner: 'bubbles'
    });
    await loading.present();
    return loading;
  }

  getItems(ev: any) {
    if (this.type == 'user_console_add_user') {
      const { value } = ev.target;
      if (value) {this.searchUserToToAdd(value);}
      else {
        this.isItemAvailable = false;
        this.userNotFound = true;
        if (isNotEmptyArray(this.dummyData)) {this.dummyData = [];}
      }
    }
    else if (this.type == 'user_console_role_mang_assign_user_role' || this.type == 'user_console_group_mang_add_group' ||
      this.type == 'assign-4-eye-quality-check-user' || this.type == 'group_mang_add_users' || this.type == 'role_mang_all_roles_add_users') {
      const { value } = ev.target;
      if (value) {this.searchUserToAddInRoles(value);}
      else {
        this.isItemAvailable = false;
        if (!this.userNotFound) {this.userNotFound = true;}
        if (this.type == 'assign-4-eye-quality-check-user') {this.anyUserAddedFor4EyeQualityCheck();}
        if (isNotEmptyArray(this.dummyData)) {this.dummyData = [];}
      }
    }
    else if (this.type == 'assign') {
      const searchValue = ev.target.value;
      if (searchValue)
        // this.searchValue = ev.target.value;
        {this.assignNewUser(searchValue);}
      else {
        this.isItemAvailable = false;
        this.userNotFound = true;
        if (isNotEmptyArray(this.dummyData)) {this.dummyData = [];}
      }
    }

    else if (this.type == 'assignProject') {
      const searchValue = ev.target.value;
      if (searchValue) {
        this.assignNewUser(searchValue);
      }
      // this.searchValue = ev.target.value;
      else {
        this.isItemAvailable = false; this.userNotFound = true;
        // if (this.type == 'assignProject') this.assignUserProject()
        if (isNotEmptyArray(this.dummyData)) {this.dummyData = [];}
      }
    }


    else if (this.type == 'offerMngTrckr') {
      const searchValue = ev.target.value;
      if (searchValue)
        // this.searchValue = ev.target.value;
        {this.assignNewUser(searchValue);}
      else {
        this.isItemAvailable = false;
        this.userNotFound = true;
        if (isNotEmptyArray(this.dummyData)) {this.dummyData = [];}
      }
    }
    else {
      // Reset items back to all of the items
      // this.initializeItem();

      // set val to the value of the searchbar
      const val = ev.target.value;

      // if the value is an empty string don't filter the items
      if (val && val.trim() !== '') {
        this.isItemAvailable = true;
        // this.dummyData = this.dummyData.filter((item) => {
        //   return item.name.toLowerCase().indexOf(val.toLowerCase()) > -1;
        // });
        if (this.type == 'user_console_add_user' || this.type == 'assign-4-eye-quality-check-user') {this.userNotFound = isEmptyArray(this.dummyData) ? true : false;}
      } else {
        this.isItemAvailable = false;
        if (this.type == 'user_console_add_user' || this.type == 'assign-4-eye-quality-check-user') {this.userNotFound = true;}
        if (this.type == 'assign-4-eye-quality-check-user') {this.anyUserAddedFor4EyeQualityCheck();}
      }
    }
  }

 async searchUserToToAdd(searchWord) {
    if(searchWord.length >= 2){
      this.backendCallService.searchUserInfoToAdd().then(_userlists => {
        const { Data } = _userlists;
        if (isNotEmptyArray(Data) && searchWord && searchWord.trim() !== '') {
          //this.isItemAvailable = true;
          this.dummyData = Data;
          this.dummyData = this.dummyData.filter((item) => item.Display_Name.toLowerCase().indexOf(searchWord.toLowerCase()) > -1);
          if (isNotEmptyArray(this.dummyData)) { this.isItemAvailable = true; this.userNotFound = false; }
          else { this.isItemAvailable = false; this.userNotFound = true; }
        }
      }).catch(err => {
        console.log('Issue in fetching User Info To Add :', err);
      });
    }

  }

async searchUserToAddInRoles(searchWord) {
    //const loader =await this.presentLoading('Please wait...');
    this.backendCallService.getAllUsers().then(_users => {
      const { Data } = _users;
    //  loader && loader.dismiss();
      if (isNotEmptyArray(Data) && searchWord && searchWord.trim() !== '') {
        this.dummyData = Data;
        this.dummyData.forEach(_dd => { _dd.Display_Name = _dd.First_Name + ' ' + _dd.Last_Name; });
        this.dummyData = this.dummyData.filter((item) => (
            item.First_Name.toLowerCase().indexOf(searchWord.toLowerCase()) > -1 ||
            item.Last_Name.toLowerCase().indexOf(searchWord.toLowerCase()) > -1
          ));
        if (isNotEmptyArray(this.dummyData)) {
          this.isItemAvailable = true; this.userNotFound = false;
        }
        else { this.isItemAvailable = false; this.userNotFound = true; }
      }
    }).catch(err => {
      console.log('Issue in fetching User Info for adding in roles :', err);
    });
  }

  addUser(event, data) {
    data.checked = event.detail.checked;
    this.dummyData.find((item) => item.Id === data.Id).checked = data.checked;
    const selectedData = this.selectedUsersList.find((item) => item.Id == data.Id);
    if(selectedData && event.detail.checked){
      this.selectedUsersList.forEach((item, index, object) => {
        if (item.Id === data.Id) {
          item.checked= event.detail.checked;
        }
      });
    }else if(selectedData && !event.detail.checked){
      this.selectedUsersList.forEach((item, index, object) => {
        if (item.Id === data.Id) {
          object.splice(index, 1);
        }
      });
    }else{
      this.selectedUsersList.push(data);
    }
    // if(this.selectedUsersList.length){
    //   this.selectedUsersList.forEach((item, index, object) => {
    //     if (item.id === data.id) {
    //       item.checked= event.detail.checked
    //       object.splice(index, 1);
    //     }else{

    //     }
    //   });
    // }else{
    //   this.selectedUsersList.push(data)
    // }
    //if (this.type == 'assign-4-eye-quality-check-user') this.anyUserAddedFor4EyeQualityCheck();
  }

  anyUserAddedFor4EyeQualityCheck() {
    if (this.selectedCustomerRadioValue) {this.selectedCustomerRadioValue = '';}
    if (!this.selectedCustomerRadioValue) {this.isAnyUserSelectedFor4EyeCheck = false;}
  }

  assignUserProject() {
    if (this.selectedCustomerRadioValue) {this.selectedCustomerRadioValue = '';}
    if (!this.selectedCustomerRadioValue) {this.isprojectAssign = false;}
  }

  changeSelectedRadioValue(event) {
    this.selectedCustomerRadioValue = event.detail.value;
    if (this.selectedCustomerRadioValue) {this.isAnyUserSelectedFor4EyeCheck = true;}
  }

  changeSelectProjectAssign(event) {
    this.selectedCustomerRadioValue = event.detail.value;
    if (this.selectedCustomerRadioValue) {this.isprojectAssign = true;}
  }

  close() {
    this.users = [];
    this.selectedUsersList=[];
    this.modalController.dismiss({
      dismissed: true,
      // selectedDashboardWidgets: value
    });
  }


  assignUser(modetype?) {
    let selectedUsers = [];
    this.dummyData.filter((each: any) => {
      each.checked ? this.users.push(each) : '';
    });
    if (this.type == 'assign-4-eye-quality-check-user') {
      selectedUsers = this.dummyData.filter(_dd => _dd.Id == this.selectedCustomerRadioValue);
    } else if(this.type == 'user_console_add_user'||this.type == 'user_console_group_mang_add_group'){
      selectedUsers = this.selectedUsersList;
    }
    else{selectedUsers = this.users;}
    console.log('assignUser',this.type, this.selectedUsersList);

    this.modalController
      .dismiss({
        dismissed: true,
        selectedUsers,
        modeType: this.type,
        groupName: this.enterUserConsoleGroupName,
        newRole: this.enterUserConsoleAddRole,
        editedNewValue: this.valueToEdit,
        roleToAssign: this.selectedRoleToAssign ? this.selectedRoleToAssign : ''
      })
      .then(() => {
        this.users = [];
        this.selectedUsersList=[];
      });
  }

  assignUserInfo() {
    let userIdValue;
    if (this.type == 'offerMngTrckr') {
      userIdValue = this.dummyData.filter(_dd => _dd.Id == this.selectedCustomerRadioValue);
    }
    this.modalController.dismiss({
      userIdValue,
    });
  }

  removeUser(data) {
    if (this.type == 'user_console_role_mang_assign_user_role' || this.type == 'group_mang_add_users' || this.type == 'role_mang_all_roles_add_users') {
      const matchedUser = this.dummyData.find(_dd => _dd.Id == data.Id);
      if (matchedUser && matchedUser.checked) {matchedUser.checked = false;}
    }
    else {
      this.dummyData.find((item) => item.id == data.id).checked = false;
      this.isItemAvailable = false;
    }
  }
  removeAddUser(data) {
    if (this.type == 'user_console_add_user') {
      const selectedData = this.selectedUsersList.find((item) => item.Id === data.Id);
      if(selectedData){
        this.selectedUsersList.forEach((item, index, object) => {
          if (item.Id === data.Id) {
            object.splice(index, 1);
          }
        });
        if (selectedData && selectedData.checked) {selectedData.checked = false;}
        const matchedUser = this.dummyData.find(_dd => _dd.Id === data.Id);
        if (matchedUser && matchedUser.checked) {matchedUser.checked = false;}
      }
    } else if(this.type == 'user_console_group_mang_add_group'){
      const selectedData = this.selectedUsersList.find((item) => item.Id === data.Id);
      if (selectedData && selectedData.checked) {selectedData.checked = false;}
      console.log('selectedUsersList',this.selectedUsersList,this.dummyData,selectedData);
      if(selectedData){
        this.selectedUsersList.forEach((item, index, object) => {
          if (item.Id === data.Id) {
            object.splice(index, 1);
          }
        });
        const matchedUser = this.dummyData.find(_dd => _dd.Id === data.Id);
        if (matchedUser && matchedUser.checked) {matchedUser.checked = false;}
      }
    }
  }

  assignNewUser(searchValue) {
    this.usersList = [];
    this.dummyData = [];
    const payload = searchValue;
    this.backendCallService.getUser(payload).subscribe(
      (data: any) => {
        this.usersList = data.value;
        this.usersList.forEach((element) => {
          this.dummyData.push({
            Display_Name: element.name,
            Id: element.id,
            UserEmail:element.email,
            disabled:false
          });
        });
        this.dummyData.find(_dd => _dd.Id == data.Id);
        if(this.type!=='offerMngTrckr'){
          this.assignedStaffList.forEach((ele: any)=>{
            this.dummyData.forEach((ele2: any)=>{
              if(ele.emailAdd ==ele2.UserEmail){
                ele2.disabled=true;
              }
            });
          });
        }
        if (isNotEmptyArray(this.dummyData)) { this.isItemAvailable = true; this.userNotFound = false; }
        else { this.isItemAvailable = false; this.userNotFound = true; }
      }
    );
  }

  getlistuser(searchValue) {
    const payload = searchValue;
    this.backendCallService.getAllProjectUsers(payload).subscribe(
      (data: any) => {
        this.usersList = data.Data;
        this.usersList.forEach((element) => {
          this.dummyData.push({
            Display_Name: element.First_Name,
            Id: element.id,
          });
        });
        this.dummyData.filter(_dd => _dd.Id == data.Id);
        if (isNotEmptyArray(this.dummyData)) { this.isItemAvailable = true; this.userNotFound = false; }
        else { this.isItemAvailable = false; this.userNotFound = true; }
      },err=>{
        this.toastService.presentToast('No Results Found', 4000, AppConstants.ERROR_SNACKBAR, 'top');
      }
    );

  }

  assignProject(value) {
    let userIdValue;
    if (this.type == 'assignProject') {
      userIdValue = this.dummyData.filter(_dd => _dd.Id == this.selectedCustomerRadioValue);
    }
    this.modalController.dismiss({
      userIdValue
    });
  }


}
