<ion-content>
  <div class="widget-container" *ngIf="!shouldDisplayAlert">
    <ion-grid>
      <ion-row class="close-row">
        <ion-col (click)="close()" class="close-col" id="columnSelector_cancel_button">
          <img tooltip="{{'Close' | translate }}" alt="" placement="bottom" src="../../../assets/icon/cancel.svg">
        </ion-col>
      </ion-row>
      <ion-row class="header-row">
        <ion-col>
          <div class="ion-text-start header-title">
            {{ 'Add Or Remove Columns' | translate }}
          </div>
        </ion-col>
        <ion-col>
          <div class="ion-text-end header-reset" (click)="resetAndCloseToDefaultSettigs()"
            id="columnSelector_reset_button">
            <img src="../../../assets/icon/reset.svg" alt="" style="margin-bottom: -8px;">
            <span class="header-reset-title">{{ 'Reset to Default Settings' | translate }}</span>
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="header-sub">
        <ion-col class="header-sub-title">
          {{ 'The selected columns will be added to' | translate}} {{ title ? title : 'Tracker' }}
        </ion-col>
      </ion-row>
      <ion-row class="content-row">
        <ion-col size-xs='12' size-sm='8' size-md="8" size-lg="8" size-xl="8">
          <ion-row>
            <ion-col size="6" size-sm='6' size-md="6" size-lg="6" size-xl="6" size-xs='12' class="col-inner-container"
              *ngFor="let item of addRemoveColumnSelectorData">
              <ion-col size-xs='1' size-md="1">
                <ion-checkbox checked={{item.isChecked}} [disabled]="item.disabled" class="col-checkbox"
                  id="columnSelector_checkbox_{{item.val}}" [ngClass]="item.disabled ? 'disabled-checkbox' : ''"
                  (ionChange)="changeWidgetCheckedValue(item)">
                </ion-checkbox>
              </ion-col>
              <ion-col size-xs='11' size-md="11">
                <ion-label class="content-card-title" id="columnSelector_title_{{item.val}}"
                  [ngClass]="item.disabled ? 'disabled-class-title' : ''">
                  {{item.title | translate}} </ion-label>
              </ion-col>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
      <ion-row class="button-row">
        <ion-button class="app-btn" shape="round" fill="outline" color="primary"
          id="columnSelector_submit_button" [disabled]="!isAnyValueChanged" (click)='submitColumnSelectorData()'>
          {{'Submit'|translate}}
        </ion-button>
      </ion-row>
    </ion-grid>
  </div>

  <span *ngIf="shouldDisplayAlert" class="alert-box">
    <div class="close-icon" (click)="resetAndCloseToDefaultSettigs()" id="columnSelector_alert_cancel_button">
      <img src="../../../assets/icon/cancel.svg" alt="">
    </div>
    <div class="title" *ngIf="alertContent" id="columnSelector_alert_content">
      <span>{{alertContent}}</span>
    </div>
    <div class="button-container">
      <button (click)="confirmColumnSelectorReset()" id="columnSelector_alert_confirm_button">{{
        'Confirm' | translate }}</button>
    </div>
  </span>

</ion-content>