import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalSettingsService } from './local-settings/local-settings.service';

const selLng = 'SELECTED_LANGUAGE';

@Injectable({
  providedIn: 'root'
})
export class LanguageSupportService {
  localLanguage: any;

  constructor(private translate: TranslateService, public localSettingsSrv: LocalSettingsService) { }

  getDefaultLanguage() {
    let language;
    const lang_preff = this.localSettingsSrv.getLanguagePreference('ln_pref');
    if (lang_preff) {language = lang_preff;}
    else {language = this.translate.getBrowserLang();}
    this.translate.setDefaultLang(language);
    return language;
  }

  setLanguage(setLang) {
    this.translate.use(setLang);
    this.localSettingsSrv.setLanguagePreference('ln_pref', setLang);
    this.localLanguage = this.localSettingsSrv.setLanguagePreference('ln_pref', setLang);
  }
}
