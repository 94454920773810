<ion-header>
  <app-header></app-header>
  <ion-grid class="header-container head-block">
    <!-- Header View -->
    <div class="flex-container available-width"
      [ngClass]="prjDetailSelectedSegment !== 'projectInformation_segment' && !IsEmptyCard ? 'available-width':
      prjDetailSelectedSegment === 'status_segment' && IsEmptyCard ? 'reduce-header-width' :prjDetailSelectedSegment === 'overview_segment' && IsEmptyCard ? 'reduce-header-width' : '' ">
      <div class="left-column">
        <app-exit-button class="left-margin" (click)="goBack()"></app-exit-button>
      </div>
      <div class="right-column">
        <div class="header-right header-top hov-label" (click)="openProjectAction($event,'openProAction')">
          {{ 'Project Actions' | translate}} <img src="../../../assets/images/Down_Arrow.svg"
            class="header-dropdown-arrow" alt="">
        </div>
        <div class="header-right header-top hov-label" (click)="openProjReport($event,'openProReport', projectList?.status,prodetailsData.selectOrder,prodetailsData.serviceType,projectList.project_Id,projectList?.erp_Project_Id,projectList?.company_Code,projectList?.project_Name, projectList.is_sap)">
          {{ 'Project Report' | translate}} <img src="../../../assets/images/Down_Arrow.svg"
            class="header-dropdown-arrow" alt="">
        </div>
        <div (click)="openStatusPopover($event,'projectInformationStatus',projectList?.status)">
          <ion-icon color="primary" tooltip="{{'More actions' | translate }}" placement="bottom" name="ellipsis-vertical-outline" class="header-more-icon"></ion-icon>
        </div>
      </div>
    </div>

    <!-- Select Job Order & Service Product Id Options View -->
    <ion-row class="alignrow">
      <!--if isFromSAP is true then job order selection will be displayed-->
      <ng-container *ngIf="isFromSAP">
        <ion-col  size-xs='1.3' size-sm='1.3' size-md="1.3" size-lg="1.3" size-xl="1.3" style="margin-top: 4px;">
          <ion-label class="sd-label">
            {{ 'Select Job Order' | translate}}
          </ion-label>
        </ion-col>
        <ion-col  size-xs='2' size-sm='2' size-md="2" size-lg="2" size-xl="2">
          <ng-select class="dropdown" [disabled]="selectServiceOrderOptions.length==0" [clearable]="false" [items]="selectServiceOrderOptions"
            [(ngModel)]="prodetailsData.selectOrder" bindLabel="erp_Order_Id" bindValue="erp_Order_Id"
            placeholder="{{'Select Job Order Id'|translate}}" (change)="selectJobOrderId($event)">
          </ng-select>
        </ion-col>
        <!-- Service Product Id Selection -->
        <ion-col size-xs='0.8' size-sm='0.8' size-md="1" size-lg="1" size-xl="1" style="margin-top: 4px;">
          <ion-label class=" sd-label">
            {{ 'Service Id' | translate}}
          </ion-label>
        </ion-col>
        <ion-col size-xs='2' size-sm='2' size-md="2" size-lg="2" size-xl="2">
          <ng-select class="dropdown" name="clientCustomerId" [clearable]="false"
            placeholder="{{'Select Service Id'|translate}}" [items]="serviceTypesList"
            [(ngModel)]="prodetailsData.serviceType" bindLabel="service_Type" bindValue="service_Type"
            (change)="selectServiceType($event)" (focus)="getServiceTypeByOrderId(prodetailsData.selectOrder)"
            [disabled]="prodetailsData.selectOrder || selectServiceOrderOptions.length !=0 ? false : true">
          </ng-select>
        </ion-col>
      </ng-container>
      <ng-container *ngIf="!isFromSAP">
        <!-- Service Product Id Selection -->
        <ion-col size-xs='0.8' size-sm='0.8' size-md="0.8" size-lg="0.8" size-xl="0.8" style="margin-top: 4px;">
          <ion-label class=" sd-label">
            {{ 'Service Id' | translate}}
          </ion-label>
        </ion-col>
        <ion-col size-xs='2' size-sm='2' size-md="2" size-lg="2" size-xl="2">
          <ng-select placeholder="{{ 'Select Service Id' | translate}}"
            (change)="selectServiceId($event,selectedServiceIdNonSAP)" 
            [(ngModel)]="selectedServiceIdNonSAP" [clearable]="false"
            [disabled]="!selectedServiceIdNonSAP" >
            <ng-option *ngFor="let service of serviceIds" [value]="service"
            >{{service}}</ng-option>
          </ng-select>
        </ion-col>
      </ng-container>
    </ion-row>
  </ion-grid>

  <!-- Segment View -->
  <div class="card-container-block">
    <div class="tabs">
      <div class="tab-option" [class.active]="activeTab === tabs.PROJECT_OVERVIEW"                    
                   (click)="segmentChanged('projectInformation_segment')">
        {{ 'Project Overview' | translate}}</div>  
      <div class="tab-option" 
        [class.disabled]="prodetailsData.serviceType==null && selectedServiceIdNonSAP === ''" 
        [class.active]="activeTab === tabs.PROJECT_SERVICE_DETAILS"
        (click)="segmentChanged('service_details_segment')">
        {{ 'Service Details' | translate}}</div>      
      <div class="tab-option" 
        [class.disabled]="(serviceDetails?.is_machinebased == true || prodetailsData.serviceType==null || machineListInfo.length != 0 )"
        [class.active]="activeTab === tabs.SERVICE_ROADMAP_DETAILS"
        (click)="segmentChanged('service_roadmap_details_segment')">
        {{ 'Service Roadmap Details' | translate}}</div>    
      <div class="tab-option" 
        [class.disabled]="(serviceDetails?.is_machinebased == false 
        || prodetailsData.serviceType == null  
        || machineListInfo.length == 0)
        && isMachineAvailable == false" 
        [class.active]="activeTab === tabs.MACHINE_DETAILS"
        (click)="segmentChanged('machine_details_segment')">
     {{ 'Machine Details' | translate }}
   </div>
   
      <div class="tab-option" 
        [class.disabled]="prodetailsData.selectOrder==null && prodetailsData.serviceType==null"
        [class.active]="activeTab === tabs.LOG_ACTUAL_HOURS"
        (click)="segmentChanged('log_actual_hours_segment')">
        {{ 'Log Actual Hours' | translate}}</div>  
      <!-- Disabling Projects Items View as there is no data to show-->   
      <!-- <div class="tab-option"         
        [class.active]="activeTab === tabs.PROJECT_ITEMS_VIEW"
        (click)="segmentChanged('overview_segment')">
        {{ 'Project Items View' | translate}}</div>    -->
    </div>
  </div>  
</ion-header>
<ion-content fullscreen="true" no-bounce [scrollEvents]="true">
  <div [ngSwitch]="prjDetailSelectedSegment" class="pd-segment-container">
    <!-- Project Information -->
    <div *ngSwitchCase="'projectInformation_segment'" class="margin-left-110">
      <ion-grid>
        <ion-row>
          <ion-col size="6">
            <ion-card class="innercard">
              <ion-card-header>
                <ion-card-title class="pdsc-title">{{ 'Project Details' | translate}}
                </ion-card-title>
              </ion-card-header>
              <ion-card-content>
                <ion-row>
                  <ion-col size="7">
                    <ion-label class="pdsc-label">{{ 'Project Name' | translate}} </ion-label><br />
                    <p class="pdscl-value">{{projectList?.project_Name}}</p>
                  </ion-col>
                  <ion-col size="4">
                    <ion-label class="pdsc-label">{{ 'Project Id' | translate}} </ion-label><br />
                    <p class="pdscl-value">
                      {{projectList?.erp_Project_Id?projectList?.erp_Project_Id:projectList?.project_Id}}</p>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size="7">
                    <ion-label class="pdsc-label">{{ 'Customer Name' | translate}} </ion-label><br />
                    <p class="pdscl-value">{{projectList?.customer_Name}}</p>
                  </ion-col>
                  <ion-col size="4">
                    <ion-label class="pdsc-label">{{ 'Company Code' | translate}} </ion-label><br />
                    <p class="pdscl-value">{{projectList?.companyCodeAndCompanyName}}</p>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size="7">
                    <ion-label class="pdsc-label">{{ 'Status' | translate}} </ion-label><br />
                    <p class="pdscl-value">{{projectList?.status}}</p>
                  </ion-col>
                  <ion-col size="4">
                    <ion-label class="pdsc-label">{{ 'Description' | translate}} </ion-label><br />
                    <p class="pdscl-value">{{projectList?.description}}</p>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size="7">
                    <ion-label class="pdsc-label">{{ 'Project Start Date' | translate}} </ion-label><br />
                    <p class="pdscl-value">{{projectList?.start_Date}}</p>
                  </ion-col>
                  <ion-col size="4">
                    <ion-label class="pdsc-label">{{ 'Project End Date' | translate}} </ion-label><br />
                    <p class="pdscl-value">{{projectList?.end_Date}}</p>
                  </ion-col>
                </ion-row>
              </ion-card-content>
            </ion-card>
          </ion-col>
          <ion-col size="6">
            <ion-card class="innercard" style="width:97%!important;">
              <ion-card-header>
                <ion-card-title class="pdsc-title">{{ 'Budget and Costing Information' | translate}} </ion-card-title>
              </ion-card-header>
              <ion-card-content>
                <ion-row>
                  <ion-col size="6">
                    <ion-label class="pdsc-label">{{ 'Project Budget' | translate}} </ion-label><br />
                    <p class="pdscl-value">{{projectList?.projectBudget}}</p>
                  </ion-col>
                  <ion-col size="6">
                    <ion-label class="pdsc-label">{{ 'Actual Cost' | translate}} </ion-label><br />
                    <p class="pdscl-value">{{projectList?.actualCost}}</p>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size="6">
                    <ion-label class="pdsc-label">{{ 'Total Number Of Questions' | translate}}</ion-label><br />
                    <p class="pdscl-value" *ngIf="projectList?.time">{{projectList?.totalStepCount}}
                    </p>
                  </ion-col>
                  <ion-col size="6">
                    <ion-label class="pdsc-label">{{ 'Total Number Of Open Questions' | translate}}</ion-label><br />
                    <p class="pdscl-value">{{projectList?.totalStepCount-projectList?.totalAnswredStepCount}}</p>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size="6">
                    <ion-label class="pdsc-label">{{ 'Total Number Of Calculated Hours' | translate}}</ion-label><br />
                    <p class="pdscl-value" *ngIf="projectList?.time">{{projectList?.totlalCalculatedHours}} h
                    </p>
                  </ion-col>
                  <ion-col size="6">
                    <ion-label class="pdsc-label">{{ 'Total Number Of Actual Hours' | translate}}</ion-label><br />
                    <p class="pdscl-value">{{projectList?.totalActualHours}} h</p>
                  </ion-col>
                </ion-row>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="6">
            <ion-card class="innercard pdsc-card-extra-space">
              <ion-card-header>
                <ion-card-title class="pdsc-title">{{ 'Customer Details' | translate}} </ion-card-title>
              </ion-card-header>
              <ion-card-content>
                <ion-row>
                  <ion-col size="4">
                    <ion-label class="pdsc-label">{{ 'Customer Name' | translate}}</ion-label><br />
                    <p class="pdscl-value">{{projectList?.customerDetails.customer_Name}}</p>
                  </ion-col>
                  <ion-col size="4">
                    <ion-label class="pdsc-label">{{ 'Customer Contact' | translate }}</ion-label><br />
                    <p class="pdscl-value">{{projectList?.customerDetails.phone_Number}}</p>
                  </ion-col>
                  <ion-col size="4">
                    <ion-label class="pdsc-label">{{ 'Customer Address' | translate}} </ion-label><br />
                    <p class="pdscl-value"> {{projectList?.customerDetails.address}}</p>
                  </ion-col>
                </ion-row>
              </ion-card-content>

              <ion-card-header>
                <ion-card-title class="pdsc-title">{{ 'Contact Details' | translate}} </ion-card-title>
              </ion-card-header>
              <ion-card-content *ngFor="let contact of projectList?.customerDetails.contacts">
                <ion-row>
                  <ion-col size="4">
                    <ion-label class="pdsc-label">{{ 'Contact Person' | translate}} </ion-label><br />
                    <p class="pdscl-value">{{contact?.display_name ? contact?.display_name : contact.first_name  + ' ' + contact.last_name}}</p>
                  </ion-col>
                  <ion-col size="4">
                    <ion-label class="pdsc-label">{{ 'Contact Number' | translate}} </ion-label><br />
                    <p class="pdscl-value">{{contact.phone_number}}</p>
                  </ion-col>
                  <ion-col size="4">
                    <ion-label class="pdsc-label">{{ 'Contact Email' | translate}} </ion-label><br />
                    <p class="pdscl-value">{{contact.email_address}}</p>
                  </ion-col>
                </ion-row>
              </ion-card-content>
            </ion-card>
          </ion-col>
          <ion-col size="6">
            <ion-card class="innercard pdsc-card-extra-space" style="width:97%!important;">
              <div class="flex-container card-top-margin">
                <div class="left-column">
                  <ion-card-title style="margin-left:1rem!important" class="pdsc-title">{{ 'Assigned Staff' |
                    translate}} </ion-card-title>
                </div>
                <div class="right-column">
                  <img tooltip="{{'Add user' | translate }}" alt="" placement="top" class="add-user" (click)="goToAddAssignedStaff('assignProject')" src="../../../assets/icon/add_user.svg" />
                </div>
              </div>
                <ion-row class="pdsc-user-info">
                  <ng-container *ngFor="let contact of assignedStaffList; let i = index">
                    <!-- Start a new row for every 2 items -->
                    <ng-container *ngIf="i % 2 === 0">
                      <ion-row class="pdsc-user-info-row"> </ion-row>
                    </ng-container>
                  <ion-col size="6" >
                    <ion-card class="pdscui-card-container">
                      <div class="staff-flex-container">
                        <div class="left-column">
                          <ion-badge class="pdscui-card-badge">
                            <img src="../../../assets/images/Default_User.svg" alt="">
                          </ion-badge>
                          <div class="pdscui-card-title">
                            <span>{{contact.firstName+ ' ' +contact.LastName | ellipsis: 13}}</span>
                            <br />
                            <p class=" pdscui-card-sub-title">{{contact.userRole | ellipsis: 10}}</p>
                          </div>
                        </div>
                        <div class="right-column">
                          <ion-icon class="pdscui-card-del-img delete-icon-transition" name="trash-outline" tooltip="{{'Delete user' | translate }}"
                            placement="bottom" (click)="deleteMemberToProject(contact.emailAdd,contact)">
                          </ion-icon>
                        </div>
                      </div>
                    </ion-card>
                  </ion-col>
                  <ng-container *ngIf="(i === assignedStaffList.length - 1) || (i % 2 === 1)">
                </ng-container>
              </ng-container>
                </ion-row>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>

    <!-- Machine Details Segemnt -->
    <div *ngSwitchCase="'machine_details_segment'" class="margin-left-110">
      <ng-container *ngIf="IsEmptyCard">
        <div class="available-width">
          <ion-grid>
            <ion-row class="labelside">
              <ion-col size='12' style="text-align: end;">
                <ion-col size='2' style="margin-right: 0.2em;">
                  <img src="../../../assets/images/Approve.svg" class="source" alt="">
                  <button class="sourLabel bucket-button" [disabled]="!isAnyMachineListSelected"
                    [ngClass]="!isAnyMachineListSelected ? 'disable-button' : 'active-button'" (click)="addToBucket()">
                    {{ 'Add to Bucket' | translate}}
                  </button>
                </ion-col>
                <ion-col class="conflict-button" size='2'>
                  <button [ngClass]="(isConflictInAnyMachine&&isConflictResolutionPermission) ? 'active-button' : ''"
                    [disabled]="!isConflictInAnyMachine && !isConflictResolutionPermission"
                    (click)="goToConflictResolutionPage()">
                    <img src="../../../assets/images/Approve.svg" class="source" alt="">
                    <ion-label class="sourLabel"> {{ 'Conflicts' | translate}}</ion-label>
                  </button>
                </ion-col>

                <ion-col size='4'>
                  <button class="butCreate" (click)="myMachineAddBucket($event, 'machineSegmentBuckesEditOrCreate')">
                    <label class="crLabel"> {{ 'Buckets' | translate}}</label>
                    <img src="../../../assets/images/Down_Arrow.svg" class="insideImage" alt="">
                  </button>
                </ion-col>
                <!-- commented till approval of removing list view functionality completely -->
                <!-- <ion-col size='0.8'>
                  <img src="../../../assets/images/List_View.svg" class="source text-hover"
                    (click)="changeMachineListView('layout')" alt="">
                </ion-col>
                <ion-col size='0.8'>
                  <img src="../../../assets/images/Grid_View.svg" class="source text-hover"
                    (click)="changeMachineListView('grid')" alt="">
                </ion-col> -->
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>

        <!-- Added Bucket & All Machine Lists -->
        <div class="available-width">
          <!-- Machines Added To Bucket -->
          <ng-container *ngIf="allBucketLists?.length && (isBucketListPermission || isBucketWritePermission)">
            <ion-card class="card-header">
              <ion-label class="titlelabel" style="padding-left: 2em;"> {{ 'All Buckets' | translate}}</ion-label>
              <div *ngFor="let bucket of allBucketLists">
                <ion-card class="list-card">
                  <ion-item lines='none' class="service-type-list mainlabel"
                    (click)='changeServiceTypeSelection(bucket)'>
                    <ion-label class="bucket-sub-title">{{bucket.Bucket_Name}}</ion-label>
                    <ion-icon name="chevron-down-outline" slot="end" *ngIf="!bucket?.opened"></ion-icon>
                    <ion-icon name="chevron-up-outline" slot="end" *ngIf="bucket?.opened"></ion-icon>
                  </ion-item>
                  <div *ngIf="bucket?.opened">
                    <ng-container *ngIf="bucket.machines?.length">
                      <ion-grid *ngIf="machineListViewType === 'grid-view' ">
                        <ion-row class="labelside">
                          <ion-col size='3' *ngFor="let machine of bucket.machines">
                            <app-machine-card [item]="machine" [showStatus]="true" [status]="machine.is_RA_Status" [showMore]="true" [showCheckbox]="false"
                              *ngIf='machine.machine_Id!=="00000000-0000-0000-0000-000000000000"'
                              (deleteMachineCheckbox)="changeMachineCheckboxSelection($event)" (checkMore)="machineMoreIcons($event, 'bucket-machine-specific-kebab-elipse', machine,bucket)"
                              id="gridView_bucket_added_machine_{{machine.machine_Id}}" (click)="goToMachineDetails($event, machine)">
                            </app-machine-card>
                          </ion-col>
                        </ion-row>
                      </ion-grid>
                      <ion-grid *ngIf="machineListViewType === 'layout-view' ">
                        <ion-row class="labelside">
                          <ion-col>
                            <label class="lableFont"> {{ 'Machine Name' | translate}}</label>
                          </ion-col>
                          <ion-col>
                            <label class="lableFont"> {{ 'Serial Number' | translate}}</label>
                          </ion-col>
                          <ion-col>
                            <label class="lableFont"> {{ 'Asset No.' | translate}}</label>
                          </ion-col>
                          <ion-col>
                            <label class="lableFont"> {{ 'Machine Type' | translate}}</label>
                          </ion-col>
                          <ion-col><label class="lableFont"> {{ 'Assigned Staff' | translate}}</label></ion-col>
                        </ion-row>
                        <ion-row class="borderdown"></ion-row>
                        <div *ngFor="let machine of bucket.machines">
                          <ion-row class="labelside" *ngIf='machine.machine_Id!=="00000000-0000-0000-0000-000000000000"'
                            (click)="goToMachineDetails($event, machine)"
                            id="listView_bucket_added_machine_{{machine.machine_Id}}">
                            <ion-col>
                              <label class="lablemain">
                                {{machine.Machine_Name}}
                              </label>
                            </ion-col>
                            <ion-col>
                              <label class="lableSub">{{machine.Serial_Number ? machine.Serial_Number :
                                '-'}}</label>
                            </ion-col>
                            <ion-col>
                              <label class="lableSub"> {{machine.Asset_Id ? machine.Asset_Id : '-'}} </label>
                            </ion-col>
                            <ion-col>
                              <label class="lableSub"> {{machine.masterMachine?.machine_Type?
                                machine.masterMachine?.machine_Type : '-'}} </label>
                            </ion-col>
                            <ion-col>
                              <label class="lableSub"> {{machine.assignedStaff?.display_Name?
                                machine.assignedStaff?.display_Name: '-'}}  </label>
                            </ion-col>
                          </ion-row>
                          <ion-row class="borderdown"></ion-row>
                        </div>
                      </ion-grid>
                    </ng-container>
                    <ng-container *ngIf="!bucket.machines?.length">
                      <ion-label class="no-machines-added">{{"No Machines Added"|translate}}</ion-label>
                    </ng-container>
                  </div>
                </ion-card>
              </div>
            </ion-card>
          </ng-container>

          <!-- Available Machines -->
          <div [ngStyle]="{'padding-top':allBucketLists?.length ? '10px' : '0px' }">
            <ion-card class="card-header" *ngIf="machineListInfo?.length && isMachineListPermission">
              <ion-row class="mainlabel">
                <ion-col size='12'>
                  <ion-label class="titlelabel"> {{ 'Available Machines' | translate}}</ion-label>
                </ion-col>
              </ion-row>
              <ion-row class="mainlabel" *ngIf="machineListInfo?.length > 0">
                <ion-col size="12" *ngIf='machineListInfo?.length>1'>
                  <ion-checkbox class="insideChebox" [(ngModel)]="isAllMachineCheckboxSelected"
                    (ionChange)="changeAllSelectedCheckboxValue()"></ion-checkbox>
                  <ion-label class="subtitlelabel"> {{ 'Select All' | translate}} 
                  </ion-label>
                </ion-col>
              </ion-row>
              <ion-grid *ngIf="machineListViewType === 'grid-view'">
                <ion-row class="labelside scroll-element">
                  <ion-col size="3" *ngFor="let machine of machineListInfo">
                    <app-machine-card [item]="machine" [showStatus]="true" [status]="machine.is_RA_Status" [showMore]="true" [showCheckbox]="true"
                      (deleteMachineCheckbox)="changeMachineCheckboxSelection(machine)" (checkMore)="machineMoreIcons($event, 'machine-specific-kebab-elipse', machine)"
                      *ngIf='machine.machine_Id!=="00000000-0000-0000-0000-000000000000"'
                      id="gridView_bucket_added_machine_{{machine.machine_Id}}" (click)="goToMachineDetails($event, machine)">
                    </app-machine-card>
                  </ion-col>
                </ion-row>
              </ion-grid>
              <ion-grid *ngIf="machineListViewType === 'layout-view'">
                <ion-row class="labelside">
                  <ion-col>
                    <label class="lableFont">{{ 'Machine Name' | translate}}</label>
                  </ion-col>
                  <ion-col>
                    <label class="lableFont"> {{ 'Serial Number' | translate}}</label>
                  </ion-col>
                  <ion-col>
                    <label class="lableFont"> {{ 'Asset No.' | translate}}</label>
                  </ion-col>
                  <ion-col>
                    <label class="lableFont"> {{ 'Machine Type' | translate}}</label>
                  </ion-col>
                  <ion-col><label class="lableFont"> {{ 'Assigned Staff' | translate}}</label></ion-col>
                </ion-row>
                <ion-row class="borderdown"></ion-row>
                <div *ngFor="let machine of machineListInfo">
                  <ion-row class="labelside" *ngIf='machine.machine_Id!=="00000000-0000-0000-0000-000000000000"'
                    (click)="goToMachineDetails($event, machine)"
                    id="listView_bucket_available_machine_{{machine.machine_Id}}">
                    <ion-col>
                      <label class="lablemain">
                        {{machine.machine_Name}}
                      </label>
                    </ion-col>
                    <ion-col>
                      <label class="lableSub">{{machine.serial_Number ? machine.serial_Number : '-'}}</label>
                    </ion-col>
                    <ion-col>
                      <label class="lableSub"> {{machine.asset_Id ? machine.asset_Id : '-'}} </label>
                    </ion-col>
                    <ion-col>
                      <label class="lableSub"> {{machine.masterMachine?.machine_Type
                        ?machine.masterMachine?.machine_Type : '-'}} </label>
                    </ion-col>
                    <ion-col>
                      <label class="lableSub"> {{machine.assignedStaff && machine.assignedStaff.display_Name ?
                        machine.assignedStaff.display_Name
                        : '-'}} </label>
                    </ion-col>
                  </ion-row>
                  <ion-row class="borderdown"></ion-row>
                </div>
              </ion-grid>
            </ion-card>

            <!-- No Machines Available View -->
            <ion-card class="card-header" *ngIf="!machineListInfo?.length || !isMachineListPermission">
              <ion-row>
                <ion-col style="text-align: center;">
                  <img src="../../../assets/images/No data-cuate.svg" alt=""/>
                  <p class="no-machines-available" *ngIf="isMachineListPermission"> {{ 'No Machines Available' |
                    translate}}.</p>
                  <p class="no-machines-available" *ngIf="!isMachineListPermission">
                    {{ 'Sorry No permissions to view Machines' | translate}}.</p>
                </ion-col>
              </ion-row>
            </ion-card>
          </div>
        </div>

      </ng-container>
      <ion-card class="insideCard" *ngIf="!IsEmptyCard">
        <img class="centerImg" src="../../../assets/icon/Bigimage.svg" alt="">
        <div>
          <p class="textLabel">Please Select Job Order & Service Product Id to View Data </p>
        </div>
      </ion-card>
    </div>

    <!-- Service Details Segment -->
  <div *ngSwitchCase="'service_details_segment'" class="margin-left-110">
    <ion-card class="service-details-card" *ngIf="IsEmptyCard">
          <!-- //////////////////////// -->
      <div #old *ngIf="isFromSAP">
        <div style="padding-left: 1em;padding-top: 1.2em;">
          <ion-grid>
            <ion-row>
              <ion-col>
                <ion-label class="textCol2" position="stacked"> {{ 'Offer Id' | translate}}</ion-label>
              </ion-col>
              <ion-col>
                <ion-label class="textCol2" position="stacked"> {{ 'Project Id' | translate}}</ion-label>
              </ion-col>
              <ion-col></ion-col>
              <ion-col></ion-col>
            </ion-row>
          </ion-grid>
          <ion-grid>
            <ion-row style="margin-top:-8px">
              <ion-col>
                <ion-label class="labeltext" style="color:black!important" position="stacked">

                  {{serviceTabData?.erp_wbs_id?serviceTabData?.erp_wbs_id:serviceTabData?.erp_Wbs_Id?serviceTabData?.erp_Wbs_Id:serviceTabData.offerId}}
                </ion-label>
              </ion-col>
              <ion-col>
                <ion-label class="labeltext" style="color:black!important" position="stacked">
                  {{projectList?.erp_Project_Id?projectList?.erp_Project_Id:serviceTabData.projectId}}
                </ion-label>
              </ion-col>
              <ion-col></ion-col>
              <ion-col></ion-col>
            </ion-row>
          </ion-grid>
        </div>
        <p class="rowborder"></p>
        <div style="padding-left: 1em;padding-top: 1.2em;">
          <ion-grid>
            <ion-row>
              <ion-col>
                <ion-label class="textCol2" position=" stacked"> {{ 'Contact Person' | translate}}</ion-label>
              </ion-col>
              <ion-col>
                <ion-label class="textCol2" position="stacked"> {{ 'Customer Name' | translate}}</ion-label>
              </ion-col>
              <ion-col>
                <ion-label class="textCol2" position="stacked"> {{ 'Contact Email' | translate}}</ion-label>
              </ion-col>
              <ion-col>
                <ion-label class="textCol2" position="stacked"> {{ 'Contact Number' | translate}}</ion-label>
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-grid>
            <ion-row style="margin-top:-8px">
              <ion-col>
                <ion-label class="labeltext" style="color:black!important" position="stacked">
                  {{serviceTabData.contactPerson || '-'}}
                </ion-label>
              </ion-col>
              <ion-col>
                <ion-label class="labeltext" style="color:black!important" position="stacked">
                  {{serviceTabData.customerName || '-'}}
                </ion-label>
              </ion-col>
              <ion-col>
                <ion-label class="labeltext" style="color:black!important" position="stacked">
                  {{serviceTabData.customerEmail || '-'}}</ion-label>
              </ion-col>
              <ion-col>
                <ion-label class="labeltext" style="color:black!important" position="stacked">
                  {{serviceTabData.customerPhoneNumber || '-'}}</ion-label>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
        <div style="padding-left: 1em;padding-top: 1.2em;">
          <ion-grid>
            <ion-row>
              <ion-col>
                <ion-label class="textCol2" position="stacked"> {{ 'Customer Address' | translate}}</ion-label>
              </ion-col>
              <ion-col>
                <ion-label class="textCol2" position="stacked"> {{ 'Customer Id' | translate}}</ion-label>
              </ion-col>
              <ion-col></ion-col>
              <ion-col></ion-col>
            </ion-row>
          </ion-grid>
          <ion-grid style="margin-top:-7px">
            <ion-row style="margin-top:-8px">
              <ion-col>
                <ion-label class="labeltext" style="color:black!important" position="stacked">
                  {{serviceTabData.customerAddress || '-'}}
                </ion-label>
              </ion-col>
              <ion-col>
                <ion-label class="labeltext" style="color:black!important" position="stacked">
                  {{serviceTabData.customerId || '-'}}</ion-label>
              </ion-col>
              <ion-col></ion-col>
              <ion-col></ion-col>
            </ion-row>
          </ion-grid>
        </div>
        <p class="rowborder"></p>
        <div style="padding-left: 1em;padding-top: 1.2em;">
          <ion-grid>
            <ion-row>
              <ion-col>
                <ion-label class="textCol2" position=" stacked"> {{ 'Service Type' | translate}}</ion-label>
              </ion-col>
              <ion-col>
                <ion-label class="textCol2" position="stacked"> {{ 'Service Location' | translate}}</ion-label>
              </ion-col>
              <ion-col>
                <ion-label class="textCol2" position="stacked"> {{ 'Service Start Date' | translate}}</ion-label>
              </ion-col>
              <ion-col>
                <ion-label class="textCol2" position="stacked"> {{ 'Service End Date' | translate}}</ion-label>
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-grid>
            <ion-row style="margin-top:-8px">
              <ion-col>
                <ion-label class="labeltext" style="color:black!important" position="stacked">
                  {{serviceTabData.serviceType || '-'}}
                </ion-label>
              </ion-col>
              <ion-col>
                <ion-label class="labeltext" style="color:black!important" position="stacked">
                  {{serviceTabData.serviceLocation || '-'}}
                </ion-label>
              </ion-col>
              <ion-col>
                <ion-label class="labeltext" style="color:black!important" position="stacked">
                  {{serviceTabData.serviceStartDate || '-'}}
                </ion-label>
              </ion-col>
              <ion-col>
                <ion-label class="labeltext" style="color:black!important" position="stacked">
                  {{serviceTabData.serviceEndDate || '-'}}
                </ion-label>
              </ion-col>
            </ion-row>
          </ion-grid>

        </div>
        <div style="padding-left: 1em;padding-top: 1.2em;">
          <ion-grid>
            <ion-row>
              <ion-col>
                <ion-label class="textCol2" position="stacked"> {{ 'Region' | translate}}</ion-label>
              </ion-col>
              <ion-col>
                <ion-label class="textCol2" position="stacked"> {{ 'Service Description' | translate}}</ion-label>
              </ion-col>
              <ion-col></ion-col>
              <ion-col></ion-col>
            </ion-row>
          </ion-grid>
          <ion-grid style="margin-top:-7px">
            <ion-row style="margin-top:-8px">
              <ion-col>
                <ion-label class="labeltext" style="color:black!important" position="stacked">
                  {{serviceTabData.region || '-'}}
                </ion-label>
              </ion-col>
              <ion-col>
                <ion-label position="stacked">
                  <p class="desLabel">
                    {{serviceTabData.serviceDescription || '-'}}
                  </p>
                </ion-label>
              </ion-col>
              <ion-col></ion-col>
              <ion-col></ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </div>
      <!-- //////////////////////////// -->
      <div *ngIf="!isFromSAP">
        <ion-list>
          <ion-row>
            <ion-col size="4">
              <div class="card-container">
                <div class="tabs">
                  <div class="tab-option" (click)="selectedTab(tabs1.SERVICE_DETAILS)"
                    [class.active]="activeTab1 === tabs1.SERVICE_DETAILS">{{'Service Details' | translate}}
                  </div>
                  <div class="tab-option" (click)="selectedTab(tabs1.MACHINE_DETAILS)" [class.active]="activeTab1 === tabs1.MACHINE_DETAILS">
                    {{'Machine Details' | translate}}
                  </div>
                </div>
              </div>
            </ion-col>
            <ion-col size="8">
              <ng-container *ngIf="!isFromSAP && activeTab1 === tabs1.MACHINE_DETAILS">
                <ion-button class="app-large-btn app-btn-margin add-library-machine" shape="round" fill="outline" color="primary"
                  (click)='addMachineFromLibrary(viewServiceDetails)'>
                  {{'Add Machine From Library'|translate}}
                </ion-button>
                <ion-button class="app-large-btn app-btn-margin" shape="round" fill="outline" color="primary"
                  (click)='addNewMachine(viewServiceDetails)'>
                  {{'Add New Machine'|translate}}
                </ion-button>
              </ng-container>
              <ion-icon name="create-outline" class="more-icon"
                *ngIf="activeTab1 === tabs1.SERVICE_DETAILS"
                (click)="editService(serviceDetails, 'serviceDetails')"
                tooltip="{{'Edit Service' | translate }}" placement="bottom">
              </ion-icon>
              <div *ngIf="activeTab1 === tabs1.SERVICE_DETAILS && serviceDetails?.isEditServiceShow">
                <ion-button fill="outline" shape="round" class="app-btn"
                  (click)="isEditServiceSave(serviceDetails)">{{ 'Save' |
                  translate}}</ion-button>
                <ion-button fill="outline" shape="round" class="app-btn" (click)="editServiceCancel(serviceDetails)">{{
                  'Cancel' | translate}}</ion-button>
              </div>
            </ion-col>
          </ion-row>
        </ion-list>
        <div class="tabs-content">
          <ng-container *ngIf="activeTab1 === tabs1.SERVICE_DETAILS">
            <ion-grid>
              <ion-row>
                <ion-col>
                  <ion-label class="textCol2" position="stacked"> {{ 'Offer Id' | translate}}</ion-label>
                </ion-col>
                <ion-col>
                  <ion-label class="textCol2" position="stacked"> {{ 'Project Id' | translate}}</ion-label>
                </ion-col>
                <ion-col></ion-col>
                <ion-col></ion-col>
              </ion-row>
            </ion-grid>
            <ion-grid>
              <ion-row>
                <ion-col>
                  <ion-label class="labeltext" position="stacked">
                    {{serviceDetails?.offerId || '-'}}
                  </ion-label>
                </ion-col>
                <ion-col>
                  <ion-label class="labeltext" position="stacked">
                    {{serviceDetails?.project_id || '-'}}
                  </ion-label>
                </ion-col>
                <ion-col></ion-col>
                <ion-col></ion-col>
              </ion-row>
            </ion-grid>
            <p class="rowborder"></p>
            <ion-grid>
              <ion-row>
                <ion-col>
                  <ion-label class="textCol2" position=" stacked"> {{ 'Contact Person' | translate}}</ion-label>
                </ion-col>
                <ion-col>
                  <ion-label class="textCol2" position="stacked"> {{ 'Customer Name' | translate}}</ion-label>
                </ion-col>
                <ion-col>
                  <ion-label class="textCol2" position="stacked"> {{ 'Contact Email' | translate}}</ion-label>
                </ion-col>
                <ion-col>
                  <ion-label class="textCol2" position="stacked"> {{ 'Contact Number' | translate}}</ion-label>
                </ion-col>
              </ion-row>
            </ion-grid>
            <ion-grid>
              <ion-row>
                <ion-col>
                  <ion-label *ngIf=!serviceDetails?.isEditServiceShow class="labeltext" position="stacked">
                    {{serviceDetails?.contact_person || '-'}}
                  </ion-label>
                  <ng-select *ngIf=serviceDetails?.isEditServiceShow dropdownPosition="bottom" name="clientContactPerson" [clearable]="false"
                    bindLabel="display_Name" (change)="changeContactPerson($event)" required
                    [items]="(sharedService.customersInfo |async)?.contacts"
                    [(ngModel)]="serviceDetails.contact_person" #clientContactPerson="ngModel"
                    placeholder="{{ 'Select Contact Person' | translate }}">
                  </ng-select>
                </ion-col>
                <ion-col>
                  <ion-label class="labeltext" position="stacked">
                    {{serviceDetails?.customer_name || '-'}}
                  </ion-label>
                </ion-col>
                <ion-col>
                  <ion-label *ngIf=!serviceDetails?.isEditServiceShow class="labeltext" position="stacked">
                    {{serviceDetails?.contact_email || '-' }}
                  </ion-label>
                  <ng-select *ngIf=serviceDetails?.isEditServiceShow dropdownPosition="bottom" name="clientContactEmail" [clearable]="false"
                    bindLabel="email_Address" (change)="changeContactEmail($event)" required
                    [items]="(sharedService.customersInfo |async)?.contacts"
                    [(ngModel)]="serviceDetails.contact_email" #clientContactEmail="ngModel"
                    placeholder="{{ 'Select Contact Email' | translate }}">
                  </ng-select>
                </ion-col>
                <ion-col>
                  <ion-label *ngIf=!serviceDetails?.isEditServiceShow class="labeltext" position="stacked">
                    {{serviceDetails?.contact_phone_number || '-'}}</ion-label>
                    <ng-select *ngIf=serviceDetails?.isEditServiceShow dropdownPosition="bottom" name="customerContact" [clearable]="false"
                      (change)="changeContactPhone($event)" bindLabel="phone_Number" required
                      [items]="(sharedService.customersInfo |async)?.contacts"
                      [(ngModel)]="serviceDetails.contact_phone_number" #customerContact="ngModel"
                      placeholder="Select Contact">
                    </ng-select>
                </ion-col>
              </ion-row>
            </ion-grid>
            <ion-grid>
              <ion-row>
                <ion-col>
                  <ion-label class="textCol2" position="stacked"> {{ 'Customer Address' | translate}}</ion-label>
                </ion-col>
                <ion-col>
                  <ion-label class="textCol2" position="stacked"> {{ 'Customer Id' | translate}}</ion-label>
                </ion-col>
                <ion-col></ion-col>
                <ion-col></ion-col>
              </ion-row>
            </ion-grid>
            <ion-grid style="margin-top:-7px">
              <ion-row style="margin-top:-8px">
                <ion-col>
                  <ion-label class="labeltext" position="stacked">
                    {{serviceDetails?.customer_address || '-'}}
                  </ion-label>
                </ion-col>
                <ion-col>
                  <ion-label class="labeltext" position="stacked">
                    {{serviceDetails?.customer_id || '-'}}</ion-label>
                </ion-col>
                <ion-col></ion-col>
                <ion-col></ion-col>
              </ion-row>
            </ion-grid>
            <p class="rowborder"></p>
            <ion-grid>
              <ion-row>
                <ion-col>
                  <ion-label class="textCol2" position=" stacked"> {{ 'Service Type' | translate}}</ion-label>
                </ion-col>
                <ion-col>
                  <ion-label class="textCol2" position="stacked"> {{ 'Service Location' | translate}}</ion-label>
                </ion-col>
                <ion-col>
                  <ion-label class="textCol2" position="stacked"> {{ 'Service Start Date' | translate}}</ion-label>
                </ion-col>
                <ion-col>
                  <ion-label class="textCol2" position="stacked"> {{ 'Service End Date' | translate}}</ion-label>
                </ion-col>
              </ion-row>
            </ion-grid>
            <ion-grid>
              <ion-row style="margin-top:-8px">
                <ion-col>
                  <ion-label class="labeltext" position="stacked">
                    {{serviceDetails?.service_type || '-'}}
                  </ion-label>
                </ion-col>
                <ion-col>
                  <ion-label class="labeltext" position="stacked">
                    {{serviceDetails?.service_location || '-'}}
                  </ion-label>
                </ion-col>
                <ion-col>
                  <ion-label class="labeltext" position="stacked">
                    {{serviceDetails?.start_date || '-'}}
                  </ion-label>
                </ion-col>
                <ion-col>
                  <ion-label class="labeltext" position="stacked">
                    {{serviceDetails?.end_date || '-'}}
                  </ion-label>
                </ion-col>
              </ion-row>
            </ion-grid>
            <ion-grid>
              <ion-row>
                <ion-col>
                  <ion-label class="textCol2" position="stacked"> {{ 'Region' | translate}}</ion-label>
                </ion-col>
                <ion-col>
                  <ion-label class="textCol2" position="stacked"> {{ 'Service Description' | translate}}</ion-label>
                </ion-col>
                <ion-col></ion-col>
                <ion-col></ion-col>
              </ion-row>
            </ion-grid>
            <ion-grid >
              <ion-row >
                <ion-col>
                  <ion-label class="labeltext" position="stacked">
                    {{serviceDetails?.service_region || '-'}}
                  </ion-label>
                </ion-col>
                <ion-col>
                  <ion-label position="stacked">
                    <p class="desLabel">
                      {{serviceDetails?.service_description || '-'}}
                    </p>
                  </ion-label>
                </ion-col>
                <ion-col></ion-col>
                <ion-col></ion-col>
              </ion-row>
            </ion-grid>
          </ng-container>
          <div *ngIf="activeTab1 === tabs1.MACHINE_DETAILS" class="machine-grid">
            <ion-grid>
              <ion-row class="labelside">
                <ion-col size='3' *ngFor="let machine of machineListInfo">
                  <app-machine-card [item]="machine" [showStatus]="false" [showCheckbox]="false"
                    *ngIf='machine.machine_Id!=="00000000-0000-0000-0000-000000000000"'
                    (deleteMachineCheckbox)="changeMachineCheckboxSelection($event)"
                    id="gridView_bucket_added_machine_{{machine.machine_Id}}">
                  </app-machine-card>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </div>
      </div>
    </ion-card>
      <ion-card class="insideCard" *ngIf="!IsEmptyCard">
        <img class="centerImg" src="../../../assets/icon/Bigimage.svg" alt="">
        <div>
          <p class="textLabel">Please Select Job Order & Service Product Id to View Data </p>
        </div>
      </ion-card>
    </div>

    <!-- Service Roadmap Details Segment -->
    <div *ngSwitchCase="'service_roadmap_details_segment'">
      <app-grid [ngClass]="isRoadmapGridDisplayed ? 'show' : 'hide' " class="service-roadmap-grid margin-left-112" [columns]="roadmapGridColumnDefs" [data]="this.sharedService.selectedServiceRoadmapsList" [gridConfigItems]="roadmapGridConfigItems" (onBtnClick)="configureRoadmaps()"
        (onCellClick)="fnOnGridCellClick($event)" [showKebabIcon]="false">
      </app-grid>
      <ng-container *ngIf="!isRoadmapGridDisplayed && !isRoadmapHistoryDisplayed">
        <div class="roadmap-view-margin">
          <roadmap-view-create [roadmapData]="roadmapData"
          [project_id]="project_Id"
          [serviceMachineId]="serviceMachineId"
          [roadmapId]="roadmapId"
          [roadmapName]="roadmapName"
          [isCustom]="isCustom"
          [isMachineBased]="false"
          (exitEvent)="fnExitEvent($event)"></roadmap-view-create>
        </div>
      </ng-container>
      <ng-container  *ngIf="isRoadmapHistoryDisplayed && !isRoadmapGridDisplayed">
        <app-roadmap-history 
                       [historyData]="historyData" 
                       (exitEvent)="fnExitEvent($event)"></app-roadmap-history>
      </ng-container>
    </div>
    <!-- Log Actual Hours Segemnt -->
    <div *ngSwitchCase="'log_actual_hours_segment'" class="margin-left-110">
      <ion-card class="insideCardData" *ngIf="IsEmptyCard" style="width: 96.5%;">
        <div class="sticky">
        </div>
        <div>
          <div *ngFor="let eachActualHour of logActualHoursInfo;let i=index">
            <ion-card-content>
              <ion-item lines='none' class="log-actual-hours-accordian" (click)='openMachineDetails(eachActualHour)'>
                <ion-label>{{'Machine Name' | translate}} : {{eachActualHour.machinename}}</ion-label>
                  <ion-icon name="chevron-down-outline" slot="end" *ngIf="!eachActualHour?.open"></ion-icon>
                  <ion-icon name="chevron-up-outline" slot="end" *ngIf="eachActualHour?.open"></ion-icon>
              </ion-item>
              <ion-list class="list-view " *ngIf="eachActualHour.open">
                  <div class="table-container scrollVertical">
                    <table class="table">
                      <thead>
                        <th class="text th"> {{ 'Actual Hours' |translate}}</th>
                        <th class="text th">{{ 'Comments' | translate}}</th>
                        <th class="text th">{{ 'User Name' | translate}}</th>
                        <th class="text th">{{ 'Created Date' | translate}}</th>
                        <th class="text th icon-align">{{'Delete'| translate}}</th>
                      </thead>
                      <tr *ngFor="let actualHours of actualHoursList">
                        <td class="td">
                           <ng-container *ngIf="!actualHours.edit">
                            {{actualHours.actualHours ? actualHours.actualHours : '-'}}
                          </ng-container>
                          <ng-container *ngIf="actualHours.edit">
                            <ion-input type="text" inputmode="numeric"  placeholder="Enter Actual Hours" (input)="onInputChange($event)" min="0.01" oninput="this.value = Math.abs(this.value)"
                              class="log-actual-hours-input input-textarea"
                              [ngClass]="actualHours.isActualHourExceedError ? 'log-actual-hours-input-error' : ''"
                              (ionChange)="editLogActualHoursValues($event, actualHours, 'edit-actual')">
                            </ion-input>
                          </ng-container>
                          <ion-label *ngIf="actualHours.enableShowWarning" class="error-text">Actual hours
                            exceeding <br> calculated hours</ion-label>
                        </td>
                        <td class="td">
                          <ng-container *ngIf="!actualHours.edit">
                            {{actualHours.comments ? actualHours.comments : '-'}}
                          </ng-container>
                          <ng-container *ngIf="actualHours.edit">
                            <ion-textarea rows="2" class="input-textarea" cols="20" placeholder="Enter Comments"
                              (ionChange)="editLogActualHoursValues($event, actualHours, 'edit-comment')"></ion-textarea>
                          </ng-container>
                        </td>
                        <td class="td">{{actualHours.userName}}</td>
                        <td class="td">{{actualHours.createdAt | date: 'dd-MM-yyyy HH:mm'}}</td>
                        <td class="td icon-align">
                          <span *ngIf="!actualHours.edit">
                            <ion-icon name="trash-outline" size="small" color="primary" (click)="deleteLogActualHours(eachActualHour,actualHours,'delete')"></ion-icon>
                          </span>
                          <span *ngIf="actualHours.edit" class="actual-hours-span">
                            <ion-button class="app-btn save-actual-hours" shape="round" fill="outline" color="primary" (click)="saveLogActualHours(eachActualHour,'add')">
                              {{'Save'|translate}}
                            </ion-button>
                          </span>
                        </td>
                      </tr>
                    </table>
                    
                  </div>
                <ion-button fill="outline" class="add-new-btn" shape="round"(click)="addNewRow(eachActualHour)">
                  <ion-icon name="add-circle-outline"  size="small" color="primary"></ion-icon>&nbsp; Add
                </ion-button>
              </ion-list>
            </ion-card-content>
          </div>
        </div>
        <div *ngIf="this.machineListInfo.length == 0">
          <img class="img-center" src="/assets/images/Group 20605.svg" alt="">
          <ion-label class="no-data-css"> {{ 'No Machines added' | translate}}</ion-label>
        </div>
      </ion-card>
      <ion-card class="insideCard" *ngIf="!IsEmptyCard">
        <img class="centerImg" src="../../../assets/icon/Bigimage.svg" alt="">
        <div>
          <p class="textLabel">Please Select Job Order & Service Product Id to View Data </p>
        </div>
      </ion-card>
    </div>

    <!-- Overview Segment -->
    <div *ngSwitchCase="'overview_segment'" class="margin-left-110">
      <app-project-items-view [gridData]="projectOverViewLists"></app-project-items-view>
    </div>

    <!-- Status Segment -->
    <div *ngSwitchCase="'status_segment'" class="margin-left-110">
      <ion-card class="insideCardData" *ngIf="IsEmptyCard" style="width: 92%;">
        <div class="scrollelement">
          <ion-grid class="status-container">
            <ion-row>
              <ion-col size="12">
                <ul class="progressbar">
                  <li [ngClass]="step.isdone ? 'done-stepper' : ''" *ngFor="let step of statusStepperData">
                    <div class="stepper-label">{{step.name}}</div>
                  </li>
                </ul>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </ion-card>
      <ion-card class="insideCard" *ngIf="!IsEmptyCard">
        <img class="centerImg" src="../../../assets/icon/Bigimage.svg" alt="">
        <div>
          <p class="textLabel">Please Select Job Order & Service Product Id to View Data </p>
        </div>
      </ion-card>
    </div>
  </div>
</ion-content>