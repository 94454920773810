import { Component, EventEmitter, Input, OnInit, Pipe, PipeTransform, Type } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { BackendCallService } from 'src/app/services/backend-call/backend-call.service';
import { ShareValuesService } from 'src/app/services/sharedValues/share-values.service';
import { NgForm } from '@angular/forms';
import { debounceTime, switchMap } from 'rxjs/operators';
import { EllipsisPipe } from 'src/app/pipes/EllipsisPipe';

@Component({
  selector: 'app-pick-hazard',
  templateUrl: './pick-hazard.component.html',
  styleUrls: ['./pick-hazard.component.scss'],
})
export class PickHazardComponent implements OnInit {
  @Input() type: string;
  initialHazardsList = [];
  filterTerm: string;
  searchTerm='';
  private onScrolled = new EventEmitter();
  url: string;
  count = 30;
  increasedCount = 0;
  limitReached = false;

  selectedData;
  constructor(public backendService: BackendCallService, public loadingController: LoadingController,
    public sharedValue: ShareValuesService, public modalController: ModalController, public ellipsis: EllipsisPipe) { }

  ionViewWillEnter() {
    // if (this.type == 'Pick Initial Hazard') this.getInitialHazardsList();
    // else this.getCounterMeasuresList()
  }

  showSearchBarIcon(){

  }

  ngOnInit() {
    this.subscribeScroll();
    this.onScrolled.emit();
  }

  subscribeScroll() {
    this.onScrolled.pipe(debounceTime(100), switchMap((res) => {
      const payload =
      {
        count: this.count,
        increasedCount: this.increasedCount,
        search: this.searchTerm
      };
      if (this.type == 'Pick Initial Hazard') {return this.backendService.getInitialHazardsListWithIndex(payload);}
      else {return this.backendService.getCounterMeasuresListWithIndex(payload);}
    })).subscribe((data: any) => {
        if (this.searchTerm !== '') {this.increasedCount = this.increasedCount + this.count;}
        if (this.initialHazardsList.length && this.initialHazardsList[0].value.startsWith(this.searchTerm)) {
          if (data.length !== 0) {
            this.initialHazardsList = this.initialHazardsList.concat(...data);
          }else{
            this.increasedCount=0;
            this.limitReached = data.length !== this.count;
          }
        } else {
          this.increasedCount=0;
          if (data.length !== 0) {
            this.initialHazardsList = this.initialHazardsList.concat(...data);
            this.initialHazardsList = [];
          }else{
            this.increasedCount=0;
            this.limitReached = data.length !== this.count;
          }
        }
        this.increasedCount = this.increasedCount + this.count;
        this.initialHazardsList = this.initialHazardsList.concat(...data);
        setTimeout(() => {
        }, 500);
      },err=>{
      }
    );

  }


  getItems(ev: any) {
    const val = ev.target.value;
    if (this.onScrolled) {
      this.onScrolled.next(true);
      this.searchTerm = val;
      this.limitReached = false;
      this.increasedCount=0;
      this.initialHazardsList =[];
      // this.subscribeScroll();
      // this.onScrolled.emit();
    }

  }

  onScroll() {
    if (!this.limitReached) {
      this.onScrolled.emit(Date.now());
    }
  }

  close() {
    this.modalController.dismiss();
  }

 async getCounterMeasuresList() {
    this.backendService.getCounterMeasuresList()
      .subscribe(
        (data: any) => {
          this.initialHazardsList = data;
        },
        (err) => {
        }
      );
  }

  async presentLoading(msg) {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-loading',
      message: msg,
      spinner: 'bubbles'
    });
    await loading.present();
    return loading;
  }

  save() {
    this.modalController.dismiss(this.selectedData);
  }

  async getInitialHazardsList() {
   // const loader =await this.presentLoading('Please wait...');
    this.backendService.getInitialHazardsList()
      .subscribe(
        (data: any) => {
          this.initialHazardsList = data;
       //   loader && loader.dismiss();
        },
        (err) => {
       //   loader && loader.dismiss();
        }
      );
  }

}
@Pipe({
  name: 'searchFilter'
})

export class SearchFilterPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) {return null;}
    if (!args) {return value;}

    args = args.toLowerCase();

    return value.filter(function(data) {
      return JSON.stringify(data).toLowerCase().includes(args);
    });
  }

}

