import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  @Input() confirmationTitle: any;
  @Input() actionText: any;
  constructor(private modalController: ModalController) { }

  ngOnInit() {}

  /**
   * Closes modal on clicking close icon
   */
  public closeModal() {
    this.modalController.dismiss({
      isActionConfirmed: false
    });
  }

  /**
   * Confirms confirmation modal component
   */
  public confirm() {
    this.modalController.dismiss({
      isActionConfirmed: true,
      dismissed: true,
    });
  }

}
