import { AfterViewInit, Component, EventEmitter, Injectable, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageSupportService } from './services/language-support.service';
import { LocalSettingsService } from './services/local-settings/local-settings.service';
import { EventTypes, OidcSecurityService, PublicEventsService } from 'angular-auth-oidc-client';
import { isNotBlank, isNotNullAndUndefined } from './utilities/utils';
import { AuthCookieManagerService } from './services/user-auth/auth-cookie-manager/auth-cookie-manager.service';
import { authCookieKey } from './utilities/constant';
import { UserAuthService } from './services/user-auth/user-auth.service';
import { filter } from 'rxjs/operators';
import { NavController, Platform } from '@ionic/angular';
import { NotificationServiceService } from './services/notification/notification-service.service';
import { ShareValuesService } from './services/sharedValues/share-values.service';
import { RefreshAlertService } from './services/refresh-alert.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  showLoader = false;
  selectedLanguage: string;
  public showSideMenu: boolean;
  constructor(private translate: TranslateService,
    public language: LanguageSupportService,
    public localSettingsSrv: LocalSettingsService,
    public notficationServ: NotificationServiceService,
    private loader: LoaderService,
    private oidcSecurityService: OidcSecurityService,
    public publicEventsService: PublicEventsService,
    public authCookieManagerService: AuthCookieManagerService,
    public userAuthService: UserAuthService,
    private nav: NavController,
    private platform: Platform,
    private refreshAlertService: RefreshAlertService,
    public sharedValue: ShareValuesService,
    private router: Router
  ) {
    window.addEventListener('beforeunload', this.showAlertOnRefresh);
    const langPref = localSettingsSrv.getLanguagePreference('ln_pref');
    translate.setDefaultLang(langPref ? langPref : 'en');
    this.loader.getLoaderStats().subscribe((res: boolean) => {
       this.showLoader = res;
    });
  }

  showAlertOnRefresh(event: BeforeUnloadEvent) {
    if (this.refreshAlertService.shouldShowAlert()) {
      event.preventDefault();
      event.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener('beforeunload', this.showAlertOnRefresh);
  }

  ngOnInit() {
    this.platform.ready().then(() => {
      //this.initializeAuth();
      // this.checkAuth();
    });
  }

  ngAfterViewInit(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const activePage = event.url;
        if (activePage == '/login' || activePage == '/') {
          this.showSideMenu = false;
        } else {
          this.showSideMenu = true;
        }
      });
  }


  checkAuth() {
    if (this.userAuthService.checkMsalAuth() && this.userAuthService.checkUserExisting()) {this.nav.navigateRoot('/dashboard');}
    else {this.nav.navigateRoot('/login');}
  }

  initializeAuth() {
    // publicEvent will be called when refrersh token or silent token will be refreshed automatically
    this.publicEventsService
      .registerForEvents()
      .pipe(filter((notification) => notification.type === EventTypes.SilentRenewStarted))
      .subscribe((value) => {
        const authInfo = this.userAuthService.getAuthenticationInfo();
        authInfo && this.authCookieManagerService.write(authCookieKey, authInfo, true);
      });

    //checking user authentcation
    this.oidcSecurityService.checkAuth().subscribe(({ isAuthenticated, userData, accessToken, idToken }) => {
      if (isAuthenticated && isNotBlank(accessToken) && isNotNullAndUndefined(accessToken)) {
        const authInfo = this.userAuthService.getAuthenticationInfo();
        authInfo && this.authCookieManagerService.write(authCookieKey, authInfo);
        this.nav.navigateRoot('/dashboard');
      } else {
        const cookieInfo = this.authCookieManagerService.read(authCookieKey);
        if (cookieInfo && isNotBlank(cookieInfo.cookie_expiry_time) && isNotNullAndUndefined(cookieInfo.cookie_expiry_time) &&
          isNotBlank(cookieInfo.access_token) && isNotNullAndUndefined(cookieInfo.access_token)) {this.userAuthService.login();}
        else {this.nav.navigateRoot('/login');}
      }
    },err=>{
    });
  }
}


@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private loaderSubject = new EventEmitter();
  /**
   * Gets loader stats
   *
   * @returns  Loader status
   */
  public getLoaderStats() {
    return this.loaderSubject.asObservable();
  }

  /**
   * Shows loader
   */
 public showLoader() {
    this.loaderSubject.emit(true);
  }

  /**
   * Hides loader
   */
  public hideLoader() {
    this.loaderSubject.emit(false);
  }
}
