import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ShareValuesService } from 'src/app/services/sharedValues/share-values.service';
import { NotificationServiceService } from 'src/app/services/notification/notification-service.service';
import { UserAuthService } from '../../services/user-auth/user-auth.service';


@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  notifications: any;
  notifyMsg: string;
  isShowNotification = false;
  firstNotification: any;
  finalNotification: any;
  results = [];
  notificationMsg: any;
  notificationCount: any;

  constructor( public sharedValue: ShareValuesService,private popCtrl: PopoverController, public sharedService: ShareValuesService, public notificationService: NotificationServiceService, private userAuthService: UserAuthService) {
    this.finalNotification = this.sharedValue.finalNotification;
  }
  ngOnInit() {

  }

  ionViewWillEnter(){
    document.addEventListener('notifications', (ev: CustomEvent) => {
      if (ev.detail) {
        this.sharedValue.finalNotification = ev.detail;
      }
    });
  }

  markAsRead(index: number){
    // this.finalNotification.splice(index,1);
    this.sharedValue.finalNotification.splice(index,1);
    localStorage.setItem('notificationvalue', JSON.stringify(this.sharedValue.finalNotification));
    // this.sharedValue.finalNotification=this.finalNotification;
  }

  async dismissClick() {
    this.clearInfo();
    await this.popCtrl.dismiss();
  }

  clearInfo() {
    this.finalNotification = '';
  }

}
