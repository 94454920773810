import { Injectable } from '@angular/core';
import { ShareValuesService } from '../sharedValues/share-values.service';
import { UserAuthService } from '../user-auth/user-auth.service';
import { BackendCallService } from '../backend-call/backend-call.service';
import { GraphService } from './graph.service';
@Injectable({
  providedIn: 'root'
})
export class GraphInitService {

  constructor(public backendService: BackendCallService, private graphService: GraphService, private sharedValue: ShareValuesService, public userAuthService: UserAuthService,) { }

  /**
   * Fnc check and create common communication site
   */
  async createCommonCommunicationSite() {
    const res: any = await this.backendService.getSiteDetails('BluePrint').toPromise();
    const groupsArray: any[] = res;
    let isExistsTeams: any = {};
    isExistsTeams = res.find(element => element.sitename === 'BluePrint');
    if (isExistsTeams == undefined) {
      const data = {
        'template@odata.bind': 'https://graph.microsoft.com/v1.0/teamsTemplates(\'standard\')',
        displayName: 'BluePrint',
        description: 'BluePrint'
      };
      await this.graphService.createTeam(data).toPromise();
    } else {
      const groupDetails = groupsArray.filter((element: any) => element.sitename === 'BluePrint');
      if (groupDetails) {
        this.graphService.bluePrintCommunicationGroupId = groupDetails[0].siteid;
        localStorage.setItem('bluePrintCommunicationGroupId',groupDetails[0].siteid);
        const data: any = await this.graphService.getBluePrintCommunicationSiteId().toPromise();
        this.graphService.bluePrintCommSiteId = data.parentReference.siteId;

      }
    }
  }


  /**
   * get share Point host details
   */
  async getSharePointHostDetails() {
    this.graphService.getUsers().subscribe((res) => {
      this.graphService.userDomain = res.mail.split('@')[1].split('.')[0];
      this.graphService.userDetails = res;
      this.graphService.sharePointHost = this.graphService.userDomain + this.graphService.serviceDomian;
      const data = {
        userDomain:res.mail.split('@')[1].split('.')[0],
        userDetails:res,
        sharePointHost:this.graphService.userDomain + this.graphService.serviceDomian
      };
      localStorage.setItem('sharePointHost',JSON.stringify(data));
    });
  }
}
