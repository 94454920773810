import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import {msalTenantUrl } from '../../utilities/constant';
import { environment } from '../../../environments/environment';

const isIE = window.navigator.userAgent.indexOf('MSIE') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
export const msalConfig: Configuration = {
    auth: {
        clientId: environment.msaAuthClientId, // This is the ONLY mandatory field that you need to supply.
        authority: msalTenantUrl, // Defaults to "https://login.microsoftonline.com/common"
        redirectUri: '/', // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback(logLevel: LogLevel, message: string) {
            },
            logLevel: LogLevel.Verbose,
            piiLoggingEnabled: false
        }
    }
};

export const protectedResources = {
    graphApi: {
        endpoint: 'https://graph.microsoft.com/v1.0/users',
        scopes: ['Files.ReadWrite.All','Group.ReadWrite.All','GroupMember.ReadWrite.All','Sites.ReadWrite.All','Tasks.ReadWrite','Team.Create','Team.ReadBasic.All','User.Read']
    },
    graphAPIUserInfo:{
        endpoint: 'https://graph.microsoft.com/v1.0/me',
        scopes: ['Files.ReadWrite.All','Group.ReadWrite.All','GroupMember.ReadWrite.All','Sites.ReadWrite.All','Tasks.ReadWrite','Team.Create','Team.ReadBasic.All','User.Read']
    },
    graphUploadFile:{
        endpoint:'https://graph.microsoft.com/v1.0/sites/',
        scopes: ['Files.ReadWrite.All','Group.ReadWrite.All','GroupMember.ReadWrite.All','Sites.ReadWrite.All','Tasks.ReadWrite','Team.Create','Team.ReadBasic.All','User.Read']
    },
    createFolder:{
        endpoint:'https://graph.microsoft.com/v1.0/sites/',
        scopes: ['Files.ReadWrite.All','Group.ReadWrite.All','GroupMember.ReadWrite.All','Sites.ReadWrite.All','Tasks.ReadWrite','Team.Create','Team.ReadBasic.All','User.Read']
    },
    getSiteId:{
        endpoint:'https://graph.microsoft.com/v1.0/groups',
        scopes: ['Files.ReadWrite.All','Group.ReadWrite.All','GroupMember.ReadWrite.All','Sites.ReadWrite.All','Tasks.ReadWrite','Team.Create','Team.ReadBasic.All','User.Read']
    },
    getFolderDetails:{
        endpoint:'https://graph.microsoft.com/v1.0/sites',
        scopes: ['Files.ReadWrite.All','Group.ReadWrite.All','GroupMember.ReadWrite.All','Sites.ReadWrite.All','Tasks.ReadWrite','Team.Create','Team.ReadBasic.All','User.Read']
    },
    getGroups:{
        endpoint:'https://graph.microsoft.com/v1.0/groups',
        scopes: ['Files.ReadWrite.All','Group.ReadWrite.All','GroupMember.ReadWrite.All','Sites.ReadWrite.All','Tasks.ReadWrite','Team.Create','Team.ReadBasic.All','User.Read']
    },
    graphApiSharePointInfo:{
        endpoint: 'https://graph.microsoft.com/v1.0/sites',
        scopes: ['Files.ReadWrite.All','Group.ReadWrite.All','GroupMember.ReadWrite.All','Sites.ReadWrite.All','Tasks.ReadWrite','Team.Create','Team.ReadBasic.All','User.Read']
    },
    createTeam:{
        endpoint: 'https://graph.microsoft.com/v1.0/teams',
        scopes: ['Files.ReadWrite.All','Group.ReadWrite.All','GroupMember.ReadWrite.All','Sites.ReadWrite.All','Tasks.ReadWrite','Team.Create','Team.ReadBasic.All','User.Read']
    },
    getListItems:{
        endpoint: 'https://graph.microsoft.com/v1.0/sites',
        scopes: ['Files.ReadWrite.All','Group.ReadWrite.All','GroupMember.ReadWrite.All','Sites.ReadWrite.All','Tasks.ReadWrite','Team.Create','Team.ReadBasic.All','User.Read']
    },
    getOfferQuotTemplates:{
        endpoint: 'https://graph.microsoft.com/v1.0/sites',
        scopes: ['Files.ReadWrite.All','Group.ReadWrite.All','GroupMember.ReadWrite.All','Sites.ReadWrite.All','Tasks.ReadWrite','Team.Create','Team.ReadBasic.All','User.Read']
    },
    getOfferDrives:{
        endpoint: 'https://graph.microsoft.com/v1.0/drives',
        scopes: ['Files.ReadWrite.All','Group.ReadWrite.All','GroupMember.ReadWrite.All','Sites.ReadWrite.All','Tasks.ReadWrite','Team.Create','Team.ReadBasic.All','User.Read']
    }
    , tokenService: {
        endpoint: environment.tokenEndpoint,
        scopes: environment.tokenScopes,
    }
};

export const loginRequest = {
    scopes: [...protectedResources.tokenService.scopes] // If you would like the admin-user to explicitly consent via "Admin" page, instead of being prompted for admin consent during initial login, remove this scope.
};
