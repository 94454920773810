<div class="vertical-stepper" [ngClass]="{ 'vertical-stepper': isVertical, 'horizontal-stepper': !isVertical}">
  <ng-container *ngFor="let step of steps; let i = index">
    <div class="step" [ngClass]="{ 'active': step.isActive}" (click)="toggleStep(i, step)">
      <div class="step-number">
        <span>{{ i + 1 }}</span>
      </div>
      <div class="step-content" tooltip="{{step.title.length > 16 ? step.title : null}}" placement="top">
        {{step.title}}
      </div>
    </div>
    <div class="step-divider" [ngClass]="{ 'active': step.isActive }" *ngIf="i !== steps.length - 1"></div>
  </ng-container>
</div>