import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BackendCallService } from 'src/app/services/backend-call/backend-call.service';
import { ShareValuesService } from 'src/app/services/sharedValues/share-values.service';
import { ToastService } from 'src/app/services/toast.service';
import { AppConstants } from 'src/app/utilities/constant';

@Component({
  selector: 'app-search-with-multi-select-checkbox',
  templateUrl: './search-with-multi-select-checkbox.component.html',
  styleUrls: ['./search-with-multi-select-checkbox.component.scss'],
})
export class SearchWithMultiSelectCheckboxComponent implements OnInit {
  @Input() type: any;
  usersList = [];
  filteredUsers = [];
  selectedUsers: any[] = [];
  constructor(public modalController: ModalController,
    public sharedValue: ShareValuesService,
    private backendCallService: BackendCallService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.selectedUsers = [];
  }

  /**
   * Gets users
   *
   * @param event event
   */
  public getUsers(event) {
    const searchTerm = event.detail.value.toLowerCase();
    this.filteredUsers = [];
    if(searchTerm.length >=3)
      this.assignNewUser(searchTerm);
  }
  /**
   * Determines whether checkbox change on
   *
   * @param user user
   */
  onCheckboxChange(user: any) {
    if (user.selected) {
      this.selectedUsers.push(user);
    } else {
      this.selectedUsers = this.selectedUsers.filter(selectedUser => selectedUser.Id !== user.Id);
    }
  }

  /**
   * Assigns new user
   *
   * @param searchValue searchValue
   */
  assignNewUser(searchValue) {
    this.usersList = [];
    const payload = searchValue;
    this.backendCallService.getUser(payload).subscribe(
      (data: any) => {
        if (data.value && data.value.length > 0) {
          this.usersList = data.value;
          this.usersList.forEach((element) => {
            this.filteredUsers.push({
              Display_Name: element.name,
              Id: element.id,
              UserEmail: element.email,
            });
          });
        } else {
          this.toastService.presentToast('No Results Found', 4000, AppConstants.ERROR_SNACKBAR, 'top');
        }
      }, err => {
         this.toastService.presentToast('Something went wrong', 4000, AppConstants.ERROR_SNACKBAR, 'top');
      }
    );
  }

  /**
   * Closes search with multi select checkbox component
   */
  public close() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  /**
   * Assigns project
   */
  public assignProject() {
    this.modalController.dismiss({
      selectedUsers: this.selectedUsers,
      modeType: this.type
    });
  }

  removeAddUser(data) {
    const selectedData = this.selectedUsers.find((item) => item.Id === data.Id);
      if(selectedData){
        this.selectedUsers.forEach((item, index, object) => {
          if (item.Id === data.Id) {
            object.splice(index, 1);
          }
        });
        if (selectedData && selectedData.selected) {selectedData.selected = false;}
      }
  }
}
