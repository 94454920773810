<ion-content class="dashboard-customize">
  <div class="heading">
    <ion-label class="title">{{'Add Custom Roadmaps'|translate}}</ion-label>
    <img tooltip="{{'Close' | translate }}" placement="bottom" src="/assets/icon/cancel.svg" class="close-icon" alt="" (click)="close()">
  </div>
  <form class="form-scroll scrollVertical" #formEle #addCustomRoadmapForm="ngForm" autocomplete="off" novalidate>
    <div class="inp-container" *ngFor="let machine of newMachinesList;let i = index" [ngModelGroup]="''+i">
      <ion-label style="font-weight: bold;"> {{ 'Roadmap Name' | translate }} *</ion-label>
      <ion-item lines=none>
        <ion-input  placeholder="{{ 'Enter Roadmap Name' | translate }}" name="customRoadmap" required [(ngModel)]="machine.customRoadmap"
          #customRoadmap="ngModel">
        </ion-input>
        <ion-icon *ngIf="newMachinesList.length>1" (click)="newMachinesList.splice(i,1)" name="trash-outline">
        </ion-icon>
      </ion-item>
      <ion-text style="font-size: x-small; font-weight: bold;" *ngIf="customRoadmap.invalid && customRoadmap.touched"
        color="danger">
        Required
      </ion-text>
    </div>
  </form>
  <ion-row class="row-margin-top">
    <ion-col class="add-button-col">
      <ion-button class="app-btn" shape="round" fill="outline" color="primary" (click)="addNew(formEle)">
        <ion-icon slot=start  name='add-circle-outline'></ion-icon>
        {{'Add'|translate}}
      </ion-button>
    </ion-col>
    <ion-col class="save-button-col">
      <ion-button class="app-btn" shape="round" fill="outline" color="primary" (click)="submit(addCustomRoadmapForm)">
        {{'Save'|translate}}
      </ion-button>
    </ion-col>
  </ion-row>
</ion-content>