import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ModalController, PopoverController } from '@ionic/angular';
import { NotificationComponent } from '../notification/notification.component';
import { StatusPopoverComponent } from '../status-popover/status-popover.component';
import { BackendCallService } from 'src/app/services/backend-call/backend-call.service';
import { ShareValuesService } from '../../services/sharedValues/share-values.service';
import AOS from 'aos';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @ViewChild('search') search: any;
  isItemAvailable = false;
  toggled = false;
  isinputitems = false;
  dummyData = [];
  suggestions: any[] = [];
  searchedProject: any;
  searchValue: any;
  itemsToLoad = 10; // Number of items to load
  isLoading = true;
  logoSrc = 'assets/images/blue-print-Logo.svg';
  constructor(
    private router: Router,
    private popController: PopoverController,
    public sharedValue: ShareValuesService,
    public backendService: BackendCallService,
  ) {
  }

  ngOnInit() {
    AOS.init(); // Initialize AOS in the component
    this.loadItems();
    // // Ensure the image is rendered as early as possible
    // const imgElement = document.createElement('img');
    // imgElement.src = this.logoSrc;
    // imgElement.classList.add('logo');
    // imgElement.alt = 'logo';

    // const headerElement = document.querySelector('div.header');
    // if (headerElement) {
    //   headerElement.appendChild(imgElement);
    // }
  }

  loadItems() {
    // Simulate fetching items from an API
    setTimeout(() => {
      const newItems = Array.from({ length: this.itemsToLoad }, (_, i) => `Item ${i + 1}`);
      this.suggestions = newItems;
      this.isLoading = false;
    }, 1000);
  }


  outsideClick() {
    this.suggestions = [];
    this.isItemAvailable = false;
  }

  onSearch(event) {
    const searchTerm = event.target.value;
    if(event.target.value == '') {
      this.suggestions = [];
      this.isItemAvailable = false;
    } else if (searchTerm.length >= 3) {
      this.isItemAvailable = true;
      const payload = {
        search: searchTerm,
        top: 50,
        suggesterName: 'qa-suggester'
      };
      this.backendService.getAutocompleteSuggestions(payload).subscribe(
        (suggestions: any) => {
          this.suggestions = [];
          suggestions.value.forEach(element => {
            const each = {
              name: element['@search.text'],
              projectID: element.project_Id,
            };
            this.suggestions.push(each);
          });

        }
      );
    }
  }

  serachTerm(val) {
    this.searchValue = val;
    this.sharedValue.globalSearchInput = val;
    const alpbheticWithSpace = /[a-zA-Z]+([\s][a-zA-Z]+)*/;
    let fullSearch;
    const alpbhetic = /^[A-Za-z]+$/;
    if (this.searchValue.match(alpbhetic)) {
      this.searchValue = val + '*';
    } else if (this.searchValue.toString().includes('-')) {
      const alpbheticNumericWithSpl = /^[ A-Za-z0-9_@./#&+-]*$/;
      const subStirng = this.searchValue.toString().slice(this.searchValue.toString().indexOf('-') + 1);
      if (this.searchValue.match(alpbheticNumericWithSpl) && subStirng.length < 5) {
        fullSearch = false;
        this.searchValue = '/' + '.' + '*' + val.trim() + '.' + '*' + '/';
      } else {
        fullSearch = true;
        this.searchValue = '"' + val.toString() + '"';
      }
    }
    else if (this.searchValue.match(alpbheticWithSpace)) {
      fullSearch = true;
      this.searchValue = '"' + val.toString() + '"';
    } else {
      fullSearch = true;
      this.searchValue = '/' + '.' + '*' + val.trim() + '.' + '*' + '/';
    }

    // if the value is an empty string don't filter the items
    if (val && val.trim() !== '') {
      this.isItemAvailable = true;
      this.dummyData = this.dummyData.filter((item) => item.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
      this.viewAllResults(fullSearch);
    } else {
      this.isItemAvailable = false;
      // this.isinputitems = false
    }
  }

  viewAllResults(fullSearch: boolean) {
    if (this.searchValue) {
      this.isItemAvailable = false;
      this.isinputitems = true;
      this.sharedValue.intiazureSearch(this.searchValue, fullSearch);
      const navigationExtras: NavigationExtras = {
        state: {
          globalSearchInput: this.searchValue
        },
      };
      this.router.navigate(['view-all-search-results'], navigationExtras);
    }
  }

  async showNotification() {
    if (this.sharedValue.finalNotification.length > 0) {
      const popover = await this.popController.create({
        component: NotificationComponent,
        cssClass: 'my-custom-class',
        // event: ev,
        translucent: true,
      });
      await popover.present();
    }
  }

  async openLanSettings(ev: any, type) {
    const props = { type };
    const popover = await this.popController.create({
      component: StatusPopoverComponent,
      cssClass: 'profile-popover',
      event: ev,
      translucent: true,
      componentProps: props,
      showBackdrop: false,
      // mode: 'ios',
    });
    await popover.present();
    await popover.onDidDismiss().then((result) => {
    });
  }

  /**
   * Closes popover
   */
  closePopOver() {
    this.popController.dismiss();
  }

}
