<ion-content>
  <div class="widget-container">
    <ion-grid>
      <div class="header-section">
        <ion-label>
          {{ 'Add User' | translate}}
        </ion-label>
        <img class="close-icon" alt="" tooltip="{{'Close' | translate }}" placement="bottom" (click)="close()" src="assets/icon/cancel.svg">
      </div>
      <ion-searchbar #search
        debounce="1000" mode='ios' placeholder="Search users to be added" (ionChange)="getUsers($event)"
        [showClearButton]="'always'">
      </ion-searchbar>
      <ion-list class="list-view scrollVertical list-view-wid scroll-searched-container">
        <ion-item *ngFor="let user of filteredUsers" lines='none' style="background: none;">
          <ion-checkbox [(ngModel)]="user.selected" (ionChange)="onCheckboxChange(user)"></ion-checkbox>
          <ion-label>{{user.Display_Name }}</ion-label>
        </ion-item>
      </ion-list>
      <div class="select-user-main scrollVertical" [ngClass]="type==='user_console_add_user' || type==='user_console_group_mang_add_group'? 'selected-user-content' : ''">
        <span *ngFor="let selectedUser of selectedUsers">
          <ion-button class="select-user"  (click)='removeAddUser(selectedUser)' shape="round" fill="clear" size="small">
            {{selectedUser.Display_Name}}&nbsp;&nbsp;<ion-icon  slot="end" name='close-outline'>
            </ion-icon>
          </ion-button>
        </span>
      </div>
    </ion-grid>
  </div>
  <ion-button (click)='assignProject()' class="app-btn submit-btn" [disabled]="selectedUsers.length==0" shape="round" fill="outline" color="primary">
    {{'Submit'|translate}}
  </ion-button>
</ion-content>