import { CanActivate } from '@angular/router';
import { UserAuthService } from '../user-auth/user-auth.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GraphInitService } from '../graph/graph-init.service';
import { GraphService } from '../graph/graph.service';

@Injectable({ providedIn: 'root' })
export class AuthRouteGuard implements CanActivate {
    constructor(private graphService: GraphService, private userAuthService: UserAuthService, private router: Router, private graphInitService: GraphInitService) { }
    async canActivate() {
        if (this.userAuthService.checkMsalAuth() && this.userAuthService.checkUserExisting()) {
            if (localStorage.getItem('bluePrintCommunicationGroupId') != null) {
                this.graphService.bluePrintCommunicationGroupId = localStorage.getItem('bluePrintCommunicationGroupId');
                const data: any = await this.graphService.getBluePrintCommunicationSiteId().toPromise();
                this.graphService.bluePrintCommSiteId = data.parentReference.siteId;
            } else {
                await this.graphInitService.createCommonCommunicationSite();
            }
            const userDetails = JSON.parse(localStorage.getItem('sharePointHost'));
            if (userDetails !== null) {
                this.graphService.userDomain = userDetails.userDomain;
                this.graphService.userDetails = userDetails.userDetails;
                this.graphService.sharePointHost = userDetails.sharePointHost;
            } else {
                await this.graphInitService.getSharePointHostDetails();
            }
            return true;
        } else {
            return this.router.createUrlTree(['login']);
        }
    }
}
