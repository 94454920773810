import { Component, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'button-cell',
  templateUrl: './buttons-renderer.component.html',
  styleUrls: ['./button-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ButtonsRendererComponent implements ICellRendererAngularComp {
  status = '';
  params: ICellRendererParams;
  backgroundColor: string;
  constructor() {

  }

  initilize(params: ICellRendererParams) {
    if(params.data)
    {
      this.backgroundColor = params.data.backgroundColor;
      this.status = params.data.status;
    }
  }


  // called on init
  agInit(params: any): void {
    this.initilize(params);
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.initilize(params);
    return true;
  }

}
