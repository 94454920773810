import { HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { IonInfiniteScroll, IonList, LoadingController, ModalController } from '@ionic/angular';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { debounceTime, switchMap } from 'rxjs/operators';
import { BackendCallService } from 'src/app/services/backend-call/backend-call.service';
import { ShareValuesService } from 'src/app/services/sharedValues/share-values.service';
import { ToastService } from 'src/app/services/toast.service';
import { AppConstants } from 'src/app/utilities/constant';
import { isNotEmptyArray } from 'src/app/utilities/utils';

@Component({
  selector: 'app-add-predefined-roadmaps',
  templateUrl: './add-predefined-roadmaps.component.html',
  styleUrls: ['./add-predefined-roadmaps.component.scss'],
})
export class AddPredefinedRoadmapsComponent implements OnInit {
  @ViewChild(IonInfiniteScroll, { static: false }) infiniteScroll: IonInfiniteScroll;
  @ViewChild(InfiniteScrollDirective, { static: false }) infScrollerEle: InfiniteScrollDirective;
  @Input() predefinedRoadmaps: any[];
  @Input() configurelevel: string;
  private onScrolled = new EventEmitter();
  isAnyValueChanged = false;

  predefinedRoadmapsTest: any[] = [];
  predefinedRoadmapLists = [];
  searchTerm = '';
  recentSearches: any = [];

  url: string;
  count = 30;
  increasedCount = 0;
  limitReached = false;

  constructor(public sharedValue: ShareValuesService, public loadingController: LoadingController, public backendService: BackendCallService, 
    public modalController: ModalController,private toastService: ToastService) {
   }

  ngOnInit() {
      this.subscribeScroll();
      this.onScrolled.emit();
  }

  async presentLoading(msg) {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-loading',
      message: msg,
      spinner: 'bubbles'
    });
    await loading.present();
    return loading;
  }


  subscribeScroll() {
    this.onScrolled.pipe(debounceTime(100), switchMap((res) => {
      const payload =
      {
        count: this.count,
        increasedCount: this.increasedCount,
        search: this.searchTerm,
        isTemplate:true
      };
      return this.backendService.getRoadmapListWithIndex(payload);
    })).subscribe(
      (data: any) => {
        data.forEach(obj => {
          obj.isChecked = false;
          obj.disabled = false;
        });
        if(this.configurelevel=='service level'){
          for (const selectedRoadmap of this.sharedValue.selectedRoadmapsList) {
            for (const eachRoadmap of data) {
              if (selectedRoadmap.roadmapMasterId== eachRoadmap.id) {
                eachRoadmap.disabled = true;
                eachRoadmap.isChecked = true;
              }
            }
          }
        }else if(this.configurelevel=='machine level'){
          for (const selectedRoadmap of this.sharedValue.selectedServiceRoadmapsList) {
            for (const eachRoadmap of data) {
              if (selectedRoadmap.roadmapMasterId== eachRoadmap.id) {
                eachRoadmap.disabled = true;
                eachRoadmap.isChecked = true;
              }
            }
          }
        }

        // this.limitReached = data.length !== this.count;
        if (this.searchTerm !== '') {this.increasedCount = this.increasedCount + this.count;}
        if (this.predefinedRoadmapLists.length && this.predefinedRoadmapLists[0].roadmapName.startsWith(this.searchTerm)) {
          if (data.length !== 0) {
            this.predefinedRoadmapLists = this.predefinedRoadmapLists.concat(...data);
          } else {
            this.increasedCount = 0;
            this.limitReached = data.length !== this.count;
            this.toastService.presentToast( 'No Records Available', 4000, AppConstants.ERROR_SNACKBAR, 'top');
          }
        } else {
          this.increasedCount = 0;
          if (data.length !== 0) {
            this.predefinedRoadmapLists = this.predefinedRoadmapLists.concat(...data);
            this.predefinedRoadmapLists = [];
          } else {
            this.increasedCount = 0;
            this.limitReached = data.length !== this.count;
            this.toastService.presentToast( 'No Records Available', 4000, AppConstants.ERROR_SNACKBAR, 'top');
          }
        }
        console.log('Limit Reached: ', this.limitReached);
        this.increasedCount = this.increasedCount + this.count;
        this.predefinedRoadmapLists = this.predefinedRoadmapLists.concat(...data);
        setTimeout(() => {
        }, 500);
      },err=>{
        this.toastService.presentToast( 'No Results Found', 4000, AppConstants.ERROR_SNACKBAR, 'top');
      }
    );

  }

  close() {
    this.modalController.dismiss({});
  }

  changeRoadmapCheckedValue(roadmap) {
    this.isAnyValueChanged = this.predefinedRoadmapLists.some(_pr => _pr.isChecked);
  }

  saveRoadmapInfo() {
    const roadmapLists = this.predefinedRoadmapLists.filter(_pr => _pr.isChecked&& !_pr.disabled);
    this.modalController.dismiss({
      selectedPredefinedRoadmapLists: roadmapLists
    });
  }

  getItems(ev: any) {
    const val = ev.target.value;
    if (this.onScrolled) {
      this.onScrolled.next(true);
      this.searchTerm = val;
      this.limitReached = false;
      this.increasedCount = 0;
      this.predefinedRoadmapLists = [];
    }

  }

  doInfinite(event) {
    // this.getPredefinedRoadMap(false, event);
  }
  onScroll() {
    if (!this.limitReached) {
      this.onScrolled.emit(Date.now());
    }
  }
  onScrollDown() {
    console.log('scrolled down!!');

  }

  onScrollUp() {
    console.log('scrolled up!!');
  }

}
