<div class="header">
  <img alt="logo" src="{{logoSrc}}" class="logo">
  <div class="right-column" (appOutsideClick)="outsideClick()">
    <app-searchbar class="header-searchbar" [showSearchBar]=false placeholder="{{ 'Search' | translate}}"
      (ionChange)="onSearch($event)" ngDefaultControl>
    </app-searchbar>
    <div *ngIf="isItemAvailable && suggestions.length > 0">
      <div class="list-view scrollVertical">
        <ion-item *ngFor="let item of suggestions" (click)="serachTerm(item.name)">
          <ion-spinner *ngIf="isLoading" class="loading-spinner"></ion-spinner>
          <ion-label>{{item.name}}</ion-label>
        </ion-item>
      </div>
    </div>
    <div>
      <ion-icon class="notification-container" tooltip="{{'Notifications' | translate }}" placement="bottom"
        name="notifications-outline" class="notification-icon"
        (click)="this.sharedValue?.finalNotification.length?showNotification():''">
      </ion-icon>
      <ion-badge *ngIf="this.sharedValue?.finalNotification.length>0"
        class="notification-badge">{{this.sharedValue?.finalNotification.length}}</ion-badge>
    </div>
    <div>
      <img src="assets\images\img_avatar.png" alt="avatar" tooltip="{{'User profile' | translate}}" placement="bottom" class="profile"
      (click)="openLanSettings($event,'openChangeLan')">
    </div>
  </div>
</div>