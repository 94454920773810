<div>
  <ion-item lines="none" style=" --background: rgba(255, 255, 255, 0.9);">
    <ion-label class="ion-text-left" style="margin-left: 8px;"> {{ 'Filters' | translate}} </ion-label>
    <ion-label class="ion-text-right">
      <div class="close-icon" (click)="closePopOver()" id="alert_modal_cancel_button">
        <img src="../../../assets/icon/cancel.svg" alt="">
      </div>
    </ion-label>
  </ion-item>
  <div class="content-div" *ngIf="!filterFor && each_service">
    <div class="inp-container">
      <ion-label> {{ 'Machine Type' | translate}}</ion-label>
      <div>
        <!-- <ng-select dropdownPosition="bottom" dropdownPosition="bottom"
        name="machineType" [clearable]="false" required
        bindLabel="machine_type"
        bindValue="machine_type"
        [items]="this.each_service.machines"
        [(ngModel)]="data.machineType" #machineType="ngModel"
        placeholder="Machine Type">
    </ng-select> -->
        <ng-select bindLabel="machine_type" placeholder="Machine Type" [searchable]="false" [clearable]="true"
          name="machineType" #machineType="ngModel" [(ngModel)]="data.machineType">
          <ng-option [value]="item.machine_type"
            *ngFor="let item of this.each_service.machines | unique : 'machine_type'">
            {{item.machine_type}}
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div class="inp-container">
      <ion-label> {{ 'Machine Manufacturer' | translate}}</ion-label>
      <div>
        <!-- <ng-select dropdownPosition="bottom"
        name="machineManufacturer" [clearable]="false" required   
        bindLabel="manufacturer"
        bindValue="manufacturer"
        [items]="this.each_service.machines"
        [(ngModel)]="data.machineManufacturer" #machineManufacturer="ngModel"
        placeholder="Machine Manufacturer">
    </ng-select> -->
        <ng-select bindLabel="machineManufacturer" placeholder="Machine Manufacturer" name="machineManufacturer"
          [searchable]="false" [clearable]="true" #machineManufacturer="ngModel" [(ngModel)]="data.machineManufacturer">
          <ng-option [value]="item.manufacturer"
            *ngFor="let item of this.each_service.machines | unique : 'manufacturer'">
            {{item.manufacturer}}
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div class="bottom-div">
      <ion-button size="small" class="ion-text-right apply-btn" shape="round" (click)="applyFilter()"> {{ 'Apply' |
        translate}}
      </ion-button>
      <ion-button size="small" class="ion-item-left" shape="round" fill="clear" (click)="closePopOver()"> {{ 'Clear' |
        translate}}
      </ion-button>
    </div>
  </div>

  <div class="content-div" *ngIf="filterFor === 'projectDetailsServiceSectionMachineDetails'">
    <div class="inp-container">
      <ion-label> {{ 'Bucket List' | translate}}</ion-label>
      <div>
        <ng-select bindLabel="bucketList" placeholder="My Buckets" name="bucketList" [searchable]="false"
          [clearable]="true" #bucketList="ngModel" [(ngModel)]="data.bucketList">
          <ng-option [value]="item.bucketType"
            *ngFor="let item of this.each_service.bucketTypes | unique : 'bucketType'">
            {{item.bucketType}}
          </ng-option>
        </ng-select>
      </div>
    </div>
    <!-- <div class="inp-container">
      <ion-label> {{ 'Assignee' | translate}}</ion-label>
      <div>
        <ng-select bindLabel="machineAssignee" placeholder="All" name="machineAssignee" [searchable]="false"
          [clearable]="true" #machineAssignee="ngModel" [(ngModel)]="data.machineAssignee">
          <ng-option [value]="item.bucketType" *ngFor="let item of this.each_service.assignee">
            {{item.bucketType}}
          </ng-option>
        </ng-select>
      </div>
    </div> -->
    <div class="inp-container">
      <ion-label> {{ 'Asset Number' | translate}}</ion-label>
      <div>
        <ng-select bindLabel="assetNumber" placeholder="All" name="assetNumber" [searchable]="false" [clearable]="true"
          #assetNumber="ngModel" [(ngModel)]="data.assetNumber">
          <ng-option [value]="item.bucketType" *ngFor="let item of this.each_service.assetNumber">
            {{item.bucketType}}
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div class="inp-container">
      <ion-label> {{ 'Machine Manufacturer' | translate}}</ion-label>
      <div>
        <ng-select bindLabel="machineManufacturer" placeholder="All" name="machineManufacturer" [searchable]="false"
          [clearable]="true" #machineManufacturer="ngModel" [(ngModel)]="data.machineManufacturer">
          <ng-option [value]="item.bucketType" *ngFor="let item of this.each_service.machineManufacturer">
            {{item.bucketType}}
          </ng-option>
        </ng-select>
      </div>
    </div>

    <div class="inp-container">
      <ion-label> {{ 'Machine Type' | translate}}</ion-label>
      <div>
        <ng-select bindLabel="machineType" placeholder="All" name="machineType" [searchable]="false" [clearable]="true"
          #machineType="ngModel" [(ngModel)]="data.machineType">
          <ng-option [value]="item.bucketType" *ngFor="let item of this.each_service.machineType">
            {{item.bucketType}}
          </ng-option>
        </ng-select>
      </div>
    </div>

    <div class="inp-container">
      <ion-label> {{ 'Serial Number' | translate}}</ion-label>
      <div>
        <ng-select bindLabel="serialNumber" placeholder="All" name="serialNumber" [searchable]="false"
          [clearable]="true" #serialNumber="ngModel" [(ngModel)]="data.serialNumber">
          <ng-option [value]="item.bucketType" *ngFor="let item of this.each_service.serialNumber">
            {{item.bucketType}}
          </ng-option>
        </ng-select>
      </div>
    </div>

    <div class="bottom-div bottom-button">
      <ion-button size="small" class="ion-text-right apply-btn" shape="round" (click)="applyFilter()"> {{ 'Apply' |
        translate}}
      </ion-button>
      <ion-button size="small" class="ion-item-left" shape="round" fill="clear" (click)="closePopOver()"> {{ 'Clear All'
        | translate}}
      </ion-button>
    </div>
  </div>
</div>