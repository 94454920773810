import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { LocalSettingsService } from '../local-settings/local-settings.service';
import * as moment from 'moment';
import { BackendCallService } from '../backend-call/backend-call.service';
import { User } from '../user';
import { isNotEmptyArray } from 'src/app/utilities/utils';


@Injectable({
  providedIn: 'root'
})
export class UserAuthService {
  public authenticated: boolean;
  constructor(private oidcSecurityService: OidcSecurityService, private authService: MsalService, public localSettingsSrv: LocalSettingsService,
    public backendService: BackendCallService) { }

  // getUserInfo() {
  //   let userInfo = {
  //     userId: '',
  //     email: '',
  //     auth_time: '',
  //     roles: []
  //   }
  //   const user = this.oidcSecurityService.getUserData();
  //   if (user) {
  //     userInfo.email = user.sub;
  //     userInfo.roles = user.role
  //     userInfo.auth_time = user.auth_time
  //     userInfo.userId = user.id
  //   }
  //   return userInfo;
  // }


  getUserList() {
    const user = {
      name: '',
      userId: '',
      email: '',
      jobTitle:'',
      isGlobalAdmin: false,
      profileData: {},
      permissions: [],
      roles: []
    };
    const userInfo = this.getUserInfoFromLocal();
    if (userInfo) {
      user.name = userInfo.name;
      user.userId = userInfo.userId;
      user.email = userInfo.email;
      user.jobTitle=userInfo.jobTitle;
      user.profileData = userInfo.profileData;
      user.permissions = userInfo.permissions || userInfo.roleAssignmentInfo;
      user.roles = userInfo?.roles;
      if (userInfo.isGlobalAdmin) {user.isGlobalAdmin = userInfo.isGlobalAdmin;}
    }
    return user;
  }

  isGlobalAdmin() {
    let isGlobalAdminRole = false;
    const user = this.getUserList();
    if (user && user.isGlobalAdmin) {isGlobalAdminRole = user.isGlobalAdmin;}
    return isGlobalAdminRole;
  }

  getAccessToken() {
    let access_Token;
    //return this.oidcSecurityService.getAccessToken();
    const user = this.getUserInfoFromLocal();
    if (user && user.accessToken) {access_Token = user.accessToken;}
    return access_Token;
  }

  getMsalAccessToken() {
    let access_Token = '';
    const user = this.getUserInfoFromLocal();
    if (user && user.msal_accessToken) {access_Token = user.msal_accessToken;}
    return access_Token;
  }

  getAuthenticationInfo() {
    return this.oidcSecurityService.getAuthenticationResult();
  }

  login() {
    this.oidcSecurityService.authorize();
  }

  logOut() {
    //this.oidcSecurityService.logoff();
    this.authService.logout();
    this.localSettingsSrv.clearAllLocalStorageInfo();
  }

  checkMsalAuth() {
    return this.authService.instance.getAllAccounts().length > 0;
  }

  checkUserExisting() {
    let isUserExisting = false;
    const user = this.getUserInfoFromLocal();
    if (user) {
      if (user.accessToken && moment().isBefore(user.msal_expiry)) {isUserExisting = true;}
      //else if (moment().isAfter(user.expiry)) this.logOut();
    }
    return isUserExisting;
  }

  storeUserInfo(userAuthInfo, msalAuthInfo) {
    const body = {
      msal_accessToken: msalAuthInfo.msal_accesstoken,
      msal_tenantId: msalAuthInfo.msal_account.tenantId,
      msal_userName: msalAuthInfo.msal_account.username,
      msal_expiry: msalAuthInfo.msal_expiry,
      accessToken: userAuthInfo.accssToken,
      userId: userAuthInfo.userInfo.Id,
      name: userAuthInfo.userInfo.Display_Name,
      email: userAuthInfo.userInfo.Email_Address,
      roles: userAuthInfo?.userInfo?.Roles,
      permissions: userAuthInfo.userInfo.Permissions,
      jobTitle:userAuthInfo.userInfo.jobTitle,
      profileData: userAuthInfo.userInfo.Profile_Data,
      isGlobalAdmin: userAuthInfo.userInfo.IsGlobalAdmin || false,
      tid: userAuthInfo.userInfo.TID
    };
    this.localSettingsSrv.storeUserInfo('userLists', body);
  }

  getUserInfoFromLocal() {
    return this.localSettingsSrv.getuserInfo('userLists');
  }

  getToken() {
    return this.backendService.getToken();
  }
}
