<div class="sidebar" [ngClass]="{'collapsed': isCollapsed}">
  <div class="sidebar-container">
    <ion-row class="row-container" *ngFor="let menu of menuList let i = index">
      <ion-col col-12 routerLink="{{menu.routerLink}}" routerLinkActive="selected-list"
      (click)="clearUp()">
        <div [ngClass]="{ 'icon': isCollapsed, 'icon-expanded': !isCollapsed }">
          <img [src]="menu.menuItemUrl" 
            class="image-icon" 
            [alt]="menu.menuItemDescription | translate"
            [tooltip]="isCollapsed ? (menu.menuItemDescription | translate) : null" 
            placement="right"
            alt="menu-icon">
          <ion-label [hidden]="showMenuText" class="sub-title">
            {{menu.menuItemDescription | translate}}
          </ion-label>
        </div>
      </ion-col>
    </ion-row>
  </div>
  <div class="chevron-block top-chevron" (click)="toggleMenu()">
    <ion-icon [name]="isCollapsed ? 'chevron-forward-outline' : 'chevron-back-outline'" class="chevron-icon"></ion-icon>
  </div>
</div>