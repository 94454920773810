export const projectStatusTrackerColumnSelectorData = [
    { title: 'Project ID', val: 'pid', disabled: true, isChecked: true },
    // { 'title': 'Number of machine', 'val': 'nom', 'isChecked': true },
    { title: 'Customer ID', val: 'custId', disabled: true, isChecked: true },
    { title: 'Customer Name', val: 'cname', disabled: true, isChecked: true },
    { title: 'Status', val: 'status', disabled: true, isChecked: true },
    { title: 'Project Name', val: 'pname', isChecked: true },
    // { 'title': 'Time(in Hours) Actual vs Planned', 'val': 'tih', 'isChecked': true },
    { title: 'Contact Person', val: 'contactName', isChecked: true },
    { title: 'Customer Address', val: 'custAddress', isChecked: true },
    // { 'title': 'Account Name', 'val': 'aname', 'isChecked': true },
    // { 'title': 'Cost(in $) Actual vs Planned', 'val': 'cid', 'isChecked': true },
    { title: 'Project Manager', val: 'pmname', isChecked: true },
    { title: 'Progress %', val: 'prg', isChecked: true },
    { title: 'Hours Progress %', val: 'hrPrg', isChecked: true },
    // { 'title': 'Project Phase', 'val': 'pp', 'disabled': true, 'isChecked': true },
    // { 'title': 'Revenue(in $) Actual vs Planned', 'val': 'rid', 'isChecked': true },
    // { 'title': 'Start Date', 'val': 'std', 'isChecked': true },
    { title: 'End Date', val: 'etd', isChecked: true },
];

export const taskManagerColumnSelectorData = [
    { title: 'Task ID', val: 'tid', disabled: true, isChecked: true },
    { title: 'Task Title', val: 'tTitle', disabled: true, isChecked: true },
    { title: 'Status', val: 'tStatus', disabled: true, isChecked: true },
    { title: 'Task Label', val: 'tLabel', isChecked: true },
    { title: 'Created Date', val: 'tCreatedDate', isChecked: true },
    { title: 'Priority', val: 'tPriority', isChecked: true },
    { title: 'Description', val: 'tDescrp', isChecked: true },
    { title: 'Assigned To', val: 'tAssignedTo', isChecked: true },
    { title: 'Due Date', val: 'tDueDate', isChecked: true }
];

export const jobOrderManagementColumnSelectorData = [
    { title: 'Job Order Id', val: 'joId', disabled: true, isChecked: true },
    { title: 'Service ID', val: 'jSId', disabled: true, isChecked: true },
    { title: 'Service Product ID', val: 'jSPrId', disabled: true, isChecked: true },
    { title: 'Offer ID', val: 'jOfferId', disabled: true, isChecked: true },
    { title: 'Project ID', val: 'jPrjId', disabled: true, isChecked: true },
    { title: 'Customer Name', val: 'jCustName', disabled: true, isChecked: true },
    { title: 'Status', val: 'jStatus', disabled: true, isChecked: true },
    { title: 'Start Date', val: 'jStartDate', isChecked: true },
    { title: 'End Date', val: 'jEndDate', isChecked: true },
    { title: 'Cost Progress %', val: 'jCostPrg', isChecked: true },
    { title: 'Hour Progress %', val: 'jHourPrg', isChecked: true },
    { title: 'Progress %', val: 'jPrg', isChecked: true }
];

export const offerManagementColumnSelectorData = [
    { title: 'Offer ID', val: 'offerId', disabled: true, isChecked: true },
    { title: 'Project ID', val: 'oPrjId', disabled: true, isChecked: true },
    { title: 'Service Id', val: 'oSId', disabled: true, isChecked: true },
    { title: 'Customer Name', val: 'oCustName', disabled: true, isChecked: true },
    { title: 'Status', val: 'oStatus', disabled: true, isChecked: true },
    { title: 'Service Product Id', val: 'oServiceType', isChecked: true },
    { title: 'Description', val: 'oDescrp', isChecked: true },
    { title: 'Region', val: 'oRegion', isChecked: true },
    { title: 'Contact Person', val: 'oContactPerson', isChecked: true },
    // { 'title': 'Hour Progress %', 'val': 'oHourPrg', 'isChecked': true },
    { title: 'Start Date', val: 'oStartDate', isChecked: true },
    { title: 'End Date', val: 'oEndDate', isChecked: true },
    { title: 'Assigned To', val: 'oAssignedTo', isChecked: true },
    // { 'title': 'Roadmap Progress %', 'val': 'oRoadmapPrg', 'isChecked': true },
    // { 'title': 'Service Product Id', 'val': 'oSPD', 'isChecked': true }
];
