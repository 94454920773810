import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { BackendCallService } from 'src/app/services/backend-call/backend-call.service';

@Injectable()
export class MachineDetailsApiResolverService implements Resolve<any> {
    constructor( public backendService: BackendCallService,) { }

    resolve(route: ActivatedRouteSnapshot) {
        // Call the API service and return the Observable
        // return ProjectManagementLayoutPage.searchServiceData.pipe(take(1))
        return this.backendService.getProjectDetailMachineListsInfo(route.queryParams.orderId, route.queryParams.serviceId, route.queryParams.project_id, false);
    }

}
