<ion-content>
  <div class="widget-container">
    <ion-grid>
      <ion-row class="header-row">
        <ion-col size="10">
          <ion-label class="header-title">{{ 'Performance Level Rating' | translate}}</ion-label>
        </ion-col>
        <ion-col size="2" style="text-align: end;" (click)="close()" class="close-col"
          id="columnSelector_cancel_button">
          <img tooltip="{{'Close' | translate }}" alt="" placement="bottom" src="../../../assets/icon/cancel.svg">
        </ion-col>
      </ion-row>
      <ion-row class="content-row">
        <ion-col size="6">
          <ion-row class="cr-row">
            <ion-col size="12">
              <ion-label class="crr-label"> {{ 'Severity' | translate}}</ion-label>
              <div class="crr-ng-bottom-marg">
                <ng-select name="severity" placeholder="{{'Select'|translate}}" [items]="severityList" [clearable]="false"
                  bindValue="val" bindLabel="name" (change)="calculatePlrValue()"
                  [(ngModel)]="selectedPlrList.severity">
                </ng-select>
              </div>
            </ion-col>
          </ion-row>

          <ion-row class="cr-row">
            <ion-col size="12">
              <ion-label class="crr-label"> {{ 'Frequency of Exposure' | translate}}</ion-label>
              <div class="crr-ng-bottom-marg">
                <ng-select name="freqExpo" placeholder="{{'Select'|translate}}" [items]="frequencyExposureList" [clearable]="false"
                  bindValue="val" bindLabel="name" (change)="calculatePlrValue()"
                  [(ngModel)]="selectedPlrList.freqExpo">
                </ng-select>
              </div>
            </ion-col>
          </ion-row>

          <ion-row class="cr-row">
            <ion-col size="12">
              <ion-label class="crr-label"> {{ 'Possibility of Avoidance' | translate}}</ion-label>
              <div class="crr-ng-bottom-marg">
                <ng-select name="posOfAvoidance" placeholder="{{'Select'|translate}}" [items]="possibilityOfAvoidanceList"
                  [clearable]="false" bindValue="val" bindLabel="name" (change)="calculatePlrValue()"
                  [(ngModel)]="selectedPlrList.posOfAvoidance">
                </ng-select>
              </div>
            </ion-col>
          </ion-row>

          <ion-row class="cr-row">
            <ion-col size="6">
              <ion-label class="crr-label"> {{ 'Performance Level Rating' | translate}}</ion-label>
              <div class="crr-ng-bottom-marg">
                <ion-input readonly value={{plr_value}} placeholder=""></ion-input>
              </div>
            </ion-col>

            <ion-col size="6">
              <ion-label class="crr-label"> {{ 'Category' | translate}}</ion-label>
              <div class="crr-ng-bottom-marg">
                <ion-input readonly placeholder="" value={{category_value}}></ion-input>
              </div>
            </ion-col>
          </ion-row>

        </ion-col>
        <ion-col size="6" style="text-align: center;">
          <ng-container *ngIf="plr_value">
            <img src="/assets/images/PLR-A.svg" alt="" class="plr-img" *ngIf="plr_value === 'a'" />
            <img src="/assets/images/PLR-B.svg" alt="" class="plr-img" *ngIf="plr_value === 'b'" />
            <img src="/assets/images/PLR-C.svg" alt="" class="plr-img" *ngIf="plr_value === 'c'" />
            <img src="/assets/images/PLR-D.svg" alt="" class="plr-img" *ngIf="plr_value === 'd'" />
            <img src="/assets/images/PLR-E.svg" alt="" class="plr-img" *ngIf="plr_value === 'e'" />
          </ng-container>
          <ng-container *ngIf="!plr_value">
            <img src="/assets/images/PLR-Formula.svg" alt="" class="plr-formulla-img" />
          </ng-container>
        </ion-col>
      </ion-row>
      <ion-row class="button-row">
        <ion-button class="app-btn" shape="round" fill="outline" color="primary" (click)="submitPlrValue()">
          {{'Submit'|translate}}
        </ion-button>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>