import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { AuthRouteGuard } from './services/user-auth/auth-guard';
import { DashboardAuthGuard } from './services/user-auth/dashboard-auth-guard';
import { MachineDetailsApiResolverService } from './pages/project-management/machine-details/machine-details-service';
import { ViewOfferApiResolverService } from './pages/offer-management/view-offer/view-offer-service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    canActivate: [DashboardAuthGuard],
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'dashboard',
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(
        (m) => m.DashboardPageModule
      ),
  },
  {
    path: 'view-all-search-results',
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import(
        './pages/project-management/view-all-search-results/view-all-search-results.module'
      ).then((m) => m.ViewAllSearchResultsPageModule),
  },
  {
    path: 'offer-management',
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import('./pages/offer-management/offer-management.module').then(
        (m) => m.OfferManagementPageModule
      ),
  },
  {
    path: 'offer-management/create-offer/create-service',
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import('./pages/project-management/create-service/create-service.module').then(
        (m) => m.CreateServicePageModule
      ),
  },
  {
    path: 'offer-management/view-offer/create-service',
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import('./pages/project-management/create-service/create-service.module').then(
        (m) => m.CreateServicePageModule
      ),
  },
  {
    path: 'project-management',
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import('./pages/project-management/project-management.module').then(
        (m) => m.ProjectManagementPageModule
      ),
  },
  // {
  //   path: 'project-management/project-detail/:id',
  //   loadChildren: () =>
  //     import('./pages/project-detail/project-detail.module').then(
  //       (m) => m.ProjectDetailPageModule
  //     ),
  // },
  {
    path: 'user-management',
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import('./pages/user-management/user-management.module').then(
        (m) => m.UserManagementPageModule
      ),
  },
  {
    path: 'project-detail',
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import('./pages/project-management/project-detail/project-detail.module').then(
        (m) => m.ProjectDetailPageModule
      ),
  },
  {
    path: 'add-machine',
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import('./pages/machine-management/add-machine/add-machine.module').then(
        (m) => m.AddMachinePageModule
      ),
  },
  {
    path: 'offer-management/add-machine',
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import('./pages/machine-management/add-machine/add-machine.module').then(
        (m) => m.AddMachinePageModule
      ),
  },
  {
    path: 'offer-management/create-offer',
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import('./pages/offer-management/create-offer/create-offer.module').then(
        (m) => m.CreateOfferModule
      ),
  },
  {
    path: 'offer-management/view-offer/:id',
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import('./pages/offer-management/view-offer/view-offer.module').then(
        (m) => m.ViewOfferPageModule
      ),
      resolve: {
        machine_details: ViewOfferApiResolverService // Add the resolver to the route
      }
  },
  {
    path: 'view-offer/create-offer',
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import('./pages/offer-management/create-offer/create-offer.module').then(
        (m) => m.CreateOfferModule
      ),
  },
  {
    path: 'order-management',
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import('./pages/order-management/order-management.module').then(
        (m) => m.OrderManagementPageModule
      ),
  },
  {
    path: 'order-management/view-order/:id',
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import('./pages/order-management/view-order/view-order.module').then(
        (m) => m.ViewOrderPageModule
      ),
  },

  {
    path: 'machine-management/machine-info/:id/view-order/:id',
    canActivate: [AuthRouteGuard],
    data: {
      previousPage: 'view-order'
    },
    loadChildren: () =>
    import('./pages/order-management/view-order/view-order.module').then(
      (m) => m.ViewOrderPageModule
    )
  },
  {
    path: 'order-management/project-detail/:id',
    canActivate: [AuthRouteGuard],
    data: {
      previousPage: 'order-management'
    },
    loadChildren: () =>
      import('./pages/project-management/project-detail/project-detail.module').then(
        (m) => m.ProjectDetailPageModule
      ),
  },
  {
    path: 'offer-management/add-from-library',
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import('./pages/library-management/add-from-library/add-from-library.module').then(
        (m) => m.AddFromLibraryPageModule
      ),
  },
  {
    path: 'offer-management/add-from-previous-project',
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import('./pages/project-management/add-from-previous-project/add-from-previous-project.module').then(
        (m) => m.AddFromPreviousProjectPageModule
      ),
  },
  {
    path: 'view-offer/view-offer-add-machine',
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import('./pages/offer-management//view-offer-add-machine/view-offer-add-machine.module').then(
        (m) => m.ViewOfferAddMachinePageModule
      ),
  },
  {
    path: 'view-offer/view-offer-add-machine-from-libary',
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import('./pages/offer-management//view-offer-add-machine-from-libary/view-offer-add-machine-from-libary.module').then(
        (m) => m.ViewOfferAddMachineFromLibaryPageModule
      ),
  },
  {
    path: 'view-offer/view-offer-machine-from-previous-project',
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import('./pages/offer-management/view-offer-machine-from-previous-project/view-offer-machine-from-previous-project.module').then(
        (m) => m.ViewOfferMachineFromPreviousProjectPageModule
      ),
  },
  {
    path: 'view-offer/add-from-previous-project',
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import('./pages/project-management/add-from-previous-project/add-from-previous-project.module').then(
        (m) => m.AddFromPreviousProjectPageModule
      ),
  },
  {
    path: 'view-offer/view-offer-edit-machine',
    canActivate: [AuthRouteGuard],
    loadChildren: () => import('./pages/offer-management/view-offer-edit-machine/view-offer-edit-machine.module').then( m => m.ViewOfferEditMachinePageModule)
  },
  {
    path: 'library-management',
    canActivate: [AuthRouteGuard],
    loadChildren: () => import('./pages/library-management/library-management.module').then(m => m.LibraryManagementPageModule)
  },
  {
    path: 'library-management/view-library/view-library-man-details/:title',
    canActivate: [AuthRouteGuard],
    loadChildren: () => import('./pages/library-management/viewLibrary/view-library-man-details/view-library-man-details.module').then(m => m.ViewLibraryManDetailsPageModule)
  },
  {
    path: 'four-eye-quality',
    canActivate: [AuthRouteGuard],
    loadChildren: () => import('./pages/project-management/four-eye-quality/four-eye-quality.module').then(m => m.FourEyeQualityPageModule)
  },
  {
    path: 'dashboard/project-detail/:id/four-eye-quality/:machineid/:servicemachineid/:machinename',
    canActivate: [AuthRouteGuard],
    loadChildren: () => import('./pages/project-management/four-eye-quality/four-eye-quality.module').then(m => m.FourEyeQualityPageModule)
  },
  {
    path: 'project-management/project-detail/:id/four-eye-quality/:machineid/:servicemachineid/:machinename',
    canActivate: [AuthRouteGuard],
    loadChildren: () => import('./pages/project-management/four-eye-quality/four-eye-quality.module').then(m => m.FourEyeQualityPageModule)
  },
  {
    path: 'order-management/project-detail/:id/four-eye-quality/:machineid/:servicemachineid/:machinename',
    canActivate: [AuthRouteGuard],
    loadChildren: () => import('./pages/project-management/four-eye-quality/four-eye-quality.module').then(m => m.FourEyeQualityPageModule)
  },
  {
    path: 'machine-details',
    canActivate: [AuthRouteGuard],
    resolve: {
      machine_details: MachineDetailsApiResolverService
    },
    loadChildren: () => import('./pages/project-management/machine-details/machine-details.module').then(m => m.MachineDetailsPageModule)
  },
  {
    path: 'machine-detail-four-eye-quality',
    canActivate: [AuthRouteGuard],
    loadChildren: () => import('./pages/project-management/machine-detail-four-eye-quality/machine-detail-four-eye-quality.module').then(m => m.MachineDetailFourEyeQualityPageModule)
  },
  {
    path: 'dashboard/project-detail/:id/four-eye-quality/:machineid/:servicemachineid/:machinename/machine-detail-four-eye-quality/:machinetype',
    canActivate: [AuthRouteGuard],
    loadChildren: () => import('./pages/project-management/machine-detail-four-eye-quality/machine-detail-four-eye-quality.module').then(m => m.MachineDetailFourEyeQualityPageModule)
  },
  {
    path: 'project-management/project-detail/:id/four-eye-quality/:machineid/:servicemachineid/:machinename/machine-detail-four-eye-quality/:machinetype',
    canActivate: [AuthRouteGuard],
    loadChildren: () => import('./pages/project-management/machine-detail-four-eye-quality/machine-detail-four-eye-quality.module').then(m => m.MachineDetailFourEyeQualityPageModule)
  },
  {
    path: 'order-management/project-detail/:id/four-eye-quality/:machineid/:servicemachineid/:machinename/machine-detail-four-eye-quality/:machinetype',
    canActivate: [AuthRouteGuard],
    loadChildren: () => import('./pages/project-management/machine-detail-four-eye-quality/machine-detail-four-eye-quality.module').then(m => m.MachineDetailFourEyeQualityPageModule)
  },
  {
    path: 'hazard-checklits-four-eye',
    canActivate: [AuthRouteGuard],
    loadChildren: () => import('./pages/project-management/hazard-checklits-four-eye/hazard-checklits-four-eye.module').then(m => m.HazardChecklitsFourEyePageModule)
  },
  {
    path: 'dashboard/project-detail/:id/four-eye-quality/:machineid/:servicemachineid/:machinename/machine-detail-four-eye-quality/:machinetype/hazard-checklits-four-eye/:hazardmachineid',
    canActivate: [AuthRouteGuard],
    loadChildren: () => import('./pages/project-management/hazard-checklits-four-eye/hazard-checklits-four-eye.module').then(m => m.HazardChecklitsFourEyePageModule)
  },
  {
    path: 'dashboard/project-detail/:id/four-eye-quality/:machineid/:servicemachineid/:machinename/machine-detail-four-eye-quality/:machinetype/hazard-checklits-four-eye/:hazardmachineid/add-hazard-details',
    canActivate: [AuthRouteGuard],
    loadChildren: () => import('./pages/project-management/add-hazard-details/add-hazard-details.module').then(m => m.AddHazardDetailsPageModule)
  },
  {
    path: 'project-management/project-detail/:id/four-eye-quality/:machineid/:servicemachineid/:machinename/machine-detail-four-eye-quality/:machinetype/hazard-checklits-four-eye/:hazardmachineid',
    canActivate: [AuthRouteGuard],
    loadChildren: () => import('./pages/project-management/hazard-checklits-four-eye/hazard-checklits-four-eye.module').then(m => m.HazardChecklitsFourEyePageModule)
  },
  {
    path: 'project-management/project-detail/:id/four-eye-quality/:machineid/:servicemachineid/:machinename/machine-detail-four-eye-quality/:machinetype/hazard-checklits-four-eye/:hazardmachineid/add-hazard-details',
    canActivate: [AuthRouteGuard],
    loadChildren: () => import('./pages/project-management/add-hazard-details/add-hazard-details.module').then(m => m.AddHazardDetailsPageModule)
  },
  {
    path: 'offer-management/edit-machines',
    canActivate: [AuthRouteGuard],
    loadChildren: () => import('./pages/machine-management/edit-machines/edit-machines.module').then(m => m.EditMachinesPageModule)
  },
  {
    path: 'project-management/project-detail/:id',
    canActivate: [AuthRouteGuard],
    data: {
      previousPage: 'project-management'
    },
    loadChildren: () => import('./pages/project-management/project-management-layout/project-management-layout.module').then(m => m.ProjectManagementLayoutPageModule)
  },
  {
    path: 'dashboard/project-detail/:id',
    canActivate: [AuthRouteGuard],
    data: {
      previousPage: 'dashboard'
    },
    loadChildren: () => import('./pages/project-management/project-management-layout/project-management-layout.module').then(m => m.ProjectManagementLayoutPageModule)
  },
  // order-management/project-detail/PRJ00000239/four-eye-quality/93e38156-7844-494b-8066-9ce212c37001/6a529cb6-f15c-42e3-b7e3-f69ea5b9a9b6/test%252020241302/machine-detail-four-eye-quality/Injection%20Moulding'
  {
    path: 'order-management/project-detail/:id/machine-details/:id',
    canActivate: [AuthRouteGuard],
    resolve: {
      machine_details: MachineDetailsApiResolverService // Add the resolver to the route
    },
    data: {
      previousPage: 'machine-details'
    },
    loadChildren: () => import('./pages/project-management/machine-details/machine-details.module').then(m => m.MachineDetailsPageModule)
  },
  {
    path: 'machine-management/project-detail/:id',
    canActivate: [AuthRouteGuard],
    data: {
      previousPage: 'machine-management'
    },
    loadChildren: () => import('./pages/project-management/project-management-layout/project-management-layout.module').then(m => m.ProjectManagementLayoutPageModule)
  },
  {
    path: 'machine-management/machine-info/:id/view-offer/:id',
    canActivate: [AuthRouteGuard],
    data: {
      previousPage: 'view-offer'
    },
    loadChildren: () =>
    import('./pages/offer-management/view-offer/view-offer.module').then(
      (m) => m.ViewOfferPageModule
    ),
    resolve: {
      machine_details: ViewOfferApiResolverService // Add the resolver to the route
    }
  },
  {
    path: 'machine-management/machine-info/:id/view-offer/:id',
    canActivate: [AuthRouteGuard],
    data: {
      previousPage: 'machine-management'
    },
    loadChildren: () => import('./pages/project-management/project-management-layout/project-management-layout.module').then(m => m.ProjectManagementLayoutPageModule)
  },
  {
    path: 'generate-report',
    canActivate: [AuthRouteGuard],
    loadChildren: () => import('./pages/project-management/generate-report/generate-report.module').then(m => m.GenerateReportPageModule)
  },
  {
    path: 'export-ra-report-xl',
    loadChildren: () => import('./pages/project-management/export-ra-report-xl/export-ra-report-xl.module').then(m => m.ExportRaReportXlPageModule)
  },
  {
    path: 'dashboard/project-detail/:id/conflict-resolution',
    canActivate: [AuthRouteGuard],
    loadChildren: () => import('./pages/project-management/conflict-resolution/conflict-resolution.module').then(m => m.ConflictResolutionPageModule)
  },
  {
    path: 'project-management/project-detail/:id/conflict-resolution',
    canActivate: [AuthRouteGuard],
    loadChildren: () => import('./pages/project-management/conflict-resolution/conflict-resolution.module').then(m => m.ConflictResolutionPageModule)
  },
  {
    path: 'provisional-quote',
    canActivate: [AuthRouteGuard],
    loadChildren: () => import('./pages/project-management/provisional-quote/provisional-quote.module').then(m => m.ProvisionalQuotePageModule)
  },
  {
    path: 'rbac',
    canActivate: [AuthRouteGuard],
    loadChildren: () => import('./pages/user-management/rbac/rbac.module').then(m => m.RBACPageModule)
  },
  {
    path: 'user-management/rbac',
    canActivate: [AuthRouteGuard],
    loadChildren: () => import('./pages/user-management/rbac/rbac.module').then(m => m.RBACPageModule)
  },
  {
    path: 'view-users-in-role',
    canActivate: [AuthRouteGuard],
    loadChildren: () => import('./pages/user-management/view-users-in-role/view-users-in-role.module').then(m => m.ViewUsersInRolePageModule)
  },
  {
    path: 'view-pdf',
    canActivate: [AuthRouteGuard],
    loadChildren: () => import('./pages/library-management/view-pdf/view-pdf.module').then(m => m.ViewPdfPageModule)
  },
  {
    path: 'doc-viewer',
    canActivate: [AuthRouteGuard],
    loadChildren: () => import('./pages/library-management/doc-viewer/doc-viewer.module').then(m => m.DocViewerPageModule)
  },
  {
    path: 'view-roadmap-data',
    canActivate: [AuthRouteGuard],
    loadChildren: () => import('./pages/project-management/view-roadmap-data/view-roadmap-data.module').then(m => m.ViewRoadmapDataPageModule)
  },
  {
    path: 'edit-roadmap-data',
    canActivate: [AuthRouteGuard],
    loadChildren: () => import('./pages/project-management/edit-roadmap-data/edit-roadmap-data.module').then(m => m.EditRoadmapDataPageModule)
  },
  {
    path: 'machine-management',
    canActivate: [AuthRouteGuard],
    loadChildren: () => import('./pages/machine-management/machine-management.module').then( m => m.MachineManagementPageModule)
  },
  {
    path: 'machine-management/machine-info/:id',
    canActivate: [AuthRouteGuard],
    loadChildren: () => import('./pages/machine-management/machine-info/machine-info.module').then( m => m.MachineInfoPageModule),
  },
  {
    path: 'view-ra-reports',
    canActivate: [AuthRouteGuard],
    loadChildren: () => import('./pages/project-management/view-ra-reports/view-ra-reports.module').then( m => m.ViewRaReportsPageModule)
  },
  {
    path: 'customer-management',
    loadChildren: () => import('./pages/customer-management/customer-management.module').then( m => m.CustomerManagementPageModule)
  },
  {
    path: 'contact-form',
    loadChildren: () => import('./pages/customer-management/contacts/contact-form/contact-form.module').then( m => m.ContactFormPageModule)
  },
  {
    path: 'create-sales-organization',
    loadChildren: () => import('./pages/customer-management/sales-organization/create-sales-organization/create-sales-organization.module').then( m => m.CreateSalesOrganizationPageModule)
  },
  {
    path: 'view-sales-org-association',
    loadChildren: () => import('./pages/customer-management/sales-organization/view-sales-org-association/view-sales-org-association.module').then( m => m.ViewSalesOrgAssociationPageModule)
  },
  {
    path: 'view-customer-grid',
    loadChildren: () => import('./pages/customer-management/sales-organization/view-customer-grid/view-customer-grid.module').then( m => m.ViewCustomerGridPageModule)
  },
  {
    path: 'g-ra-report',
    loadChildren: () => import('./pages/project-management/generate-report/g-ra-report/g-ra-report.module').then( m => m.GRaReportPageModule)
  },


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading }),
  ],
  exports: [RouterModule],
  providers: [ViewOfferApiResolverService,MachineDetailsApiResolverService] // Add the resolver to the providers
})
export class AppRoutingModule { }
