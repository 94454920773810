<ion-content>
  <div class="widget-container" *ngIf="!isAlertBox">
    <ion-grid>
      <ion-row class="close-row">
        <ion-col (click)="close()" class="close-col" id="add-machine_cancel_button">
          <img src="../../../assets/icon/cancel.svg" alt="" tooltip="{{'Close'  | translate }}" placement="bottom">
        </ion-col>
      </ion-row>
      <ion-row class="header-row">
        <ion-col>
          <div class="ion-text-start header-title">
            {{title}}
          </div>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="shouldEditBucket && bucketListInfo.length" class="header-edit-container">
        <ion-col size-xs='1' size-md="1" class="hec-check">
          <ion-checkbox [(ngModel)]="editOrDeleteSelectionCheckboxInfo.isAllCheckboxSelected"
            (ionChange)="changeAllEditOrDeleteBucketSelection()"></ion-checkbox>
        </ion-col>
        <ion-col size-xs='5' size-md="5">
          <ion-label class="selected-bucket-count-name">
            {{editOrDeleteSelectionCheckboxInfo.selectedCount}}/{{bucketListInfo.length}} {{ 'Buckets are Selected' | translate }}
          </ion-label>
        </ion-col>
        <ion-col size="1" class="edit-count-button-container">
          <button (click)="deleteAllSelectedBucket()">
            <img src="../../../assets/images/Delete.svg" class="edit-delete-img" alt=""/>
          </button>
        </ion-col>
        <ion-col size="5" *ngIf="chooseAnyBucketFirstToDelete">
          <ion-label style="color: red;">{{'Please select any bucket to delete.'|translate}}</ion-label>
        </ion-col>
      </ion-row>
      <ion-row class="header-sub" *ngIf="shouldNewBucketCreate && !shouldCreateOnlyNewBucket">
        <ion-col class="header-sub-title">
          Selected Machines will be added to this bucket
        </ion-col>
      </ion-row>

      <!-- All Available Buckets -->
      <div class="scrollcontent scrollVertical" *ngIf="!shouldNewBucketCreate && !shouldEditBucket">
        <ion-row class=" content-row" *ngIf="bucketListInfo.length">
          <ion-col class=" content-col" size-xs='12' size-sm='6' size-md="6" size-lg="6" size-xl="4"
            *ngFor="let item of bucketListInfo">
            <ion-row>
              <ion-col size-xs='1' size-md="1" style="padding-top: 40px;">
                <ion-radio-group value={{selectedBucketRadioValue}} (ionChange)="changeSelectedBucketRadioData($event)">
                  <ion-radio mode='md' item-left slot="start" value="{{item.Bucket_Id}}" name="{{item.Bucket_Name}}">
                  </ion-radio>
                </ion-radio-group>
              </ion-col>
              <ion-col size-xs='11' size-md="11">
                <ion-card class="content-card-container">
                  <div class="content-card-title">
                    <ion-label id="add_machine_title_{{item.Bucket_Name}}">{{item.Bucket_Name}}</ion-label>
                  </div>
                </ion-card>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="!bucketListInfo.length" class="content-row">
          <ion-col class="empty-bucket">Bucket is Empty. Please create one.</ion-col>
        </ion-row>
      </div>

      <!-- create new bucket -->
      <div class="scrollcontent" *ngIf="shouldNewBucketCreate && !shouldEditBucket">
        <ion-row class="content-row">
          <ion-col size="4" class="create-bucket-container">
            <ion-label class="create-bucket-name"> {{ 'Bucket Name' | translate }}</ion-label><br />
            <ion-input [(ngModel)]="newBucketInput" placeholder="{{'Enter Bucket Name' | translate }}"></ion-input>
          </ion-col>
        </ion-row>
      </div>

      <!-- Edit or Delete Bucket -->
      <div *ngIf="shouldEditBucket" class="edit-content-container scrollVertical">
        <ng-container *ngIf="bucketListInfo.length">
          <div *ngFor="let bucket of bucketListInfo" class="ecc-one-bucket">
            <ion-row>
              <ion-col>
                <ion-label class="create-bucket-name edit-bucket-name-margin"> {{ 'Bucket Name' | translate }}
                </ion-label>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size-xs='1' size-md="1" style="padding-top: 20px;">
                <ion-checkbox [(ngModel)]="bucket.isCheckboxSelected"
                  (ionChange)="changeParticularEditOrDeleteBucketCheckboxSelection(bucket)"></ion-checkbox>
              </ion-col>
              <ion-col size-xs='5' size-md="5" style="margin-left: -5%;">
                <ion-input [readonly]="isReadonlyCustomerSearch()" style="color: black;" value={{bucket.Bucket_Name}}
                  (ionChange)="editBucketName($event, bucket)" class="edit-bucket-input"
                  [ngClass]="!isBucketUpdatePermission ? 'disable-edit-input' : 'active-edit-input' ">
                </ion-input>
              </ion-col>
              <ion-col size="3" class="delete-bucket-container">
                <button (click)="deleteBucket(bucket)">
                  <img src="../../../assets/images/Delete.svg" class="edit-img" alt=""/>
                </button>
              </ion-col>
            </ion-row>
          </div>
        </ng-container>
        <ng-container *ngIf="!bucketListInfo.length">
          <ion-row class="content-row">
            <ion-col class="empty-bucket">Bucket is Empty. Please Create a Bucket First.</ion-col>
          </ion-row>
        </ng-container>
      </div>
      <ion-row>
        <ion-col class="create-button-col">
          <ion-button class="create-bucket" shape="round" fill="outline" color="primary" *ngIf="!shouldNewBucketCreate && !shouldEditBucket"
            [disabled]="!isBucketCreatePermission" (click)="createNewBucket()">
            <ion-icon slot=start  name='add-circle-outline'></ion-icon>
            {{'Create New Bucket'|translate}}
          </ion-button>
        </ion-col>
        <ion-col class="save-button-col">
          <ion-button class="app-btn" shape="round" fill="outline" color="primary" *ngIf="!shouldEditBucket"
            id="addBucket_add_button" (click)="addBucket()">
            {{'Add'|translate}}
          </ion-button>
          <ion-button class="app-btn" shape="round" fill="outline" color="primary" *ngIf="shouldEditBucket"
            id="addBucket_add_button" (click)="saveEditOption()">
            {{'Save'|translate}}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

  <span class="alert-box" *ngIf="isAlertBox">
    <div class="close-icon" (click)="closeAlert()" id="alert_modal_cancel_button">
      <img src="../../../assets/icon/cancel.svg" alt="">
    </div>
    <div class="title" id="alert_modal_content">
      <span> {{ 'Are You Sure You Want to delete' | translate }} {{ contentToDelete}} ?</span>
    </div>
    <div class="button-container">
      <button id="alert_modal_confirm_button" (click)="confirmBucketDelete()"> {{ 'Confirm' | translate }}</button>
    </div>
  </span>

</ion-content>