import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { GraphInitService } from 'src/app/services/graph/graph-init.service';

@Injectable()
export class ViewOfferApiResolverService implements Resolve<any> {
    constructor( public graphInitService: GraphInitService,) { }

    resolve(route: ActivatedRouteSnapshot) {
        // Call the API service and return the Observable
        // return ProjectManagementLayoutPage.searchServiceData.pipe(take(1))
        return this.graphInitService.getSharePointHostDetails();
    }

}
