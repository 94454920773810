<ion-content>
  <div class="blur-container"></div>
  <ion-list class="list-view" *ngIf="popoverMenu">
    <ion-item lines='none' button *ngFor="let item of popoverMenu.items" (click)="selectType(item.type)">
      <img [src]="'assets/' + item.icon" alt=""/>
      <ion-label>{{ item.title | translate }}</ion-label>
    </ion-item>
  </ion-list>

  <ion-list class="list-view" *ngIf='type==="ChangeStatus"'>
    <ion-item lines='none' button (click)='selectType("Yet To Start")'>
      <ion-label>
        {{ 'Yet To Start' | translate }}
      </ion-label>
    </ion-item>
    <ion-item button lines='none' (click)='selectType("in-progress")'>
      <ion-label> {{ 'In Progress' | translate }}</ion-label>
    </ion-item>
    <ion-item lines='none' button (click)='selectType("On Hold")'>
      <ion-label> {{ 'On Hold' | translate }}</ion-label>
    </ion-item>
    <ion-item lines='none' button (click)='selectType("Resolved")'>
      <ion-label> {{ 'Resolved' | translate }}</ion-label>
    </ion-item>
  </ion-list>

  <ion-list class="list-view" *ngIf='type==="userConsoleRoleMang"' style="overflow: hidden;border-radius: 0px;">
    <ion-item lines='none' button (click)="selectType('assign-user-role')">
      <ion-label class="user-console-roleMang-text"> {{ 'Role Assignments' | translate }}</ion-label>
    </ion-item>
    <ion-item button lines='none' style="border-bottom: none;" (click)="selectType('all-roles')">
      <ion-label class="user-console-roleMang-text"> {{ 'All Roles' | translate }}</ion-label>
    </ion-item>
  </ion-list>

  <!-- don't change -->
  <ion-list class="list-view" *ngIf='type==="user_mang_allRole_More_Icon"'>
    <ion-item lines='none' style="border-bottom: none;" button (click)="selectType('allRoles-add-user')"
      [disabled]="!isRoleWritePermission" [ngClass]="!isRoleWritePermission ? 'disabled-text' : '' ">
      <img src="../../../assets/icon/Add User.svg" alt=""/>
      <ion-label class="user-console-roleMang-text"> {{ 'Add Members' | translate }}</ion-label>
    </ion-item>
    <ion-item button lines='none' style="border-bottom: none;" (click)="selectType('allRoles-edit-role')"
      [disabled]="!isRoleWritePermission" [ngClass]="!isRoleWritePermission ? 'disabled-text' : '' ">
      <img src="../../../assets/icon/edit1.svg" alt=""/>
      <ion-label class="user-console-roleMang-text"> {{ 'Rename Role' | translate }}</ion-label>
    </ion-item>
    <ion-item button lines='none' style="border-bottom: none;" (click)="selectType('allRoles-delete-role')"
      [disabled]="!isRoleDeletePermission" [ngClass]="!isRoleDeletePermission ? 'disabled-text' : '' ">
      <img src="../../../assets/icon/Delete1.svg" alt=""/>
      <ion-label class="user-console-roleMang-text"> {{ 'Delete Role' | translate }}</ion-label>
    </ion-item>
  </ion-list>
  <!-- don't change -->
  <ion-list class="list-view" *ngIf='type==="user_mang_groupMang_More_Icon"'
    style="overflow: hidden;border-radius: 0px;">
    <ion-item lines='none' button (click)="selectType('groupMang-add-user')" [disabled]="!isGroupWritePermission"
      [ngClass]="!isGroupWritePermission ? 'disabled-text' : '' ">
      <img src="../../../assets/icon/Add User.svg" alt=""/>
      <ion-label class="user-console-roleMang-text"> {{ 'Add Members' | translate }}</ion-label>
    </ion-item>
    <ion-item button lines='none' style="border-bottom: none;" (click)="selectType('groupMang-edit-group')"
      [disabled]="!isGroupWritePermission" [ngClass]="!isGroupWritePermission ? 'disabled-text' : '' ">
      <img src="../../../assets/icon/edit1.svg" alt=""/>
      <ion-label class="user-console-roleMang-text"> {{ 'Rename Group' | translate }}</ion-label>
    </ion-item>
    <ion-item button lines='none' style="border-bottom: none;" (click)="selectType('groupMang-delete-group')"
      [disabled]="!isGroupDeletePermission" [ngClass]="!isGroupDeletePermission ? 'disabled-text' : '' ">
      <img src="../../../assets/icon/Delete1.svg" alt=""/>
      <ion-label class="user-console-roleMang-text"> {{ 'Delete Group' | translate }}</ion-label>
    </ion-item>
  </ion-list>
  <!-- don't change -->
  <ion-list class="list-view" *ngIf='type==="machineSegmentBuckesEditOrCreate"'
    style="overflow: hidden;border-radius: 0px;">
    <ion-item lines='none' button (click)="myMachinesSegmentAddBucketButton('create')"
      [disabled]="!isCreateBucketPermission" [ngClass]="!isCreateBucketPermission ? 'disabled-text' : '' ">
      <img src="../../../assets/icon/add_user.svg" alt=""/>
      <ion-label class="user-console-roleMang-text"> {{ 'Create New Bucket' | translate }}</ion-label>
    </ion-item>
    <ion-item button lines='none' style="border-bottom: none;" (click)="myMachinesSegmentAddBucketButton('edit')"
      [disabled]="!isDeleteBucketPermission && !isUpdateBucketPermission"
      [ngClass]="!isUpdateBucketPermission && !isDeleteBucketPermission  ? 'disabled-text' : '' ">
      <img src="../../../assets/icon/edit1.svg" alt=""/>
      <ion-label class="user-console-roleMang-text"> {{ 'Edit/Delete Buckets' | translate }}</ion-label>
    </ion-item>
  </ion-list>
  <!-- don't change -->
  <ion-list class="list-view"
    *ngIf="type === 'machine-specific-kebab-elipse' ||  type === 'bucket-machine-specific-kebab-elipse'"
    style="overflow: hidden;border-radius: 0px;">
    <ion-item button lines='none' (click)="myMachinesSegmentAddBucketButton('edit-machine')"
      *ngIf='type==="machine-specific-kebab-elipse"'>
      <img src="../../../assets/icon/edit1.svg" alt=""/>
      <ion-label class="user-console-roleMang-text">{{ 'Add Estimated Hours' | translate }}</ion-label>
    </ion-item>
    <ion-item button lines='none' (click)="myMachinesSegmentAddBucketButton('delete-Bucket')"
      *ngIf='type==="bucket-machine-specific-kebab-elipse"'>
      <img src="../../../assets/images/Approve.svg" alt=""/>
      <ion-label class="user-console-roleMang-text"> {{ 'Remove machine from this bucket' | translate }}</ion-label>
    </ion-item>
    <ion-item lines='none' style="border-bottom: none;" button (click)='selectType("request-4-eye-quality-check")'
      *ngIf="!isFourEyeRequested" [disabled]="!is_RA_Status || !isFourEyeQualityCheckPermission">
      <img src='../../../assets/images/4_eye_quality_check.svg' alt=""/>
      <ion-label> {{ 'Request 4 Eyes Quality Check' | translate }}</ion-label>
    </ion-item>
    <ion-item lines='none' style="border-bottom: none;" button (click)='selectType("perform-4-eye-quality-check")'
      *ngIf="isFourEyeRequested"
      [disabled]="!is_RA_Status || !isFourEyeQualityCheckPermission"><!--|| !isFourEyeQualityPerform -->
      <img src='../../../assets/images/4_eye_quality_check.svg' alt=""/>
      <ion-label> {{ 'Perform 4 Eyes Quality Check' | translate }} </ion-label>
    </ion-item>
  </ion-list>

  <ion-list class="list-view" *ngIf='type==="addMachine"'>
    <ion-item lines='none' button (click)='selectType("add-new-machine")'>
      <ion-icon slot=start style="font-size: 16px ;" name='add-circle-outline'></ion-icon>
      <ion-label style="font-size: 7.9px ;">
        {{ 'Add New Machine' | translate}}
      </ion-label>
    </ion-item>
    <ion-item button lines='none' (click)='selectType("add-from-library")'>
      <ion-icon slot=start style="font-size: 16px ;" name='add-circle-outline'></ion-icon>
      <ion-label style="font-size: 7.9px ;"> {{ 'Add From Library' | translate}}</ion-label>
    </ion-item>
  </ion-list>
  <!-- don't change -->
  <ion-list class="list-view" *ngIf='type === "4_eyeQualityCheck"'>
    <ion-item lines='none' button (click)='selectType("request-4-eye-quality-check")'
      *ngIf="!sharedValue.isFourEyeQualityCheckRequested">
      <img src='../../../assets/images/4_eye_quality_check.svg' alt=""/>
      <ion-label> {{ 'Request 4 Eyes Quality Check' | translate}}</ion-label>
    </ion-item>
    <ion-item lines='none' button (click)='selectType("perform-4-eye-quality-check")'
      *ngIf="sharedValue.isFourEyeQualityCheckRequested">
      <img src='../../../assets/images/4_eye_quality_check.svg' alt=""/>
      <ion-label> {{ 'Perform 4 Eyes Quality Check' | translate}}</ion-label>
    </ion-item>
  </ion-list>
  <!-- don't change -->
  <ion-list class="list-view" *ngIf='type==="listOfServiceMoreIcon"'>
    <ion-item lines='none' button
      [hidden]="each_service?.pageName==='createOffer' || this.each_service?.pageName === 'ProjectAction' ||this.each_service?.pageName === 'viewOpp'"
      (click)='listOfServiceMore("edit-service")'>
      <ion-label>
        {{ 'Edit Service' | translate}}
      </ion-label>
    </ion-item>
    <ion-item lines='none' button (click)='listOfServiceMore("delete-service")'>
      <ion-label> {{ 'Delete Service' | translate}} </ion-label>
    </ion-item>
  </ion-list>

  <ion-list class="list-view" *ngIf='type==="viewOfferMore"'>
    <ion-item lines='none' button (click)='selectOffer("edit-Offer")'
      [disabled]="!isDisableEditOfferButton &&!isEditOfferCheckPermission"
      [ngClass]="!isDisableEditOfferButton ? 'disabled-text' : '' ">
      <ion-label>
        {{ 'Edit Offer' | translate}}
      </ion-label>
    </ion-item>
  </ion-list>

  <!-- don't change -->
  <ion-list class="list-view" *ngIf='type==="offerMngTrckr"'>
    <ion-item lines='none' button [disabled]='offerDetail.status==="Approved"' (click)='selectOfferType("Assign To")'>
      <ion-label>
        {{ 'Assign To' | translate}}
      </ion-label>
    </ion-item>

    <ion-item lines='none' button (click)='selectOfferType("Review Offer")'>
      <ion-label>
        {{ 'View Offer' | translate}}
      </ion-label>
    </ion-item>
    <ion-item lines='none' button (click)='selectOfferType("Edit Offer")'>
      <ion-label> {{ 'Edit Offer' | translate}}</ion-label>
    </ion-item>
  </ion-list>

  <ion-list class="list-view" *ngIf='type==="projectInformationStatus"'>
    <ion-item style="cursor: pointer;" lines='none' button (click)='selectOption("editProj")'
      [disabled]="!isEditProjectCheckPermission || shouldDisableEditProjectButton "
      [ngClass]="shouldDisableEditProjectButton ? 'disabled-text' : '' ">
      <img src='../../../assets/icon/edit1.svg' [ngClass]="shouldDisableEditProjectButton ? 'disabled-text' : '' " alt=""/>
      <ion-label>
        {{ 'Edit Project' | translate }}
      </ion-label>
    </ion-item>
    <ion-item lines='none' button (click)='selectOption("deleteProj")' [disabled]="!shouldDisableDeleteProjectButton"
      [ngClass]="!shouldDisableDeleteProjectButton ? 'disabled-text' : '' ">
      <img src='../../../assets/icon/Delete1.svg' alt=""
        [ngClass]="!shouldDisableDeleteProjectButton ? 'disabled-text' : '' " />
      <ion-label>{{ 'Delete Project' | translate}}</ion-label>
    </ion-item>
    <ion-item style="cursor: pointer;" lines='none' (click)='selectOption("blockProj")'
      *ngIf="projectStatus !== 'block' && projectStatus !== 'blocked'" [disabled]="!shouldDisableDeleteProjectButton">
      <img src='../../../assets/icon/Block Project1.svg' alt=""/>
      <ion-label>{{ 'Block Project' | translate }}</ion-label>
    </ion-item>
    <ion-item style="cursor: pointer;" lines='none' (click)='selectOption("releaseProj")'
      *ngIf="projectStatus === 'block' || projectStatus === 'blocked'">
      <img src='../../../assets/icon/Block Project1.svg' alt=""/>
      <ion-label>Release</ion-label>
    </ion-item>
    <ion-item lines='none' button (click)='selectOption("closeProj")' [disabled]="!shouldDisableDeleteProjectButton">
      <img src='../../../assets/icon/Close Project.svg' alt=""/>
      <ion-label>{{ 'Close Project' | translate }}</ion-label>
    </ion-item>
  </ion-list>

  <ion-list class="list-view" *ngIf='type==="share"'>
    <ion-item lines='none' button (click)='selectType("shareoutLook")'>
      <img src='../../../assets/icon/outlook.svg' alt=""/>
      <ion-label> {{ 'Share Via Outlook' | translate}}</ion-label>
    </ion-item>
    <ion-item lines='none' button (click)='selectType("shareTeams")'>
      <img src='../../../assets/icon/teams.svg' alt=""/>
      <ion-label> {{ 'Share Via Teams' | translate}}</ion-label>
    </ion-item>
  </ion-list>

  <ion-list class="list-view" *ngIf='type==="openSort"'>
    <ion-item lines='none' button>
      <img src='../../../assets/icon/refresh.svg' alt=""/>
      <ion-label>
        {{ 'Date Added' | translate}}
      </ion-label>
    </ion-item>
    <ion-item lines='none' button>
      <img src='../../../assets/icon/copy.svg' alt=""/>
      <ion-label> {{ 'Publication Date' | translate}}</ion-label>
    </ion-item>
    <ion-item lines='none' button>
      <img src='../../../assets/icon/Add.svg' alt=""/>
      <ion-label> {{ 'Last week' | translate}}</ion-label>
    </ion-item>
    <ion-item lines='none' button>
      <img src='../../../assets/icon/documents.svg' alt=""/>
      <ion-label> {{ 'Last Month' | translate}}</ion-label>
    </ion-item>
    <ion-item lines='none' button>
      <img src='../../../assets/icon/upload.svg' alt=""/>
      <ion-label> {{ 'Custom Range' | translate}}</ion-label>
    </ion-item>
  </ion-list>

  <ion-list class="list-view" *ngIf='type==="openFilter"'>
    <ion-item lines='none' button>
      <img src='../../../assets/icon/refresh.svg' alt=""/>
      <ion-label>
        {{ 'Showall' | translate}}
      </ion-label>
    </ion-item>
    <ion-item lines='none' button>
      <img src='../../../assets/icon/copy.svg' alt=""/>
      <ion-label> {{ 'Microsoft Word' | translate}}</ion-label>
    </ion-item>
    <ion-item lines='none' button>
      <img src='../../../assets/icon/Add.svg' alt=""/>
      <ion-label> {{ 'Microsoft Excel' | translate}}</ion-label>
    </ion-item>
    <ion-item lines='none' button>
      <img src='../../../assets/icon/documents.svg' alt=""/>
      <ion-label> {{ 'Autocad DWG' | translate}}</ion-label>
    </ion-item>
    <ion-item lines='none' button>
      <img src='../../../assets/icon/upload.svg' alt=""/>
      <ion-label> {{ 'Adobe PDF' | translate}}</ion-label>
    </ion-item>
  </ion-list>


  <ion-list class="list-view" *ngIf='type==="openProAction"'>
    <ion-item *ngIf="isFromSAP" lines='none' button (click)="redirectOffer()" [disabled]="shouldDisableCreateOffer"
      [ngClass]="shouldDisableCreateOffer ? 'disabled-text' : '' ">
      <img src='../../../assets/icon/add_user.svg' alt=""/>
      <ion-label>
        {{ 'Create Offer' | translate}}
      </ion-label>
    </ion-item>
    <ion-item *ngIf="!isFromSAP" lines='none' button (click)="redirectService()">
      <img src='../../../assets/icon/add_user.svg' alt=""/>
      <ion-label>
        {{ 'Create Service' | translate}}
      </ion-label>
    </ion-item>
    <ion-item lines='none' button (click)="redirectProjectHistory()">
      <img src='../../../assets/icon/history.svg' alt=""/>
      <ion-label>
        {{ 'View Project History' | translate}}
      </ion-label>
    </ion-item>
  </ion-list>

  <ion-list class="list-view1" *ngIf='type==="openChangeLan"'>
    <div class="profile-container">
      <div class="text-avatar">
        {{ getInitials(userInfo?.name) }}
      </div>
      <div class="profile-details">
        <div class="profile-name">{{ userInfo?.name }}</div>
        <div class="profile-email">{{ userInfo?.email }}</div>
      </div>
    </div>
    

    <ion-item lines='none' button class="profile-item" (click)="goToUserMgmt()">
      <ion-icon name="person-circle-outline"></ion-icon>
      <ion-label>
        <div class="role-container">
          <span class="left-label">{{'Role'|translate}}</span>
          <span>{{ (userInfo?.roles.length > 1) ? 'Multiple' : userInfo?.roles[0] }}</span>
        </div>
      </ion-label>
      <img class="right-icon" *ngIf="userInfo?.roles.includes('Global Admin')" src='../../../assets/icon/right-arrow.png' alt=""/>
    </ion-item>

    <ion-item lines="none" class="profile-item">
      <ion-icon name="language-outline"></ion-icon>
      <!-- <img class="lang-icon" src='../../../assets/icon/translate.svg' alt=""/> -->
      <ion-label>
        <div class="role-container">
          <span class="left-label">{{ 'Language' | translate }} </span>
          <span>{{ languages[selectedLanguage] | translate }}</span>
        </div>
      </ion-label>
      <img class="right-icon" src='../../../assets/icon/right-arrow.png' (click)="openLanguageSelect($event)" alt=""/>
      <ion-select [interfaceOptions]="{ cssClass: 'popover-wide' }" value="en" [(ngModel)]="selectedLanguage"
        placeholder="Select Language" (ionChange)="languageChange()" #languageSelect hidden>
        <ion-select-option value="en">English (en)</ion-select-option>
        <ion-select-option value="pt">Portuguese (pt)</ion-select-option>
        <ion-select-option value="de">German (de)</ion-select-option>
        <ion-select-option value="sp">Spanish (sp)</ion-select-option>
      </ion-select>
    </ion-item>

    <ion-item lines='none' button class="profile-item" (click)="openUserGuide()">
      <!-- <img class="video-icon" src='assets/icon/video-icon.svg' alt=""/> -->
      <ion-icon name="film-outline"></ion-icon>
      <ion-label class="left-label">{{ 'PRODUCT_GUIDE' | translate}}</ion-label>
    </ion-item>
    <ion-item lines='none' button class="profile-item last-profile-item" (click)="logOutModalConfirmation()">
      <!-- <img style="width: 8% !important;" src='../../../assets/icon/exit.png' alt=""/> -->
      <ion-icon name="log-out-outline"></ion-icon>
      <ion-label class="left-label"> {{ 'Logout' | translate}}</ion-label>
    </ion-item>
  </ion-list>

  <ion-list class="list-view" *ngIf='type==="conflict_resolution_filter"'>
    <ion-item lines='none' button (click)='selectType("sort_by_name")'>
      <ion-label>
        {{ 'Sort by name' | translate}}
      </ion-label>
    </ion-item>
    <ion-item button lines='none' (click)='selectType("sort_by_hrn")'>
      <ion-label>{{ 'Sort by HRN Value' | translate}}</ion-label>
    </ion-item>
  </ion-list>

  <ion-list class="list-view" *ngIf='type==="configure-roadmap"'>
    <ion-item lines='none' button (click)='selectType("custom_roadmap")'>
      <ion-label>
        {{ 'New...' | translate}}
      </ion-label><!--Create Custom Roadmaps-->
    </ion-item>
    <ion-item button lines='none' (click)='selectType("predefined_roadmap")'>
      <ion-label> {{ 'From Template...' | translate}}</ion-label>
    </ion-item>

    <!-- <ion-item button lines='none' (click)='selectType("predefined_roadmap")'>
    <ion-label> {{ 'From Project' | translate}}</ion-label>
  </ion-item> -->
  </ion-list>

  <ion-list class="list-view" *ngIf='type==="save_to_template"'>
    <ion-item lines='none' button (click)='selectType("save_to_template")'>
      <ion-label>
        {{ 'Save To Template' | translate}}
      </ion-label><!--Create Custom Roadmaps-->
    </ion-item>
  </ion-list>

  <ion-list class=" list-view" *ngIf='type==="openProReport"'>
    <ion-item [disabled]='this.generateProjectDisable' lines='none' button
      (click)="viewReport('Generate Project Report')">
      <img src='../../../assets/icon/refresh.svg' alt=""/>
      <ion-label>{{'Generate RA Report' | translate }}
      </ion-label>
    </ion-item>
    <ion-item [disabled]='this.generateProjectDisable' lines='none' button (click)="viewReport('Export To Excel')">
      <img src='../../../assets/images/Excel.png' alt=""/>
      <ion-label>{{'Export to Excel' | translate }}
      </ion-label>
    </ion-item>
    <ion-item [disabled]='this.generateProjectDisable' lines='none' button (click)="viewReport('View RA Reports')">
      <img src='../../../assets/icon/documents.svg' alt=""/>
      <ion-label>{{'View RA Reports' | translate }}
      </ion-label>
    </ion-item>
  </ion-list>

  <ion-list class="list-view" *ngIf='type==="user_mangament"'>
    <ion-item lines='none' button (click)='selectType("addSalseOrg")'>
      <ion-label> {{'Add' | translate }} {{ 'Sales_Organisation' | translate}}</ion-label>
    </ion-item>
    <ion-item lines='none' button (click)='selectType("deleteUser")'>
      <ion-label> {{ 'Delete user' | translate}}</ion-label>
    </ion-item>
  </ion-list>
  <ion-list class=" list-view" *ngIf='type==="roadmapHistory" && this.roadmapHistory?.length'>
    <ion-item lines='none' button (click)="changeRoadmapVersion(history)" *ngFor="let history of this.roadmapHistory">
      <div class="item-container">
        <div class="left-content">
          <ion-label>{{history?.roadmapName }} {{history?.version }}
          </ion-label>
        </div>
        <div class="right-content">
          <ion-label>{{history?.modifiedBy }}
          </ion-label>
          <p>{{history?.modifiedAt }}
          </p>
        </div>
      </div>
    </ion-item>
  </ion-list>
</ion-content>