<ion-content>
  <div class="widget-container">
    <ion-grid>
      <div class="flex-container">
        <div class="left-column header-row">
          <div class="ion-text-start header-title">
            <span *ngIf="type=='assign'">{{ 'Assign/Edit User' | translate}}</span>
            <span *ngIf="type=='offerMngTrckr'">{{ 'Assign/Edit User' | translate}}</span>
            <span *ngIf="type=='assign-4-eye-quality-check-user'"> {{ 'Assign User' | translate}}</span>
            <span *ngIf="type=='edit'"> {{ 'Edit Task' | translate}}</span>
            <span *ngIf="type=='delete'"> {{ 'Delete Task' | translate}}</span>
            <span *ngIf="type=='user_console_add_user' || type == 'role_mang_all_roles_add_users' "> {{ 'Add Members' |
              translate}}</span>
            <span *ngIf="type == 'user_console_group_mang_add_group'"> {{ 'Create Group' | translate}}</span>
            <span *ngIf="type=='user_console_role_mang_all_roles'"> {{ 'Create Role' | translate}}</span>
            <span *ngIf="type=='user_console_role_mang_assign_user_role'"> {{ 'Assign Role' | translate}}</span>
            <span *ngIf="type=='assignProject'"> {{ 'Add User' | translate}}</span>
            <span *ngIf="type=='role_mang_all_roles_edit_role'"> {{ 'Edit Role' | translate}}</span>
            <span *ngIf="type=='group_mang_edit_group'"> {{ 'Edit Group' | translate}}</span>
            <span *ngIf="type=='group_mang_add_users'"> {{ 'Add Members' | translate}}</span>
          </div>
        </div>
        <div class="right-column">
          <img class="close-icon" alt="" tooltip="{{'Close'  | translate }}" placement="bottom" (click)="close()" src="assets/icon/cancel.svg">
        </div>
      </div>
      <div
        *ngIf="type == 'user_console_group_mang_add_group' || type=='user_console_role_mang_all_roles' || 
        type=='user_console_role_mang_assign_user_role' || type == 'role_mang_all_roles_edit_role' || type == 'group_mang_edit_group'"
        class="user-group-mang-container">
        <ion-row>
          <ion-col [ngClass]="type == 'user_console_role_mang_assign_user_role' || 
          type == 'user_console_group_mang_add_group' ? 'extra-top-marg': '' ">
            <ion-label class="ugmc-title" *ngIf="type == 'user_console_group_mang_add_group'"> {{ 'Group Name' |
              translate}}
            </ion-label>
            <ion-label class="ugmc-title" *ngIf="type == 'user_console_role_mang_all_roles'"> {{ 'Role Name' |
              translate}}</ion-label>
            <ion-label class="ugmc-title" *ngIf="type == 'user_console_role_mang_assign_user_role'"> {{ 'Choose Role' |
              translate}}
            </ion-label>
            <ion-label class="ugmc-title" *ngIf="type == 'role_mang_all_roles_edit_role'"> {{ 'Role Name' |
              translate}}</ion-label>
            <ion-label class="ugmc-title" *ngIf="type == 'group_mang_edit_group'"> {{ 'Group Name' |
              translate}}</ion-label>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="5.5">
            <ion-input placeholder="{{ 'Enter group name' | translate}}" [(ngModel)]="enterUserConsoleGroupName"
              *ngIf="type == 'user_console_group_mang_add_group'" #inputEl></ion-input>
            <ion-input placeholder="{{ 'Enter role name' | translate}}" [(ngModel)]="enterUserConsoleAddRole"
              *ngIf="type == 'user_console_role_mang_all_roles'" #inputEl></ion-input>
            <ion-input placeholder="{{ 'Enter role name' | translate}}" [(ngModel)]="valueToEdit"
              *ngIf="type == 'role_mang_all_roles_edit_role'" #inputEl></ion-input>
            <ion-input placeholder="{{ 'Enter Group' | translate}}" [(ngModel)]="valueToEdit"
              *ngIf="type == 'group_mang_edit_group'" #inputEl></ion-input>
            <ng-container *ngIf="type == 'user_console_role_mang_assign_user_role'">
              <ng-select [items]="availableRolesInfo" bindLabel="role_name" bindValue="id" [clearable]="false"
                placeholder="Select Role" class="dropdown" [(ngModel)]="selectedRoleToAssign">
              </ng-select>
            </ng-container>
          </ion-col>
        </ion-row>
      </div>

      <ion-searchbar #search
        *ngIf="type !== 'user_console_role_mang_all_roles' && type!=='role_mang_all_roles_edit_role' && type!== 'group_mang_edit_group' "
        debounce="1000" mode='ios' placeholder="Search users to be added" (ionChange)="getItems($event)"
        [showClearButton]="'always'">
      </ion-searchbar>
      <ion-row *ngIf="type=='user_console_add_user' && userNotFound && dummyData.length==0" class="no-user-found-container">
        <span class="no-user-found-text"> {{ 'No Users Found' | translate}}</span>
      </ion-row>
      <ion-row *ngIf="type=='offerMngTrckr' && userNotFound && dummyData.length==0" class="no-user-found-container">
        <span class="no-user-found-text"> {{ 'No Users Found' | translate}}</span>
      </ion-row>
      <ion-row *ngIf="type=='assign-4-eye-quality-check-user' && userNotFound && dummyData.length==0" class="no-user-found-container">
        <span class="no-user-found-text"> {{ 'Search for users' | translate}}</span>
      </ion-row>
      <ion-list *ngIf="isItemAvailable" class="list-view scrollVertical" [ngClass]="type=='user_console_add_user' ? 'list-view-wid scroll-searched-container' : 
        type == 'user_console_role_mang_assign_user_role' || type == 'user_console_group_mang_add_group' || type=='assignProject' || type== 'offerMngTrckr' || type == 'user_console_group_mang_add_group' ?  
        'role-and-group-scroll-content' : ''">
        <ion-item *ngFor="let item of dummyData" lines='none' style="background: none;">
          <img [src]='item.thumbnailUrl' alt="" style="width: 20px; border-radius: 50%;" />
          <ion-label>{{type == 'user_console_add_user' || type == 'user_console_add_user' || type=='assignProject' ||
            type == 'offerMngTrckr' ||
            type ==
            'user_console_group_mang_add_group' || type == 'user_console_role_mang_assign_user_role' ||
            type=='assign-4-eye-quality-check-user' || type == 'group_mang_add_users' || type ==
            'role_mang_all_roles_add_users' ?
            item.Display_Name :
            item.name}}</ion-label>
          <ng-container
            *ngIf="type!=='assign-4-eye-quality-check-user' && type!== 'offerMngTrckr' && type!== 'assignProject'">
            <ion-checkbox slot="start" [(ngModel)]="item.checked" (ionChange)="addUser($event,item)"
              *ngIf="type == 'user_console_add_user' || type == 'user_console_role_mang_assign_user_role' || 
                type == 'user_console_group_mang_add_group' || type == 'group_mang_add_users' || type == 'role_mang_all_roles_add_users'">
            </ion-checkbox>
            <ion-checkbox slot="start" (ionChange)="addUser($event,item)" value="{{ item.checked }}" *ngIf="type != 'user_console_add_user' && type != 'user_console_role_mang_assign_user_role' && 
                type != 'user_console_group_mang_add_group' && type !='assignProject' && type!= 'group_mang_add_users' && 
                type != 'role_mang_all_roles_add_users'">
            </ion-checkbox>
          </ng-container>

          <!-- <ng-container>
            <ion-checkbox slot="start" [(ngModel)]="item.checked" *ngIf="type=='assignProject'">
            </ion-checkbox>
            <ion-checkbox slot="start" (ionChange)="addUser($event,item)" value="{{ item.checked }}"
              *ngIf="type! =='assignProject'">
            </ion-checkbox>
          </ng-container> -->

          <ng-container
            *ngIf="type=='assign-4-eye-quality-check-user' || type== 'offerMngTrckr' || type== 'assignProject'">
            <ion-radio-group slot="start" value={{selectedCustomerRadioValue}}
              (ionChange)="changeSelectedRadioValue($event)">
              <ion-radio mode='md' [disabled]="item.disabled" item-left value="{{item.Id}}" name="{{item.Display_Name}}">
              </ion-radio>
              <!-- <ion-lable>{{item.Display_Name}}</ion-lable> -->
            </ion-radio-group>
          </ng-container>

          <!-- <ng-container *ngIf="type== 'assign'">
            <ion-radio-group slot="start" value={{selectedCustomerRadioValue}}
              (ionChange)="changeSelectedRadioValue($event)">
              <ion-radio mode='md' item-left value="{{item.Id}}" name="{{item.Display_Name}}">
              </ion-radio>
            </ion-radio-group>
          </ng-container> -->
        </ion-item>
      </ion-list>
      <div class="select-user-main scrollVertical" [ngClass]="type == 'user_console_role_mang_assign_user_role' || type =='assignProject' || type == 'role_mang_all_roles_add_users' ?  
        'selected-role-group-user-content' : ''" *ngIf="type != 'group_mang_add_users' || type !== 'user_console_add_user' || type !== 'user_console_group_mang_add_group'">
        <!-- <h2 *ngIf="type!=='user_console_add_user' && type !== 'user_console_group_mang_add_group' && type!=='assignProject'">{{ 'Selected User' | translate}}
        </h2> -->
        <span *ngFor="let user of dummyData">
          <ion-button (click)='removeUser(user)' class="select-user" shape="round" fill="clear" size="small"
            *ngIf="user.checked && type !== 'user_console_add_user' && type !=='user_console_group_mang_add_group'">
            {{type == 'user_console_role_mang_assign_user_role' || type
            =='assignProject'|| type == 'role_mang_all_roles_add_users' ? user.Display_Name :
            user.name}} &nbsp;&nbsp;<ion-icon slot="end" name='close-outline'>
            </ion-icon>
          </ion-button>
        </span>
      </div>
      <div class="select-user-main scrollVertical" [ngClass]="type=='user_console_add_user' || type=='user_console_group_mang_add_group'? 'selected-user-content' : ''" *ngIf="type == 'user_console_add_user'|| type=='user_console_group_mang_add_group'">
        <span *ngFor="let user of selectedUsersList">
          <ion-button class="select-user"  (click)='removeAddUser(user)' shape="round" fill="clear" size="small"
            *ngIf="user.checked">
            {{type == 'user_console_add_user' || type=='user_console_group_mang_add_group' ? user.Display_Name :
            user.name}}&nbsp;&nbsp;<ion-icon  slot="end" name='close-outline'>
            </ion-icon>
          </ion-button>
        </span>
      </div>
      <ion-row class="button-row">
        <ion-button *ngIf="type=='assign'" (click)='assignUser("offerMngTrckr")' class="app-btn" shape="round" fill="outline" color="primary">
          {{'Assign'|translate}}
        </ion-button>
        <ion-button *ngIf="type=='edit'" (click)='assignUser("edit")' class="app-btn" shape="round" fill="outline" color="primary">
          {{'Edit'|translate}}
        </ion-button>
        <ion-button *ngIf="type=='delete'" (click)='assignUser("delete")' class="app-btn" shape="round" fill="outline" color="primary">
          {{'Delete'|translate}}
        </ion-button>
        <ion-button class="app-btn" shape="round" fill="outline" color="primary"
          *ngIf="type=='user_console_add_user' || type == 'user_console_group_mang_add_group' || 
            type == 'user_console_role_mang_all_roles' || type == 'user_console_role_mang_assign_user_role' || 
            type == 'role_mang_all_roles_edit_role' || type == 'group_mang_edit_group' || type == 'group_mang_add_users' || type == 'role_mang_all_roles_add_users' "
          (click)='assignUser("user_console_add_user")'>
          {{'Submit'|translate}}
        </ion-button>
        <ion-button *ngIf="type=='assignProject'" (click)='assignProject("assignProject")' class="app-btn" shape="round" fill="outline" color="primary">
          {{'Submit'|translate}}
        </ion-button>
        <ion-button [disabled]="!isAnyUserSelectedFor4EyeCheck" *ngIf="type=='assign-4-eye-quality-check-user'" (click)='assignUser(" assign")' class="app-btn" shape="round" fill="outline" color="primary">
          {{'Assign'|translate}}
        </ion-button>
        <ion-button *ngIf="type=='offerMngTrckr'" (click)='assignUserInfo()' class="app-btn" shape="round" fill="outline" color="primary">
          {{'Assign'|translate}}
        </ion-button>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>