import { NgModule } from '@angular/core';
import { AuthModule, LogLevel } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';
import { AuthCookieManagerService } from './auth-cookie-manager/auth-cookie-manager.service';
import { authIdpAuthorityUrl, authClientId } from '../../utilities/constant';

@NgModule({
    imports: [
        AuthModule.forRoot({
            config: {
                authority: authIdpAuthorityUrl,
                redirectUrl: window.location.origin + '/login',
                postLogoutRedirectUri: window.location.origin + '/login',
                clientId: authClientId,
                scope: 'offline_access openid profile user.api',
                responseType: 'code',
                silentRenew: true,
                useRefreshToken: true,
                renewTimeBeforeTokenExpiresInSeconds: 10,
                logLevel: LogLevel.None,
                //logLevel: environment.production ? LogLevel.None : LogLevel.Debug,
            }, storage: AuthCookieManagerService
        }),
    ],
    exports: [AuthModule],
})
export class AuthConfigModule { }
