import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { AppConstants } from 'src/app/utilities/constant';

@Component({
  selector: 'app-copy-roadmap',
  templateUrl: './copy-roadmap.component.html',
  styleUrls: ['./copy-roadmap.component.scss'],
})
export class CopyRoadmapComponent implements OnInit {
  private roadmapSections: any;
  public hazardData = [];
  public hazard: any;
  private roadmap: any;
  public copiedHazardData = [];
  constructor(private modalController: ModalController, private navParams: NavParams) { }

  ngOnInit() {
    this.hazardData = [];
    this.roadmapSections = this.navParams.get('roadmapSections');
    this.roadmap = this.roadmapSections
      .map((section: any) => {
        section['isCopied'] = false;
        const filteredSubSections = section.subSections
          .map((subSection: any) => {
            const filteredSteps = subSection.steps.filter((step: any) => step.hazard);
            return filteredSteps.length > 0 ? { ...subSection, steps: filteredSteps } : null;
          })
          .filter((subSection: any) => subSection !== null);

        return filteredSubSections.length > 0
          ? { ...section, subSections: filteredSubSections }
          : null;
      })
      .filter((section: any) => section !== null);

    setTimeout(() => {
      this.hazardData = this.extractHazards(this.roadmap);
    }, 200);
  }

  /**
   * Closes copy roadmap component
   */
  public close() {
    this.modalController.dismiss();
  }
  /**
   * Extracts hazards
   * 
   * @param data 
   * @returns hazards data
   */
  private extractHazards(data) {
    const result = [];
    let hazards = [];
    if (data) {
      data.forEach((section) => {
        const sectionName = section.sectionName;
        const isCopied = section.isCopied;
        const sectionId = section.sectionId;
        section.subSections?.forEach((subSection) => {
          const subSectionName = subSection.subSectionName;
          subSection.steps?.forEach((step) => {
            const stepBody = step.stepBody;
            
            step.answers?.forEach((answer) => {
              if (answer.hazard && answer.hazardDetails?.length > 0) {    
                hazards = [];           
                answer.hazardDetails.forEach((hazard) => {                 
                  hazards.push({
                    hazardId : hazard.id,
                    hazardName: hazard.name,
                    isChecked: hazard.isChecked
                  });
                });
                result.push({
                  sectionId,
                  isCopied,
                  sectionName,
                  subSectionName,
                  stepBody,
                  hazardDetails : hazards
                });                
              }
            });
          });
        });
      });
    }
    return result;
  }

  /**
   * Updates hazard
   * 
   * @param item 
   * @param event 
   */
  public updateHazard(item: any, event) {
    this.hazardData.map((hazard) => {
      if (hazard.hazardId === item.hazardId && event) {
        hazard.isCopied = true;
      } else {
        hazard.isCopied = false;
      }
    })
    if (event) {
      this.roadmapSections.forEach((section) => {
        const sectionName = section.sectionName;
        const isCopied = section.isCopied;
        const sectionId = section.sectionId;
        section.subSections?.forEach((subSection) => {
          const subSectionName = subSection.subSectionName;
          const subSectionId = subSection.subSectionId;
          subSection.steps?.forEach((step) => {
            const stepBody = step.stepBody;
            const stepId = step.stepId;
            step.answers?.forEach((answer) => {
              if (answer.hazard && answer.hazardDetails?.length > 0) {
                answer.hazardDetails.forEach((hazard) => {
                  const isDuplicate = this.copiedHazardData.find(
                    (existingHazard) => existingHazard.id === hazard.id
                  );
                  if (!isDuplicate && hazard.id === item.hazardId) {
                    this.copiedHazardData.push({
                      ...hazard,
                    });
                  }
                });
              }
            });
          });
        });
      });
    } else {
      this.copiedHazardData = this.copiedHazardData.filter(
        (existingHazard) => existingHazard.id !== item.hazardId
      );
    }
  }

  /**
   * Opens add hazard
   */
  public openAddHazard() {
    this.modalController.dismiss({ dataType: AppConstants.ADD_HAZARD, copiedHazard: '' });
  }

  /**
   * Adds copied hazard
   */
  public addCopiedHazard() {
    const data: any = { dataType: AppConstants.COPY_HAZARD, copiedHazard: this.copiedHazardData }
    this.modalController.dismiss(data);
  }
}
