import { Component } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'progress-bar-renderer',
    styleUrls: ['./progress-bar-renderer.component.scss'],
    templateUrl: './progress-bar-renderer.component.html'
})
export class ProgressBarRendererComponent implements ICellRendererAngularComp {
    public total = 0;
    public value = 0;
    public isCompleted = false;

    initilize(params: ICellRendererParams) {
        this.value = params.getValue();
    }

    // called on init
    agInit(params: any): void {
        this.initilize(params);
    }

    // called when the cell is refreshed
    refresh(params: any): boolean {
        this.initilize(params);
        return true;
    }

}
