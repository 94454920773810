<ion-content class="dashboard-customize">
  <span>
    <div class="close-icon" (click)="closeModal()">
      <img tooltip="{{'Close'  | translate }}" alt="" placement="bottom" src="/assets/icon/cancel.svg">
    </div>
    <div class="title">
      <span> {{ 'Create Machine' | translate }}</span>
    </div>
  </span>
  <form class="form-scroll scrollVertical" #infScroller infiniteScroll [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="1000" [scrollWindow]="false" #formEle #addMachine="ngForm" ngNativeValidate
    autocomplete="off">
    <ion-grid class="inp-container" *ngIf="formData">
      <ion-row>
        <ion-col size="6">
          <div class="each_div">
            <ion-label> {{ 'Machine Name' | translate }} *</ion-label>
            <ion-item lines=none>
              <ion-input placeholder="{{ 'Enter Machine Name' | translate }}" name="machine_name" required
                [(ngModel)]="formData.machine_name" #machine_name="ngModel">
              </ion-input>
            </ion-item>
            <ion-text class="validate-error" *ngIf="machine_name.invalid && machine_name.touched">
              {{ 'Required' | translate}}
            </ion-text>
          </div>
        </ion-col>
        <ion-col size="6">
          <div class="each_div">
            <ion-label> {{ 'Description' | translate }} </ion-label>
            <ion-item lines=none>
              <ion-textarea type="text" name="machine_description" placeholder="{{ 'Machine Description' | translate}}"
                [(ngModel)]="formData.machine_description" #machine_description="ngModel">
              </ion-textarea>
            </ion-item>
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="6" [hidden]="each_service && each_service.page">
          <div class="each_div">
            <ion-label> {{ 'Customer Name' | translate }} *</ion-label>
            <div>
              <ng-select required [loading]="formData.customerLoading" dropdownPosition="bottom"
                class="cust-name-select placeholder-color" [typeahead]="contactInput$" [bindValue]="'customer_name'"
                (change)="customerSearchByErpId($event)" [bindLabel]="'customer_name'" name="customerName"
                [clearable]="true" [items]="contactList |async" [(ngModel)]="formData.customerName"
                #customerName="ngModel" placeholder="{{ 'Search Customer Name' | translate }}">
              </ng-select>
              <input type="hidden" name="customerId" [(ngModel)]="formData.customerId" #customerId="ngModel" />
              <ion-text class="validate-error" *ngIf="customerName.invalid && customerName.touched">
                {{ 'Required' | translate}}
              </ion-text>
            </div>
          </div>
        </ion-col>
        <ion-col size="6" [hidden]="!this.each_service && !this.each_service?.page">
          <div class="each_div">
            <ion-label> {{ 'Customer Name' | translate }} *</ion-label>
            <div>
              <ion-item lines=none>
                <ion-input readonly type="text" name="customerName" placeholder="{{ 'Customer Name' | translate}}"
                  [(ngModel)]="formData.customerName" #customerName="ngModel">
                </ion-input>
              </ion-item>
              <input type="hidden" name="customerId" [(ngModel)]="formData.customerId" #customerId="ngModel" />
              <ion-text class="validate-error" *ngIf="customerName.invalid && customerName.touched">
                {{ 'Required' | translate}}
              </ion-text>
            </div>
          </div>
        </ion-col>
        <ion-col size="6" *ngIf="formData.customerName && formData.industrySegment">
          <div class="each_div">
            <ion-label> {{ 'Industry Code' | translate }}</ion-label>
            <ion-item lines=none>
              <ion-input readonly [disabled]="true" [value]="formData?.industrySegment">
              </ion-input>
            </ion-item>
            <input type="hidden" name="industrySegment" [(ngModel)]="formData.industrySegment"
              #industrySegment="ngModel" />
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="6">
          <div class="each_div">
            <ion-label> {{ 'Application' | translate}} *</ion-label>
            <ng-select placeholder="{{ 'Select Application' | translate}}" class="placeholder-color"
              (change)="setApplication($event)" (scrollToEnd)="fetchMoreApplication()" [virtualScroll]='true'>
              <ng-option *ngFor="let item of applicationList" [value]="item.id">{{item.application}}</ng-option>
            </ng-select>
            <ion-text class="validate-error" *ngIf="isFormSubmitted && !formData.application">
              {{ 'Required' | translate}}
            </ion-text>
          </div>
        </ion-col>
        <ion-col size="6">
          <div class="each_div">
            <ion-label> {{ 'Machine Type' | translate}} *</ion-label>
            <ng-select placeholder="{{ 'Select Machine Type' | translate}}" class="placeholder-color"
              (change)="setMachineType($event)" (scrollToEnd)="fetchMoreMachineType()" [virtualScroll]='true'>
              <ng-option *ngFor="let item of machineTypeList" [value]="item.id">{{item.machine_type}}</ng-option>
            </ng-select>
            <ion-text class="validate-error" *ngIf="isFormSubmitted && !formData.machineType">
              {{ 'Required' | translate}}
            </ion-text>
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="6">
          <div class="each_div">
            <ion-label> {{ 'Specific Feature' | translate}} *</ion-label>
            <ng-select placeholder="{{ 'Select Features' | translate}}"
              class="multi-select multi-select-bg placeholder-color" [multiple]="true" [selectableGroup]="true"
              (change)="setFeature($event)" #feature (add)="removeFeatureFocus($event)" (scrollToEnd)="fetchMoreFeature()" [virtualScroll]='true'>
              <ng-option *ngFor="let item of featureList" [value]="item.id">{{item.specific_feature}}</ng-option>
            </ng-select>
            <ion-text class="validate-error" *ngIf="isFormSubmitted && !formData.features.length">
              {{ 'Required' | translate}}
            </ion-text>
          </div>
        </ion-col>
        <ion-col size="6">
          <div class="each_div">
            <ion-label> {{ 'Control System' | translate}} *</ion-label>
            <ng-select placeholder="{{ 'Select Control System' | translate}}"
              class="multi-select multi-select-bg placeholder-color" [multiple]="true" [selectableGroup]="true"
              (change)="setControlSystem($event)" #controlSystem (add)="removeControlSystemFocus($event)" (scrollToEnd)="fetchMoreControlSystem()" [virtualScroll]='true'>
              <ng-option *ngFor="let item of controlSystemList" [value]="item.id">{{item.control_system}}</ng-option>
            </ng-select>
            <ion-text class="validate-error" *ngIf="isFormSubmitted && !formData.controlSystem.length">
              {{ 'Required' | translate}}
            </ion-text>
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="6">
          <div class="each_div">
            <ion-label> {{ 'Manufacturer' | translate }} </ion-label>
            <ion-item lines=none>
              <ion-input placeholder="{{ 'Enter Manufacturer' | translate }}" name="manufacturer"
                [(ngModel)]="formData.manufacturer" #manufacturer="ngModel">
              </ion-input>
            </ion-item>
          </div>
        </ion-col>
        <ion-col size="6">
          <div class="each_div">
            <ion-label> {{ 'Model Number' | translate }} </ion-label>
            <ion-item lines=none>
              <ion-input placeholder="{{ 'Enter Model' | translate }}" name="model" [(ngModel)]="formData.model"
                #model="ngModel">
              </ion-input>
            </ion-item>
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="6">
          <div class="each_div">
            <ion-label> {{ 'Serial Number' | translate }} </ion-label>
            <ion-item lines=none>
              <ion-input placeholder="{{ 'Enter Serial Number' | translate }}" name="serial_number"
                [(ngModel)]="formData.serial_number" #serial_number="ngModel">
              </ion-input>
            </ion-item>
          </div>
        </ion-col>
        <ion-col size="6">
          <div class="each_div">
            <ion-label> {{ 'Asset Number' | translate }} </ion-label>
            <ion-item lines=none>
              <ion-input placeholder="{{ 'Enter Asset Number' | translate }}" name="asset_id"
                [(ngModel)]="formData.asset_id" #asset_id="ngModel">
              </ion-input>
            </ion-item>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
  <ion-row>
    <ion-col>
      <ion-button class="app-btn save-btn" shape="round" fill="outline" color="primary" (click)="onSubmit(addMachine)">
        {{'Submit'|translate}}
      </ion-button>
    </ion-col>
  </ion-row>
</ion-content>