import { Injectable } from '@angular/core';
import { AbstractSecurityStorage } from 'angular-auth-oidc-client';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthCookieManagerService implements AbstractSecurityStorage {

  constructor(private cookieService: CookieService) { }

  read(key: string) {
    const item = this.cookieService.get(key);
    if (!!item) {return JSON.parse(item);}
    else {return null;}
  }

  write(key: string, value: any, isSiletTokenRenew = false) {
    value = value || null;
    const cookieExpireInfo = this.read(key);
    let cookieExpiryTime: any;
    if (cookieExpireInfo && !isSiletTokenRenew) {
      const date = new Date(cookieExpireInfo.cookie_expiry_time);
      cookieExpiryTime = new Date(date.getTime());
    } else {
      const date = new Date();
      cookieExpiryTime = new Date(date.getTime() + value.expires_in * 1000);
    }
    const cookieInfo = {
      access_token: value.access_token,
      cookie_expiry_time: cookieExpiryTime
    };
    this.cookieService.set(`${key}`, JSON.stringify(cookieInfo), cookieExpiryTime, undefined, undefined, true, 'Strict');
    return true;
  }

  remove(key): void {
    this.cookieService.delete(key);
  }

  clear(): void {
    this.cookieService.deleteAll();
  }

}
