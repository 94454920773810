import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AlertModalPage } from 'src/app/components/alert-modal/alert-modal.page';
import { SearchModalComponent } from 'src/app/components/search-modal/search-modal.component';
import { AuthCookieManagerService } from 'src/app/services/user-auth/auth-cookie-manager/auth-cookie-manager.service';
import { BackendCallService } from 'src/app/services/backend-call/backend-call.service';
import { LanguageSupportService } from 'src/app/services/language-support.service';
import { LocalSettingsService } from 'src/app/services/local-settings/local-settings.service';
import { ShareValuesService } from 'src/app/services/sharedValues/share-values.service';
import { UserAuthService } from 'src/app/services/user-auth/user-auth.service';
import {
  isNotEmptyArray
} from 'src/app/utilities/utils';
import { MachineCreationFormComponent } from '../machine-creation-form/machine-creation-form.component';
import { AppConstants, urlPathConstants } from 'src/app/utilities/constant';
import { NavParams } from '@ionic/angular';
import { ToastService } from 'src/app/services/toast.service';
@Component({
  selector: 'app-status-popover',
  templateUrl: './status-popover.component.html',
  styleUrls: ['./status-popover.component.scss'],
})
export class StatusPopoverComponent implements OnInit {
  @Input() type: string;
  @Input() projectStatus: string;
  @Input() isFromSAP: boolean;
  @Input() viewServiceDetails: any;
  @Input() offerDetail: any;
  @Input() orderId: string;
  @Input() productId: string;
  @Input() projectName: string;
  @Input() projectId: string;
  @Input() erp_Project_Id: string;
  @Input() company_code: string;
  @Input() offer_id: any;
  @Input() each_service: any;
  @Input() serviceId: any;
  @Input() isFourEyeRequested = false;
  @Input() is_RA_Status = false;
  @Input() isFourEyeQualityPerform = false;
  @Input() isCreateBucketPermission = false;
  @Input() isUpdateBucketPermission = false;
  @Input() isDeleteBucketPermission = false;
  @Input() generateProjectDisable: boolean;
  @Input() roadmapHistory: any;
  shouldDisableCreateOffer = false;

  @Input() popoverMenu: any;
  @Input() isEnable: boolean;
  @Output() selectAction: EventEmitter<string> = new EventEmitter<string>();
  selectedUsers: any = [];
  projectLists = [];
  msg: any;
  selectedLanguage: any;
  shouldDisableEditProjectButton = false;
  shouldDisableDeleteProjectButton = false;
  userInfo: any;
  isDisableEditOfferButton = false;
  isOfferWritePermission: boolean;
  isProjectWritePermission: any;
  isRoleDeletePermission: any;
  isRoleWritePermission: boolean;
  isGroupWritePermission: any;
  isGroupDeletePermission: any;
  isFourEyeQualityCheckPermission: any;
  isEditProjectCheckPermission: boolean;
  isEditOfferCheckPermission: any;
  existingMachine: string[] = [];
  isLanguagePopoverOpen = false;
  languages = {
    en: 'English',
    sp: 'Spanish',
    de: 'German',
    pt: 'Portuguese'
  };

  constructor(
    public modalController: ModalController,
    public popoverController: PopoverController,
    public router: Router,
    public backendService: BackendCallService,
    public sharedValue: ShareValuesService,
    public translateService: TranslateService,
    private tModule: TranslateModule, private serviceMulti: LanguageSupportService,
    public authCookieManagerService: AuthCookieManagerService,
    public userAuthService: UserAuthService,
    public localStorageService: LocalSettingsService,
    public alertController: AlertController, public translate: TranslateService,
    private navParams: NavParams,
    private toastService: ToastService
  ) { this.selectedLanguage = this.serviceMulti.getDefaultLanguage(); }

  ngOnInit() {
    const props = this.navParams.data;
    if (props.hasOwnProperty('exisitingMachine')) {
      this.existingMachine = props.exisitingMachine;
    }
  }

  ionViewWillEnter() {
    this.userInfo = this.userAuthService.getUserList();
    const { permissions } = this.userInfo;
    this.isOfferWritePermission = (permissions.includes('Offer.Manage') || permissions.includes('Offer.Write'));
    this.isProjectWritePermission = (permissions.includes('Project.Manage') || permissions.includes('Project.Write'));
    this.isRoleWritePermission = (permissions.includes('URAssociation.Manage') || permissions.includes('URAssociation.Write'));
    this.isRoleDeletePermission = (permissions.includes('Role.Manage') || permissions.includes('Role.Delete'));
    this.isGroupWritePermission = (permissions.includes('UGAssociation.Manage') || permissions.includes('UGAssociation.Write'));
    this.isGroupDeletePermission = (permissions.includes('Group.Manage') || permissions.includes('Group.Delete'));
    this.isFourEyeQualityCheckPermission = (permissions.includes('FourEyesQuality.Manage'));
    this.isEditProjectCheckPermission = (permissions.includes('Project.Edit'));
    this.isEditOfferCheckPermission = (permissions.includes('Offer.Edit'));
    if (this.projectStatus == 'Initiated') {this.shouldDisableCreateOffer = true;}
    else if (this.projectStatus == 'yet to start') {this.shouldDisableDeleteProjectButton = true;}
    else if (this.projectStatus == 'block' || this.projectStatus == 'blocked') {this.shouldDisableEditProjectButton = true;}
    if (!this.isProjectWritePermission) {this.shouldDisableEditProjectButton = true;}
    if (!this.isOfferWritePermission) {this.shouldDisableCreateOffer = true;}
    if (isNotEmptyArray(permissions)) {
      if (this.type == 'viewOfferMore') {
        this.isDisableEditOfferButton = (permissions.includes('Offer.Manage') || permissions.includes('Offer.Write'));
      }
    }
    // this.getProjects();
  }

  /**
   * Selects actionn
   *
   * @param type type
   */
  public selectActionn(type) {
    this.selectAction.emit(type);
    switch (type) {
      case 'assign':
      case 'edit':
      case 'delete':
        this.openSearchModal(type);
        break;

      case 'add-new-machine':
      case 'add-from-library':
      case 'add-from-previous-project':
        this.addMachine(type);
        break;

      case 'editProj':
      case 'deleteProj':
      case 'blockProj':
      case 'closeProj':
      case 'releaseProj':
        this.closeBox(type);
        break;

      case 'edit-Offer':
      case 'deleteOffer':
        this.closeBox(type);
        break;

      case 'Review Offer':
      case 'Hold Offer':
      case 'Edit Offer':
      case 'Assign To':
        this.closeBox(type);
        break;

      default:
        this.popoverController.dismiss(type);
        break;
    }
  }

  selectType(type) {
    if(type == 'addSalseOrg' || 'deleteUser'){
      this.popoverController.dismiss(type);
    }
    type == 'assign' || type == 'edit' || type == 'delete'
      ? this.openSearchModal(type)
      : type == 'add-new-machine' || type == 'add-from-library' || type == 'add-from-previous-project'
        ? this.addMachine(type)
        : this.popoverController.dismiss(type);
  }

  selectOption(type) {
    type == 'editProj' || type == 'deleteProj' || type == 'blockProj' || type == 'closeProj' || type == 'releaseProj'
      ? this.closeBox(type) :
      this.popoverController.dismiss(type);
  }

  selectOffer(type) {
    type == 'edit-Offer' || type == 'deleteOffer'
      ? this.closeBox(type) :
      this.popoverController.dismiss(type);
  }

  selectOfferType(type) {
    type == 'Review Offer' || type == 'Hold Offer' || type == 'Edit Offer' || type == 'Assign To'
      ? this.closeBox(type) :
      this.popoverController.dismiss(type);
  }

  viewReport(type) {
    if (type == 'Generate Project Report') {
      const navigationExtras: NavigationExtras = {
        queryParamsHandling: 'merge',
        queryParams: {
          productId: this.productId,
          orderId: this.orderId,
          projectId: this.projectId,
          erpProjectId: this.erp_Project_Id,
          company_code: this.company_code,
          projectName: this.projectName,
          service_id: this.serviceId
        }
      };
      this.popoverController.dismiss(type);
      this.router.navigate(['g-ra-report'], navigationExtras);
      // this.router.navigate(
      //   ['generate-report'],
      // );
    } else if (type == 'Export To Excel') {
      const navigationExtras: NavigationExtras = {
        queryParamsHandling: 'merge',
        queryParams: {
          productId: this.productId,
          erpProjectId: this.erp_Project_Id,
          company_code: this.company_code,
          project_id: this.projectId,
        }
      };
      this.popoverController.dismiss(type);
      this.router.navigate(['export-ra-report-xl'], navigationExtras);
    }
    else if (type == 'delete-offer') {
      this.popoverController.dismiss(type);
    } else if (type == 'View RA Reports') {
      this.popoverController.dismiss(type);
      const navigationExtras: NavigationExtras = {
        queryParamsHandling: 'merge',
        queryParams: {
          projectId: this.projectId,
        }
      };
      this.router.navigate(['view-ra-reports'], navigationExtras);
    }
  }

  viewOfferPopOver(type) {
    if (type == 'edit-offer') {
      this.router.navigate(
        ['offer-management/create-offer'],
      );
    } else if (type == 'delete-offer') {
      this.offerDelete();
    }
  }

  async listOfServiceMore(type) {
    if (type == 'edit-service') {
      this.popoverController.dismiss(type);
    } else if (type == 'delete-service') {
      if (this.each_service?.pageName == 'createOffer' || this.each_service?.pageName == 'ProjectAction') {
        this.sharedValue.listOfServices.forEach((item, index, object) => {
          if (item.id === this.each_service.id) {
            object.splice(index, 1);
          }
        });
        this.toastService.presentToast('Service is deleted successfully', 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
        this.popoverController.dismiss(type);
      } else {
        const props = {
          deleteService: true,
        };
        const msg = AppConstants.ARE_YOU_SURE_TO_DELETE;
        props['alertContent'] = msg;
        const modal = await this.modalController.create({
          component: AlertModalPage,
          cssClass: 'small-alert-modal',
          backdropDismiss: false,
          showBackdrop: false,
          componentProps: props,
        });
        await modal.present();
        const {
          data: { isOfferCreationConfirmClose },
        } = await modal.onWillDismiss();
        if (isOfferCreationConfirmClose) {
          this.popoverController.dismiss(type);
          this.deleteService();
        }
      }
    } else if (type == 'In-Active') {
    }
  }

  /**
   * Opens user guide
   */
  openUserGuide() {
    window.open(urlPathConstants.userVideoGuide, '_blank');
  }

  deleteService() {
    let value = 'no_service_id';
    if (this.each_service?.pageName == 'viewOpp' && this.each_service.hasOwnProperty('serviceId')) {
      value = this.each_service.serviceId;
    } else if (this.viewServiceDetails?.pageName == 'viewOffer' && this.each_service.hasOwnProperty('service_id')) {
      value = this.each_service.service_id;
    }
    const payload = [value];
    if (value !== 'no_service_id') {
      this.backendService.deleteOfferService(payload).subscribe(
        (data: any) => {
          this.popoverController.dismiss(this.viewServiceDetails);
          if (data.value == 'Failed to delete') {
            this.toastService.presentToast(data.value, 4000, AppConstants.ERROR_SNACKBAR, 'top');
          } else {
            this.toastService.presentToast(data.value, 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
          }

          this.router.navigate(['offer-management']);
        }, err => {
          this.toastService.presentToast('No Results Found', 4000, AppConstants.ERROR_SNACKBAR, 'top');
        }
      );
    } else {
      this.sharedValue.listOfServices.forEach((item, index, object) => {
        if (item.id === this.each_service.id) {//newService:true
          object.splice(index, 1);
        }
      });
      this.toastService.presentToast('Service is deleted successfully', 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
      this.popoverController.dismiss(this.viewServiceDetails);
    }
  }
  async addMachine(type) {
    sessionStorage.removeItem('each_service');
    const navigationExtras: NavigationExtras = {
      queryParamsHandling: 'preserve',
      queryParams: {
        project_Id: this.each_service.project_id,
        order_Id: this.each_service.selectOrder,
        service_Id: this.each_service.serviceType,
        customerId: this.each_service?.customerId
      }
    };
    sessionStorage.setItem('each_service', JSON.stringify(this.each_service));

    if (type == 'add-new-machine') {
      // WILL THIS ONCE SIRBULAND DEMO DONE
      // this.router.navigate(['view-offer/view-offer-add-machine'], navigationExtras);

      const modal = await this.modalController.create({
        component: MachineCreationFormComponent,
        cssClass: 'mango-dashboard-customization-modal',
        backdropDismiss: false,
        showBackdrop: false,
      });
      await modal.present();
      await modal.onDidDismiss().then((result) => {
      });
      this.popoverController.dismiss();
    } else if (type == 'add-from-library') {
      this.router.navigate(['view-offer/view-offer-add-machine-from-libary'], navigationExtras);
      this.popoverController.dismiss();
    } else if (type == 'add-from-previous-project') {
      if (this.each_service.pageName == 'viewOffer') {
        this.router.navigate(['view-offer/view-offer-machine-from-previous-project'], navigationExtras);
      } else {
        this.router.navigate(['offer-management/add-from-previous-project'], navigationExtras);
      }

      this.popoverController.dismiss();
    }
    // if (type == 'edit-offer' || type == 'delete-offer') {
    //   this.router.navigate(['view-offer/create-offer']);
    //   this.popoverController.dismiss(type);
    // } else {
    //   let navigationExtras: NavigationExtras = {
    //     state: {
    //       each_service: this.each_service,
    //     },
    //   };
    //   this.router.navigate(['offer-management/add-machine'], navigationExtras);
    //   this.popoverController.dismiss();
    // }
  }

  async openSearchModal(type) {
    const props = { type };
    const modal = await this.modalController.create({
      component: SearchModalComponent,
      cssClass: 'my-custom-class',
      backdropDismiss: true,
      componentProps: props,
      showBackdrop: false,
    });
    await modal.present();
    await modal.onDidDismiss().then((result) => {
      result.data.selectedUsers
        ? (this.selectedUsers = result.data.selectedUsers)
        : (this.selectedUsers = []);
      this.popoverController.dismiss(this.selectedUsers);
    });
  }

  myMachinesSegmentAddBucketButton(type) {
    this.popoverController.dismiss(type);
  }

  async closeBox(type) {
    const props = { type };
    if (type == 'editProj') {
      this.msg = AppConstants.ARE_YOU_SURE_TO_EDIT_PROJECT;
    }
    else if (type == 'deleteProj') {
      this.msg = AppConstants.ARE_YOU_SURE_TO_DELETE_PROJECT;
    }
    else if (type == 'blockProj') {
      this.msg = AppConstants.ARE_YOU_SURE_TO_BLOCK_PROJECT;
    }
    else if (type == 'releaseProj') {
      this.msg = AppConstants.ARE_YOU_SURE_TO_RELEASE_PROJECT;
    }
    else if (type == 'closeProj') {
      this.msg = AppConstants.ARE_YOU_SURE_TO_CLOSE_PROJECT;
    }
    else if (type == 'deleteOffer') {
      this.msg = AppConstants.ARE_YOU_SURE_TO_DELETE_OFFER;
    }
    else if (type == 'edit-Offer') {
      this.msg = AppConstants.ARE_YOU_SURE_TO_EDIT_OFFER;
    }
    else if (type == 'Edit Offer') {
      this.msg = AppConstants.ARE_YOU_SURE_TO_EDIT_OFFER;
    }
    else if (type == 'Review Offer') {
      this.msg = AppConstants.ARE_YOU_SURE_TO_VIEW_OFFER;
    }
    else if (type == 'Assign To') {
      this.msg = AppConstants.ARE_YOU_SURE_TO_ASSIGN_OFFER;
    }
    else if (type == 'delete-service') {
      this.msg = AppConstants.ARE_YOU_SURE_TO_DELETE_SERVICE;
    }

    this.translate.get(this.msg).subscribe(res => {
      this.msg = res;
    });

    props['alertContent'] = this.msg;
    const modal = await this.modalController.create({
      component: AlertModalPage,
      cssClass: 'small-alert-modal',
      backdropDismiss: false,
      showBackdrop: false,
      componentProps: props,
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    let info;
    if (data && data.isConfirmed) {info = type;}
    this.popoverController.dismiss(info);
  }

  async offerDelete() {
    const props = {
      isViewOfferDelete: true
    };
    const msg = AppConstants.ARE_YOU_SURE_TO_DELETE_OFFER;
    props['alertContent'] = msg;
    const modal = await this.modalController.create({
      component: AlertModalPage,
      cssClass: 'small-alert-modal',
      backdropDismiss: false,
      showBackdrop: false,
      componentProps: props,
    });
    await modal.present();
    const {
      data: { isOfferCreationConfirmClose },
    } = await modal.onWillDismiss();
    if (isOfferCreationConfirmClose)
      {this.popoverController.dismiss();}
    this.offerDel();
  }


  offerDel() {
    const payload = this.offer_id;
    this.backendService.deleteOffer(payload).then(
      (data: any) => {
        if (data.value == 'Deleted Successfully') {
          this.popoverController.dismiss();
          this.toastService.presentToast(data.value, 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
          this.router.navigate(['offer-management']);
        }
        else {
          this.toastService.presentToast('Something went Wrong', 4000, AppConstants.ERROR_SNACKBAR, 'top');
        }
      },
      (err) => {

      }
    );
  }

  redirectOffer() {
    this.popoverController.dismiss({ isCreateOfferSeleted: true });
  }

  /**
   * To create service via project-actions on project details page
   */
  public redirectService() {
    this.popoverController.dismiss({ isCreateServiceSelected: true });
  }

  redirectProjectHistory() {
    this.popoverController.dismiss({ isProjectHistorySeleted: true });
  }

  languageChange() {
    this.serviceMulti.setLanguage(this.selectedLanguage);
    this.popoverController.dismiss('openChangeLan');
  }

  openLanguageSelect(event: Event) {
    this.isLanguagePopoverOpen = true;
    const languageSelect = document.querySelector('ion-select');
    if (languageSelect) {
      languageSelect.open();
    }
  }
  

  logOut() {
    // let cookieInfo = this.authCookieManagerService.read(authCookieKey);
    // if (cookieInfo) this.authCookieManagerService.remove(authCookieKey);
    this.userAuthService.logOut();
  }

  async logOutModalConfirmation() {
    const props = { alertContent: AppConstants.ARE_YOU_SURE_TO_LOGOUT };
    const modal = await this.modalController.create({
      component: AlertModalPage,
      cssClass: 'small-alert-modal',
      backdropDismiss: false,
      showBackdrop: false,
      componentProps: props,
    });
    await modal.present();
    const { data: { isConfirmed } } = await modal.onWillDismiss();
    if (isConfirmed) {
      this.popoverController.dismiss();
      this.logOut();
    }
  }

  onOptionClick(type){

  }

  public changeRoadmapVersion(event) {
    this.popoverController.dismiss(event);
  }

  public goToUserMgmt() {
    if(this.userInfo?.roles.includes('Global Admin')) {
      this.popoverController.dismiss();
      this.router.navigate(['user-management']);
    }
  }

  public getInitials(name: string): string {
    const initials = name?.split(' ').map(n => n[0])?.join('');
    return initials?.toUpperCase();
  }
}
