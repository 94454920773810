import { EventEmitter, Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { BackendCallService } from 'src/app/services/backend-call/backend-call.service';
import { environment } from 'src/environments/environment';
import { ShareValuesService } from '../../services/sharedValues/share-values.service';
import { UserAuthService } from '../../services/user-auth/user-auth.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationServiceService {
  result: any;
  results = [];
  notifyMsg: any;
  responeMsg: any;
  openResult: any;
  onMessageReceived = new EventEmitter<any>();

  constructor(public sharedValue: ShareValuesService, private userAuthService: UserAuthService, public backendService: BackendCallService,) {
    if (this.userAuthService.checkMsalAuth()) {
      this.createConnection();
    }
  }

  public async createConnection() {
    const token = this.userAuthService.getAccessToken();
    const connection = new HubConnectionBuilder()
      .withUrl(`${environment.tokenEndpoint}` + '/hub/notificationhub',
        {
          accessTokenFactory: () => token
          , transport: signalR.HttpTransportType.WebSockets
        })
      .configureLogging(LogLevel.Debug)
      .build();
    try {
      await connection.start();
      console.log('connected');
      connection.on('ClientNotification', (data: any) => {
        console.log('SignalR Message :' + data);
        this.results.push({
          notification: data,
        });
        localStorage.setItem('notificationvalue', JSON.stringify(this.results));
        this.results.forEach((each) => {
          this.sharedValue.finalNotification.push(each);
        });
        const arrayWithDuplicates = this.sharedValue.finalNotification;
        const uniqueArray = arrayWithDuplicates.reduce((unique: any[], current: any) => {
          const isDuplicate = unique.some(item => item.notification === current.notification);
          if (!isDuplicate) {
            unique.push(current);
          }
          return unique;
        }, []);
        this.sharedValue.finalNotification = uniqueArray;
        //JSON.parse(localStorage.getItem('notificationvalue'));
        document.dispatchEvent(new CustomEvent('notifications', { detail: this.results }));
        console.log('notifications', this.sharedValue.finalNotification.reverse());
      });
    } catch (err) {
      // setTimeout(() => this.createConnection(), 5000);
    }
  }
}
