import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import { Froala_Constants } from './app/utilities/constant';

declare var FroalaEditor: any;
FroalaEditor.DEFAULTS.key = Froala_Constants.LICENSE_KEY;

if (environment.production) {
  enableProdMode();
  console.log = (...arg) => { };
}
AOS.init(); // Initialize AOS

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
