import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ProjectDetailPage } from 'src/app/pages/project-management/project-detail/project-detail.page';
import { MenuService } from 'src/app/services/menu.service';
import { UserAuthService } from 'src/app/services/user-auth/user-auth.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  public menuList = [];
  public isCollapsed = true;
  public showMenuText = true;

  constructor(public userAuth: UserAuthService,
    private menuService: MenuService,
    public prjDetail: ProjectDetailPage,
    private router: Router) { }

  ngOnInit() {
    const userData = this.userAuth.getUserList();
    this.menuService.getMenu(userData.userId).subscribe(
      menu => { this.menuList = menu; },
      error => { console.error('Error fetching menu items:', error); });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Collapse the menu after route change
        this.isCollapsed = true;
        this.showMenuText = true;
      }
    });
  }

  /**
   * Toggles menu
   */
  public toggleMenu() {
    this.isCollapsed = !this.isCollapsed;
    if (!this.isCollapsed) {
      this.showMenuText = false;
    } else {
      this.showMenuText = true;
    }
  }

  /**
   * Clears up
   */
  public clearUp() {
    this.prjDetail.cleanUpSegment();
  }
}
