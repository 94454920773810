import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HeaderComponent } from './header/header.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { StatusPopoverComponent } from './status-popover/status-popover.component';
import { SearchModalComponent } from './search-modal/search-modal.component';
import { AlertModalPage } from './alert-modal/alert-modal.page';
import { AddRemoveColumnSelectorComponent } from './add-remove-column-selector/add-remove-column-selector.component';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { NotificationComponent } from './notification/notification.component';
import { AddMachineToBucketComponent } from './add-machine-to-bucket/add-machine-to-bucket.component';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FilterMoodalComponent } from './filter-moodal/filter-moodal.component';
import { UploadMachineDocComponent } from './upload-machine-doc/upload-machine-doc.component';
import { NgPipesModule } from 'ngx-pipes';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
// import {AngularFileDragDropModule} from '../../projects/file-drag-drop/src/lib/angular-file-drag-drop.module';
import { AddCustomRoadmapsComponent } from './add-custom-roadmaps/add-custom-roadmaps.component';
import { ProjectAccessComponent } from './project-access/project-access.component';
import { ProjectrequestComponent } from './projectrequest/projectrequest.component';
import { RiskassessmentAccordionComponent } from './riskassessment-accordion/riskassessment-accordion.component';
import { AddCustomSectionsComponent } from './add-custom-sections/add-custom-sections.component';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { PickHazardComponent } from './pick-hazard/pick-hazard.component';
import { PlrScreenComponent } from './plr-screen/plr-screen.component';
import { SearchFilterPipe } from '../components/pick-hazard/pick-hazard.component';
import { ViewOpportunitiesComponent } from '../components/view-opportunities/view-opportunities.component';
import { AddPredefinedRoadmapsComponent } from './add-predefined-roadmaps/add-predefined-roadmaps.component';
import { RiskassessmentAccorViewComponent } from './riskassessment-accor-view/riskassessment-accor-view.component';
import { ServiceRiskassessmentAccordionComponent } from './service-riskassessment-accordion/service-riskassessment-accordion.component';
import { AddRoadmapToMachineComponent } from './add-roadmap-to-machine/add-roadmap-to-machine.component';
// import { NgPipesModule } from 'ngx-pipes';
import { OutsideClickDirective } from '../directives/outside-click.directive';
import { RiskRessessmentMachineListComponent } from './risk-ressessment-machine-list/risk-ressessment-machine-list.component';
import { AddMachineAttributesComponent } from './add-machine-attributes/add-machine-attributes.component';
import { MachineCreationFormComponent } from './machine-creation-form/machine-creation-form.component';
import { SearchbarComponent } from './searchbar/searchbar.component';
import { MachineCardComponent } from './machine-card/machine-card.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { EditAddOnsComponent } from './edit-add-ons/edit-add-ons.component';
import { ExitButtonComponent } from './exit-button/exit-button.component';
import { DirectivesModule } from '../directives/directive.module';
import { ChartsComponent } from './charts/charts.component';
import { NgxEchartsDirective, NgxEchartsModule } from 'ngx-echarts';
import { StepperComponent } from './stepper/stepper.component';
import { MetricsCardsComponent } from './metrics-cards/metrics-cards.component';
import { MachineFilterComponent } from './machine-filter/machine-filter.component';
import { RAStaticReportConfirmationComponent } from './ra-static-report-confirmation/ra-static-report-confirmation.component';
import { SearchWithMultiSelectCheckboxComponent } from './search-with-multi-select-checkbox/search-with-multi-select-checkbox.component';
import { ImageGalleryComponent } from './image-gallery/image-gallery.component';
import { EllipsisPipe } from '../pipes/EllipsisPipe';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { ViewScreenComponent } from './view-screen/view-screen.component';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { MultiselectSearchComponent } from './multiselect-search/multiselect-search.component';
import { EditorComponent } from './editor/editor.component';
import { MachineListComponent } from './machine-list/machine-list.component';
import { AccordionComponent } from './accordion/accordion.component';
import { SectionListComponent } from './section-list/section-list.component';
import { SectionViewComponent } from './section-view/section-view.component';
import { ProjectDetailPage } from '../pages/project-management/project-detail/project-detail.page';
import { RoadmapViewCreateComponent } from '../pages/project-management/machine-details/roadmap-view-create/roadmap-view-create.component';
import { AddHazardDetailsPage } from '../pages/project-management/machine-details/add-hazard-details/add-hazard-details.page';
import { RoadmapHistoryComponent } from './roadmap-history/roadmap-history.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { AddHazardPage } from '../pages/project-management/machine-details/add-hazard/add-hazard.page';
import "froala-editor/js/plugins.pkgd.min.js";
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { FroalaEditorComponent } from './froala-editor/froala-editor.component';
@NgModule({
  declarations: [
    SideNavComponent,
    HeaderComponent,
    StatusPopoverComponent,
    AddRoadmapToMachineComponent,
    AlertModalPage,
    SearchModalComponent,
    AddRemoveColumnSelectorComponent,
    NotificationComponent,
    AddMachineToBucketComponent,
    UploadFileComponent,
    OutsideClickDirective,
    ServiceRiskassessmentAccordionComponent,
    FilterMoodalComponent,
    RiskassessmentAccordionComponent,
    UploadMachineDocComponent,
    AddCustomRoadmapsComponent,
    ProjectAccessComponent,
    ProjectrequestComponent,
    AddCustomSectionsComponent,
    AddMachineAttributesComponent,
    PickHazardComponent,
    PlrScreenComponent,
    SearchFilterPipe,
    RiskassessmentAccorViewComponent,
    ViewOpportunitiesComponent,
    RiskRessessmentMachineListComponent,
    AddPredefinedRoadmapsComponent,
    MachineCreationFormComponent,
    SearchbarComponent,
    MachineCardComponent,
    ExitButtonComponent,
    ChartsComponent,
    StepperComponent,
    MetricsCardsComponent,
    ConfirmationModalComponent,
    EditAddOnsComponent,
    MachineFilterComponent,
    RAStaticReportConfirmationComponent,
    ImageGalleryComponent,
    SearchWithMultiSelectCheckboxComponent,
    EllipsisPipe,
    DynamicFormComponent,
    ViewScreenComponent,
    MultiselectSearchComponent,
    EditorComponent,
    MachineListComponent,
    AccordionComponent,
    SectionListComponent,
    SectionViewComponent,
    RoadmapViewCreateComponent,
    AddHazardDetailsPage,
    RoadmapHistoryComponent,
    FileUploadComponent,
    FileUploadComponent,
    AddHazardPage,
    FroalaEditorComponent,
  ],
  exports: [SideNavComponent,
    ChartsComponent,
    RiskRessessmentMachineListComponent,
    ServiceRiskassessmentAccordionComponent,
    HeaderComponent,
    RiskassessmentAccorViewComponent,
    OutsideClickDirective,
    RiskassessmentAccordionComponent,
    ViewOpportunitiesComponent,
    SearchbarComponent,
    MachineCardComponent,
    ExitButtonComponent,
    ConfirmationModalComponent,
    DirectivesModule,
    StepperComponent,
    MetricsCardsComponent,
    MachineFilterComponent,
    ImageGalleryComponent,
    SearchWithMultiSelectCheckboxComponent,
    EllipsisPipe,
    DynamicFormComponent,
    ViewScreenComponent,
    MultiselectSearchComponent,
    EditorComponent,
    MachineListComponent,
    AccordionComponent,
    SectionListComponent,
    SectionViewComponent,
    RoadmapViewCreateComponent,
    AddHazardDetailsPage,
    RoadmapHistoryComponent,
    FileUploadComponent,
    UploadMachineDocComponent,
    AddHazardPage,
    FroalaEditorComponent,
  ],
  imports: [
    DirectivesModule,
    FormsModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    FormlyModule.forRoot(
      {
        validators: [
          { name: 'required', validation: Validators.required }
        ],
        validationMessages: [
          { name: 'required', message: 'This field is required' }
        ],
      }
    ),
    FormlyBootstrapModule,
    CommonModule, RouterModule, IonicModule,
    NgSelectModule, NgPipesModule, TranslateModule,
    InfiniteScrollModule, ReactiveFormsModule,
    DragDropModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    ProjectDetailPage
  ]
})
export class componentModule { }
export function httpTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
