import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { BackendCallService } from 'src/app/services/backend-call/backend-call.service';
import { ShareValuesService } from 'src/app/services/sharedValues/share-values.service';

@Component({
  selector: 'app-add-custom-roadmaps',
  templateUrl: './add-custom-roadmaps.component.html',
  styleUrls: ['./add-custom-roadmaps.component.scss'],
})
export class AddCustomRoadmapsComponent implements OnInit {
  // customRoadmapName:string;
  newMachinesList: any=[{}];
  @Input() configureLevel: string;
  constructor(public modalController: ModalController, public backendService: BackendCallService, public sharedValue: ShareValuesService,) {
  }

  ngOnInit() {
   }

  close() {
    this.modalController.dismiss();
  }
  addNew(roadMapInp: HTMLElement){
    this.newMachinesList.push({});
    setTimeout(() => {
      // objDiv.scrollIntoView({behavior:'smooth'});
      roadMapInp.scrollTop = roadMapInp.scrollHeight;
    }, 100);
  }
  submit(form: NgForm) {
    if(form.invalid){
      form.form.markAllAsTouched();
      return;
    }
    if(this.configureLevel=='machine level'){
      const roadmapEnteredLists = [];
      const valueAdded = this.newMachinesList.find(_nml => _nml.customRoadmap);
      if (valueAdded && valueAdded.customRoadmap) {
        this.newMachinesList.forEach(_nml => {
          roadmapEnteredLists.push(_nml.customRoadmap);
        });
      }
      this.modalController.dismiss(roadmapEnteredLists);
    }else if(this.configureLevel=='service level'){
      const roadmapEnteredLists = [];
      const valueAdded = this.newMachinesList.find(_nml => _nml.customRoadmap);
      if (valueAdded && valueAdded.customRoadmap) {
        this.newMachinesList.forEach(_nml => {
          roadmapEnteredLists.push(_nml.customRoadmap);
        });
      }
      this.modalController.dismiss(roadmapEnteredLists);
    }
  }
}
