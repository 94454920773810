import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiUrls } from '../utilities/api-urls';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  constructor(private http: HttpClient) {}

  logError(payload: {
    errorMessage: string;
    stackTrace: string;
    controllerName: string;
    actionName: string;
    url: string;
    request: number;
    response: string;
    userId: string;
  }): Observable<any> {
    return this.http.post(`${ApiUrls.logError}`, payload);
  }
}
