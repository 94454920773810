import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { AppConstants, IconUrls } from '../utilities/constant';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastController: ToastController) { }

  /**
   * Presents toast
   *
   * @param message
   * @param [duration]
   * @param type
   * @param [position]
   */
  async presentToast(message: string, duration: number = 200000, type: string, position: 'top' | 'bottom' | 'middle' = 'top') {
    let snackbarIcon: string;
    let closeIcon: string;
    let cssClas: string;
    switch (type) {
      case AppConstants.SUCCESS_SNACKBAR:
        snackbarIcon = IconUrls.SUCCESS_ICON;
        closeIcon = IconUrls.SUCCESS_CLOSE;
        cssClas = 'success-toast';
        break;
      case AppConstants.WARNING_SNACKBAR:
        snackbarIcon = IconUrls.WARNING_ICON;
        closeIcon = IconUrls.WARNING_CLOSE;
        cssClas = 'warning-toast';
        break;
      case AppConstants.ERROR_SNACKBAR:
        snackbarIcon = IconUrls.ERROR_ICON;
        closeIcon = IconUrls.ERROR_CLOSE;
        cssClas = 'error-toast';
        break;
      default:
        break;
    }
    const toast = await this.toastController.create({
      icon: snackbarIcon,
      message,
      duration,
      position,
      cssClass: 'custom-toast ' + `${cssClas}`,
      buttons: [
        {
          icon: closeIcon,
          role: 'cancel',
        },
      ]
    });
    toast.present();
  }
}
