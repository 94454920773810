import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { OfferDetailsComponent } from 'src/app/pages/offer-management/create-offer/offer-details/offer-details.component';
import { EventProvider } from 'src/app/services/event';
import { ObservationEvents } from 'src/app/utilities/constant';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnInit{
  @Input() steps: any[] = [];
  @Output() stepDetail = new EventEmitter<string>();
  @Output() steper = new EventEmitter<string>();
  @Output() navBack = new EventEmitter<string>();
  @Input() currentStep: string;
  @Input() isToggleAllowed: boolean = true;
  @ViewChild('offerDetailsRef') offerDetailsCmpnt: OfferDetailsComponent;
  @Input() isStepComplited = false;
  @Input() isVertical = false;
  private isValidToToggle = false;
  constructor(public eventProvider: EventProvider) {}

  ngOnInit() {}

  ngOnChanges() {
    this.eventProvider
      .event(ObservationEvents.STEPPER_TYPE)
      .subscribe((res) => {
        this.steps.forEach((step) => {
          setTimeout(() => {
            step.isActive = step.key === this.currentStep || step.title === this.currentStep;
          }, 100);
        });
      });
  }

  /**
   * Toggles step
   *
   * @param index index
   * @param stepData stepData
   */
  public toggleStep(index: number, stepData: string) {
    if (this.isToggleAllowed) {
      this.eventProvider
        .event(ObservationEvents.OFFER_DETAIL_FORM_VALIDITY)
        .subscribe((isValid) => {
          this.isValidToToggle = isValid;
        });
      this.stepDetail.emit(stepData);
      this.steps.forEach((step, i) => {
        if (this.isValidToToggle) {
          step.isActive = i === index ? true : false;
        }
      });
    }
  }
}
