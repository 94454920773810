import { Component, ChangeDetectorRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-roadmap-history',
  templateUrl: './roadmap-history.component.html',
  styleUrls: ['./roadmap-history.component.scss'],
})
export class RoadmapHistoryComponent implements OnInit {

  @Input() historyData: any;
  @Output() exitEvent = new EventEmitter<boolean>();
  public activeVersionIndex: number = 0;
  public versionDetails: any;
  public versionDetail: any
  selectedVersion: string | null = null;
  selectedVersionDetails: any = null; // Details of the selected version
  editedBy : string;
  editedAt : string;
  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit() {   
    
  }

  ngOnChanges(){
    this.getVersionDetail(this.historyData.roadmapVersions[0], 0);
  }

  /**
   * To exit from this component
   */
  public exit()
  {
    this.exitEvent.emit(true);
  }

  /**
   * To get the version detail
   * @param version 
   * @param index 
   */
  public getVersionDetail(version, index) {
    this.activeVersionIndex = index;
    this.selectedVersion = version;
    const versionDetail = this.historyData.versionDetails.find(detail => detail.version === version) || null;
    this.editedBy = versionDetail.modifiedBy;
    this.editedAt = versionDetail.modifiedAt;
    if (versionDetail) {
      this.selectedVersionDetails = this.getCombinedVersionDetails(versionDetail);
    } else {
      this.selectedVersionDetails = null;
    }
    this.cdr.detectChanges();
    console.log(this.selectedVersionDetails);
}


  getCombinedVersionDetails(versionDetails) {
    const result = [];
    if (versionDetails.editedValues.length > 0) {
      result.push(...versionDetails.editedValues.map(item => ({ ...item, action: 'edit' })));
    }
    if (versionDetails.deletedValues.length > 0) {
      result.push(...versionDetails.deletedValues.map(item => ({ ...item, action: 'delete' })));
    }
    if (versionDetails.addedValues.length > 0) {
      result.push(...versionDetails.addedValues.map(item => ({ ...item, action: 'add' })));
    }
    return result;
  }

}
