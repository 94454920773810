import { Injectable } from '@angular/core';
import { UserAuthService } from '../user-auth/user-auth.service';
import { ShareValuesService } from '../sharedValues/share-values.service';
import { User } from '../user';
import { MsalService } from '@azure/msal-angular';
import { protectedResources } from 'src/app/services/user-auth/auth-config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class GraphService {

  graphAPIUserInfo = protectedResources.graphAPIUserInfo.endpoint;
  getOfferQuotTemplatesUrl=protectedResources.getOfferQuotTemplates.endpoint;
  getListItems = protectedResources.getListItems.endpoint;
  qoutSiteId: string;
  groupId: string;
  siteId: string;
  sharePointHost='';//'cosmoaicode.sharepoint.com';
  sharePointSiteAddress: any;//= "/sites/A218E0AE-F7AA-4791-AE37-6EF895132E98_0020"//'/sites/BhavyaSampleTeam307';//Dynamic
  graphClient;
  authenticated: boolean;
  user: User;
  userDomain='';
  serviceDomian='.sharepoint.com';
  QuotDriveId: string;
  userDetails: any;
  driveId: string;
  bluePrintCommunicationSite: any[];
  bluePrintCommSiteId: any;
  bluePrintCommunicationGroupId='';
  constructor(private http: HttpClient) {
  }

  getUsers() {
    return this.http.get<any>(this.graphAPIUserInfo);
  }

  getUSerByUserPrincipleName(UserEmail?: string){//e3fa380c-e115-4480-aaa5-c69e0bf512f0
    if(UserEmail){return this.http.get<any>(`${protectedResources.graphApi.endpoint}/${UserEmail}`);}
    else {return this.http.get<any>(`${protectedResources.graphApi.endpoint}/${environment.userPrincipleName}`);}
  }


  addMemberToSite(groupId,data){
    return this.http.post(`${protectedResources.getGroups.endpoint}/${groupId}/members/$ref`, data);
  }

  deleteMemberToSite(groupId,memId){
    return this.http.delete(`${protectedResources.getGroups.endpoint}/${groupId}/members/${memId}/$ref`);
  }

  addOwnerToGroup(groupId,data){//484789db-bc1a-44b5-b73f-78e43abd4bfd
    return this.http.post(`${protectedResources.getGroups.endpoint}/${groupId}/owners/$ref`, data);
  }

  checkForExistingFileName(folderId,filename){
    return this.http.get(`${protectedResources.getOfferDrives.endpoint}/${this.driveId}/items/${folderId}:/children?$filter=name eq '${filename}'`);
  }

  UploadFile(fileName,data,folderPath){
    return this.http.put(`${protectedResources.graphUploadFile.endpoint}${this.siteId}/drive/root:/${folderPath}/${fileName}:/content`, data);
  }

  createUploadSession(name,fileSize,folderPath){
    const endpoint = `${protectedResources.graphUploadFile.endpoint}${this.siteId}/drive/root:/${folderPath}/${name}:/createUploadSession`;
    const body = {
      item: {
        '@microsoft.graph.conflictBehavior': 'rename'
      }
    };
    return this.http.post(endpoint, body);
  }

  createUploadSessionInCommmicationSite(fileName,data,folderPath){
    const endpoint = `${protectedResources.graphUploadFile.endpoint}${this.bluePrintCommSiteId}/drive/root:/${folderPath}/${fileName}:/createUploadSession`;
    const body = {
      item: {
        '@microsoft.graph.conflictBehavior': 'rename'
      }
    };
    return this.http.post(endpoint, body);
    // return this.http.put(`${protectedResources.graphUploadFile.endpoint}${this.sharePointHost}/drive/root:/General/${folderPath}/${fileName}:/content`, data);
  }

  UploadFileToCommmicationSite(fileName,data,folderPath){
    return this.http.put(`${protectedResources.graphUploadFile.endpoint}${this.bluePrintCommSiteId}/drive/root:/${folderPath}/${fileName}:/content`, data);
    // return this.http.put(`${protectedResources.graphUploadFile.endpoint}${this.sharePointHost}/drive/root:/General/${folderPath}/${fileName}:/content`, data);
  }

  getLayoutQuotation(path){
    return this.http.get(`${protectedResources.getOfferDrives.endpoint}/${this.driveId}/root:${path}:/children`);
  }

  // UploadlayoutQuote(fileName,data,folderPath){
  //   return this.http.put(`${protectedResources.graphUploadFile.endpoint}${this.sharePointHost}/drive/root:/${folderPath}/${fileName}:/content`, data);
  // }

  getCommunicationFolderDetails(folderPath){
    return this.http.get(`${protectedResources.getFolderDetails.endpoint}/${this.sharePointHost}/drive/root:/${folderPath}:/children`);
  }

  getBluePrintCommunicationFolderDetails(folderPath){
    return this.http.get(`${protectedResources.getFolderDetails.endpoint}/${this.bluePrintCommSiteId}/drive/root/children`);
  }

  getBluePrintCommunicationSiteId(){
    return this.http.get(`${protectedResources.getSiteId.endpoint}/${this.bluePrintCommunicationGroupId}/drive/list`);
  }

  createBluePrintCommunicationSiteFolder(data,folderPath){
    return this.http.post(`${protectedResources.createFolder.endpoint}${this.bluePrintCommSiteId}/drive/root:/${folderPath}:/children`, data);
  }

  getSiteId(){
    return this.http.get(`${protectedResources.getSiteId.endpoint}/${this.groupId}/drive/list`);
  }

  getSiteDetailsBySiteName(commincationSiteName){//Blueprint-Test
    return this.http.get(`${protectedResources.getFolderDetails.endpoint}/${this.sharePointHost}:/sites/${commincationSiteName}`);
  }

  getGroups(){
    return new Observable((observer)=>{
      const makeApi = async (url)=>{
        const response = await (this.http.get(url).toPromise() as Promise<{value: any[]}>);
        if(response['@odata.nextLink']){
          const data = await makeApi(response['@odata.nextLink']);
          response.value.push(...data.value);
        }
        return response;
      };
      makeApi(protectedResources.getGroups.endpoint).then((data)=>{
        observer.next(data);
      }).catch((err)=>{
        observer.error(err);
      }).finally(()=>{
        observer.complete();
      });
      // this.http.get(protectedResources.getGroups.endpoint)
      // observer.complete();
    });

    return this.http.get(protectedResources.getGroups.endpoint);
  }

  GroupsTest(displayName: string){//https://graph.microsoft.com/v1.0/groups?$filter=startswith(displayName, 'a')&$count=true&$top=1&$orderby=displayName
    return this.http.get(`${protectedResources.getGroups.endpoint}$filter=startswith(displayName,'${displayName}')`);
  }

  getDriveId(){
    return this.http.get(`${protectedResources.graphApiSharePointInfo.endpoint}/${this.driveId}/drive`);
  }

  createFolder(data,folderPath){
    return this.http.post(`${protectedResources.createFolder.endpoint}${this.siteId}/drive/root:/${folderPath}:/children`, data);
  }

  getFolderDetails(folderPath){
    return this.http.get(`${protectedResources.getFolderDetails.endpoint}/${this.siteId}/drive/root:/${folderPath}:/children`);
  }

  getSharePointInfo(){
    return this.http.get(`${protectedResources.graphApiSharePointInfo.endpoint}/${this.sharePointHost}:${this.sharePointSiteAddress}`);
  }

  createTeam(data){
    return this.http.post(`${protectedResources.createTeam.endpoint}`, data);
  }

  getListItemss() {
    return this.http.get<any>(this.getListItems + '/cosmoaicode.sharepoint.com,34bd66d6-b62a-48e9-8344-bd765bb59623,a69fa774-68ce-4e8d-8289-7ca7fd33fd89/drive/root/children');
  }

  getItemById(driveId,itemId){
    //https://graph.microsoft.com/v1.0/drives/b!SfKynB5KikSrbN2fmnY_KpV55XpWonpEuU6C36VtjkVq1CfJW9zyQ7j00tsmi__e/items/01NIBPC7EWOG5QN5D2FRFI4NIL3GITUGLU
    return this.http.get(`${protectedResources.getOfferDrives.endpoint}/${driveId}/items/${itemId}`);
  }

  deleteItem(driveId,itemId){ ///drives/{drive-id}/items/{item-id}
    return this.http.delete(`${protectedResources.getOfferDrives.endpoint}/${driveId}/items/${itemId}`);
  }

  getDriveDetails(siteId){
    return this.http.get<any>(`${protectedResources.getOfferQuotTemplates.endpoint}/${siteId}/drive`);
  }

  getQuotationList(path){
    return this.http.get(`${protectedResources.getOfferDrives.endpoint}/${this.QuotDriveId}/root:${path}:/children`);
  }

  getPdfOfQuotation(path){
    return this.http.get(`${protectedResources.getOfferDrives.endpoint}/${this.driveId}/root:${path}:/children`);
  }

  getGenerateQuotFolderDetails(driveId,folderPath){
   //https://graph.microsoft.com/v1.0/drives/b!FoX3tWCFQ0m1TnTvb_xWXZEzDs_ChxBGrcTWWOGLrztrFf7nzdVOS7vBqi0MPpcU/root/children
    return this.http.get(`${protectedResources.getOfferDrives.endpoint}/${driveId}/root${folderPath}/children`);
  }

  getMachineThumbnail(driveId,itemId){
    return this.http.get(`${protectedResources.getOfferDrives.endpoint}/${driveId}/items/${itemId}/thumbnails`);
  }

}
