import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { BackendCallService } from 'src/app/services/backend-call/backend-call.service';
import { NgForm } from '@angular/forms';
import { LoadingController, ModalController, PopoverController } from '@ionic/angular';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  switchMap
} from 'rxjs/operators';
import { Location } from '@angular/common';
import { ShareValuesService } from 'src/app/services/sharedValues/share-values.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ToastService } from 'src/app/services/toast.service';
import { AppConstants } from 'src/app/utilities/constant';

@Component({
  selector: 'app-machine-creation-form',
  templateUrl: './machine-creation-form.component.html',
  styleUrls: ['./machine-creation-form.component.scss'],
})
export class MachineCreationFormComponent implements OnInit {

  @ViewChild('feature') feature: NgSelectComponent;
  @ViewChild('controlSystem') controlSystem: NgSelectComponent;
  formData: any;
  isFormSubmitted = false;
  contactList: Subject<string[]> = of([]) as Subject<string[]>;
  public contactInput$ = new Subject<string>();
  private onApplicationScrolled = new EventEmitter();
  private onMachineTypeScrolled = new EventEmitter();
  private onFeatureScrolled = new EventEmitter();
  private onControlSystemScrolled = new EventEmitter();
  getApplicationListLoading = false;
  getMachineTypeListLoading = false;
  getFeatureListLoading = false;
  getControlSystemListLoading = false;
  count = 20;
  // Following count is used to update the value of increasedCount onScroll of respective dropdown
  increasedApplicationCount = 0;
  increasedMachineTypeCount = 0;
  increasedFeaturesCount = 0;
  increasedControlSystemCount = 0;
  applicationLimitReached = false;
  machineTypeLimitReached = false;
  featureLimitReached = false;
  controlSystemLimitReached = false;
  // Following string is used to search the data in the respective dropdown
  searchApplicationTerm = '';
  searchMachineTypeTerm = '';
  searchFeaturesTerm = '';
  searchControlSystemTerm = '';
  // Following lists will be used to show the data in the form under respective dropdown
  public applicationList = [];
  public machineTypeList = [];
  public featureList = [];
  public controlSystemList = [];
  public each_service: any = null;
  constructor(
    public backendService: BackendCallService,
    private modalCtrl: ModalController,
    private sharedValue: ShareValuesService,
    public loading: LoadingController,
    private _location: Location,
    private popoverController:  PopoverController,
    private toastService: ToastService
  ) {
  }

  ngOnInit() {
    this.subscribeApplicationScroll();
    this.subscribeMachineTypeScroll();
    this.subscribeFeaturesScroll();
    this.subscribeControlSystemScroll();
    this.onApplicationScrolled.emit();
    this.onMachineTypeScrolled.emit();
    this.onFeatureScrolled.emit();
    this.onControlSystemScrolled.emit();
    this.contactList = this.contactInput$.pipe(
      debounceTime(600),
      distinctUntilChanged(),
      switchMap((inp: string) => {
        this.formData.customerLoading = true;
        let obs: Observable<Object>;
        if (inp) {
          return this.backendService.searchServiceCustomerName(inp).pipe(
            map((res: any) => res.value),
            catchError((err) => []),
            finalize(() => {
              this.formData.customerLoading = false;
            })
          );
        } else {
          return of([]).pipe(
            finalize(() => {
              this.formData.customerLoading = false;
            })
          );
        }
      })
    ) as Subject<string[]>;
  }

  ionViewWillEnter() {
    const eachService = sessionStorage.getItem('each_service');
    this.each_service = JSON.parse(eachService);
    console.log(this.each_service,' this.each_service from storage');

    this.formData = {
      machine_name: '',
      machine_description: '',
      manufacturer: '',
      model: '',
      serial_number: '',
      asset_id: '',
      customerId: '',
      application: '',
      machineType: '',
      industrySegment: '',
      features: [],
      controlSystem: [],
      customerLoading: false,
      customerName: ''
    };
    if (this.each_service && (this.each_service.customer_id || this.each_service.customer_Id)) {
      this.formData.customerId = this.each_service.customer_id || this.each_service.customer_Id;
      this.formData.customerName = this.each_service.customer_name || this.each_service.customer_Name;
      this.formData.industrySegment = this.each_service.industryCode;
    }
  }

  ngOnChanges() {
    this.formData.customerId = this.formData.customerId;
  }

  // Method to display the data under application dropdown
  public subscribeApplicationScroll() {
    this.onApplicationScrolled.pipe(debounceTime(100), switchMap((res) => {
      const payload =
      {
        search: this.searchApplicationTerm == '' ? ' ' : this.searchApplicationTerm,
        count: this.count,
        increasedCount: this.increasedApplicationCount,
      };
      return this.backendService.getApplicationData(payload);
    })).subscribe(
      (data: any) => {
        if (data?.value && data?.value?.length) {
          this.increasedApplicationCount = this.increasedApplicationCount + this.count;
          if (this.searchApplicationTerm !== '') {this.increasedApplicationCount = this.increasedApplicationCount + this.count;}
          if (this.applicationList.length > 1) {
            if (data.value.length !== 0) {
              this.applicationList = this.applicationList.concat(...data.value);
            } else {
              this.increasedApplicationCount = 0;
              this.applicationLimitReached = data.value.length !== this.count;
              this.toastService.presentToast('No Application Records Available', 4000, AppConstants.ERROR_SNACKBAR, 'top');
            }
          } else {
            this.increasedApplicationCount = 0;
            if (data.value.length !== 0) {
              this.applicationList = this.applicationList.concat(...data.value);
            } else {
              this.increasedApplicationCount = 0;
              this.applicationLimitReached = data.value.length !== this.count;
              this.toastService.presentToast('No Application Records Available', 4000, AppConstants.ERROR_SNACKBAR, 'top');
            }
          }
          this.increasedApplicationCount = this.increasedApplicationCount + this.count;
        }
        setTimeout(() => {
        }, 500);
      }, err => {
      }
    );
  }

  public setApplication(id) {
    this.formData.application = id;
  }

  fetchMoreApplication() {
    this.onApplicationScrolled.emit();
  }

  // Method to display the data under Machine Type dropdown
  public subscribeMachineTypeScroll() {
    this.onMachineTypeScrolled.pipe(debounceTime(100), switchMap((res) => {
      const payload =
      {
        search: this.searchMachineTypeTerm == '' ? ' ' : this.searchMachineTypeTerm,
        count: this.count,
        increasedCount: this.increasedMachineTypeCount,
      };
      return this.backendService.getMachineTypeData(payload);
    })).subscribe(
      (data: any) => {
        if (data?.value && data?.value?.length) {
          this.increasedMachineTypeCount = this.increasedMachineTypeCount + this.count;
          if (this.searchMachineTypeTerm !== '') {this.increasedMachineTypeCount = this.increasedMachineTypeCount + this.count;}
          if (this.machineTypeList.length > 1) {
            if (data.value.length !== 0) {
              this.machineTypeList = this.machineTypeList.concat(...data.value);
            } else {
              this.increasedMachineTypeCount = 0;
              this.machineTypeLimitReached = data.value.length !== this.count;
              this.toastService.presentToast('No Machine Type Records Available', 4000, AppConstants.ERROR_SNACKBAR, 'top');
            }
          } else {
            this.increasedMachineTypeCount = 0;
            if (data.value.length !== 0) {
              this.machineTypeList = this.machineTypeList.concat(...data.value);
            } else {
              this.increasedMachineTypeCount = 0;
              this.machineTypeLimitReached = data.value.length !== this.count;
              this.toastService.presentToast('No Machine Type Records Available', 4000, AppConstants.ERROR_SNACKBAR, 'top');
            }
          }
          this.increasedMachineTypeCount = this.increasedMachineTypeCount + this.count;
        }
        setTimeout(() => {
        }, 500);
      }, err => {
      }
    );
  }

  setMachineType(id) {
    this.formData.machineType = id;
  }

  fetchMoreMachineType() {
    this.onMachineTypeScrolled.emit();
  }

  // Method to display the data under Specific Features dropdown
  public subscribeFeaturesScroll() {
    this.onFeatureScrolled.pipe(debounceTime(100), switchMap((res) => {
      const payload =
      {
        search: this.searchFeaturesTerm == '' ? ' ' : this.searchFeaturesTerm,
        count: this.count,
        increasedCount: this.increasedFeaturesCount,
      };
      return this.backendService.getFeaturesData(payload);
    })).subscribe(
      (data: any) => {
        if (data?.value && data?.value?.length) {
          this.increasedFeaturesCount = this.increasedFeaturesCount + this.count;
          if (this.searchFeaturesTerm !== '') {this.increasedFeaturesCount = this.increasedFeaturesCount + this.count;}
          if (this.featureList.length > 1) {
            if (data.value.length !== 0) {
              this.featureList = this.featureList.concat(...data.value);
            } else {
              this.increasedFeaturesCount = 0;
              this.featureLimitReached = data.value.length !== this.count;
              this.toastService.presentToast('No Feature Records Available', 4000, AppConstants.ERROR_SNACKBAR, 'top');
            }
          } else {
            this.increasedFeaturesCount = 0;
            if (data.value.length !== 0) {
              this.featureList = this.featureList.concat(...data.value);
            } else {
              this.increasedFeaturesCount = 0;
              this.featureLimitReached = data.value.length !== this.count;
              this.toastService.presentToast('No Feature Records Available', 4000, AppConstants.ERROR_SNACKBAR, 'top');
            }
          }
          this.increasedFeaturesCount = this.increasedFeaturesCount + this.count;
        }
        setTimeout(() => {
        }, 500);
      }, err => {
      }
    );
  }

  fetchMoreFeature() {
    this.onFeatureScrolled.emit();
  }

  setFeature(id) {
    this.formData.features = id;
  }

  removeFeatureFocus(ev) {
    const isNotDefined = this.featureList.some(ele => ele.id == ev && ele.specific_feature == 'Not Defined');
    if (isNotDefined) {this.feature.blur();}
  }

  removeControlSystemFocus(ev) {
    const isNotDefined = this.controlSystemList.some(ele => ele.id == ev && ele.control_system == 'Not Defined');
    if (isNotDefined) {this.controlSystem.blur();}
  }

  // Method to display the data under control system dropdown
  public subscribeControlSystemScroll() {
    this.onControlSystemScrolled.pipe(debounceTime(100), switchMap((res) => {
      const payload =
      {
        search: this.searchControlSystemTerm == '' ? ' ' : this.searchControlSystemTerm,
        count: this.count,
        increasedCount: this.increasedControlSystemCount,
      };
      return this.backendService.getControlSystemData(payload);
    })).subscribe(
      (data: any) => {
        if (data?.value && data?.value?.length) {
          this.increasedControlSystemCount = this.increasedControlSystemCount + this.count;
          if (this.searchControlSystemTerm !== '') {this.increasedControlSystemCount = this.increasedControlSystemCount + this.count;}
          if (this.controlSystemList.length > 1) {
            if (data.value.length !== 0) {
              this.controlSystemList = this.controlSystemList.concat(...data.value);
            } else {
              this.increasedControlSystemCount = 0;
              this.controlSystemLimitReached = data.value.length !== this.count;
              this.toastService.presentToast('No Control System Records Available', 4000, AppConstants.ERROR_SNACKBAR, 'top');
            }
          } else {
            this.increasedControlSystemCount = 0;
            if (data.value.length !== 0) {
              this.controlSystemList = this.controlSystemList.concat(...data.value);
            } else {
              this.increasedControlSystemCount = 0;
              this.controlSystemLimitReached = data.value.length !== this.count;
              this.toastService.presentToast('No Control System Records Available', 4000, AppConstants.ERROR_SNACKBAR, 'top');
            }
          }
          this.increasedControlSystemCount = this.increasedControlSystemCount + this.count;
        }
        setTimeout(() => {
        }, 500);
      }, err => {
      }
    );
  }

  fetchMoreControlSystem() {
    this.onControlSystemScrolled.emit();
  }

  setControlSystem(id) {
    this.formData.controlSystem = id;
  }

  onSubmit(myForm: NgForm) {
    this.isFormSubmitted = true;
    if (myForm.invalid) {
      myForm.form.markAllAsTouched();
      return;
    } else {
      if (myForm.value && this.formData.application && this.formData.machineType
        && this.formData.features.length && this.formData.controlSystem.length) {
        if (this.each_service == null) {
          this.storeCreateMachineInfo(myForm.value);
        } else if (this.each_service?.page == 'projectDetails') {
          const machines = [];
          machines.push(myForm.value);
          if (machines.length > 0) {
            this.addNewMachine(machines);
          }
        } else if (this.each_service?.pageName === 'viewOffer') {
          //New Machine added to already existing service
          if (!this.each_service.hasOwnProperty('newService') && !this.each_service.newService) {
            this.sharedValue.viewOfferListOfServices.forEach(element => {
              if (this.each_service.service_id === element.service_id) {
                const machines = [];
                machines.push(myForm.value);
                if (machines.length > 0) {
                  this.addNewMachine(machines);
                }
                //Adding machine
                for (const each of this.sharedValue.viewOfferListOfServices) {
                  if (myForm.valid) {
                    if (each.service_id == this.each_service.service_id) {
                      each.machines = each.machines || [];
                      each.machines.push(myForm.value);
                      for (const machine of each.machines) {
                        if (machine.selectedMachinechecked) {
                          machine.selectedMachinechecked = false;
                        }
                      }
                      break;
                    }
                  } else {
                    myForm.form.markAllAsTouched();
                  }
                }
              }
            });
          }
        }
        else {
          if (this.sharedValue.listOfServices.length) {
            for (const each of this.sharedValue.listOfServices) {
              if (each.id == this.each_service.id) {
                if (each.id == this.each_service.id) {
                  each.machines = each.machines || [];
                  each.machines.push(myForm.value);
                  if (each.machines.length > 0) {
                    this.addNewMachine(each.machines);
                  }
                  for (const machine of each.machines) {
                    if (machine.selectedMachinechecked) {
                      machine.selectedMachinechecked = false;
                    }
                  }
                  break;
                }
              }
            }
          }
        }
      }
    }
  }

  /**
   * Add new machines
   *
   * @param machines
   */
  addNewMachine(machines) {
    const machinesArray = [];
    machines.forEach((each_machine) => {
      const data = {
        id: '',
        machine_name: each_machine?.machine_name,
        service_id: this.each_service.service_id || this.each_service.service_Id,
        customer_Id: this.each_service.customer_id,
        asset_id: each_machine?.asset_id,
        machine_type: each_machine?.machine_type,
        industry_type: '',
        master_machine_id: '',
        serial_number: each_machine?.serial_number,
        serviceMachine_Id: '',
        machine_description: each_machine?.machine_description,
        estimated_hours: each_machine?.estimatedHours,
        created_at: new Date().toISOString(),
        created_by: '',
        modified_at: new Date().toISOString(),
        modified_by: '',
        machine_location: '',
        erp_wbs_id: '',
        machineMetadata: [
          {
            application: this.formData.application,
            machineType: this.formData.machineType,
            specificFeature: this.formData.features,
            controlSystem: this.formData.controlSystem
          }
        ]
      };
      machinesArray.push(data);
    });
    this.backendService.addNewMachines(machinesArray).subscribe((data: any) => {
      if (data.statusCode == 200
        && Array.isArray(JSON.parse(data.response))
        && JSON.parse(data.response).length > 0) {
        if((this.each_service?.page == 'projectDetails'
        ||this.each_service?.pageName == 'viewOffer'
        ||this.each_service?.pageName == 'ProjectAction' 
        ||this.each_service?.pageName == 'createOffer')) {
          const response = JSON.parse(data.response);
          machines.forEach((machine: any) => {
            response?.filter((item: any) => item.MachineName == machine.machine_name).map((ele: any) => {
              machine.machine_Name = ele.MachineName;
              machine.global_Id = ele.GlobalId;
              // While adding the machine from create-offer we need to set Id as well, further it will use to bind with service
              machine.id = ele.Id;
              machine.customer_Id = ele.CustomerId;
            });
          });
        }
        const APIPayload = {
          project_Id: this.each_service.project_id ? this.each_service.project_id : this.each_service.project_Id,
          order_Id: this.each_service.order_id ? this.each_service.order_id : this.each_service.order_Id,
          service_Id: this.each_service.service_id ? this.each_service.service_id : this.each_service.service_Id
        };
        document.dispatchEvent(new CustomEvent('APICallMachineList', { detail: APIPayload }));
        this.toastService.presentToast(`Machine's added successfully.`, 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
        this.closeModal();
      }else {
        this.toastService.presentToast(data.message, 4000, AppConstants.ERROR_SNACKBAR, 'top');
      }
    }, err => {
       this.toastService.presentToast('Something went wrong', 4000, AppConstants.ERROR_SNACKBAR, 'top');      
    });
  }

  noSubmit(e) {
    e.preventDefault();
  }

  customerSearchByErpId(customer) {
    this.getCustomerAndSegment(customer).subscribe((responses: any) => {
      if (responses && responses.length) {
        const customerInfo = responses[0];
        const segmentInfo = responses[1].result;
        customerInfo.contacts.map((i) => { i.fullName = (i.first_Name != null ? i.first_Name : '') + ' ' + (i.last_Name != null ? i.last_Name : ''); return i; });
        this.sharedValue.customersInfo.next(customerInfo);
        this.formData.customerId = customerInfo?.customer_Id;
        this.formData.customerName = customerInfo?.customer_Name;
        this.formData.industrySegment = segmentInfo[0]?.segment;
      }
    }, err => {
      this.toastService.presentToast('No Results Found', 4000, AppConstants.ERROR_SNACKBAR, 'top');   
    });
  }

  // To get the industryCode as per the customerId
  getCustomerAndSegment(cust) {
    const getCustomer = this.backendService.offerCustomerInfoSearchByErpId(cust.erp_customer_id);
    const getSegment = this.backendService.getSegments(cust.erp_customer_id);
    return forkJoin([getCustomer, getSegment]);
  }

  // To prepare the payload for creating a machine
  private async storeCreateMachineInfo(data) {
    const payload = [];
    const createMachine = {
      machine_Name: data.machine_name,
      machine_Type: data.machineType,
      manufacturer: data.manufacturer,
      model: data.model,
      serial_Number: data.serial_number,
      asset_Number: data.asset_id,
      description: data.machine_description,
      customer_Id: data.customerId,
      industrySegment: data.industrySegment ? data.industrySegment : ' ',
      subIndustrySegment: '',
      machineImage: [],
      machineMetadata: [
        {
          application: this.formData.application,
          machineType: this.formData.machineType,
          specificFeature: this.formData.features,
          controlSystem: this.formData.controlSystem
        }
      ]
    };
    payload.push(createMachine);
    this.backendService.createMachine(payload).then(async (res) => {
      if (res.value.statusReason == 'Machine\'s created successfully') {
        this.toastService.presentToast('Machines Created Successfully', 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
      } else {
        this.toastService.presentToast(res.value.statusReason, 4000, AppConstants.ERROR_SNACKBAR, 'top'); 
      }
      this.closeModal();
    }).catch((err) => {
      this.toastService.presentToast('Something went wrong', 4000, AppConstants.ERROR_SNACKBAR, 'top'); 
      this.closeModal();
    });
  }

  async presentLoading(msg) {
    const loading = await this.loading.create({
      cssClass: 'my-custom-loading',
      message: msg,
      spinner: 'bubbles'
    });
    await loading.present();
    return loading;
  }

  closeModal(): void {
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }
}
