import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, forkJoin, merge } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { BackendCallService } from 'src/app/services/backend-call/backend-call.service';
import { ShareValuesService } from 'src/app/services/sharedValues/share-values.service';
import { GraphService } from '../../../services/graph/graph.service';
import { BucketData, BucketMachines, MachineDetailsList, ProjectDetails } from '../project-detail/project-interface';


@Component({
  selector: 'app-project-management-layout',
  templateUrl: './project-management-layout.page.html',
  styleUrls: ['./project-management-layout.page.scss'],
})
export class ProjectManagementLayoutPage implements OnInit, OnDestroy {
  static projectDetails: BehaviorSubject<any> = new BehaviorSubject({});
  static projectServiceDetails: BehaviorSubject<any> = new BehaviorSubject({});
  static searchServiceData: BehaviorSubject<{
    projectListInfo: ProjectDetails;
    machineListInfo: MachineDetailsList;
    bucketListInfo: BucketData[];
    serviceRoadmaps: any;
  }> = new BehaviorSubject({
    projectListInfo: {} as ProjectDetails,
    machineListInfo: {} as MachineDetailsList,
    bucketListInfo: {} as BucketData[],
    serviceRoadmaps: {}
  });
  private projectData = null;
  private apiWaitObserver = new EventEmitter();
  private destroyEvents = new EventEmitter();
  constructor(
    private route: ActivatedRoute,
    public backendService: BackendCallService,
    public sharedValue: ShareValuesService,
    private graphService: GraphService,
  ) { }


  ngOnInit() {
    this.searchService();
    this.getProjectDetails();
    this.route.params.pipe(takeUntil(this.destroyEvents)).subscribe((params) => {
      const lastVal = ProjectManagementLayoutPage.projectDetails.getValue();
      if (lastVal?.encrypted_Project_Id && lastVal.encrypted_Project_Id != params.id) {
        // this.clearAllValues();
      }

    }, err => {
      // this.sharedValue.errorShowToast("","No Results Found")
    });
  }

  async ionViewWillEnter() {
    document.addEventListener('reloadProjectLayout', async (ev: CustomEvent) => {
      let projectListInfo;
      let bucketListInfo;
      let serviceRoadmaps;
      const params = this.route.snapshot.queryParams;
      const machineListInfo = await this.backendService.getProjectDetailMachineListsInfo(params.orderId , params.serviceId , params.project_id, false);
      await ProjectManagementLayoutPage.searchServiceData.toPromise().then((res) => {
        projectListInfo = res.projectListInfo;
        bucketListInfo = res.bucketListInfo;
        serviceRoadmaps = res.serviceRoadmaps;
      });
      ProjectManagementLayoutPage.searchServiceData.next({ projectListInfo, machineListInfo, bucketListInfo, serviceRoadmaps });
      setTimeout(() => {
        document.dispatchEvent(new CustomEvent('updateMachineListInfo', { detail: 'edited' }));
      }, 100);
    });
  }

  clearAllValues() {
    ProjectManagementLayoutPage.projectDetails.next({});
    ProjectManagementLayoutPage.projectServiceDetails.next({});
    ProjectManagementLayoutPage.searchServiceData.next({
      projectListInfo: {} as ProjectDetails,
      machineListInfo: {} as MachineDetailsList,
      bucketListInfo: {} as BucketData[],
      serviceRoadmaps: {}
    });
    this.projectData = null;

  }
  ngOnDestroy(): void {
    this.destroyEvents.emit(true);
    this.destroyEvents.unsubscribe();
  }

  searchService() {
    merge(this.apiWaitObserver, this.route.queryParams)
      .pipe(filter((params) => params.orderId && params.serviceType && params.serviceId && params.projectId && this.projectData), switchMap((params) => {
        const projectListInfo = this.backendService
          .getProjectDetailServiceListsInfo(params.orderId, params.serviceId , this.route.snapshot.params.id);
        const machineListInfo = this.backendService
          .getProjectDetailMachineListsInfo(params.orderId, params.serviceId, this.route.snapshot.params.id, false);
        const bucketListInfo = this.backendService.getBucketLists(this.route.snapshot.params.id, params.orderId, params.serviceId);
        const serviceRoadmaps = this.backendService.getServiceLevelRoadmap(params.serviceId, this.route.snapshot.params.id);

        return forkJoin([projectListInfo, machineListInfo, bucketListInfo, serviceRoadmaps]);
      }), takeUntil(this.destroyEvents)).subscribe((res) => {
        const [projectListInfo, machineListInfo, bucketListInfo, serviceRoadmaps] = res;
        ProjectManagementLayoutPage.searchServiceData.next({ projectListInfo, machineListInfo, bucketListInfo, serviceRoadmaps });
      });
  }

  private getProjectDetails() {
    const id = this.route.snapshot.params.id;
    return this.backendService
      .getprojectDetail(id)
      .then((res) => {
        if (res?.project_Id) {//A218E0AE-F7AA-4791-AE37-6EF895132E98_0020
          this.graphService.sharePointSiteAddress = '/sites/' + res.erp_Project_Id + '_' + res.company_Code;
          // this.graphService.getGroups().subscribe((data: any) => {
          this.graphService.groupId = '';
          this.graphService.siteId = '';
          this.backendService.getSiteDetails(res.erp_Project_Id || res.project_Id + '_' + res.company_Code).subscribe((data: any) => {
            if (data) {
                data.forEach(element => {
                if (element.sitename == res.erp_Project_Id + '_' + res.company_Code) {//res.erp_Project_Id + "_" + res.company_Code
                  this.graphService.groupId = element.siteid;
                  this.graphService.getSiteId().subscribe((data: any) => {
                    this.graphService.siteId = data.parentReference.siteId;
                    const folderPath = 'General';
                    const folderCreatePayload = {
                      name: 'Machines',
                      folder: {},
                      '@microsoft.graph.conflictBehavior': 'rename'
                    };
                    this.graphService.getFolderDetails('General').subscribe((res: any) => {
                      if (res) {
                        if (res.value.filter(function(e) { return e.name === 'Machines'; }).length == 0) {
                          this.graphService.createFolder(folderCreatePayload, folderPath).subscribe(() => {
                          }, err => {
                          });
                        }
                      } else {
                        this.graphService.createFolder(folderCreatePayload, folderPath).subscribe((res) => {

                        }, err => {
                        });
                      }
                    }, err => {
                    });

                  }, err => {
                  });
                }
              });
            }
          }, err => {
          });
        }
        ProjectManagementLayoutPage.projectDetails.next(res);
        this.getProjectServiceDetails(res);
      })
      .catch((err) => {
        console.log('getting error for fetching projects detail:', err);
      });
  }

  private getProjectServiceDetails(res: any) {
    if (res?.project_Id) {
      this.backendService
        .getProjecDetailOrderService(res.project_Id)
        .then((res) => {
          ProjectManagementLayoutPage.projectServiceDetails.next(res);
          const params = this.route.snapshot.queryParams;
          // if(!this.projectData && params.orderId && params.serviceType && params.projectId){
          if (!this.projectData && params.orderId && params.serviceType && params.project_id) {
            setTimeout(() => {
              this.apiWaitObserver.emit(params);
            }, 100);
          }
          this.projectData = res;
        })
        .catch((err) => {
          console.log(
            'getting error for fetching projects service information:',
            err
          );
        });
    }
  }
}
