<ion-content [ngClass]="isDefaultAlert ? 'alert-class' : 'dashboard-customize'">
  <span *ngIf="isDefaultAlert && !isDashboardCustomize" class="alert-box">
    <img src="assets/icon/cancel.svg" alt="" tooltip="{{'Close' | translate }}" placement="bottom" class="close-icon" (click)="close('', false, true)" id="alert_modal_cancel_button">
    <div class="title" *ngIf="alertContent" id="alert_modal_content">
      <span>{{alertContent}}</span>
      <div class="button-container" *ngIf="isDefaultAlert && !isDashboardCustomize">
        <ion-button class="app-btn" shape="round" fill="outline" color="primary" (click)="confirm()" id="alert_modal_confirm_button">
          {{'Confirm'|translate}}
        </ion-button>
      </div>
    </div>
  </span>

  <div *ngIf="isDashboardCustomize && !isDefaultAlert" class="widget-container">
    <ion-grid>
      <ion-row class="close-row">
        <ion-col (click)="close()" class="close-col" id="dashboard_customization_alert_modal_cancel_button">
          <img src="../../../assets/icon/cancel.svg" alt="" tooltip="{{'Close' | translate }}" placement="bottom">
        </ion-col>
      </ion-row>
      <ion-row class="header-row">
        <ion-col>
          <div class="ion-text-start header-title">
            {{ 'Select Widgets' | translate }}
          </div>
        </ion-col>
        <ion-col>
          <div class="ion-text-end header-reset" (click)="resetDashboard()"
            id="dashboard_customization_alert_modal_reset_button">
            <img src="../../../assets/icon/reset.svg" style="margin-bottom: -8px;" alt="">
            <span class="header-reset-title">{{ 'Reset Dashboard' | translate }}</span>
          </div>
        </ion-col>
      </ion-row>

      <ion-row class="content-row">
        <ion-col class="content-col" size-xs='12' size-sm='6' size-md="6" size-lg="6" size-xl="4"
          *ngFor="let item of dashboardCustomizationValue">
          <ion-row>
            <ion-col size-xs='1' size-md="1" style="padding-top: 40px;">
              <ion-checkbox checked={{item.isChecked}} (ionChange)="changeWidgetCheckedValue(item)"
                id="dashboard_customization_alert_modal_checkbox_{{item.val}}" [disabled]="item.disabled"
                [ngClass]="item.disabled ? 'disabled-checkbox' : ''"></ion-checkbox>
            </ion-col>
            <ion-col size-xs='11' size-md="11">
              <ion-card class="content-card-container">
                <ion-badge class="content-card-badge">
                  <img src="{{item.icon}}" alt="">
                </ion-badge>
                <div class="content-card-title">
                  <ion-label id="dashboard_customization_alert_modal_title_{{item.val}}">{{item.title}}</ion-label>
                </div>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
      <ion-row class="button-row">
        <ion-button [disabled]="!isAnyValueChanged" class="app-btn" shape="round" fill="outline" color="primary" (click)="submitDashboardWidgets()">
          {{'Submit'|translate}}
        </ion-button>
      </ion-row>
    </ion-grid>
  </div>

</ion-content>