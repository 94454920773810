import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { LoaderService } from '../app.component';
import { UserAuthService } from './user-auth/user-auth.service';
import { ToastService } from './toast.service';
import { AppConstants, ObservationEvents } from '../utilities/constant';
import { EventProvider } from './event';
import { BackendCallService } from './backend-call/backend-call.service';
import { LoggingService } from './logging.service';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private noLoaderApis = [
    'https://graph.microsoft.com/v1.0/groups',
    'https://graph.microsoft.com/v1.0/sites',
    'https://graph.microsoft.com/v1.0/teams',
    'https://graph.microsoft.com/v1.0/users',
    'https://graph.microsoft.com/v1.0/me',
    'https://dna-westeur',
    'https://graph.microsoft.com/v1.0/teamsTemplates',
    'https://login.microsoftonline.com/organizations',
    'https://login.microsoftonline.com/common'
  ];
  private ongoingRequests = 0;
  private errorLoggingInProgress: boolean;

  constructor(
    private loader: LoaderService,
    private userAuthService: UserAuthService,
    private toastService: ToastService,
    private eventProvider: EventProvider,
    private backendService: BackendCallService,
    private loggingService: LoggingService 
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.userAuthService.getAccessToken();
    const excludedLoaderApis = [
      ...this.noLoaderApis, // Existing no loader APIs
      'offersvc/mastermachinesvc/filteredmachines',
      '/api/user/users',
      '/api/role/roles',
      '/api/group/groupwithusercount',
      '/api/customersvc/GetAllSalesOrganization',
      '/api/offersvc/service/getallservicematerial',
      '/api/customersvc/GetAllCustomer',
      '/api/customersvc/GetAllContact',
      '/projectsvc/projectsv2/',
      '/ordersvc/ordersv2/',
      'api/projectsvc/projectsv2/',
      '/api/offersvc/v2/',
      '/notificationhub/negotiate?negotiateVersion=1',
      '/finalquoteversions',
      '/commercialquoteversions',
      '/api/customersvc/menuItems',
      '/day/dailymetrics',
      '/api/ordersvc/effortsmetrics',
      '/api/offersvc/effortmetrics',
      '/api/projectsvc/projectmetrics',
      '/api/projectsvc/companydetails',
      '/api/riskassessmentsvc/SaveStaticTemplateV1'
    ];

    const shouldShowLoader = !excludedLoaderApis.some(api => req.url.includes(api));

    // Show loader only if the request requires it
    if (shouldShowLoader) {
      this.ongoingRequests++;
      if (this.ongoingRequests === 1) {
        this.loader.showLoader();
      }
    }

    // Add token to all outgoing requests except for specific endpoints
    if (
      !req.url.startsWith(environment.tokenEndpoint + '/Token') &&
      !req.url.startsWith(environment.tokenEndpoint + '/refresh-token') &&
      !req.url.startsWith('https://graph.microsoft.com/v1.0/')
    ) {
      if (token) {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (this.errorLoggingInProgress) {
          return throwError(error);
        }
        this.errorLoggingInProgress = true;
        const payload = {
          errorMessage: error.message,
          stackTrace: '',
          controllerName: '',
          actionName: '',
          url: req.url,
          request: null,
          response: '',
          userId: this.userAuthService.getUserList().userId
        }
        this.loggingService.logError(payload).subscribe((res) => {
          this.errorLoggingInProgress = false;
        }, (error) => {
          this.errorLoggingInProgress = false;
        });
        this.eventProvider.BroadcastEvent(ObservationEvents.ERROR_OCCURRED, error);
        if (error.status === 500) {
          const errorMessage = 'Internal Server Error: The server encountered an error and could not complete your request.';
          this.toastService.presentToast(errorMessage, 4000, AppConstants.ERROR_SNACKBAR, 'top');
        } else if (error.status === 504 || error.status === 0) {
          const errorMessage = 'Gateway Timeout : The server did not respond in time. Please try again later.';
          this.toastService.presentToast(errorMessage, 4000, AppConstants.ERROR_SNACKBAR, 'top');
          this.eventProvider.BroadcastEvent(ObservationEvents.GATEWAY_TIMEOUT, true);
        }
        return throwError(error);
      }),
      finalize(() => {
        if (shouldShowLoader) {
          this.ongoingRequests--;
          if (this.ongoingRequests === 0) {
            this.loader.hideLoader();
          }
        }
      })
    );
  }
}
