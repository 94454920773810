import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { isNotEmptyArray } from 'src/app/utilities/utils';
import { LocalSettingsService } from '../../services/local-settings/local-settings.service';
import { ShareValuesService } from 'src/app/services/sharedValues/share-values.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.page.html',
  styleUrls: ['./alert-modal.page.scss'],
})
export class AlertModalPage implements OnInit {
  isDefaultAlert = true;
  isAnyValueChanged = false;
  @Input() alertContent: string;
  @Input() isDashboardCustomize = false;
  @Input() isOfferCreation = false;
  @Input() deleteService = false;
  @Input() isProjectManagement = false;
  @Input() isViewOfferDelete = false;
  @Input() isUploadFile = false;
  @Input() isUploadMachineFile = false;
  @Input() isProjectAccess = false;
  @Input() isProjectRequestAccess = false;
  @Input() isViewOpportunity = false;
  shouldResetDashboard = false;
  dashboardCustomizationValue = [];

  constructor(
    public modalController: ModalController,
    public localSettingsSrv: LocalSettingsService,
    public sharedValue: ShareValuesService,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    if (this.isDashboardCustomize) {
      this.isDefaultAlert = false;
      let msg: string;
      this.translate.get('Project Tracker').subscribe(res=>{
        msg = res;//`Are You Sure You Want to Cancel?`;
      });
      this.dashboardCustomizationValue = [
        {
          title: msg,
          val: 'pt',
          isChecked: true,
          disabled: true,
          icon: '../../../assets/icon/Project_Tracker_Widget.svg',
        },
        // {
        //   title: 'Task Manager',
        //   val: 'tm',
        //   isChecked: false,
        //   icon: '../../../assets/icon/Task_Manager_Widget.svg',
        // },
        // { title: 'Future Projects', val: 'fp', isChecked: false, icon: '../../../assets/icon/Future_Projects_Widget.svg' },
        // { title: 'Offer Management', val: 'ofm', isChecked: false, icon: '../../../assets/icon/Offer_Management_Widget.svg' },
        // { title: 'Order Management', val: 'om', isChecked: false, icon: '../../../assets/icon/Order_Management_Widget.svg' },
        // { title: 'Risk Assessment', val: 'rm', isChecked: false, icon: '../../../assets/icon/Risk-Assessment-widget.svg' }
      ];
      this.changeWidgetSelectedValue();
    }
  }

  close(value?, shouldConfirmReset = false, isClosedClicked = false) {
    const _self = this;
    if (
      _self.isDefaultAlert &&
      !_self.isDashboardCustomize &&
      _self.shouldResetDashboard
    ) {
      if (shouldConfirmReset) {_self.resetDashboardWidget();}
      else {
        _self.isDefaultAlert = !_self.isDefaultAlert;
        _self.isDashboardCustomize = !_self.isDashboardCustomize;
        _self.alertContent = null;
      }
    } else {_self.dismissModal(value, false, isClosedClicked);}
  }

  dismissModal(value?, shouldResetDashboardWidget = false, isClosedClicked = false) {
    let isConfirmClicked = false;
    if (!isClosedClicked) {isConfirmClicked = true;}
    const _self = this;
    _self.modalController.dismiss({
      dismissed: true,
      selectedDashboardWidgets: value,
      shouldResetDashboardWidgetPermission: shouldResetDashboardWidget,
      isConfirmed: isConfirmClicked
    });
  }

  confirm() {
    if (this.isOfferCreation || this.isProjectManagement || this.isViewOfferDelete || this.deleteService) {
      this.modalController.dismiss({
        isOfferCreationConfirmClose: true,
        dismissed: true,
      });
    } else {this.shouldResetDashboard ? this.close('', true) : this.close();}
  }

  // confirmPro() {
  //   if (this.isProjectManagement) {
  //     this.modalController.dismiss({
  //       isprojectManagementConfirmClose: true,
  //       dismissed: true,
  //     });
  //   }
  // }

  resetDashboard() {
    const _self = this;
    _self.shouldResetDashboard = true;
    _self.isDefaultAlert = !_self.isDefaultAlert;
    _self.isDashboardCustomize = !_self.isDashboardCustomize;
    _self.alertContent = 'Are You Sure You Want to Reset to default widgets?';
  }

  submitDashboardWidgets() {
    const _self = this;
    const selectedValue = _self.dashboardCustomizationValue.filter(
      (_val) => _val.isChecked
    );
    _self.changeWidgetSelectedValue(true);
    _self.close(selectedValue);
  }

  changeWidgetSelectedValue(isSubmit = false) {
    const _self = this;
    if (isSubmit) {_self.updateDashboardCustomizationSubjectValue(true);}
    if (isNotEmptyArray(_self.dashboardCustomizationValue)) {
      _self.dashboardCustomizationValue.forEach((_widget) => {
        const { val, isChecked } = _widget;
        if (isSubmit)
          {_self.localSettingsSrv.storeDashboardCustomizationChoice(
            val,
            isChecked
          );}
        else {
          const data =
            _self.localSettingsSrv.getDashboardCustomizationChoice(val);
          if (val == 'pt' && data == null) {_widget.isChecked = true;}
          else {_widget.isChecked = data ? data : false;}
        }
      });
    }
  }

  changeWidgetCheckedValue(item) {
    const _self = this;
    _self.isAnyValueChanged = true;
    if (isNotEmptyArray(_self.dashboardCustomizationValue)) {
      const matchedVal = _self.dashboardCustomizationValue.find(
        (_p) => _p.val === item.val
      );
      if (matchedVal) {matchedVal.isChecked = !matchedVal.isChecked;}
    }
  }

  resetDashboardWidget() {
    const _self = this;
    _self.updateDashboardCustomizationSubjectValue(true);
    _self.localSettingsSrv.resetDashboardWidget();
    _self.dismissModal('', true);
  }

  updateDashboardCustomizationSubjectValue(value = false) {
    const body = {
      isValueChanged: value,
    };
    this.sharedValue.updateDashboardCustomizationSubject(body);
  }
}
