export class GridConfig {
    constructor(
        public defaultSortColumn: string,
        public rowKey: string,
        public enableRowSelection: boolean,
        public suppressCellSelection: boolean,
        public suppressRowClickSelection: boolean,
        public isCheckAllAllowed: boolean,
        public defaultSortOrder: string,
        public rowHeight: number,
        public noDataMessage: string,
        public title: string,
        public placeholder: string,
        public buttonTitle: string,
        public isWritePermission: boolean,
        public menuType: number,
        public gridWrapperHeightClass: string,
        public sheetName: string,
        public workbookName: string,
        public isHoverRowColumnHighlight: any,
        public suppressRowHoverHighlight: any
    ) {
    }
}

