  <div class="notification-header">
    <ion-label class="title">
      {{ 'Notifications' | translate}}
    </ion-label>
    <div class="close-icon" (click)="dismissClick()">
      <img src="../../../assets/icon/cancel.svg" alt="">
    </div>
  </div>
  <ion-list class="list-view">
    <ion-item button lines='none' *ngFor="let item of this.sharedValue.finalNotification;let i = index">
      <ion-label text-wrap (click)="markAsRead(i)"> {{item.notification}} </ion-label>
    </ion-item>
  </ion-list>