import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiUrls } from '../utilities/api-urls';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private cachedMenu: any; // Define a variable to store the cached menu data

  constructor(private http: HttpClient) { }

  getMenu(userId: string): Observable<any> {
    if (this.cachedMenu) {
      // If menu data is already cached, return it as an observable
      return of(this.cachedMenu);
    } else {
      // If menu data is not cached, fetch it from the API
      return this.http.get<any>(ApiUrls.getMenu + userId).pipe(
        map((res: any) => {
          if (res && res.statusCode === 200) {
            this.cachedMenu = res.response; // Cache the menu data
            return this.cachedMenu;
          } else {
            throw new Error('Invalid response format');
          }
        }),
        catchError(error => {
          console.error('Error fetching menu items:', error);
          throw error; // Propagate the error to the caller
        })
      );
    }
  }
}
