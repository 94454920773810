import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { isNotBlank, isNotNullAndUndefined } from 'src/app/utilities/utils';
import { ShareValuesService } from 'src/app/services/sharedValues/share-values.service';

@Component({
  selector: 'app-plr-screen',
  templateUrl: './plr-screen.component.html',
  styleUrls: ['./plr-screen.component.scss'],
})
export class PlrScreenComponent implements OnInit {
  plr_value = '';
  category_value = '';

  severityList = [
    { name: 'S1 = Slight (normally reversible) injury', val: 's1' },
    { name: 'S2 = Serious (normally irreversible) injury, including death', val: 's2' }
  ];

  frequencyExposureList = [
    { name: 'F1 = Seldom to less often and/or the exposure time is short', val: 'f1' },
    { name: 'F2 = Frequent to continuous and/or the exposure time is', val: 'f2' }
  ];

  possibilityOfAvoidanceList = [
    { name: 'P1 = Possible under specific conditions', val: 'p1' },
    { name: 'P2 = Scarcely possible', val: 'p2' }
  ];

  selectedPlrList = { severity: null, freqExpo: null, posOfAvoidance: null };
  @Input() plrObj: any;
  @Input() category: any;

  constructor(public modalController: ModalController, public sharedValue: ShareValuesService, private navParams: NavParams) { }

  ngOnInit() {
    this.plrObj = this.navParams.get('plrObj');
    if (this.plrObj) {
      this.selectedPlrList.severity = this.plrObj.sValue;
      this.selectedPlrList.freqExpo = this.plrObj.fValue;
      this.selectedPlrList.posOfAvoidance = this.plrObj.pValue;
      this.calculatePlrValue();
    }
    if (this.category) {
      this.category_value = this.category;
      this.calculateCategory();
    }
    this.sharedValue.sValue = '';
    this.sharedValue.fValue = '';
    this.sharedValue.pValue = '';
  }

  close() {
    this.modalController.dismiss({});
  }

  calculatePlrValue() {
    const { severity, freqExpo, posOfAvoidance } = this.selectedPlrList;
    if (isNotBlank(severity) && isNotNullAndUndefined(severity) && isNotBlank(freqExpo) && isNotNullAndUndefined(freqExpo) && isNotBlank(posOfAvoidance) && isNotNullAndUndefined(posOfAvoidance)) {
      if (severity == 's1' && freqExpo == 'f1' && posOfAvoidance == 'p1') {this.plr_value = 'a';}
      else if (severity == 's1' && freqExpo == 'f1' && posOfAvoidance == 'p2') {this.plr_value = 'b';}
      else if (severity == 's1' && freqExpo == 'f2' && posOfAvoidance == 'p1') {this.plr_value = 'b';}
      else if (severity == 's1' && freqExpo == 'f2' && posOfAvoidance == 'p2') {this.plr_value = 'c';}
      else if (severity == 's2' && freqExpo == 'f1' && posOfAvoidance == 'p1') {this.plr_value = 'c';}
      else if (severity == 's2' && freqExpo == 'f1' && posOfAvoidance == 'p2') {this.plr_value = 'd';}
      else if (severity == 's2' && freqExpo == 'f2' && posOfAvoidance == 'p1') {this.plr_value = 'd';}
      else if (severity == 's2' && freqExpo == 'f2' && posOfAvoidance == 'p2') {this.plr_value = 'e';}
    } else if (this.plr_value) {this.plr_value = '';}
    this.sharedValue.sValue = severity;
    this.sharedValue.fValue = freqExpo;
    this.sharedValue.pValue = posOfAvoidance;
    this.calculateCategory();
  }

  calculateCategory() {
    if (this.plr_value) {
      if (this.plr_value == 'a') {this.category_value = 'B';}
      else if (this.plr_value == 'b') {this.category_value = '1';}
      else if (this.plr_value == 'c') {this.category_value = '2';}
      else if (this.plr_value == 'd') {this.category_value = '3';}
      else if (this.plr_value == 'e') {this.category_value = '4';}
    }
  }

  submitPlrValue() {
    if (this.plr_value) {
      this.modalController.dismiss({
        plr_value: this.plr_value,
        category_value: this.category_value,
        sValue: this.selectedPlrList.severity,
        fValue: this.selectedPlrList.freqExpo,
        pValue: this.selectedPlrList.posOfAvoidance
       
      });
    }
  }

}
